import { SummaryInfoModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request-summary.model';
import { Mapper } from '../../../../core/base/mapper';
import { SummaryInfoEntity } from '../pre-order-request-summary.entity';
import { mapPreOrderRequestStatus } from './pre-order-status.mapper';

export class PreOrderRequestStatusMapper extends Mapper<SummaryInfoEntity, SummaryInfoModel> {
  mapFrom(request: SummaryInfoEntity): SummaryInfoModel {
    return {
      status: mapPreOrderRequestStatus(request.status),
      numberOfRequests: request.numberOfRequests,
    };
  }

  mapTo(param: SummaryInfoModel): SummaryInfoEntity {
    throw new Error('Method not implemented.');
  }
}
