import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { OrderSummaryEntity } from '../auth/remote/entities/auth-entity';
import { CancelOrderEntity } from './entities/cancel-order.entity';
import {
  ChildOrdersFilterEntity,
  ChildOrdersResultEntity,
} from './entities/child-orders-filter.entity';
import { FailedOrdersResponseEntity } from './entities/failed-orders.entity';
import {
  OrderActivityResponseEntity,
  OrderActivityWithStatusEntity,
  OrderActivityWithStatusResponseEntity,
} from './entities/order-activity-with-status.entity';
import {
  CalculateOrderCostRequestEntity,
  CalculateOrderCostResponseEntity,
  OrderEntity,
  OrdersListEntity,
  OrderStatusListEntity,
} from './entities/order.entity';
import { OrderProductListEntity, ProductPriceOrderEntity } from './entities/product-price.entity';
import { RateOrderEntity } from './entities/rate-order.entity';
import { ReferralsEntity } from './entities/referrals.entity';
import { ORDER_APIS_ENDPOINTS } from './order-apis-endpoints';

@Injectable({
  providedIn: 'root',
})
export class OrderApisService {
  constructor(private _httpClient: HttpClient) {}

  public calculateOrderPrices(
    orderData: CalculateOrderCostRequestEntity,
  ): Observable<CalculateOrderCostResponseEntity> {
    return this._httpClient
      .post<{ data: CalculateOrderCostResponseEntity }>(
        ORDER_APIS_ENDPOINTS.CALCULATE_ORDER_COST_URL,
        orderData,
      )
      .pipe(map(({ data }) => data));
  }

  public makeOrderByCart(params: OrderEntity): Observable<{ order: { orderID: string } }> {
    return this._httpClient.post<{ order: { orderID: string } }>(
      ORDER_APIS_ENDPOINTS.ORDER_CART_URL,
      params,
    );
  }

  rateOrder(rateOrder: RateOrderEntity): Observable<void> {
    return this._httpClient.patch<void>(ORDER_APIS_ENDPOINTS.RATE_ORDER_URL, rateOrder);
  }

  getOrderActivityWithStatus(
    filterEntity: OrderActivityWithStatusEntity,
  ): Observable<OrderActivityWithStatusResponseEntity> {
    filterEntity = {
      ...filterEntity,
      pageNum: filterEntity.pageNum || 1,
      pageSize: filterEntity.pageSize || 10,
    };
    return this._httpClient.post<OrderActivityWithStatusResponseEntity>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_ACTIVITY_WITH_STATUS_URL,
      filterEntity,
    );
  }

  cancelOrder(cancelOrder: CancelOrderEntity): Observable<any> {
    return this._httpClient.patch(ORDER_APIS_ENDPOINTS.CANCEL_ORDER_URL, cancelOrder);
  }

  getOrderSummary(startDate: string, endDate: string): Observable<{ data: OrderSummaryEntity }> {
    return this._httpClient.get<{ data: OrderSummaryEntity }>(
      ORDER_APIS_ENDPOINTS.GET_MERCHANT_ORDER_SUMMARY_URL(startDate, endDate),
    );
  }

  getReferralsOrders(): Observable<ReferralsEntity> {
    return this._httpClient.get<ReferralsEntity>(ORDER_APIS_ENDPOINTS.GET_REFERRALS_ORDERS_URL);
  }

  getChildOrders(childOrdersFilter: ChildOrdersFilterEntity): Observable<ChildOrdersResultEntity> {
    const { pageNum, pageSize, ...otherFilterProperties } = childOrdersFilter;
    const params: any = {
      page: childOrdersFilter.pageNum,
      pageSize: childOrdersFilter.pageSize,
      ...otherFilterProperties,
    };

    const filteredParams = Object.keys(params)
      .filter((key) => params[key])
      .reduce((resultObject: any, key) => {
        resultObject[key] = params[key];
        return resultObject;
      }, {});

    return this._httpClient.get<ChildOrdersResultEntity>(
      ORDER_APIS_ENDPOINTS.GET_CHILD_ORDERS_URL,
      {
        params: filteredParams,
      },
    );
  }

  getOrderProductPrices(productList: OrderProductListEntity): Observable<ProductPriceOrderEntity> {
    return this._httpClient
      .post<{ data: ProductPriceOrderEntity }>(
        ORDER_APIS_ENDPOINTS.GET_PRODUCT_PRICES_URL,
        productList,
      )
      .pipe(map(({ data }) => data));
  }

  getFailedOrders(page: number, pageSize: number): Observable<FailedOrdersResponseEntity> {
    return this._httpClient
      .post<{ data: FailedOrdersResponseEntity }>(ORDER_APIS_ENDPOINTS.GET_FAILED_ORDERS_URL, {
        page,
        pageSize,
      })
      .pipe(map(({ data }) => data));
  }

  getOrdersList(filters: any): Observable<OrdersListEntity> {
    filters = {
      ...filters,
      sortBy: 'creationDate',
      sortOrder: 'descending',
    };
    const filteredParams = Object.keys(filters)
      .filter((key) => filters[key] !== undefined && filters[key] !== null)
      .reduce((resultObject: any, key) => {
        resultObject[key] = filters[key];
        return resultObject;
      }, {});
    return this._httpClient.get<OrdersListEntity>(ORDER_APIS_ENDPOINTS.GET_ORDERS_LIST_URL, {
      params: filteredParams,
    });
  }

  trackOrderActivity(
    filter: OrderActivityWithStatusEntity,
  ): Observable<OrderActivityResponseEntity> {
    return this._httpClient.get<OrderActivityResponseEntity>(
      ORDER_APIS_ENDPOINTS.TRACK_ORDER_ACTIVITY_URL(filter.filterObj.orderID),
    );
  }

  getOrderStatusList(): Observable<OrderStatusListEntity> {
    return this._httpClient.get<OrderStatusListEntity>(
      ORDER_APIS_ENDPOINTS.GET_ORDER_STATUS_LIST_URL,
    );
  }
}
