import { Mapper } from 'src/app/core/base/mapper';
import { CreateWithdrawalRequestModel } from 'src/app/core/domain/wallet/wallet.model';
import { CreateWithdrawalRequestEntity } from '../entities/wallet.entity';
import { mapWithdrawalMethods } from './withdrawal-method.mapper';

export class WithdrawalRequestMapper
  implements Mapper<CreateWithdrawalRequestModel, CreateWithdrawalRequestEntity>
{
  mapFrom(modelParams: CreateWithdrawalRequestModel): CreateWithdrawalRequestEntity {
    return {
      currency: modelParams.currency,
      amount: modelParams.amount,
      paymentMethod: mapWithdrawalMethods(modelParams.paymentMethod),
      phoneNum: modelParams.phoneNum,
      otpVerificationMode: modelParams.otpVerificationMode,
    };
  }

  mapTo(entityParams: CreateWithdrawalRequestEntity): CreateWithdrawalRequestModel {
    throw new Error('Method not implemented.');
  }
}
