import { Injectable } from '@angular/core';
import { LocalizableStringEntity } from '../entities/localizable-string-entity';

@Injectable({
  providedIn: 'root',
})
export class LocalizableStringEntityMapper {
  map(entity: LocalizableStringEntity): string {
    // TODO Check for locale to choose which one to map
    return entity.ar;
  }
}
