import { GenericObject } from '@mongez/reinforcements';
import { routerAtom } from './navigation';

export const queryString = {
  get(name: string): string | null {
    const searchParams = new URLSearchParams(window.location.search);

    return searchParams.get(name);
  },
  all(): GenericObject {
    // get all query string parameters as an object
    const searchParams = new URLSearchParams(window.location.search);

    const params: GenericObject = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  },
  clear(): void {
    const router = routerAtom.get('router');
    const activatedRoute = routerAtom.get('activatedRoute');

    const url = router
      .createUrlTree([], { relativeTo: activatedRoute, queryParams: {} })
      .toString();

    routerAtom.get('location').go(url);
  },
};
