import { Observable } from 'rxjs';
import {
  ProvinceModel,
  ProvinceZoneDistrictFilterModel,
  ProvinceZoneDistrictModel,
  ProvinceZoneFilterModel,
  ProvinceZoneModel,
} from '../domain/provinces.model';

export abstract class ProvinceRepository {
  abstract getAllProvinceZoneDistrictList(country: string): Observable<ProvinceModel[]>;
  abstract getProvinceZoneList(
    zoneFilter: ProvinceZoneFilterModel,
  ): Observable<ProvinceZoneModel[]>;
  abstract getProvinceZoneDistrictList(
    distrcitFilter: ProvinceZoneDistrictFilterModel,
  ): Observable<ProvinceZoneDistrictModel[]>;
}
