import { NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { getCopyrightDateUtility } from 'src/app/presentation/shared/utilities/get-copyright-date.utility';
import { LandingEventsService } from '../../containers/logged-out-layout/services/landing-events.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, NgFor, TranslateModule],
})
export class FooterComponent implements OnInit {
  public copyrightYear: string;

  public copyrightLabel: string;

  public assetsFolder: string;

  public socialLinks: Array<{
    link: string;
    alt: string;
    iconURL: string;
    platform: string;
  }>;

  public socialsLinkInstructionalText: string;

  constructor(
    private _landingEventsService: LandingEventsService,
    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    this.copyrightLabel = 'LANDING.FOOTER.COPYRIGHT';
    this.assetsFolder = '/assets/img/footer-icons/';
    this.socialLinks = [
      {
        link: 'https://www.instagram.com/taagercom/?hl=en',
        alt: 'taager-instagram',
        iconURL: 'social-instagram.svg',
        platform: 'Instagram',
      },
      {
        link: 'https://www.youtube.com/channel/UCukMlDP68rQwho-P6WsuJMw',
        alt: 'taager-youtube',
        iconURL: 'social-youtube.svg',
        platform: 'Youtube',
      },
      {
        link: 'https://www.facebook.com/Taagercom/',
        alt: 'taager-facebook',
        iconURL: 'social-facebook.svg',
        platform: 'Facebook',
      },
      {
        link: 'https://www.linkedin.com/company/taager',
        alt: 'taager-linkedin',
        iconURL: 'social-linkedin.svg',
        platform: 'LinkedIn',
      },
    ];
    this.socialsLinkInstructionalText = 'LANDING.FOOTER.TEXT_2';
  }

  ngOnInit(): void {
    this.copyrightYear = getCopyrightDateUtility();
  }

  onSocialLinkClicked(platform: string): void {
    this._landingEventsService.sendLandingMixpanelEvent('social_media_button_click', {
      $platform: platform,
    });
    this._doLogGtagSocialMediaPlatformClicked(platform);
  }

  private _doLogGtagSocialMediaPlatformClicked(platform: string): void {
    const payload = {
      'Social Platform': platform,
    };
    this._sharedLogGtagEventMethod('social_media_buttons_click', payload);
  }

  onHelpCenterClick(): void {
    this._landingEventsService.sendLandingMixpanelEvent('help_center_click');
    this._doLogGtagOnHelpCenterClick();
  }

  private _doLogGtagOnHelpCenterClick(): void {
    const payload = {
      'Button Location': 'footer',
    };
    this._sharedLogGtagEventMethod('help_center_click', payload);
  }

  onCareersClick(): void {
    this._landingEventsService.sendLandingMixpanelEvent('careers_click');
    this._doLogGtagOnCareersClick();
  }

  private _doLogGtagOnCareersClick(): void {
    const payload = {
      'Button Location': 'footer',
    };
    this._sharedLogGtagEventMethod('careers_click', payload);
  }

  onTermsAndCondtionsClick(): void {
    this._landingEventsService.sendLandingMixpanelEvent('terms-conditions_click');
    this._doLogGtagOnTermsAndConditionsClick();
  }

  private _doLogGtagOnTermsAndConditionsClick(): void {
    const payload = {
      'Button Location': 'footer',
    };
    this._sharedLogGtagEventMethod('terms_click', payload);
  }

  onAndroidDowloadNowClicked(): void {
    this._landingEventsService.sendLandingMixpanelEvent('android_download_now_button_click', {
      $button_location: 'footer',
    });
    this._doLogGtagAndroidDownloadNowEvent();
  }

  private _doLogGtagAndroidDownloadNowEvent(): void {
    const payload = {
      'Button Location': 'footer',
    };
    this._sharedLogGtagEventMethod('download_app_android_click', payload);
  }

  private _sharedLogGtagEventMethod(eventName: string, payload: any): void {
    this._landingEventsService.sendGtagMixpanelEvent(eventName, payload);
  }
}
