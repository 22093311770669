import { EventSubscription } from '@mongez/events';
import { Subscription } from 'rxjs';

export abstract class SubscribableComponent {
  /**
   * Subscribed events
   */
  protected events: EventSubscription[] = [];

  /**
   * Subscription to unsubscribe from all subscriptions when the component is destroyed
   */
  protected subscription = new Subscription();

  /**
   * Subscribe the given event
   */
  public subscribe(event: EventSubscription | Subscription): void {
    if (event instanceof Subscription) {
      this.subscription.add(event);
      return;
    }

    this.events.push(event);
  }

  /**
   * Unsubscribe all events
   */
  public unsubscribeAll(): void {
    this.subscription.unsubscribe();
    this.events.forEach((event) => event.unsubscribe());
  }
}
