import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DynamicIncentiveProgramRepository } from '../../repositories/dynamic-incentive-program.repository';

export class DeclineDynamicIncentiveProgramUseCase implements UseCase<string, void> {
  constructor(private _repository: DynamicIncentiveProgramRepository) {}

  execute(id: string): Observable<void> {
    return this._repository.declineDynamicIncentiveProgram(id);
  }
}
