import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class RejectPreOrderDownPaymentUseCase implements UseCase<string, any> {
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(requestId: string): Observable<any> {
    return this._bulkPreOrderRepository.rejectPreOrderDownPayment(requestId);
  }
}
