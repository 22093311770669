import type { GenericObject } from '@mongez/reinforcements';

export function objectToFormData<Data extends GenericObject>(data: Data): FormData {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
}
