import { UserFeaturesRepository } from '../../data/repositories/user-features.repository';
import { ListFirebaseRemoteConfigFeaturesUseCase, ListUserFeaturesUseCase } from '../types';

export const listUserFeaturesUseCase: ListUserFeaturesUseCase = async (
  repository: UserFeaturesRepository,
) => {
  return repository.list();
};

export const listFirebaseRemoteConfigFeaturesUseCase: ListFirebaseRemoteConfigFeaturesUseCase =
  async (repository: UserFeaturesRepository) => {
    return repository.list();
  };
