import { Mapper } from 'src/app/core/base/mapper';
import { WalletTransactionHistoryModel } from 'src/app/core/domain/wallet/wallet.model';
import { WalletTransactionHistoryEntity } from '../entities/wallet.entity';

export class WalletTransactionHistoryMapper
  implements Mapper<WalletTransactionHistoryEntity, WalletTransactionHistoryModel>
{
  mapFrom(param: WalletTransactionHistoryEntity): WalletTransactionHistoryModel {
    return {
      transactions: param.transactions.map((transaction) => ({
        amount: transaction.amount,
        category: transaction.category,
        currency: transaction.currency,
        details: {
          type: transaction.details.type,
          typeId: transaction.details.typeId,
          toStatus: transaction.details.toStatus,
          fromStatus: transaction.details.fromStatus,
        },
        notes: transaction.notes,
        transactionDate: transaction.transactionDate,
      })),
      count: param.count,
    };
  }

  mapTo(param: WalletTransactionHistoryModel): WalletTransactionHistoryEntity {
    return {
      transactions: param.transactions.map((transaction) => ({
        amount: transaction.amount,
        category: transaction.category,
        currency: transaction.currency,
        details: {
          type: transaction.details.type,
          typeId: transaction.details.typeId,
          toStatus: transaction.details.toStatus,
          fromStatus: transaction.details.fromStatus,
        },
        notes: transaction.notes,
        transactionDate: transaction.transactionDate,
      })),
      count: param.count,
    };
  }
}
