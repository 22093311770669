import { ChangeDetectorRef, Directive, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { dialogAtom, DialogComponent, DialogId, DialogOptions } from '@presentation/shared/dialog';
import { BasePresenter, EventWithType } from './base.presenter';
import { LocalizedComponent } from './localized.component';

export type SideEffectWithType = {
  type: string;
  [key: string]: any;
};

export type SideEffectsHandler<SideEffect extends SideEffectWithType> = Record<
  SideEffect['type'],
  (sideEffect: SideEffect) => void
>;

/**
 * A Component is part of the view. It should be used to monitor and prepare the HTML, and own the Presenter
 * Ideally a component should have no logic. It should blindly pass everything to the Presenter and obey to Presenter's changes / actions
 * The Component is an Angular Component
 */
@Directive()
export abstract class BaseComponent<
    Presenter extends BasePresenter<ViewState, ViewEvent, SideEffect>,
    ViewState,
    ViewEvent extends EventWithType = EventWithType,
    SideEffect = {},
  >
  extends LocalizedComponent
  implements OnInit
{
  /**
   * Change detector reference to manually trigger change detection
   */
  protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * Component view state object
   */
  public viewState: ViewState = {} as ViewState;

  /**
   * The presenter that will be used by the component
   */
  public presenter: Presenter;

  /**
   * Easier way to open the dialog
   */
  protected $dialog?: MatDialog;

  public get dialogModal(): MatDialog {
    if (this.$dialog) {
      return this.$dialog;
    }

    this.$dialog = inject(MatDialog);

    return this.$dialog;
  }

  /**
   * A shorthand way to handle side effects so we don'e need to use the onSideEffect method
   */
  // protected sideEffects?: SideEffectsHandler<SideEffect>;

  /**
   * Angular OnInit lifecycle hook
   */
  public ngOnInit(): void {
    this.onViewStateChange();
    this.listenToPresenterSideEffectsChanges();
    this.onInit();
  }

  /**
   * Subscribe to the view state changes
   */
  private onViewStateChange(): void {
    this.subscription.add(
      this.presenter.onViewStateChange().subscribe((viewState) => {
        this.viewState = viewState;
      }),
    );
  }

  /**
   * Subscribe to the side effect changes
   */
  private listenToPresenterSideEffectsChanges(): void {
    this.subscription.add(
      this.presenter.onSideEffect().subscribe((sideEffect) => {
        this.onSideEffect(sideEffect);
      }),
    );
  }

  /**
   * Function to be called when a side effect is received
   * @param sideEffect The side effect received
   */
  public onSideEffect(sideEffect: SideEffect): void {
    // if (!this.sideEffects) return;
    // const handler = this.sideEffects[sideEffect.type as keyof SideEffectsHandler<SideEffect>];
    // if (!handler) {
    //   throw new Error(`No handler found for side effect type: ${sideEffect.type}`);
    // }
    // // make sure the handler is called with the right context
    // // in this case it will be our component instance
    // handler.call(this, sideEffect);
  }

  /**
   * Angular OnDestroy lifecycle hook
   */
  public ngOnDestroy(): void {
    this.presenter.destroy();
    this.unsubscribeAll();
    this.onDestroy();
  }

  /**
   * Open a dialog
   */
  public openDialog(component: DialogComponent, options: DialogOptions): MatDialogRef<any> {
    const ref = dialogAtom.open(component, options);

    return ref;
  }

  /**
   * Close the dialog of the given id
   */
  public closeDialog(dialogId: DialogId): void {
    dialogAtom.close(dialogId);
  }

  /**
   * A callback to be called on init
   * This should be a replacement for `ngOnInit` method so that the base component can handle it
   */
  protected onInit(): void {
    //
  }

  /**
   * A callback to be called on destroy
   */
  protected onDestroy(): void {
    //
  }
}
