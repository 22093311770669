import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AvailabilityFeatures } from '../../domain/market-availability.model';
import { MarketAvailabilityRepository } from '../../repositories/market-availability.repository';

export class IsFeatureEnabledUseCase implements UseCase<AvailabilityFeatures, boolean> {
  constructor(private _marketAvailabilityRepository: MarketAvailabilityRepository) {}

  execute(feature: AvailabilityFeatures): Observable<boolean> {
    return this._marketAvailabilityRepository.isFeatureEnabled(feature);
  }
}
