import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { QuestionnaireBannerRepository } from '../repositories/questionnaire-banner.repository';

export class DeclineQuestionnaireUseCase implements UseCase<string, void> {
  constructor(private bannerRepository: QuestionnaireBannerRepository) {}

  execute(questionnaireName: string): Observable<void> {
    return this.bannerRepository.declineQuestionnaire(questionnaireName);
  }
}
