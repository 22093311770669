import { Mapper } from 'src/app/core/base/mapper';
import { ReferralsModel } from 'src/app/core/domain/referrals.model';
import { ReferralsEntity } from '../../order/entities/referrals.entity';

export class ReferralsMapper extends Mapper<ReferralsEntity, ReferralsModel> {
  mapFrom(param: ReferralsEntity): ReferralsModel {
    return {
      countUsers: param.countUsers,
      countOrders: param.countOrders,
    };
  }

  mapTo(param: ReferralsModel): ReferralsEntity {
    return {
      countUsers: param.countUsers,
      countOrders: param.countOrders,
    };
  }
}
