import type {
  CountriesRepositoryContract,
  ListCountriesUseCase,
  UpdateSelectedMarketUseCase,
} from '../types';

/**
 * List countries use case
 * This use case is responsible for listing all countries that merchant can ship to
 */
export const listCountriesUseCase: ListCountriesUseCase = async (
  countriesRepository: CountriesRepositoryContract,
) => {
  return countriesRepository.list();
};

export const updateSelectedMarketUseCase: UpdateSelectedMarketUseCase = async (
  countriesRepository: CountriesRepositoryContract,
  iso3: string,
) => {
  return countriesRepository.updateSelectedMarketUseCase(iso3);
};
