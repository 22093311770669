/**
 * Okay, we need a way to know the type of users who we have in our project. This is where we get to introduce this service.
 * It will basically tell us the vefirication state of the user among other things such as the current step in the verification
 * that the user is at with our system.
 *
 * Ideally, at the time of writing this documentation, we have two types of users:
 *
 * 1. Legacy.
 * 2. New Users.
 *
 * Who are legacy users?
 *
 * Legacy users are the original users of Taager, who joined Taager before we had the signupv2 option. So, we need to make sure that
 * we have similar information as that of our new users, that is why we need to gently and gradually ask these users to verify their
 * information.
 *
 * Who are New Users?
 *
 * New Users are the users who joined Taager using signupv2 flow. Well, if they joined using signupv2 flow, then why do we mention them
 * here? Well, some of the users had skipped phone number verification, and since we need this information, that is why we are including
 * them in this flow.
 *
 * How this service will work.
 *
 * It will accept the user object's token value. Then it will decode it to retrieve the needed information, which is the verification state.
 * And now, let's talk about verification. What verification state will we be getting from the token?
 *
 * actualVerificationState: { registrationCompleted: boolean, phoneNumberVerified: boolean, merchantDataVerified: boolean, }
 *
 * In this case, the most important attributes here are:
 *
 * 1. registrationCompleted -> this can be true or false. If it is true, then the user will be able to do anything in the system as usual
 * BUT that does not mean that they can be able to make withdrawals.
 * 2. phoneNumberVerified -> this will let us know whether the user will see the otp screen or not. If false, then they will see it.
 * 3. merchantDataVerified -> this will decide whether the user will see the details screen.
 *
 * Now, why are the above important? They will help us in knowing the abilities that the received user can have in the system.
 *
 * So, we will have a couple of NextStepForTheUser, and these are:
 *
 * 1. details -> user will need to fill in their details for them to proceed IFF merchantDataVerified from above is false.
 * 2. otp -> user will need to verify their phone number, by resolving their case with the OTP screen IFF phoneNumberVerified from above is false
 * 3. re-login-user -> users will need to login once again. Why is this the case, well, some of the users
 * may have an outdated JWT token actualVerificationState information. Because of this, we need to gracefully
 * logout the user so that they can login once again and have their JWT token status updated. Again, this is an
 * edge case.
 */

import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { GenericObject } from '@mongez/reinforcements';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { NEXT_STEP_FOR_THE_USER, USER_TYPES } from '../constants';
import { EMAIL_OTP_WITHDRAW } from '../constants/feature-flags';
import { featureAttributeAssign } from '../utilities/feature-attribute-assign.utility';
import { JWTDecoder } from '../utilities/jwt-decoder.utility';
import { LocalStorageService } from './local-storage.service';

export interface EvaluatedUserAbilities {
  canMakeWithdrawal: boolean;
  nextStepForTheUser: NEXT_STEP_FOR_THE_USER;
}

interface RequiredUserData {
  registrationCompleted: boolean;
  phoneNumberVerified: boolean;
  merchantDataVerified: boolean;
  emailVerified: boolean;
}

/**
 *
 * @param verificationData
 * @returns
 *
 * Uses the verification data to analyze whether the user can make a withdrawal.
 */
const canUserMakeWithdrawal = (
  verificationData: RequiredUserData,
  isEmailOTPEnabled?: boolean,
): boolean => {
  const allowUserToMakeWithdrawal = isEmailOTPEnabled
    ? verificationData.phoneNumberVerified &&
      verificationData.emailVerified &&
      verificationData.merchantDataVerified
    : verificationData.phoneNumberVerified && verificationData.merchantDataVerified;
  return allowUserToMakeWithdrawal;
};

const evaluateNextStepForTheUser = (
  verificationData: RequiredUserData,
  userType: USER_TYPES,
  isEmailOTPEnabled?: boolean,
): NEXT_STEP_FOR_THE_USER => {
  if (userType === 'legacy') {
    if (!verificationData.merchantDataVerified) {
      return 'details';
    }
    if (
      !verificationData.phoneNumberVerified ||
      (isEmailOTPEnabled && !verificationData.emailVerified)
    ) {
      return 'otp';
    }
  } else {
    if (
      !verificationData.phoneNumberVerified ||
      (isEmailOTPEnabled && !verificationData.emailVerified)
    ) {
      return 'otp';
    }
    if (!verificationData.merchantDataVerified) {
      return 'details';
    }
  }
  return 'products';
};

/**
 *
 * @param verificationData
 * @returns
 *
 * Does an analysis on the Legacy User
 */
const analyzeLegacyUser = (
  verificationData: RequiredUserData,
  isEmailOTPEnabled?: boolean,
): EvaluatedUserAbilities => {
  return {
    canMakeWithdrawal: canUserMakeWithdrawal(verificationData, isEmailOTPEnabled),
    nextStepForTheUser: evaluateNextStepForTheUser(verificationData, 'legacy', isEmailOTPEnabled),
  };
};

/**
 *
 * @param verificationData
 * @returns
 *
 * Does an analysis on the New User
 */
const analyzeNewUser = (
  verificationData: RequiredUserData,
  isEmailOTPEnabled?: boolean,
): EvaluatedUserAbilities => {
  return {
    canMakeWithdrawal: canUserMakeWithdrawal(verificationData, isEmailOTPEnabled),
    nextStepForTheUser: evaluateNextStepForTheUser(verificationData, 'new', isEmailOTPEnabled),
  };
};

@Injectable({
  providedIn: 'root',
})
export class UserResolutionService {
  constructor(
    private _localStorageService: LocalStorageService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {}

  public isEmailOTPWithdrawEnabled = false;

  public analyzeUserToken(): EvaluatedUserAbilities {
    this._shouldEnableEmailOTPOnWithdrawal();
    const decodedToken: GenericObject = JWTDecoder(user.accessToken)!;
    let verdict: EvaluatedUserAbilities = {
      canMakeWithdrawal: false,
      nextStepForTheUser: 'reLogin',
    };
    if (decodedToken) {
      const { actualVerificationState } = decodedToken.user;
      if (actualVerificationState) {
        if (
          actualVerificationState.registrationCompleted &&
          !actualVerificationState.merchantDataVerified
        ) {
          verdict = analyzeLegacyUser(actualVerificationState, this.isEmailOTPWithdrawEnabled);
        } else {
          verdict = analyzeNewUser(actualVerificationState, this.isEmailOTPWithdrawEnabled);
        }
      }
    }
    return verdict;
  }

  private _shouldEnableEmailOTPOnWithdrawal(): void {
    this._getFeatureAttributeUseCase.execute(EMAIL_OTP_WITHDRAW).subscribe({
      next: (attribute) => {
        this.isEmailOTPWithdrawEnabled = featureAttributeAssign(attribute, user.id);
      },
    });
  }
}
