import { BidiModule } from '@angular/cdk/bidi';
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { hardNavigate } from '@presentation/shared/router';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  FEATURE_FLAGS,
  PRE_ORDER_USER_FEATURE,
  PROFILE_URL,
} from 'src/app/presentation/shared/constants';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import {
  CUSTOM_CHECKOUT_BUTTON,
  NEW_INCENTIVE_BUTTON,
  PRE_ORDER_REQUESTS_BUTTON,
  PROFILE_NAVIGATION_TABS,
  SIGNOUT_BUTTON,
} from 'src/app/presentation/shared/constants/header-tabs';
import { HeaderTab } from 'src/app/presentation/shared/interfaces/header.interface';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';

@Component({
  selector: 'app-account-dropdown',
  templateUrl: './account-dropdown.component.html',
  styleUrls: ['./account-dropdown.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, BidiModule, NgFor, NgClass, TranslateModule, AsyncPipe],
})
export class AccountDropdownComponent implements OnInit, OnDestroy {
  @Input() userName: string;

  tabs: HeaderTab[];

  isLTR: boolean;

  darkModeClass$: Observable<string>;

  private _languageChangeSubscription: Subscription;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private router: Router,
    private _logoutUseCase: LogoutUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _translateService: TranslateService,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _themeService: ThemeService,
    private _multitenancyService: MultitenancyService,
  ) {
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  ngOnInit(): void {
    this.tabs = PROFILE_NAVIGATION_TABS;
    this.updateTabsBasedOnUserFeatures();
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (value: LangChangeEvent) => {
        this.isLTR = value.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  updateTabsBasedOnUserFeatures(): void {
    combineLatest({
      preOrder: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3),
      newIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.INCENTIVE_PROGRAM_V3),
      ksaIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.KSA_INCENTIVE_PROGRAM),
      productCheckout: this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_CUSTOM_CHECKOUT),
    }).subscribe({
      next: ({ preOrder, newIncentiveProgram, ksaIncentiveProgram, productCheckout }) => {
        if (
          newIncentiveProgram &&
          (country.is('EGY') || (ksaIncentiveProgram && country.is('KSA')))
        ) {
          this.tabs = [
            ...this.tabs.filter((tab) => tab.tabName !== SIGNOUT_BUTTON.tabName),
            NEW_INCENTIVE_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + country.code.toLowerCase(),
        );
        if (preOrder && userHasPreOrder) {
          this.tabs = [
            ...this.tabs.filter((tab) => tab.tabName !== SIGNOUT_BUTTON.tabName),
            PRE_ORDER_REQUESTS_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        const shouldShowCustomCheckout = featureAttributeAssign(
          productCheckout,
          user.id,
          this._multitenancyService.getCurrentCountry().isoCode3,
        );
        if (shouldShowCustomCheckout) {
          this.tabs = [
            ...this.tabs.filter((tab) => tab.tabName !== SIGNOUT_BUTTON.tabName),
            CUSTOM_CHECKOUT_BUTTON,
            SIGNOUT_BUTTON,
          ];
        }
        this.filterDuplicateTabs();
      },
    });
  }

  onMenuItemClick(tab: HeaderTab): void {
    this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName! });

    if (tab.tabName === SIGNOUT_BUTTON.tabName) {
      this._logoutUseCase.execute();
    } else if (tab.tabName === PROFILE_NAVIGATION_TABS[0].tabName) {
      hardNavigate(PROFILE_URL);
    } else {
      this.router.navigate(tab.routerLink!);
    }
  }

  filterDuplicateTabs(): void {
    const uniqueTabLinks: any = [];
    this.tabs = this.tabs.filter((element) => {
      const isDuplicate = uniqueTabLinks.includes(element.routerLink!);
      if (!isDuplicate) {
        uniqueTabLinks.push(element.routerLink!);
        return true;
      }
      return false;
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
