import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetPreorderableProductsUseCase
  implements
    UseCase<
      {
        page?: number;
        pageSize?: number;
        sortBy?: string;
        commercialCategoryId?: string;
      },
      any
    >
{
  constructor(private productRepository: ProductsRepository) {}

  execute(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    commercialCategoryId?: string;
  }): Observable<any> {
    return this.productRepository.getBulkPreorderableProducts(params);
  }
}
