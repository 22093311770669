import { environment } from 'src/environments/environment';

export const DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS = {
  GET_DYNAMIC_INCENTIVE_PROGRAM: `${environment.BACKEND_URL}api/engagement/dynamic-incentives/challenges`,
  GET_DYNAMIC_INCENTIVE_PROGRAM_HISTORY: `${environment.BACKEND_URL}api/engagement/dynamic-incentives/challenges/history`,
  ACCEPT_DYNAMIC_INCENTIVE_PROGRAM: (merchantChallengeBusinessId: string): string =>
    `${environment.BACKEND_URL}api/engagement/dynamic-incentives/challenges/${merchantChallengeBusinessId}/accept`,
  DECLINE_DYNAMIC_INCENTIVE_PROGRAM: (merchantChallengeBusinessId: string): string =>
    `${environment.BACKEND_URL}api/engagement/dynamic-incentives/challenges/${merchantChallengeBusinessId}/decline`,
  FEEDBACK_DYNAMIC_INCENTIVE_PROGRAM: (merchantChallengeBusinessId: string): string =>
    `${environment.BACKEND_URL}api/engagement/dynamic-incentives/challenges/${merchantChallengeBusinessId}/feedback`,
};
