import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FAQEntity } from './faq.entity';
import { LOYALTY_PROGRAM_APIS_ENDPOINTS } from './loyalty-program-apis-endpoints';
import { FAQsLocalDataSource } from './loyalty-program-faqs.data-source';
import { MerchantLoyaltyProgressEntity, TierEntity } from './loyalty-program.entity';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramApisService {
  constructor(private _fAQsLocalDataSource: FAQsLocalDataSource, private _http: HttpClient) {}

  getTieringData(): Observable<TierEntity[]> {
    return this._http.get<TierEntity[]>(LOYALTY_PROGRAM_APIS_ENDPOINTS.GET_LOYALTY_TIERS);
  }

  getProgress(): Observable<MerchantLoyaltyProgressEntity> {
    return this._http.get<MerchantLoyaltyProgressEntity>(
      LOYALTY_PROGRAM_APIS_ENDPOINTS.GET_MERCHANT_LOYALTY_PROGRESS,
    );
  }

  getFAQssData(): Observable<FAQEntity[]> {
    return of(this._fAQsLocalDataSource.getFAQssData());
  }
}
