import { UseCase } from '../../base/use-case';
import { PhoneDataModel } from '../../domain/auth/auth.model';
import { UserRepository } from '../../repositories/user.repository';

export class GetPhoneDataUseCase implements UseCase<void, PhoneDataModel> {
  constructor(private _userRepository: UserRepository) {}

  execute(params: void): PhoneDataModel {
    return this._userRepository.getPhoneData();
  }
}
