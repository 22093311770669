/**
 * As a user, for me to be able to access shipping info I must:
 *
 * - be logged in
 * - be fully signed up
 * - be already onboarded
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import { AUTH_URL, ONBOARDING_URL, REGISTER_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { loggedInFullySignedUpOnboardedMixinResolver } from './shared';

export const shippingInfoResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  const userResolutionService = inject(UserResolutionService);
  return loggedInFullySignedUpOnboardedMixinResolver({
    router,
    localStorageService,
    userRegistrationStepUtility,
    getRegisterTokenUseCase,
    getOnboardingFeatureUseCase,
    isUserEligibleForOnboardingUseCase,
    getQuestionnaireEligibilityUseCase,
    onboardingStoryGuideStateManager,
    userResolutionService,
    nextPath: {
      ifNotLoggedIn: AUTH_URL,
      ifNotFullySignedUp: REGISTER_URL,
      ifNotOnboarded: ONBOARDING_URL,
    },
  });
};
