import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryModel } from 'src/app/core/domain/country.model';
import { CountriesRepository } from 'src/app/core/repositories/countries.repository';
import { CountriesApisService } from './countries-apis.service';

@Injectable({
  providedIn: 'root',
})
export class CountryImplementationRepository extends CountriesRepository {
  constructor(private _countriesApisService: CountriesApisService) {
    super();
  }

  getCountries(): Observable<CountryModel[]> {
    return this._countriesApisService.getCountries();
  }

  getCountryVATByIsoCode3(isoCode3: string): Observable<number> {
    return this._countriesApisService.getCountryVATByIsoCode3(isoCode3);
  }
}
