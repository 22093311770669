import { Mapper } from 'src/app/core/base/mapper';
import { CommercialSubCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { CommercialSubCategoryEntity } from '../entities/commercial-category-entity';

export class CommercialSubCategoryRepositoryMapper extends Mapper<
  CommercialSubCategoryEntity,
  CommercialSubCategoryModel
> {
  mapFrom(param: CommercialSubCategoryEntity): CommercialSubCategoryModel {
    if (param) {
      const commercialSubCategoryMapper = new CommercialSubCategoryRepositoryMapper();
      return {
        id: param.categoryId,
        name: {
          arabicName: param.arabicName,
          englishName: param.englishName,
        },
        parentId: param.parentId,
        children: param.children?.map(commercialSubCategoryMapper.mapFrom) || [],
        featured: param.featured,
        icon: param.icon,
      };
    }
    return {} as CommercialSubCategoryModel;
  }

  mapTo(param: CommercialSubCategoryModel): CommercialSubCategoryEntity {
    return {
      categoryId: param.id,
      arabicName: param.name.arabicName,
      englishName: param.name.englishName,
      parentId: param.parentId,
      featured: param.featured,
      icon: param.icon,
    };
  }
}
