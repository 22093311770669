import { Observable } from 'rxjs';
import { QuestionnaireModel, QuestionnaireSubmissionModel } from '../domain/questionnaire.model';

export abstract class QuestionnaireRepository {
  abstract getQuestionnaireQuestions(questionnaireName: string): Observable<QuestionnaireModel>;
  abstract submitQuestionnaire(params: {
    questionnaireName: string;
    data: QuestionnaireSubmissionModel;
  }): Observable<{ status: string }>;
}
