import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import * as Merchant from '@taager-experience-shared/merchant';

import SessionIdProvider = Merchant.com.taager.experience.api.configuration.SessionIdProvider;
import CountryIdProvider = Merchant.com.taager.experience.api.configuration.CountryIdProvider;

@Injectable({
  providedIn: 'root',
})
export class ExperienceConfigurationHandler implements SessionIdProvider, CountryIdProvider {
  public provideSessionId(): string | null {
    return user.uiSessionKey;
  }

  public provideCountryId(): string | null {
    return country.code;
  }
}
