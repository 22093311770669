import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { IntlBankTransferModel } from '../../domain/payment/intl-bank-transfer.model';
import { WalletRepository } from '../../repositories/wallet.repository';

/**
 * @deprecated Use CreateSecureIntlBankTransferUseCase to require OTP during withdrawal request creation
 */
export class WithdrawIntlBankTransferUseCase
  implements UseCase<IntlBankTransferModel, boolean | Error>
{
  constructor(private walletRepository: WalletRepository) {}

  execute(params: IntlBankTransferModel): Observable<any | Error> {
    return this.walletRepository.withdrawViaIntlBankTransfer(params);
  }
}
