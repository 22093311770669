import { Injectable } from '@angular/core';
import { concatMap, finalize, from, map, Observable } from 'rxjs';
import { CartModel, CartProductUpdateModel, CartUpdateModel } from 'src/app/core/domain/cart.model';
import { CartRepository } from 'src/app/core/repositories/cart.repository';
import { SharedService } from 'src/app/presentation/shared/services/shared.service';
import { CartApisService } from './cart-apis.service';
import { CartProductUpdateDataMapper } from './mappers/cart-product-update-data.mapper';
import { UserCartMapper } from './mappers/cart-repository.mapper';
import { CartUpdateDataMapper } from './mappers/cart-update.mapper';

@Injectable({
  providedIn: 'root',
})
export class CartRepositoryImplementation implements CartRepository {
  public userCartMapper = new UserCartMapper();

  public cartProductUpdateDataMapper = new CartProductUpdateDataMapper();

  public cartUpdateDataMapper = new CartUpdateDataMapper();

  constructor(private cartApisService: CartApisService, private _sharedService: SharedService) {}

  getUserCart(): Observable<CartModel> {
    return this.cartApisService
      .getUserCart()
      .pipe(map((cart) => this.userCartMapper.mapFrom(cart)));
  }

  removeProductFromCart(id: string): Observable<void> {
    return this.cartApisService.removeProductFromCart(id).pipe(
      finalize(() => {
        this._sharedService.emitshoppingItemCountChanged();
      }),
    );
  }

  updateProductInCart(params: CartProductUpdateModel): Observable<void> {
    return this.cartApisService
      .updateProductInCart(this.cartProductUpdateDataMapper.mapTo(params))
      .pipe(
        finalize(() => {
          this._sharedService.emitshoppingItemCountChanged();
        }),
      );
  }

  updateCart(params: CartUpdateModel): Observable<void> {
    return this.cartApisService.updateCart(this.cartUpdateDataMapper.mapTo(params)).pipe(
      finalize(() => {
        this._sharedService.emitshoppingItemCountChanged();
      }),
    );
  }

  addToCart(params: { productId: string; quantity: number }): Observable<void> {
    return this.cartApisService.addToCart(params).pipe(
      finalize(() => {
        this._sharedService.emitshoppingItemCountChanged();
      }),
    );
  }

  emptyCart(productIds: string[]): Observable<void> {
    const requests = productIds.map((productId) =>
      this.cartApisService.removeProductFromCart(productId),
    );
    return from(requests).pipe(concatMap((request) => request));
  }
}
