import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { CountryModel } from '../domain/country.model';
import { CountriesRepository } from '../repositories/countries.repository';

export class GetCountriesUseCase implements UseCase<void, Observable<CountryModel[]>> {
  constructor(private _countriesRepository: CountriesRepository) {}

  execute(): Observable<CountryModel[]> {
    return this._countriesRepository.getCountries();
  }
}
