import { environment } from '../../../environments/environment';

export const API_ENDPOINTS = {
  LINK_MERCHANT_STORE: `${environment.BACKEND_URL}api/stores`,
  UNLINK_MERCHANT_STORE: (storeId: string): string =>
    `${environment.BACKEND_URL}api/stores/${storeId}`,
  ADD_PRODUCT_TO_STORE: (storeId: string, productId: string): string =>
    `${environment.BACKEND_URL}api/stores/${storeId}/products/${productId}`,
  GET_USERS_STORES: `${environment.BACKEND_URL}api/users/stores`,
  GENERATE_API_KEY: `${environment.BACKEND_URL}api/api-keys/generate`,
  GET_API_KEYS: `${environment.BACKEND_URL}api/api-keys`,
};
