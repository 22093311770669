/**
 * Why we have this service?
 *  - To know which overlay type we will show
 *      - overlayType: pleaseVerify | success | error | noOverlay
 *      - setOverlayType(overlayType)
 *      - getOverlayType() --> to access it from the component wherever we wanna use the service
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OVERLAY_TYPES } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class SharedOverlayService {
  private _overlayType$: BehaviorSubject<OVERLAY_TYPES> = new BehaviorSubject<OVERLAY_TYPES>(
    'noOverlay',
  );

  public setOverlayType(overlayType: OVERLAY_TYPES): void {
    this._overlayType$.next(overlayType);
  }

  public getOverlayType(): Observable<OVERLAY_TYPES> {
    return this._overlayType$;
  }
}
