import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { CESQuestionModel, QuestionPosition, SubmittedCESQuestion } from '../../interfaces';

@Component({ template: '' })
export class CESQuestionTypesCore {
  @Input() position: QuestionPosition;

  @Input() blueprint: CESQuestionModel;

  @Input() currentAnswer?: any;

  @Output() public submitAnswer$: EventEmitter<SubmittedCESQuestion> = new EventEmitter();

  public questionForm: FormGroup;

  public assetsPath: string = 'assets/img/customer-effort-support/';

  public controlName: string;

  public siteTranslateService: SiteTranslateService = inject(SiteTranslateService);

  public instantiateForm(controlName: string): void {
    const validators = this.createValidators();
    this.questionForm = new FormGroup<{ [controlName: string]: FormControl }>({
      [controlName]: new FormControl(null, validators),
    });
  }

  public optionallyPatchForm(answer: { [field: string]: any }): void {
    if (Object.keys(answer).length === 0) {
      return;
    }
    this.questionForm.patchValue(answer);
    this.questionForm.markAsDirty();
  }

  public createValidators(): Array<any> {
    const validators: any = [];
    if (this.blueprint.required) {
      validators.push(Validators.required);
    }
    return validators;
  }

  public goToNextQuestion(answer: any): void {
    this.submitAnswer$.next({
      questionId: this.blueprint.id,
      answer,
    });
  }
}
