import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import {
  AUTH_URL,
  ONBOARDING_URL,
  PRODUCTS_V2_URL,
  REGISTER_URL,
  STATICS_FEATURE,
} from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { isUserLoggedIn, stringToUrlTree, userNeedsToFillOnboardingQuestionnaire } from './shared';
import { userHasFullySignedUp } from './shared/user-has-fully-signed-up.shared.resolver';

/**
 * As a user, if I want to see the statistics page, then I must meet a coupl of
 * conditions:
 *
 * - I must be logged in
 *      - else send me to home page
 * - I should be fully verified
 *      - else send me to auth v2 so that I can be fully verified
 * - I should not be a candidate for onboarding
 *      - else send me to onboarding
 * - I must have statistics feature in my user features
 *      - else send me to products page
 */
export const statisticsResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
            next: (hasFullySignedUp) => {
              if (hasFullySignedUp) {
                userNeedsToFillOnboardingQuestionnaire({
                  getOnboardingFeatureUseCase,
                  isUserEligibleForOnboardingUseCase,
                  getQuestionnaireEligibilityUseCase,
                  onboardingStoryGuideStateManager,
                }).subscribe({
                  next: (needsToFillOnboardingQuestionnaire) => {
                    if (needsToFillOnboardingQuestionnaire) {
                      subscriber.next(stringToUrlTree(ONBOARDING_URL, router));
                    } else {
                      if (checkUserFeatureExistsUseCase.execute(STATICS_FEATURE)) {
                        subscriber.next(true);
                      } else {
                        subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
                      }
                    }
                  },
                });
              } else {
                subscriber.next(stringToUrlTree(REGISTER_URL, router));
              }
            },
          });
        } else {
          subscriber.next(stringToUrlTree(AUTH_URL, router));
        }
      },
    });
  });
};
