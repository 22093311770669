import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { VariantModel } from '../../domain/variant-group.model';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetVariantsByProductIdsUseCase implements UseCase<Array<string>, Array<VariantModel>> {
  constructor(private _productsRepository: ProductsRepository) {}

  execute(params: string[]): Observable<VariantModel[]> {
    return this._productsRepository.getVariantsByProductIds(params);
  }
}
