import { InjectionToken } from '@angular/core';
import { MerchantStoreRepository } from 'src/app/core/repositories/merchant-store.repository';

/**
 * Notice here that we are required to add a mandatory first parameter. This is
 * just the description of the Injection Token which you are providing, so that
 * it is easy to debug down the line.
 *
 * So, this token is what we will add in the 'deps' property when creating a
 * user case factory. We add it there, and then it will be passed into the
 * factory function, by position, and then when the use case is instantiated
 * for example, the positional parameters are what will be available for the use case.
 */
export const MerchantStoreRepositoryInjectionToken: InjectionToken<MerchantStoreRepository> =
  new InjectionToken<MerchantStoreRepository>('Merchant Store Injection Token');
