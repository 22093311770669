import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BaseOverlayDialogComponent } from 'src/app/presentation/shared/components/overlay/base-overlay-dialog.component';
import { ActionSetActiveStep } from '../../state-manager/actions/actions-names';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

@Component({
  selector: 'app-onboarding-getting-started-dialog',
  templateUrl: './onboarding-getting-started-dialog.component.html',
  styleUrls: ['./onboarding-getting-started-dialog.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class OnboardingGettingStartedDialogComponent extends BaseOverlayDialogComponent {
  public assetsPath = '../../../../../assets/img/on-boarding/';

  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
  ) {
    super();
  }

  onGetStartedClicked() {
    const eventName = 'onboarding_journey_started';
    this.dismissOverlay();
    this._logMixpanelEventUseCase.execute({ eventName });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName);
    }
  }

  public dismissOverlay(): void {
    this._onboardingStoryGuideStateManager.updateStatePiece(ActionSetActiveStep, 1);
    super.dismissOverlay();
  }
}
