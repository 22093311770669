import { Mapper } from 'src/app/core/base/mapper';
import { QuestionnaireSubmissionModel } from 'src/app/core/domain/questionnaire.model';
import { QuestionnaireSubmissionEntity } from '../entities/questionnaire-entity';

export class QuestionnaireSubmissionRepositoryMapper extends Mapper<
  QuestionnaireSubmissionEntity,
  QuestionnaireSubmissionModel
> {
  mapFrom(param: QuestionnaireSubmissionEntity): QuestionnaireSubmissionModel {
    return {
      answers: param.answers,
    };
  }

  mapTo(param: QuestionnaireSubmissionModel): QuestionnaireSubmissionEntity {
    return {
      answers: param.answers,
    };
  }
}
