// achieve-earn-menu Presenter

import { Injectable } from '@angular/core';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { AchieveEarnMenuSideEffect } from './achieve-earn-menu.side-effects';
import { AchieveEarnMenuViewEvent } from './achieve-earn-menu.view-events';
import { AchieveEarnMenuViewState } from './achieve-earn-menu.view-state';

@Injectable({ providedIn: 'root' })
export class AchieveEarnMenuPresenter extends BasePresenter<
  AchieveEarnMenuViewState,
  AchieveEarnMenuViewEvent,
  AchieveEarnMenuSideEffect
> {
  constructor(private _siteTranslateService: SiteTranslateService) {
    super();
  }

  protected defaultViewState(): AchieveEarnMenuViewState {
    return {
      textIsRTL: false,
    };
  }

  protected onViewEvent(event: AchieveEarnMenuViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.add(
          this._siteTranslateService.onTextDirectionChange().subscribe((currDir) => {
            this.updateViewState({ ...this.viewState, textIsRTL: currDir === 'rtl' });
          }),
        );
        break;
      }
    }
  }
}
