import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ReferralStepsModel } from '../../domain/referral-steps.model';
import { ReferralProgramRepository } from '../../repositories/referral-program.repository';

export class GetReferralStepsDataUseCase implements UseCase<void, ReferralStepsModel[]> {
  constructor(private _referralProgramRepository: ReferralProgramRepository) {}

  execute(): Observable<ReferralStepsModel[]> {
    return this._referralProgramRepository.getReferralStepsData();
  }
}
