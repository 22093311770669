import { Injectable } from '@angular/core';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { Mapper } from '../../../../core/base/mapper';
import { OrdersFilter } from '../../../../core/domain/order-model';

@Injectable({
  providedIn: 'any',
})
export class OrdersFilterMapper extends Mapper<OrdersFilter, any> {
  mapFrom(from: OrdersFilter): any {
    const countryCode = from.country ? from.country.isoCode3 : undefined;
    const fromDateString = from.fromDate
      ? DateWrapperUtility.dateToISOString(from.fromDate)
      : undefined;
    const toDateString = from.toDate ? DateWrapperUtility.dateToISOString(from.toDate) : undefined;
    return {
      country: countryCode,
      fromDate: fromDateString,
      toDate: toDateString,
      showAllOrders: true,
      status: from.status,
    };
  }

  mapTo(to: any): OrdersFilter {
    throw new Error('Unsupported');
  }
}
