import { LOCAL_STORAGE_PREFIX, USER_TOKEN } from 'app/presentation/shared/constants';
import { http } from '../../drivers/data';
import './locales';
import { user } from './services';

http.events.beforeSending((options) => {
  options.headers['ui-session-key'] = user.uiSessionKey;

  // TODO: uncomment this when auth is fully transformed
  // if (!user.isLoggedIn) return;

  const accessToken =
    localStorage.getItem(`${LOCAL_STORAGE_PREFIX}.${USER_TOKEN}`) || user.accessToken;

  options.headers.Authorization = `Bearer ${accessToken}`;
  options.headers.taagerId = user.id;
});
