import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ReferralStepsModel } from 'src/app/core/domain/referral-steps.model';
import { ReferralProgramRepository } from 'src/app/core/repositories/referral-program.repository';
import { ReferralProgramMapper } from './entities/mappers/referral-program.mapper';
import { ReferralsProgramEntity } from './entities/referrals-program.entity';
import { ReferralProgramApisService } from './referral-program-api.services';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramRepositoryImplementation implements ReferralProgramRepository {
  constructor(private _referralProgramApisService: ReferralProgramApisService) {}

  private _ReferralProgramMapper = new ReferralProgramMapper();

  getReferralStepsData(): Observable<ReferralStepsModel[]> {
    return this._referralProgramApisService.getReferralStepsData();
  }

  getReferralsProgram(page: number, pageSize: number): Observable<ReferralsProgramEntity> {
    return this._referralProgramApisService
      .getReferralsProgram(page, pageSize)
      .pipe(map((res) => this._ReferralProgramMapper.mapFrom(res)));
  }
}
