import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  AUTH_URL,
  MERCHANT_INSIGHTS_USER_FEATURE,
  PRODUCTS_V2_URL,
  REGISTER_URL,
} from '../../../constants';
import { MERCHANT_INSIGHTS } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';
import { loggedInFullySignedUpMixinResolver } from './shared/mixins/logged-in-fully-signed-up.mixin.shared.resolver';

/**
 * as a user, I can only access stores if:
 *
 * - I am logged in.
 * - I am fully signed up.
 * - I have met other internal rules for me to view the merchant insights page
 */
export const merchantInsightsResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const userResolutionService = inject(UserResolutionService);
  const getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  return new Observable((subscriber) => {
    loggedInFullySignedUpMixinResolver({
      router,
      localStorageService,
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
      userResolutionService,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
      },
    }).subscribe({
      next: (verdict) => {
        if (verdict === true) {
          getFeatureFlagUsecase.execute(MERCHANT_INSIGHTS).subscribe({
            next: (flagIsOn) => {
              const userHasMerchantInsights = checkUserFeatureExistsUseCase.execute(
                MERCHANT_INSIGHTS_USER_FEATURE,
              );
              if (flagIsOn && userHasMerchantInsights) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              }
            },
          });
        } else {
          subscriber.next(verdict);
        }
      },
    });
  });
};
