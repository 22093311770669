<button
  [matMenuTriggerFor]="menu"
  (onclick)="onMenuButtonClick()"
  (onMenuOpen)="onMenuOpen()"
  (menuClosed)="onMenuClosed()"
  class="megaMenuButton"
  [ngClass]="darkModeClass$ | async"
  matRipple
>
  <img
    loading="lazy"
    src="assets/img/header-icons/megamenu-icon.svg"
    alt=""
    class="megaMenuButton__icon"
  />
  <span class="caption1--medium">
    {{ 'HEADER.CATEGORIES_MEGA_MENU.ALL_CATEGORIES' | translate }}
  </span>
  <img
    loading="lazy"
    src="assets/img/header-icons/chevron-down.svg"
    alt=""
    class="megaMenuButton__chevron"
  />
</button>

<mat-menu
  #menu="matMenu"
  hasBackdrop="true"
  matRippleTrigger="hover"
  backdropClass="mega-menu-backdrop"
>
  <app-mega-menu></app-mega-menu>
</mat-menu>
