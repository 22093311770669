import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterOutlet],
})
export class AuthLayoutComponent {
  public currentUrl: string;

  constructor(private router: Router) {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
          this.currentUrl = this.currentUrl.split('/').pop()!;
        }
      },
    });
  }
}
