import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartRepository } from '../../repositories/cart.repository';

export class RemoveProductFromCartUseCase implements UseCase<string, Observable<void>> {
  constructor(private _cartRepository: CartRepository) {}

  execute(id: string): Observable<void> {
    return this._cartRepository.removeProductFromCart(id);
  }
}
