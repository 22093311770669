import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { MERCHANT_OPTIN_REDIRECT } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { BaseOverlayDialogComponent } from '../overlay/base-overlay-dialog.component';

@Component({
  selector: 'app-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss'],
  standalone: true,
})
export class VerificationDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  authURL = 'assets/img/auth';

  public onStartVerification$: Subject<boolean> = new Subject();

  private _localStorageService: LocalStorageService = inject(LocalStorageService);

  private _router: Router = inject(Router);

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  ngOnInit(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_page_load' });
  }

  onStartVerification() {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_start_click' });
    this._localStorageService.setStorage(MERCHANT_OPTIN_REDIRECT, this._router.url);
    this.onStartVerification$.next(true);
  }

  dismissOverlay(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_dismiss_click' });
    super.dismissOverlay();
  }
}
