/* eslint-disable @typescript-eslint/naming-convention */
interface authError {
  errorCode: string;
  description: {
    arabic: string;
    english: string;
  };
}

export const REGISTER_ERRORS_MAP = new Map<string, string>([
  ['user-already-registered', 'البريد الالكتروني/رقم الهاتف مسجل لدينا بالفعل'],
  [
    'rate-limit-triggered',
    'لقد تجاوزت العدد المسموح من محاولات “إعادة ارسال الكود”، يرجى المحاولة في وقت آخر',
  ],
  ['phone-number-already-taken', 'رقم الهاتف مسجل لدينا بالفعل'],
  ['phone-number-already-verified', 'رقم الهاتف تم التحقق منه بالفعل'],
  ['otp-doesnt-match', 'الكود المُدخل خطأ'],
  ['otp-expired', 'صلاحية هذا الكود انتهت، يرجى طلب "إعادة ارسال الكود"'],
  ['otp-too-many-retries', 'لقد أجريت محاولات كثيرة ، يرجى المحاولة في وقت آخر'],
  ['otp-already-verified', 'هذا الكود تم تأكيده بالفعل'],
  ['user-data-cant-be-published', 'حدث خطأ ما، من فضلك أعد المحاولة'],
  ['user-data-already-published', ' مسجل لدينا بالفعل'],
  ['otp-already-requested', 'يرجى الانتظار لمدة قد تصل إلى 5 دقائق قبل طلب رمز OTP آخر'],
]);

export const LOGIN_VIA_EMAIL = 'تسجيل الدخول بالبريد الالكتروني';
export const LOGIN_VIA_PHONE = 'تسجيل الدخول برقم الهاتف';
