import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuestionnaireBannerRepository } from 'src/app/core/repositories/questionnaire-banner.repository';
import { BannerQuestionnaireAPIservice } from './banner-questionnaire-api.service';

@Injectable({
  providedIn: 'root',
})
export class BannerQuestionnaireRepositoryImplementation extends QuestionnaireBannerRepository {
  constructor(private bannerQuestionnaireAPIService: BannerQuestionnaireAPIservice) {
    super();
  }

  getQuestionnaireElgibility(questionnaireName: string): Observable<{ enabled: boolean }> {
    return this.bannerQuestionnaireAPIService.getQuestionnaireEligibility(questionnaireName);
  }

  declineQuestionnaire(questionnaireName: string): Observable<void> {
    return this.bannerQuestionnaireAPIService.postDeclineQuestionnaire(questionnaireName);
  }
}
