import { Observable } from 'rxjs';
import { OnboardingStoryGuideState } from '../interfaces';

/**
 * Selectors to pick certain UI pieces
 */

/**
 * Select the current active user step index
 */
export const selectCurrentActiveUserStepIndex = (
  state: OnboardingStoryGuideState,
): Observable<number> => state.currentActiveUserStepIndex;

/**
 * Select current user's reward type from state
 */
export const selectRewardType = (state: OnboardingStoryGuideState): Observable<string> =>
  state.rewardType;

/**
 * Select the status of the user having completed the onboarding journey
 */
export const selectUserOnboardingCompletionStatus = (
  state: OnboardingStoryGuideState,
): Observable<boolean> => state.hasCompletedOnboardingStory;
