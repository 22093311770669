import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { CUSTOMER_TOUCH_POINT } from '../constants';
import { USER_ACQUISITION_CHANNELS } from '../constants/feature-flags';
import { LocalStorageService } from '../services/local-storage.service';

/**
 * We need a way to keep track of how we are getting the users. But how do we do that exactly? Well, we need
 * to get the origin from where the user came from.
 *
 * Once we know the origin, in terms of a URL, we will decode this and then we will know from where the
 * user came from, based on our pre-defined sources and for sources which we don't know for example
 * a link was shared from other social media, then in that case, we will provide this information as well.
 *
 * The end goal will be to make sure that we capture as much as we can about where we are getting our users
 * from.
 */

interface AcquisitionChannel {
  source_key: string;
  source_logged_name: string;
}

@Injectable({
  providedIn: 'root',
})
export class GetUserAcquisitionUtility {
  constructor(
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    private _localStorageService: LocalStorageService,
  ) {}

  public async getUserAcquisitionUtility(referrer: string) {
    let possibleUserAqcuisitionChannels = await lastValueFrom(
      this._getFeatureAttributeUsecase.execute(USER_ACQUISITION_CHANNELS).pipe(take(1)),
    );
    if (possibleUserAqcuisitionChannels) {
      possibleUserAqcuisitionChannels = JSON.parse(possibleUserAqcuisitionChannels);
      this._decodeUserAcquisition(possibleUserAqcuisitionChannels.sources, referrer);
    }
  }

  /**
   * We will be optionally setting the decoded user aquisition.
   */
  private _decodeUserAcquisition(channels: Array<AcquisitionChannel>, referrer: string): void {
    const hostname = this._getUserReferrerHostName(referrer);
    const matchedChannel = channels.find((channel) => channel.source_key === hostname);
    if (matchedChannel) {
      this._optionallySetUserAcquisition(matchedChannel);
    }
  }

  /**
   *
   * @param matchedChannel
   *
   * So, after getting he channel, update the storage acquisition value with this new channel.
   *
   * BUT what is the rule for this? Well, we will update the value:
   *
   * 1. If there is no existing storage value, then we update with the newly received.
   * 2. If there is an existing storage value, and this value is not Direct ie user got here
   * by entering the URL on the browser and it is not similar to what we already have, then we
   * update
   */
  private _optionallySetUserAcquisition(matchedChannel: AcquisitionChannel): void {
    const existingTouchPointInStorage = this._localStorageService.getStorage(CUSTOMER_TOUCH_POINT);
    if (existingTouchPointInStorage) {
      if (
        matchedChannel.source_logged_name !== 'Direct' &&
        matchedChannel.source_logged_name !== existingTouchPointInStorage
      ) {
        this._doWriteToStorage(matchedChannel.source_logged_name);
      }
    } else {
      this._doWriteToStorage(matchedChannel.source_logged_name);
    }
  }

  private _doWriteToStorage(channel: string): void {
    this._localStorageService.setStorage(CUSTOMER_TOUCH_POINT, channel);
  }

  private _getUserReferrerHostName(referrer: string): string {
    return referrer ? new URL(referrer).hostname.replace('www.', '').replace('.com', '') : '';
  }
}
