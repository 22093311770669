import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { CESQuestionTypesCore } from '../ces-question-types-core';

@Component({
  selector: 'app-ces-rating-question',
  standalone: true,
  imports: [NgTemplateOutlet, NgFor, NgIf, TranslateModule],
  templateUrl: './ces-rating-question.component.html',
  styleUrls: ['./ces-rating-question.component.scss'],
})
export class CesRatingQuestionComponent extends CESQuestionTypesCore {
  public ratings: Array<{ label: string; rating: number; value: string }>;

  private _siteTranslateService: SiteTranslateService = inject(SiteTranslateService);

  constructor() {
    super();
    this.controlName = 'rating';
    this.ratings = [];
  }

  ngOnInit(): void {
    this.instantiateForm(this.controlName);
    this.optionallyPatchForm({ [this.controlName]: this.currentAnswer });
    this._createRatings();
  }

  private _createRatings(): void {
    const currentLanguage = this._siteTranslateService.getCurrentLanguage();
    this.blueprint.options?.forEach((option, index) => {
      this.ratings.push({
        label: currentLanguage === 'ar' ? option.textArabic : option.textEnglish,
        rating: index + 1,
        value: option.id,
      });
    });
  }

  public pickRating(value: string): void {
    this.optionallyPatchForm({ rating: value });
  }
}
