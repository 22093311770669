import { FAQModel } from 'src/app/core/domain/faq.model';

export const LANDING_CATEGORIES: { name: string; iconClass: string }[] = [
  { name: 'LANDING.CATEGORIES.CATEGORY_1', iconClass: 'icon-landing-categories-toys' },
  { name: 'LANDING.CATEGORIES.CATEGORY_2', iconClass: 'icon-landing-categories-gifts' },
  { name: 'LANDING.CATEGORIES.CATEGORY_3', iconClass: 'icon-landing-categories-automotive' },
  { name: 'LANDING.CATEGORIES.CATEGORY_4', iconClass: 'icon-landing-categories-accessories' },
  { name: 'LANDING.CATEGORIES.CATEGORY_5', iconClass: 'icon-landing-categories-home' },
  { name: 'LANDING.CATEGORIES.CATEGORY_6', iconClass: 'icon-landing-categories-pets' },
  { name: 'LANDING.CATEGORIES.CATEGORY_7', iconClass: 'icon-landing-categories-electronics' },
  { name: 'LANDING.CATEGORIES.CATEGORY_8', iconClass: 'icon-landing-categories-fashion' },
  { name: 'LANDING.CATEGORIES.CATEGORY_9', iconClass: 'icon-landing-categories-cosmetics' },
  { name: 'LANDING.CATEGORIES.CATEGORY_10', iconClass: 'icon-landing-categories-kids' },
  {
    name: 'LANDING.CATEGORIES.CATEGORY_11',
    iconClass: 'icon-landing-categories-mobile-accessories',
  },
  { name: 'LANDING.CATEGORIES.CATEGORY_12', iconClass: 'icon-landing-categories-pc-accessories' },
  { name: 'LANDING.CATEGORIES.CATEGORY_13', iconClass: 'icon-landing-categories-shoes' },
  { name: 'LANDING.CATEGORIES.CATEGORY_14', iconClass: 'icon-landing-categories-kitchen' },
];

export const LANDING_COMMON_QUESTIONS: FAQModel[] = [
  {
    question: 'LANDING.FAQ.1.QUESTION',
    answer: 'LANDING.FAQ.1.ANSWER',
  },
  {
    question: 'LANDING.FAQ.2.QUESTION',
    answer: 'LANDING.FAQ.2.ANSWER',
  },
  {
    question: 'LANDING.FAQ.3.QUESTION',
    answer: 'LANDING.FAQ.3.ANSWER',
  },
  {
    question: 'LANDING.FAQ.4.QUESTION',
    answer: 'LANDING.FAQ.4.ANSWER',
  },
  {
    question: 'LANDING.FAQ.5.QUESTION',
    answer: 'LANDING.FAQ.5.ANSWER',
  },
  {
    question: 'LANDING.FAQ.6.QUESTION',
    answer: 'LANDING.FAQ.6.ANSWER',
  },
];
export interface Testimonial {
  style: { [style: string]: string };
  textContent: string;
  user: {
    avatar: string;
    name: string;
    location: string;
  };
}
export const LANDING_MERCHANT_TESTEMONIALS: Testimonial[] = [
  {
    style: { 'border-color': '#E3F4F3' },
    textContent: 'LANDING.TESTIMONIAL.1.TEXT',
    user: {
      avatar: 'man-1.svg',
      name: 'LANDING.TESTIMONIAL.1.NAME',
      location: 'LANDING.TESTIMONIAL.1.LOCATION',
    },
  },
  {
    style: { 'border-color': '#FBEFD7' },
    textContent: 'LANDING.TESTIMONIAL.3.TEXT',
    user: {
      avatar: 'woman.svg',
      name: 'LANDING.TESTIMONIAL.3.NAME',
      location: 'LANDING.TESTIMONIAL.3.LOCATION',
    },
  },
  {
    style: { 'border-color': '#F6F6F7' },
    textContent: 'LANDING.TESTIMONIAL.2.TEXT',

    user: {
      avatar: 'man-1.svg',
      name: 'LANDING.TESTIMONIAL.2.NAME',
      location: 'LANDING.TESTIMONIAL.2.LOCATION',
    },
  },
];
