import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartRepository } from '../../repositories/cart.repository';

export class AddToCartUseCase implements UseCase<{ productId: string; quantity: number }, void> {
  constructor(private _cartRepository: CartRepository) {}

  execute(params: { productId: string; quantity: number }): Observable<void> {
    return this._cartRepository.addToCart(params);
  }
}
