import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStorageService } from '../../../../presentation/shared/services/local-storage.service';
import { LearningLocalEntity } from './entities/learning-local-entity';

export const LEARNING_STORAGE_KEY = 'onboarding/learning';

@Injectable({
  providedIn: 'root',
})
export class LearningCache {
  constructor(private _localStorageService: LocalStorageService) {}

  private _subj$: Subject<LearningLocalEntity> = new BehaviorSubject<LearningLocalEntity>(
    this._localStorageService.getStorage(LEARNING_STORAGE_KEY),
  );

  get(): Observable<LearningLocalEntity> {
    return this._subj$;
  }

  put(entity: LearningLocalEntity): void {
    this._localStorageService.setStorage(LEARNING_STORAGE_KEY, entity);
    this._subj$.next(entity);
  }
}
