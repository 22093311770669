import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ALL_PRODUCTS_CATEGORY, API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public isRecommendedProductsReducerEnabled: boolean;

  constructor(private http: HttpClient) {}

  getProductsForCategory(params: any): Observable<any> {
    if (params.category === ALL_PRODUCTS_CATEGORY) {
      params.category = undefined;
    }
    return this.http.post(API_URLS.GET_PRODUCTS_FOR_CATEGORY_URL, params);
  }

  getProductById(id: string): Observable<any> {
    return this.http.get(API_URLS.GET_PRODUCT_BY_ID_URL(id));
  }

  getVariantGroupByVariantId(variantId: string): Observable<any> {
    return this.http.get(API_URLS.GET_VARIANT_GROUP_BY_VARIANT_ID(variantId));
  }

  getProductsByIds(ids: any): Observable<any> {
    return this.http.post(API_URLS.GET_PRODUCTS_BY_ID_URL, ids);
  }

  getProductsByProdIds(prodIDs: any): Observable<any> {
    return this.http.post(API_URLS.GET_PRODUCTS_BY_PROD_IDS_URL, prodIDs);
  }

  getBestsellers(): Observable<any> {
    return this.http.get(API_URLS.GET_BEST_SELLERS_URL);
  }

  getFeaturedProductsGroup(id: number, country: string): Observable<any> {
    return this.http.get(API_URLS.FEATURED_PRODUCTS_GROUP_URL(id, country));
  }

  postProductRequest(requestBody: any): Observable<any> {
    return this.http.post(API_URLS.REQUEST_PRODUCT_URL, requestBody);
  }
}
