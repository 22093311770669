import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { dialogAtom } from '@presentation/shared/dialog';
import { BaseComponent } from '../../base/base.component';
import { KycPopupContentComponent } from './presentation/kyc-popup-content/kyc-popup-content.component';
import { KYCPopupPresenter } from './presentation/kyc-popup.presenter';
import { KYCPopupSideEffects } from './presentation/kyc-popup.side-effects';
import { KYCPopupViewEvent } from './presentation/kyc-popup.view-events';
import { KYCPopupViewState } from './presentation/kyc-popup.view-state';

@Component({
  selector: 'app-kyc-popup',
  templateUrl: './kyc-popup.component.html',
  styleUrls: ['./kyc-popup.component.scss'],
  standalone: true,
  providers: [KYCPopupPresenter],
  imports: [NgIf],
})
export class KycPopupComponent extends BaseComponent<
  KYCPopupPresenter,
  KYCPopupViewState,
  KYCPopupViewEvent,
  KYCPopupSideEffects
> {
  public presenter: KYCPopupPresenter = inject(KYCPopupPresenter);

  protected onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  public onSideEffect(sideEffect: KYCPopupSideEffects): void {
    if (sideEffect.type === 'Opened') {
      dialogAtom.open(KycPopupContentComponent, {
        id: 'kyc',
      });
    }
  }
}
