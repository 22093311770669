import { APP_BASE_HREF, AsyncPipe, DatePipe } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFirePerformanceModule } from '@angular/fire/compat/performance';
import { AngularFireRemoteConfigModule, SETTINGS } from '@angular/fire/compat/remote-config';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import '@features/init';
import '@presentation/init';

import { country } from '@features/country/data';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// @ts-ignore
import { user } from '@features/user/data';
import {
  countryIsDifferentFromUrlCountryCode,
  updateCountryToUrlCountryCode,
} from '@presentation/country/utils';
import { environment } from 'environment';
import { initializeApp } from 'firebase/app';
import { GalleryModule } from 'ng-gallery';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { provideLottieOptions } from 'ngx-lottie';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { ROUTES } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { AppTracker } from './app/components/tracking/app-tracker';
import { FirebaseAnalyticsTracker } from './app/components/tracking/custom-trackers/firebase-analytics-tracker';
import { DomainModule } from './app/core/di/domain.module';
import { GetUserSuperPropertiesUseCase } from './app/core/usecases/user/get-user-super-properties.usecase';
import { DataModule } from './app/data/data.module';
import { ExperienceModule } from './app/experience/di/experience.module';
import {
  ARABIC_LANGUAGE,
  DEFAULT_LANDING_COUNTRY,
} from './app/presentation/shared/constants/country-language-codes-mapping.constants';
import { LazyLoadDirective } from './app/presentation/shared/directives/image-lazyload.directive.';
import { HttpRequestInterceptor } from './app/presentation/shared/services/HttpRequestInterceptor.service';

initializeApp(environment.firebase);

function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/translations/', '.json');
}

function appInitializerFactory(translate: TranslateService) {
  // if (environment.production) {
  //   datadogRum.init({
  //     applicationId: environment.datadog.applicationId,
  //     clientToken: environment.datadog.clientToken,
  //     site: 'datadoghq.eu',
  //     service: environment.datadog.service,
  //     env: environment.datadog.env,
  //     // Specify a version number to identify the deployed version of your application in Datadog
  //     version: packageInfo.version,
  //     allowedTracingUrls: [/https:\/\/.*\.api\.taager\.com/],
  //     sessionSampleRate: 100,
  //     sessionReplaySampleRate: 10,
  //     trackUserInteractions: true,
  //     trackResources: true,
  //     trackLongTasks: true,
  //     defaultPrivacyLevel: 'mask-user-input',
  //   });

  //   datadogRum.startSessionReplayRecording();
  // }
  return () => {
    translate.setDefaultLang(ARABIC_LANGUAGE);
    return translate.use(ARABIC_LANGUAGE);
  };
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
const getBaseLocation = (): string => {
  if (user.isLoggedIn) {
    if (countryIsDifferentFromUrlCountryCode()) {
      updateCountryToUrlCountryCode();
    }

    const basePath = country.shortCode.toLowerCase();

    return `/${basePath}`;
  }

  /**
   *
   * This will fire in below conditions:
   *
   * 1. If the user is not logged in, that is, they are on the landing page, then we need to return,
   * the APP_BASE_HREF for landing i.e logged out user.
   *
   * 2. Okay, now we have another classical case, where we have a user object (joined taager), but the
   * user has not verified their account fully, so, we get to handle this here as well.
   *
   * NB: We are making these small small changes such as 1 then 2 and so forth, so that we don't break
   * the existing code.
   */
  if (!user.isDataVerified || !user.isPhoneNumberVerified) {
    const { pathname } = window.location;
    if (!pathname || pathname === '/') {
      return `/${DEFAULT_LANDING_COUNTRY}`;
    }

    const actualLandingPath = pathname.split('/')[1];

    if (actualLandingPath?.length === 2) {
      return `/${actualLandingPath}`;
    }

    return `/${DEFAULT_LANDING_COUNTRY}`;
  }

  return '/';
};

const AppTrackerFactory = (
  _firebaseAnalyticsTracker: FirebaseAnalyticsTracker,
  _getUserSuperPropertiesUseCase: GetUserSuperPropertiesUseCase,
) => new AppTracker([_firebaseAnalyticsTracker], _getUserSuperPropertiesUseCase);
const DIRECTIVES = [LazyLoadDirective];

const MIXPANEL_CUSTOM_LIB_URL = environment.MIXPANEL_PROXY_DOMAIN
  ? `${environment.MIXPANEL_PROXY_DOMAIN}lib.min.js`
  : undefined;
const script = document.createElement('script');
if (environment.MIXPANEL_PROXY_DOMAIN) {
  script.innerHTML = `(function (c, a) {
    if (!a.__SV) {
      var b = window;
      try {
        var d,
          m,
          j,
          k = b.location,
          f = k.hash;
        d = function (a, b) {
          return (m = a.match(RegExp(b + '=([^&]*)'))) ? m[1] : null;
        };
        f &&
          d(f, 'state') &&
          ((j = JSON.parse(decodeURIComponent(d(f, 'state')))),
          'mpeditor' === j.action &&
            (b.sessionStorage.setItem('_mpcehash', f),
            history.replaceState(j.desiredHash || '', c.title, k.pathname + k.search)));
      } catch (n) {}
      var l, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function (b, d, g) {
        function c(b, i) {
          var a = i.split('.');
          2 == a.length && ((b = b[a[0]]), (i = a[1]));
          b[i] = function () {
            b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var e = a;
        'undefined' !== typeof g ? (e = a[g] = []) : (g = 'mixpanel');
        e.people = e.people || [];
        e.toString = function (b) {
          var a = 'mixpanel';
          'mixpanel' !== g && (a += '.' + g);
          b || (a += ' (stub)');
          return a;
        };
        e.people.toString = function () {
          return e.toString(1) + '.people (stub)';
        };
        l =
          'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
            ' ',
          );
        for (h = 0; h < l.length; h++) c(e, l[h]);
        var f = 'set set_once union unset remove delete'.split(' ');
        e.get_group = function () {
          function a(c) {
            b[c] = function () {
              call2_args = arguments;
              call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
              e.push([d, call2]);
            };
          }
          for (
            var b = {},
              d = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)),
              c = 0;
            c < f.length;
            c++
          )
            a(f[c]);
          return b;
        };
        a._i.push([b, d, g]);
      };
      a.__SV = 1.2;
      b = c.createElement('script');
      b.type = 'text/javascript';
      b.async = !0;
      b.src = '${MIXPANEL_CUSTOM_LIB_URL}';
      d = c.getElementsByTagName('script')[0];
      d.parentNode.insertBefore(b, d);
    }
  })(document, window.mixpanel || []);`;
} else {
  script.innerHTML =
    '(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" "); for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);';
}
document.head.appendChild(script);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(ROUTES, withPreloading(PreloadAllModules), withInMemoryScrolling()),
    importProvidersFrom(
      ExperienceModule,
      DomainModule,
      DataModule,
      BrowserModule,
      ToastrModule.forRoot(),
      GalleryModule,
      PerfectScrollbarModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
      AngularFireRemoteConfigModule,
      AngularFirePerformanceModule,
      AngularFireAnalyticsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: ARABIC_LANGUAGE,
      }),
      DIRECTIVES,
      PaginationModule.forRoot(),
    ),
    DatePipe,
    AsyncPipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseLocation,
    },
    {
      provide: SETTINGS,
      useFactory: () =>
        environment.production
          ? {
              minimumFetchIntervalMillis: 14400000,
              fetchTimeoutMillis: 60000, // 1 minute
            }
          : {
              minimumFetchIntervalMillis: 0,
              fetchTimeoutMillis: 60000, // 1 minute
            },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: AppTracker,
      useFactory: AppTrackerFactory,
      deps: [FirebaseAnalyticsTracker, GetUserSuperPropertiesUseCase],
    },
    provideAnimations(),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
}).catch((err) => console.error(err));
