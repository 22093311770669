import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { PreOrderRequestApiStatus } from '../pre-order-status-model';

const statusMappings: Record<string, PreOrderStatuses> = {
  [PreOrderRequestApiStatus.STARTED]: PreOrderStatuses.STARTED,
  [PreOrderRequestApiStatus.PENDING_APPROVAL]: PreOrderStatuses.AWAITING,
  [PreOrderRequestApiStatus.PendingExternalProductApproval]:
    PreOrderStatuses.AWAITING_EXTERNAL_PRODUCT_APPROVAL,
  [PreOrderRequestApiStatus.ACCEPTED]: PreOrderStatuses.INPROGRESS,
  [PreOrderRequestApiStatus.REJECTED]: PreOrderStatuses.REJECTED,
  [PreOrderRequestApiStatus.MONEY_TRANSFERRED_APPROVED]:
    PreOrderStatuses.MONEY_TRANSFERRED_APPROVED,
  [PreOrderRequestApiStatus.DOWN_PAYMENT_REJECTED_BY_MERCHANT]:
    PreOrderStatuses.DOWN_PAYMENT_REJECTED_BY_MERCHANT,
  [PreOrderRequestApiStatus.DOWN_PAYMENT_OFFERED]: PreOrderStatuses.DOWN_PAYMENT_OFFERED,
  [PreOrderRequestApiStatus.COMPLETE]: PreOrderStatuses.COMPLETED,
  [PreOrderRequestApiStatus.INCOMPLETE]: PreOrderStatuses.INCOMPLETE,
  [PreOrderRequestApiStatus.DOWN_PAYMENT_AGREED]: PreOrderStatuses.DOWN_PAYMENT_AGREED,
};

export const mapPreOrderRequestStatus = (status: string): PreOrderStatuses => {
  if (status in statusMappings) {
    return statusMappings[status];
  }

  throw new Error('Invalid Status');
};
