import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

export class InitializeMixpanelUseCase implements UseCase<void, void> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute() {
    this._analyticsRepository.initializeMixpanel();
  }
}
