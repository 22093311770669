import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { HeaderTab } from 'src/app/presentation/shared/interfaces/header.interface';

@Component({
  selector: 'app-taager-sidenav-button',
  templateUrl: './taager-sidenav-button.component.html',
  styleUrls: ['./taager-sidenav-button.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, NgClass, TranslateModule],
})
export class TaagerSidenavButtonComponent implements OnInit, OnDestroy {
  @Input() tab: HeaderTab;

  @Input() shouldShowChevronButton: boolean;

  @Output() tabClicked: EventEmitter<void> = new EventEmitter();

  isLTR: boolean;

  private _languageChangeSubscription: Subscription;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  onTabClicked(tab: HeaderTab) {
    this.tabClicked.emit();
    if (tab.mixpanelEventName) {
      this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName });
    }
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
