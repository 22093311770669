import { Component, Inject, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { BaseOverlayDialogComponent } from 'src/app/presentation/shared/components/overlay/base-overlay-dialog.component';
import { ActionSetActiveStep } from '../../state-manager/actions/actions-names';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

@Component({
  selector: 'app-onboarding-last-step-dialog',
  templateUrl: './onboarding-last-step-dialog.component.html',
  styleUrls: ['./onboarding-last-step-dialog.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class OnboardingLastStepDialogComponent
  extends BaseOverlayDialogComponent
  implements OnDestroy
{
  public assetsPath: string;

  private _ondestroy$: Subject<boolean>;

  private _redirectToURL: string;

  constructor(
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    super();
    this.assetsPath = '../../../../../assets/img/on-boarding/';
    this._ondestroy$ = new Subject<boolean>();
    this._redirectToURL = this._appURLs.PRODUCTS_V2_URL;
  }

  public startUsingTaager(): void {
    const eventName = 'onboarding_journey_completed';
    this._logMixpanelEventUseCase.execute({ eventName });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName);
    }
    this._router.navigate([this._redirectToURL]);
    this.gracefullyRedirectUserBackToHome();
  }

  /**
   * Well, when the user finishes the onboarding journey, we should send them back to
   * the home page, but we should make the experience seamless.
   */
  private gracefullyRedirectUserBackToHome(): void {
    this._router.events
      .pipe(
        takeUntil(this._ondestroy$),
        filter((event) => event instanceof NavigationEnd && event.url === this._redirectToURL),
      )
      .subscribe({
        next: () => {
          this.dismissOverlay();
          this._onboardingStoryGuideStateManager.updateStatePiece(ActionSetActiveStep, 9);
        },
      });
  }

  ngOnDestroy(): void {
    this._ondestroy$.next(true);
    this._ondestroy$.complete();
  }
}
