import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { MerchantInsightsModel } from '../../domain/merchant-insights.model';
import { MerchantInsightsRepository } from '../../repositories/merchant-insights.repository';

export class GetInsightsDataUseCase implements UseCase<void, MerchantInsightsModel[]> {
  constructor(private _merchantInsightsRepository: MerchantInsightsRepository) {}

  execute(): Observable<MerchantInsightsModel[]> {
    return this._merchantInsightsRepository.getInsightsData();
  }
}
