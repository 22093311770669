import { NgModule } from '@angular/core';
import { AnalyticsRepositoryInjectionToken } from '../../data/injection-tokens/analytics-repository.injection-token';
import { AnalyticsRepository } from '../repositories/analytics.repository';
import { TrackErrorUseCase } from '../usecases/analytics/track-error.usecase';

const TrackErrorUseCaseFactory = (analyticsRepository: AnalyticsRepository) =>
  new TrackErrorUseCase(analyticsRepository);
const TrackErrorUseCaseProvider = {
  provide: TrackErrorUseCase,
  useFactory: TrackErrorUseCaseFactory,
  deps: [AnalyticsRepositoryInjectionToken],
};

// TODO Move the other analytics domain providers to here as part of a future refactoring task.

@NgModule({
  providers: [TrackErrorUseCaseProvider],
})
export class AnalyticsDomainModule {}
