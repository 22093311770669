import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ReferralsModel } from '../../domain/referrals.model';
import { UserRepository } from '../../repositories/user.repository';

export class GetReferralsUseCase implements UseCase<void, ReferralsModel | Error> {
  constructor(private userRepository: UserRepository) {}

  execute(): Observable<ReferralsModel | Error> {
    return this.userRepository.getReferrals();
  }
}
