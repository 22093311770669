import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { WalletTransactionRequestFilterModel } from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class GetTransactionsSheetUseCase
  implements UseCase<WalletTransactionRequestFilterModel, Blob>
{
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: WalletTransactionRequestFilterModel): Observable<Blob> {
    return this._walletRepository.getTransactionsSheet(params);
  }
}
