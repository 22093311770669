import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class ListenToProductsPageExperienceChangesUseCase implements UseCase<void, void> {
  constructor(private _productsRepository: ProductsRepository) {}

  execute(): Observable<void> {
    return this._productsRepository.listenToProductsPageExperienceChanges();
  }
}
