import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { getStoreRedirectUri } from 'src/app/data/repositories/merchant-store/utilities/get-store-redirect-uri.utility';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import {
  AUTH_URL,
  ONBOARDING_URL,
  PRODUCTS_V2_URL,
  REGISTER_URL,
  ZID_PROVIDER_NAME,
} from '../../../constants';
import { ZID_INTEGRATION } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { loggedInFullySignedUpOnboardedMixinResolver, stringToUrlTree } from './shared';

/**
 * So, what decides if a user can have access to zid integrations.
 *
 * - must be logged in
 *      - else send them to login
 * - must be fully verified.
 *      - else send them to auth page
 * - must not be a candidate for onboarding.
 *      - else send them to onboarding questionnaire
 * - feature flag to access zid integration must be on.
 *      - else send them to products page
 */
export const zidIntegrationRedirectionResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  const userResolutionService = inject(UserResolutionService);
  return new Observable((subscriber) => {
    loggedInFullySignedUpOnboardedMixinResolver({
      router,
      localStorageService,
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
      getOnboardingFeatureUseCase,
      getQuestionnaireEligibilityUseCase,
      onboardingStoryGuideStateManager,
      userResolutionService,
      isUserEligibleForOnboardingUseCase,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
        ifNotOnboarded: ONBOARDING_URL,
      },
    }).subscribe({
      next: (status) => {
        if (status === true) {
          getFeatureFlagUsecase.execute(ZID_INTEGRATION).subscribe({
            next: (isOn) => {
              if (isOn) {
                const { code } = router.parseUrl(
                  router.getCurrentNavigation()!.extractedUrl.toString(),
                ).queryParams;
                if (!code) {
                  window.location.href = getStoreRedirectUri(ZID_PROVIDER_NAME)!;
                } else {
                  subscriber.next(stringToUrlTree(`stores?provider=${'zid'}&code=${code}`, router));
                }
              } else {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              }
            },
          });
        } else {
          subscriber.next(status);
        }
      },
    });
  });
};
