export enum PaymentTypes {
  MOBILE_WALLET,
  BANK_TRANSFER,
  DIGITAL_PAYMENT,
}

export enum WithdrawalMethods {
  VODAFONE_CASH_METHOD,
  ORANGE_CASH_METHOD,
  ETISALAT_CASH_METHOD,
  WE_PAY_METHOD,
  EGP_BANK_TRANSFER_METHOD,
  INTL_BANK_TRANSFER_METHOD,
  PAYONEER_TRANSFER_METHOD,
}

export const WithdrawalsPaymentTypes = {
  [PaymentTypes.MOBILE_WALLET]: [
    WithdrawalMethods.VODAFONE_CASH_METHOD,
    WithdrawalMethods.ORANGE_CASH_METHOD,
    WithdrawalMethods.ETISALAT_CASH_METHOD,
    WithdrawalMethods.WE_PAY_METHOD,
  ],
  [PaymentTypes.BANK_TRANSFER]: [
    WithdrawalMethods.EGP_BANK_TRANSFER_METHOD,
    WithdrawalMethods.INTL_BANK_TRANSFER_METHOD,
  ],
  [PaymentTypes.DIGITAL_PAYMENT]: [WithdrawalMethods.PAYONEER_TRANSFER_METHOD],
};
