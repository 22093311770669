import { Injectable } from '@angular/core';
import { Mapper } from '../../../../../core/base/mapper';
import { OrderStep, OrdersTracking } from '../../../../../core/domain/orders-tracking.model';
import { ALL_STATUSES } from '../../../../../presentation/shared/constants/order-statuses';

const mapCurrentStep = (status: string): number => {
  const statusFound = ALL_STATUSES.find(({ statusInEnglish }) => statusInEnglish === status);
  switch (statusFound?.statusStage) {
    case 'received':
      return OrderStep.Placed;
    case 'cancelled':
    case 'pending':
      return OrderStep.Confirmed;
    case 'delivered':
      return OrderStep.Delivered;
    default:
      return -1;
  }
};

@Injectable({
  providedIn: 'any',
})
export class OrdersTrackingRemoteMapper extends Mapper<any, OrdersTracking> {
  mapFrom(remote: any): OrdersTracking {
    const orders = remote.data;
    return {
      orders: orders.map((order: any) => {
        return {
          createdAt: new Date(order.createdAt),
          steps: Object.values(OrderStep),
          currentStep: mapCurrentStep(order.status),
        };
      }),
    };
  }

  mapTo(domain: OrdersTracking): any {
    throw new Error('Unsupported');
  }
}
