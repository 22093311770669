import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OrderSummaryModel } from '../../domain/order-summary.model';
import { UserRepository } from '../../repositories/user.repository';

/**
 * Return the order summary for the user.
 */
export class GetOrderSummaryUseCase
  implements UseCase<{ fromDate: string; toDate: string }, OrderSummaryModel>
{
  constructor(private _userRepository: UserRepository) {}

  execute(params: { fromDate: string; toDate: string }): Observable<OrderSummaryModel> {
    return this._userRepository.getOrderSummary(params.fromDate, params.toDate);
  }
}
