import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ResetWalletPasswordModel } from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class ResetWalletPasswordUseCase implements UseCase<ResetWalletPasswordModel, void> {
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: ResetWalletPasswordModel): Observable<void> {
    return this._walletRepository.resetWalletPassword(params);
  }
}
