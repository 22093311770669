import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { GetUserIPAddressUseCase } from 'src/app/core/usecases/user-location/get-user-ip-address.usecase';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
import { ResponsiveService } from '../../../services/responsive.service';
import { resolveUserLandingUtility } from '../../../utilities/resolve-user-landing.utility';

@Injectable({
  providedIn: 'root',
})
export class LandingEventsService {
  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _logGtagEventUseCase: LogGTagEventUseCase,
    private _getUserIPAddressUseCase: GetUserIPAddressUseCase,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _responsiveService: ResponsiveService,
  ) {}

  sendLandingMixpanelEvent(eventName: string, eventProperties?: {}) {
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        ...eventProperties,
        landing_page_country: this._getLandingCountry(),
      },
    });
  }

  async sendGtagMixpanelEvent(eventName: string, payload?: { [attr: string]: any }) {
    const commonAttributes = await this._getGtagCommonEvents();
    this._logGtagEventUseCase.execute({
      eventName,
      payload: {
        ...payload,
        'Landing Page Country': this._getLandingCountry(),
        ...commonAttributes,
      },
    });
  }

  private _getLandingCountry() {
    return CountryResolver.getCountryFromIsoCode2(resolveUserLandingUtility().code.toUpperCase())
      .isoCode3;
  }

  private async _getGtagCommonEvents() {
    return {
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute().pipe(take(1))),
      'IP Address': await lastValueFrom(this._getUserIPAddressUseCase.execute().pipe(take(1))),
      Device: this._responsiveService.returnDeviceCategory(),
    };
  }
}
