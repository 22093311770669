import { Mapper } from 'src/app/core/base/mapper';
import { NewIncentiveProgramModel } from 'src/app/core/domain/new-incentive-program.model';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { NewIncentiveProgramEntity } from '../entities/new-incentive-program-entity';

export class CurrentIncentiveProgramMapper extends Mapper<
  NewIncentiveProgramEntity,
  NewIncentiveProgramModel
> {
  mapFrom(incentiveProgram: NewIncentiveProgramEntity): NewIncentiveProgramModel {
    const currentLevel = incentiveProgram.levels.filter(
      (item) => item.level === incentiveProgram.currentLevel,
    )[0];
    const nextLevel = incentiveProgram.levels.filter(
      (item) => item.level === incentiveProgram.currentLevel + 1,
    )[0];
    const currentBonusLevel = incentiveProgram.bonusLevels.filter(
      (item) => item.level === incentiveProgram.currentBonusLevel,
    )[0];
    return {
      arabicName: incentiveProgram.name.ar,
      nextIncentiveProgramDate: DateWrapperUtility.dateToISOString(
        DateWrapperUtility.getTheStartOfATimeUnit(
          DateWrapperUtility.calculateTimeUnitsFromGivenPoint('month', new Date(), 1, 'forward'),
          'month',
        ),
      ),
      startDate: DateWrapperUtility.dateToISOString(
        DateWrapperUtility.stringToDate(incentiveProgram.startDate),
      ),
      endDate: DateWrapperUtility.dateToISOString(
        DateWrapperUtility.stringToDate(incentiveProgram.endDate),
      ),
      currentLevel: incentiveProgram.currentLevel,
      maximumLevel: incentiveProgram.levels.length,
      rewardPerOrder: currentLevel?.rewardPerOrder || 0,
      deliveredOrders: incentiveProgram.deliveredOrders,
      targetDeliveredOrders: currentLevel?.maxOrders || nextLevel?.minOrders,
      nextLevel: nextLevel?.level || 0,
      nextRewardPerOrder: nextLevel?.rewardPerOrder || 0,
      deliveryRate: incentiveProgram.deliveryRate,
      minimumDeliveryRate: incentiveProgram.minimumDeliveryRate,
      bonusMultiplier: currentBonusLevel?.bonusMultiplier || 0,
      isEligible: incentiveProgram.isEligible,
      expectedRewards: incentiveProgram.reward,
      deliveryCalculationDate: DateWrapperUtility.dateToISOString(
        DateWrapperUtility.stringToDate(incentiveProgram.deliveryCalculationDate),
      ),
      levels: incentiveProgram.levels,
      bonusLevels: incentiveProgram.bonusLevels,
      isIncentiveCapped: incentiveProgram.incentiveType === 'capped',
      renewalDate: incentiveProgram.renewalDate,
    };
  }

  mapTo(incentiveProgram: NewIncentiveProgramModel): NewIncentiveProgramEntity {
    throw new Error('Method not implemented.');
  }
}
