import { Mapper } from 'src/app/core/base/mapper';
import { WalletTransactionRequestFilterModel } from 'src/app/core/domain/wallet/wallet.model';
import { WalletTransactionRequestFilterEntity } from '../entities/wallet.entity';

export class WalletTransactionHistoryFilterMapper
  implements Mapper<WalletTransactionRequestFilterEntity, WalletTransactionRequestFilterModel>
{
  mapFrom(param: WalletTransactionRequestFilterEntity): WalletTransactionRequestFilterModel {
    return {
      page: param.page,
      pageSize: param.pageSize,
      currency: param.currency,
      type: param.type,
      toDate: param.toDate,
      fromDate: param.fromDate,
    };
  }

  mapTo(param: WalletTransactionRequestFilterModel): WalletTransactionRequestFilterEntity {
    return {
      page: param.page,
      pageSize: param.pageSize,
      currency: param.currency!,
      ...(param.currency ? { currency: param.currency } : {}),
      ...(param.type ? { type: param.type } : {}),
      ...(param.toDate ? { toDate: param.toDate } : {}),
      ...(param.fromDate ? { fromDate: param.fromDate } : {}),
    };
  }
}
