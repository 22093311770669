import { resolveFirebaseRemoteConfigRepository, resolveUserFeaturesRepository } from '@di/app';
import { ListFirebaseRemoteConfigFeaturesUseCase, ListUserFeaturesUseCase } from '../types';
import { listFirebaseRemoteConfigFeaturesUseCase, listUserFeaturesUseCase } from '../use-cases';

export class FeatureManagerUseCasesResolver {
  /**
   * Get user features list
   */
  public static async userFeatures(): ReturnType<ListUserFeaturesUseCase> {
    return listUserFeaturesUseCase(resolveUserFeaturesRepository());
  }

  /**
   * Get firebase features
   */
  public static async firebaseFeatures(): ReturnType<ListFirebaseRemoteConfigFeaturesUseCase> {
    return listFirebaseRemoteConfigFeaturesUseCase(resolveFirebaseRemoteConfigRepository());
  }
}
