import { Mapper } from 'src/app/core/base/mapper';
import { AuthModel } from 'src/app/core/domain/auth/auth.model';
import { AuthEntity } from '../remote/entities/auth-entity';

export class AuthMapper extends Mapper<AuthEntity, AuthModel> {
  mapFrom(param: AuthEntity): AuthModel {
    return {
      verificationState: param.verificationState,
      actualVerificationState: param.actualVerificationState,
      token: param.jwtToken,
      taagerId: param.taagerId,
      email: param.email,
      password: param.password,
      selectedMarket: param.selectedMarket,
      phoneNumber: param.phoneNumber,
      callingCode: param.callingCode,
      features: param.features,
      fullName: param.fullName,
    };
  }

  mapTo(param: AuthModel): AuthEntity {
    throw new Error('Method not implemented.');
  }
}
