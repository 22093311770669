import { BidiModule } from '@angular/cdk/bidi';
import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from '../../shared/interfaces/commercial-categories.interface';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { sortByAttribute } from '../../shared/utilities/sort-by-attribute.utility';

@Component({
  selector: 'app-subcategories-list',
  templateUrl: './subcategories-list.component.html',
  styleUrls: ['./subcategories-list.component.scss'],
  standalone: true,
  imports: [NgIf, BidiModule, RouterLink, NgFor, TranslateModule],
})
export class SubcategoriesListComponent implements OnChanges, OnDestroy {
  @Input() hoveredCategoryId: string;

  commercialSubCategoriesList: CommercialCategoryTreeNode[];

  isEnglishLanguage: boolean;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
  ) {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnChanges(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (commercialCategoriesTree) => {
        const commercialSubCategoriesNodesList = commercialCategoriesTree.root.children;
        const hoveredCategory = commercialSubCategoriesNodesList.filter(
          (category) => category.key === this.hoveredCategoryId,
        )[0];
        this.commercialSubCategoriesList =
          hoveredCategory.children.sort((categoryOne, categoryTwo) =>
            sortByAttribute(categoryOne.children, categoryTwo.children, 'length'),
          ) || [];
      },
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
