import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  ArticleModel,
  SubTopicModel,
  TopicContentModel,
  TopicModel,
  VideoModel,
} from 'src/app/core/domain/learning-center.model';
import { TopicEntity } from './entities/learning-center.entity';
import { LearningCenterProvider } from './learning-center-provider';

@Injectable({
  providedIn: 'root',
})
export class LearningCenterApi {
  constructor(private provider: LearningCenterProvider) {}

  getTopics(): Observable<TopicEntity[]> {
    return of(this.provider.getTopics());
  }

  getSubTopics(topicId: string): Observable<SubTopicModel[]> {
    return of(this.provider.getTopicById(topicId)?.subTopics);
  }

  getSubTopicContent(subTopicId: string): Observable<TopicContentModel> {
    return of(this.provider.getSubTopicById(subTopicId)?.content);
  }

  getSubTopicContentItem(contentId: string): Observable<VideoModel | ArticleModel> {
    return of(this.provider.getContentById(contentId));
  }

  getTopicFromSubTopicId(subTopicId: string): Observable<TopicModel> {
    return of(this.provider.getTopicFromSubTopicId(subTopicId));
  }
}
