import { Observable } from 'rxjs';
import { CommercialCategoryTree } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { UseCase } from '../../base/use-case';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class GetPreOrderCommercialCategoriesUseCase
  implements UseCase<string, CommercialCategoryTree>
{
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(countryCode: string): Observable<CommercialCategoryTree> {
    return this._bulkPreOrderRepository.getPreOrderCommercialCategories(countryCode);
  }
}
