import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { UpdateProfileRequest } from './entities/update-profile.entity';
import { UserEntity } from './entities/user.entity';
import { USER_APIS_ENDPOINTS } from './user-apis-endpoints';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  updateSwitchedSelectedMarket(selectedMarket: string): Observable<any> {
    return this.http.patch(API_URLS.UPDATE_SWITCHED_SELECTED_MARKET, { selectedMarket });
  }

  updatePicture(data: any): Observable<any> {
    return this.http.post(API_URLS.UPDATE_PICTURE_URL, data);
  }

  updateUserProfile(userData: UpdateProfileRequest): Observable<void> {
    if (!userData?.storeInformation?.logo) {
      delete userData.storeInformation?.logo;
    }
    if (!userData.storeInformation?.storeName) {
      delete userData.storeInformation?.storeName;
    }
    return this.http.patch<void>(API_URLS.UPDATE_USER_PROFILE_URL, userData);
  }

  deleteAccount(password: string): Observable<void> {
    return this.http.patch<void>(USER_APIS_ENDPOINTS.DELETE_ACCOUNT, { password });
  }

  getUserProfile(): Observable<{ data: UserEntity }> {
    return this.http.get<{ data: UserEntity }>(USER_APIS_ENDPOINTS.GET_PROFILE_URL);
  }
}
