import { Injectable } from '@angular/core';
import { MerchantInsightsModel } from 'src/app/core/domain/merchant-insights.model';

const INSIGHTS_DATA: MerchantInsightsModel[] = [
  {
    name: 'INSIGHTS.MY_ORDERS',
    id: 1,
    type: 'dashboard',
    index: 0,
  },
  {
    name: 'INSIGHTS.PRODUCTS_SOLD',
    id: 14,
    type: 'question',
    index: 1,
  },
  {
    name: 'INSIGHTS.PROVINCES_SOLD_TO',
    id: 16,
    type: 'question',
    index: 2,
  },
  {
    name: 'INSIGHTS.UPSELLING',
    id: 34,
    type: 'dashboard',
    index: 4,
  },
  {
    name: 'INSIGHTS.NOT_CONFIRMED_REASONS',
    id: 67,
    type: 'dashboard',
    index: 5,
    new: true,
  },
];

export const BREAK_EVEN_AND_AVG_PROFIT_DATA: MerchantInsightsModel = {
  name: 'INSIGHTS.PROFITS',
  id: 4,
  type: 'dashboard',
  index: 3,
};

@Injectable({
  providedIn: 'root',
})
export class InsightsLocalDataSource {
  getInsights(): MerchantInsightsModel[] {
    return INSIGHTS_DATA;
  }
}
