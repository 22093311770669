import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TopicModel } from '../../domain/learning-center.model';
import { LearningCenterRepository } from '../../repositories/learning-center.repository';

export class GetTopicFromSubTopicId implements UseCase<string, TopicModel> {
  constructor(private learningCenterRepository: LearningCenterRepository) {}

  execute(subTopicId: string): Observable<TopicModel> {
    return this.learningCenterRepository.getTopicFromSubTopicId(subTopicId);
  }
}
