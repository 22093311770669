<div class="wrapper">
  <div class="wrapper__steps">
    <div
      *ngFor="let step of questionnaire.questions; let idx = index"
      class="wrapper__steps__step {{ idx <= questionIndex ? 'filled' : 'empty' }}"
    ></div>
  </div>
  <div class="wrapper__question">
    <div
      class="caption1--bold wrapper__question__text {{
        questionnaire.questions[questionIndex].required ? 'required' : 'optional'
      }}"
    >
      {{
        translationLanguage === 'ar'
          ? questionnaire.questions[questionIndex].textArabic
          : questionnaire.questions[questionIndex].textEnglish
      }}
      <span
        *ngIf="!questionnaire.questions[questionIndex].required"
        class="wrapper__question__required caption2--bold"
      >
        ({{ 'CUSTOMER_EFFORT_SUPPORT.OPTIONAL' | translate }})
      </span>
    </div>
    <ng-container #questionOutlet></ng-container>
  </div>
</div>
