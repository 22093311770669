import { Injectable } from '@angular/core';
import { FAQEntity } from './faq.entity';

const LANDING_COMMON_QUESTIONS: FAQEntity[] = [
  {
    question: 'LOYALTY_PROGRAM.FAQ.1.QUESTION',
    answer: 'LOYALTY_PROGRAM.FAQ.1.ANSWER',
  },
  {
    question: 'LOYALTY_PROGRAM.FAQ.2.QUESTION',
    answer: 'LOYALTY_PROGRAM.FAQ.2.ANSWER',
  },
];

@Injectable({
  providedIn: 'root',
})
export class FAQsLocalDataSource {
  getFAQssData(): FAQEntity[] {
    return LANDING_COMMON_QUESTIONS;
  }
}
