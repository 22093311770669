import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class SetSelectedProductsPageExperienceUseCase implements UseCase<boolean, void> {
  constructor(private _productsRepository: ProductsRepository) {}

  execute(displayNewProductsPage: boolean = true) {
    return this._productsRepository.setSelectedProductsPageExperience(displayNewProductsPage);
  }
}
