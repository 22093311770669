import { UseCase } from '../../base/use-case';
import { UserModel } from '../../domain/user/user.model';
import { UserRepository } from '../../repositories/user.repository';

export class SetUserUseCase implements UseCase<UserModel, void> {
  constructor(private userRepository: UserRepository) {}

  execute(params: UserModel): void {
    return this.userRepository.setUser(params);
  }
}
