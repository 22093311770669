import {
  AuthRepositoryContract,
  EmailLoginData,
  ForgetPasswordData,
  ForgetPasswordUseCase,
  GoogleLoginData,
  LoginByEmailUseCase,
  LoginByGoogleUseCase,
  LoginByPhoneUseCase,
  LogoutUseCase,
  PhoneNumberLoginData,
  RegisterData,
  RegisterUseCase,
  ResetPasswordData,
  ResetPasswordUseCase,
  RotateRefreshTokenData,
  RotateRefreshTokenUseCase,
  UpdateJwtData,
  UpdateJwtUseCase,
  VerifyPhoneNumberData,
  VerifyPhoneNumberUseCase,
} from '../types';

/**
 * Perform login by email
 */
export const loginByEmailUseCase: LoginByEmailUseCase = async (
  repository: AuthRepositoryContract,
  data: EmailLoginData,
) => {
  return repository.loginByEmail(data);
};

/**
 * Perform login by phone
 */
export const loginByPhoneUseCase: LoginByPhoneUseCase = async (
  repository: AuthRepositoryContract,
  data: PhoneNumberLoginData,
) => {
  return repository.loginByPhone(data);
};

/**
 * Perform login by google
 */
export const loginByGoogleUseCase: LoginByGoogleUseCase = async (
  repository: AuthRepositoryContract,
  data: GoogleLoginData,
) => {
  return repository.loginByGoogle(data);
};

/**
 * Perform forget password
 */
export const forgetPasswordUseCase: ForgetPasswordUseCase = async (
  repository: AuthRepositoryContract,
  data: ForgetPasswordData,
) => {
  return repository.forgetPassword(data);
};

/**
 * Reset user password
 */
export const resetPasswordUseCase: ResetPasswordUseCase = async (
  repository: AuthRepositoryContract,
  data: ResetPasswordData,
) => {
  return repository.resetPassword(data);
};

/**
 *  Register new user
 */
export const registerPasswordUseCase: RegisterUseCase = async (
  repository: AuthRepositoryContract,
  data: RegisterData,
) => {
  return repository.register(data);
};

/**
 *  Verify user phone number
 */
export const verifyPhoneNumberUseCase: VerifyPhoneNumberUseCase = async (
  repository: AuthRepositoryContract,
  data: VerifyPhoneNumberData,
) => {
  return repository.verifyPhoneNumber(data);
};

/**
 * Log the user out
 */
export const logoutUseCase: LogoutUseCase = async (repository: AuthRepositoryContract) => {
  return repository.logout();
};

/**
 *  Rotate refresh token
 */
export const rotateRefreshTokenUseCase: RotateRefreshTokenUseCase = async (
  repository: AuthRepositoryContract,
  data: RotateRefreshTokenData,
) => {
  return repository.rotateRefreshToken(data);
};

/**
 *  Update JWT
 */
export const updateJwtUseCase: UpdateJwtUseCase = async (
  repository: AuthRepositoryContract,
  data: UpdateJwtData,
) => {
  return repository.updateJwt(data);
};
