<button mat-button class="caption1--medium" [matMenuTriggerFor]="achieveMenu" class="header-button">
  <div class="header-button-content">
    <ng-lottie [options]="options" class="header-button-content__coin-animation"></ng-lottie>
    <p class="caption1--medium header-button-content__title">
      {{ ACHIEVE_EARN_TAB.tabName | translate }}
    </p>
    <i class="icon icon-header-icons-chevron-down header-button-content__dropdown-icon"></i>
    <img
      [src]="'assets/img/header-icons/new-' + (viewState.textIsRTL ? 'ar' : 'en') + '.svg'"
      alt="new"
    />
  </div>
  <mat-menu #achieveMenu="matMenu">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngIf="checkTabUserFeature(tab.tabUserFeature)">
        <button
          mat-menu-item
          *ngIf="checkTabFeatureFlag(tab.tabFeatureFlag) | async"
          class="tab"
          ngClass="{{ viewState.textIsRTL ? 'rtl' : 'ltr' }}"
          (click)="onMenuItemClick(tab)"
        >
          <img
            loading="lazy"
            src="{{ tab.iconPath }}"
            alt="{{ tab.tabName | translate }} 's image"
            class="tab__icon"
          />
          <span class="tab__name body2--bold">{{ tab.tabName | translate }}</span>
          <img
            [src]="'assets/img/header-icons/new-' + (viewState.textIsRTL ? 'ar' : 'en') + '.svg'"
            alt="new"
            *ngIf="tab.newTab"
          />
        </button>
      </ng-container>
    </ng-container>
  </mat-menu>
</button>
