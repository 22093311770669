import { Mapper } from 'src/app/core/base/mapper';
import { UserInfoModel } from 'src/app/core/domain/auth/auth.model';
import { UserInfoEntity } from '../../auth/remote/entities/auth-entity';

export class UserInfoMapper extends Mapper<UserInfoEntity, UserInfoModel> {
  mapFrom(param: UserInfoEntity): UserInfoModel {
    return {
      fullName: param.fullName,
      nationality: param.nationality,
      preferredCategories: param.preferredCategories,
      storeName: param.storeName,
      gender: param.gender,
      age: param.age,
    };
  }

  mapTo(param: UserInfoModel): UserInfoEntity {
    return {
      fullName: param.fullName,
      nationality: param.nationality,
      preferredCategories: param.preferredCategories,
      storeName: param.storeName!,
      gender: param.gender,
      age: param.age,
    };
  }
}
