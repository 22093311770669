import { Component, inject, OnInit } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BaseOverlayDialogComponent } from 'src/app/presentation/shared/components/overlay/base-overlay-dialog.component';

@Component({
  selector: 'app-country-not-covered-dialog',
  templateUrl: './country-not-covered-dialog.component.html',
  styleUrls: ['./country-not-covered-dialog.component.scss'],
  standalone: true,
})
export class CountryNotCoveredDialogComponent extends BaseOverlayDialogComponent implements OnInit {
  public assetsPath: string = '/assets/img/';

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  ngOnInit(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_phone_verify_skipped' });
  }
}
