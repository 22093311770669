import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { user } from '@features/user/data';
import { Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { KYC_USER_FEATURE, LOGIN_URL, WALLET_URL } from '../../../constants';
import { KYC_FEATURE } from '../../../constants/feature-flags';
import { stringToUrlTree } from './shared';

export const KYCResolver = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);
  return new Observable((subscriber) => {
    if (user.isLoggedIn) {
      getFeatureFlagUseCase.execute(KYC_FEATURE).subscribe({
        next: (flagIsOn) => {
          const isUserFeatureExist = checkUserFeatureExistsUseCase.execute(KYC_USER_FEATURE);
          if (flagIsOn && isUserFeatureExist) {
            subscriber.next(true);
          } else {
            subscriber.next(stringToUrlTree(WALLET_URL, router));
          }
        },
      });
    } else {
      subscriber.next(stringToUrlTree(LOGIN_URL, router));
    }
  });
};
