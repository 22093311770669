import {
  COUNTRY_LANGUAGE_CODES_MAPPING,
  DEFAULT_LANDING_COUNTRY,
} from '../constants/country-language-codes-mapping.constants';
import { decodeUserCountryCodeUtility } from './decode-user-country-code.utility';

interface MatchedLandingMeta {
  path: string;
  code: string;
}

/**
 * if we already have resolved the user landing in some other part of the app
 *
 * we no longer need this check, as the user may change their location within the
 * app
 *
 * DEV NOTES:
 *
 * 1. We no longer need to store this, since the value may change in-app
 * as the user changes their selectedMarkets, so we will be calculating
 * and returning the value each time.
 */
let MATCHED_LANDING: MatchedLandingMeta;

export const resolveUserLandingUtility = (): MatchedLandingMeta => {
  // get the landing url
  const { pathname } = window.location;
  let landingBase: string = '';
  // if the path is an empty landing page, maybe an organic
  if (pathname === '/') {
    /**
     * so, if the path is empty one, then we need to infer the location from
     * where the user is visiting, and we gently route them there, IFF the
     * origin is supported, else we use the default landing country
     */
    landingBase = DEFAULT_LANDING_COUNTRY;
    const visitingUserCountryCode = decodeUserCountryCodeUtility();
    if (COUNTRY_LANGUAGE_CODES_MAPPING[visitingUserCountryCode]) {
      /**
       * visiting country code is supported, so no problem, we then we proceed and
       * set this as the landing base
       */
      landingBase = visitingUserCountryCode;
    }
  } else {
    const actualLandingPath = pathname.split('/')[1];
    if (actualLandingPath && COUNTRY_LANGUAGE_CODES_MAPPING[actualLandingPath]) {
      landingBase = actualLandingPath;
    } else {
      landingBase = DEFAULT_LANDING_COUNTRY;
    }
  }
  MATCHED_LANDING = {
    path: `/${landingBase}`,
    code: landingBase,
  };
  return MATCHED_LANDING;
};
