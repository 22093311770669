import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { OperationRateModel } from '../domain/incentive-program.model';
import { IncentiveProgramRepository } from '../repositories/incentive-program.repository';

export class GetOperationRateUseCase implements UseCase<void, OperationRateModel> {
  constructor(private incentiveProgramRepository: IncentiveProgramRepository) {}

  execute(): Observable<OperationRateModel> {
    return this.incentiveProgramRepository.getOperationRateDetails();
  }
}
