import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { FEATURE_FLAGS, PRE_ORDER_USER_FEATURE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {}

  isDarkMode(): Observable<boolean> {
    const currentCountry = country.code;
    const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
      PRE_ORDER_USER_FEATURE + currentCountry.toLowerCase(),
    );
    return combineLatest([
      this.darkMode.asObservable(),
      this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_UI_REVAMP),
    ]).pipe(map(([darkModeValue, flagValue]) => darkModeValue && flagValue && userHasPreOrder));
  }

  setDarkMode(isDarkMode: boolean): void {
    this.darkMode.next(isDarkMode);
  }
}
