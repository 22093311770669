import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { FinalIncentiveProgramModel } from '../domain/new-incentive-program.model';
import { NewIncentiveProgramRepository } from '../repositories/new-incentive-program.repository';

export class GetNewIncentiveProgramDetailsUseCase
  implements UseCase<void, FinalIncentiveProgramModel>
{
  constructor(private newIncentiveProgramRepository: NewIncentiveProgramRepository) {}

  execute(): Observable<FinalIncentiveProgramModel> {
    return this.newIncentiveProgramRepository.getNewIncentiveProgramDetails();
  }
}
