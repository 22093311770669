import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ReferralsProgramModel } from '../../domain/referrals-program.model';
import { ReferralProgramRepository } from '../../repositories/referral-program.repository';

export class GetReferralsProgramUseCase
  implements UseCase<{ page: number; pageSize: number }, ReferralsProgramModel>
{
  constructor(private _referralProgramRepository: ReferralProgramRepository) {}

  execute(param: { page: number; pageSize: number }): Observable<ReferralsProgramModel> {
    return this._referralProgramRepository.getReferralsProgram(param.page, param.pageSize);
  }
}
