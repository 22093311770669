import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductCheckoutModel } from '../../domain/products/product-checkout';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetProductCheckoutDetailUseCase implements UseCase<string, ProductCheckoutModel> {
  constructor(private _productRepository: ProductsRepository) {}

  execute(productId: string): Observable<ProductCheckoutModel> {
    return this._productRepository.getProductCheckoutDetail(productId);
  }
}
