<div>
  <section class="header">
    <p class="header__title body1--bold content-main">{{ trans('kyc-popup.header') }}</p>
    <button (click)="closeDialog()" class="header__close-button">
      <img src="assets/img/wallet-icons/close-circle.svg" />
    </button>
  </section>
  <section class="verify">
    <img src="assets/img/id-card.svg" alt="" class="verify__img" />
    <div>
      <p class="heading2--extra-bold verify__title content-main">
        {{ trans('kyc-popup.title') }}
      </p>
      <p class="body2--regular verify__body content-medium">
        {{ trans('kyc-popup.body') }}
      </p>
    </div>
    <button (click)="goToKYC()" class="verify__button body1--bold">
      {{ trans('kyc-popup.button') }}
    </button>
  </section>
</div>
