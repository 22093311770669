import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { CommercialCategoryTreeNode } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, NgIf],
})
export class CategoryListItemComponent implements OnInit, OnDestroy {
  @Input() categoryNode: CommercialCategoryTreeNode;

  @Input() hoveredCategoryId: string;

  public iconsBaseUrl = '';

  public isEnglishLanguage: boolean;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _translateService: TranslateService,
    private _siteTranslationService: SiteTranslateService,
  ) {}

  ngOnInit(): void {
    this.iconsBaseUrl = environment.IMAGES_BUCKET_URL;

    this.isEnglishLanguage = this._siteTranslationService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }

  getCategoryImage(): string {
    if (!this.categoryNode.value.icon) {
      return 'assets/img/header-icons/megamenu-icon.svg';
    }

    return `${this.iconsBaseUrl}commercial-categories/icons/${this.categoryNode.value.icon}`;
  }
}
