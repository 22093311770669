import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanProductModel } from '../../domain/dukan-shop/dukan-product.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class GetDukanProductDetailsUseCase implements UseCase<string, DukanProductModel> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(sku: string): Observable<DukanProductModel> {
    return this._dukanShopRepository.getProductDetails(sku);
  }
}
