import { UseCase } from '../../base/use-case';
import { UserRepository } from '../../repositories/user.repository';

export class GetUserSuperPropertiesUseCase implements UseCase<void, any> {
  constructor(private userRepository: UserRepository) {}

  execute(params: void): any {
    return this.userRepository.getUserSuperProperties();
  }
}
