import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { MerchantStore, MerchantStoreAuth } from 'src/app/core/domain/merchant-store.model';
import { StoreModel } from 'src/app/core/domain/store.model';
import { UserStoreEntity } from '../repositories/merchant-store/entities/user-store.entity';
import { API_ENDPOINTS } from './endpoints';
import { APIKeyGenerateEntity, APIKeysGetEntity } from './entities/api-keys-integration.entity';
import { StoresLocalDataSource } from './mappers/stores-local-data-source';

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreApiService {
  constructor(private http: HttpClient, private _storesLocalDataSource: StoresLocalDataSource) {}

  linkStore(request: MerchantStoreAuth): Observable<MerchantStore> {
    return this.http.post<MerchantStore>(API_ENDPOINTS.LINK_MERCHANT_STORE, request);
  }

  unlinkStore(storeId: string): Observable<void> {
    return this.http.delete<void>(API_ENDPOINTS.UNLINK_MERCHANT_STORE(storeId));
  }

  addProductToStore(merchantStore: MerchantStore, productId: string): Observable<null> {
    return this.http
      .post(
        API_ENDPOINTS.ADD_PRODUCT_TO_STORE(merchantStore.storeId, productId),
        {},
        { params: { provider: merchantStore.provider } },
      )
      .pipe(map(() => null));
  }

  getStores(): Observable<StoreModel[]> {
    return of(this._storesLocalDataSource.getStores());
  }

  getUserStores(): Observable<UserStoreEntity[]> {
    return this.http.get<UserStoreEntity[]>(API_ENDPOINTS.GET_USERS_STORES);
  }

  generateAPIKey(expiresAt: string): Observable<APIKeyGenerateEntity> {
    return this.http.post<APIKeyGenerateEntity>(API_ENDPOINTS.GENERATE_API_KEY, { expiresAt });
  }

  getAPIKeys(): Observable<APIKeysGetEntity[]> {
    return this.http.get<APIKeysGetEntity[]>(API_ENDPOINTS.GET_API_KEYS);
  }
}
