import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductCheckoutCreateModel } from '../../domain/products/product-checkout';
import { ProductsRepository } from '../../repositories/products.repository';

export class CreateProductCheckoutUseCase implements UseCase<ProductCheckoutCreateModel, void> {
  constructor(private productsRepository: ProductsRepository) {}

  execute(data: ProductCheckoutCreateModel): Observable<void> {
    return this.productsRepository.productCheckoutCreate(data);
  }
}
