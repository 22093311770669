/**
 * So, we will be having a logic shared for checking whether the user is:
 *
 * - logged in.
 * - fully verified
 *
 * It will also accept a couple of navigation rules, so that the user can be
 * gently guided if they cannot activate the current path
 */

import { Router } from '@angular/router';
import { hardNavigate } from '@presentation/shared/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { UserResolutionService } from 'src/app/presentation/shared/services/user-resolution.service';
import { UserRegistrationStepUtility } from 'src/app/presentation/shared/utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from '../../interfaces';
import { isUserLoggedIn } from '../is-user-logged-in.shared.resolver';
import { stringToUrlTree } from '../string-to-url-tree.shared.resolver';
import { userHasFullySignedUp } from '../user-has-fully-signed-up.shared.resolver';

export const loggedInFullySignedUpMixinResolver = ({
  router,
  localStorageService,
  userResolutionService,
  nextPath,
  userRegistrationStepUtility,
  getRegisterTokenUseCase,
}: {
  router: Router;
  localStorageService: LocalStorageService;
  userResolutionService: UserResolutionService;
  nextPath: {
    ifNotLoggedIn: string;
    ifNotFullySignedUp: string;
  };
  userRegistrationStepUtility: UserRegistrationStepUtility;
  getRegisterTokenUseCase: GetRegisterTokenUseCase;
}): Observable<RouteAccessResolutionTypes> => {
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
            next: (hasFullySignedUp) => {
              if (hasFullySignedUp) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(nextPath.ifNotFullySignedUp, router));
              }
            },
          });
        } else {
          // subscriber.next(stringToUrlTree(nextPath.ifNotLoggedIn, router));
          hardNavigate(nextPath.ifNotLoggedIn);
        }
      },
    });
  });
};
