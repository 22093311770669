import { Injectable } from '@angular/core';
import { http } from '@features/drivers/data';
import { MARKET_AVAILABILITY_ENDPOINTS } from './market-availability.api-endpoints';
import { MarketAvailabilityEntity } from './market-availability.entity';

@Injectable({
  providedIn: 'root',
})
export class MarketAvailabilityApiService {
  protected markets: MarketAvailabilityEntity[] = [];

  public async getMarketAvailability(): Promise<MarketAvailabilityEntity[]> {
    if (this.markets.length) {
      return this.markets;
    }

    const response = await http.get<MarketAvailabilityEntity[]>(
      MARKET_AVAILABILITY_ENDPOINTS.GET_AVAILABILITY_URL,
    );

    this.markets = response.data;

    return response.data;
  }
}
