import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MERCHANT_ONBOARDING_FEATURE } from '../../presentation/shared/constants/feature-flags';
import { UseCase } from '../base/use-case';
import { OnboardingFeature, OnboardingRewardType } from '../domain/feature-attributes.model';
import { GetFeatureFlagUsecase } from './get-feature-flag.usecase';

@Injectable({
  providedIn: 'any',
})
export class GetOnboardingFeatureUseCase
  implements UseCase<void, Observable<OnboardingFeature | null>>
{
  constructor(private getFeatureFlagUseCase: GetFeatureFlagUsecase) {}

  execute(): Observable<OnboardingFeature | null> {
    return this.getFeatureFlagUseCase
      .execute(MERCHANT_ONBOARDING_FEATURE)
      .pipe(map((isEnabled) => (isEnabled ? this.buildOnboardingFeature() : null)));
  }

  private buildOnboardingFeature(): OnboardingFeature {
    return {
      rewardType: OnboardingRewardType.Cashback,
    };
  }
}
