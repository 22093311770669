import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { trans } from '@mongez/localization';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductCheckoutModel } from 'app/core/domain/auth/update-profile.model';
import { GetUserProfileUseCase } from 'app/core/usecases/user/get-user-profile.usecase';
import { UpdateProfileUseCase } from 'app/core/usecases/user/update-profile-usecase';
import { UploadImageUseCase } from 'app/core/usecases/utilities/upload-image.usecase';
import { ToastrService } from 'ngx-toastr';
import { finalize, forkJoin } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ProductCheckoutPresenter } from './presenter/product-checkout.presenter';
import { ProductCheckoutSideEffect } from './presenter/product-checkout.sideEffects';
import { ProductCheckoutViewEvents } from './presenter/product-checkout.viewEvents';
import { ProductCheckoutViewState } from './presenter/product-checkout.viewState';

@Component({
  selector: 'app-product-checkout',
  templateUrl: './product-checkout.component.html',
  styleUrls: ['./product-checkout.component.scss'],
  standalone: true,
  providers: [ProductCheckoutPresenter],
  imports: [CommonModule, FormsModule, TranslateModule, LoaderComponent],
})
export class ProductCheckoutComponent extends BaseComponent<
  ProductCheckoutPresenter,
  ProductCheckoutViewState,
  ProductCheckoutViewEvents,
  ProductCheckoutSideEffect
> {
  @ViewChild('fileInput') el: ElementRef;

  imageUrl: any = 'assets/img/gallery-add.svg';

  selectedFile: string;

  storeDetails: ProductCheckoutModel;

  constructor(
    @Inject(ProductCheckoutPresenter) public presenter: ProductCheckoutPresenter,
    private toastr: ToastrService,
    private _updateProfileUseCase: UpdateProfileUseCase,
    private _getUserProfileUseCase: GetUserProfileUseCase,
    private _translateService: TranslateService,
    private _uploadImageUseCase: UploadImageUseCase,
    private _toasterService: ToastrService,
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
    this.getUserProfile();
  }

  uploadFile(event: any) {
    this.selectedFile = event.target.files;
    this.viewState.loading = true;
    const formDataArray = this.getFormDataFromFiles(this.selectedFile);
    forkJoin({
      ...formDataArray.map((file) => this._uploadImageUseCase.execute(file)),
    })
      .pipe(
        finalize(() => {
          this.viewState.loading = false;
        }),
      )
      .subscribe({
        next: (data: any) => {
          this.viewState.showInitialImage = false;
          this.imageUrl = data[0].url;
        },
        error: (err) => {
          this._toasterService.error(
            trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.ERROR_OCCURED'),
          );
        },
      });
  }

  getFormDataFromFiles(files: any) {
    const formDataArray = [];
    const maxSize = 1 * 1024 * 1024;
    if (files && files[0]) {
      const numberOfFiles = files.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const formData = new FormData();
        if (files[i].size < maxSize) {
          formData.append('image', files[i]);
          formDataArray.push(formData);
        } else {
          this._toasterService.error(
            trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.IMAGE_SIZE_ERROR'),
          );
        }
      }
    }
    return formDataArray;
  }

  removeUpload(): void {
    this.viewState.showInitialImage = true;
    this.imageUrl = 'assets/img/gallery-add.svg';
  }

  onSideEffect(sideEffect: ProductCheckoutSideEffect): void {
    switch (sideEffect.type) {
      case 'SaveChanges': {
        this.updateStoreChanges();
        break;
      }
      case 'Discard': {
        this.discardStoreChanges();
        break;
      }
    }
  }

  getUserProfile(): void {
    this._getUserProfileUseCase.execute().subscribe({
      next: (profileDetails) => {
        this.storeDetails = profileDetails.data.storeInformation!;
        this.viewState.storeName = this.storeDetails?.storeName!;
        if (this.storeDetails?.logo) {
          this.imageUrl = this.storeDetails?.logo;
          this.viewState.showInitialImage = false;
        }
      },
    });
  }

  updateStoreChanges(): void {
    if (this.viewState.showInitialImage) {
      this.imageUrl = null;
    }
    if (this.viewState.showInitialImage && !this.viewState.storeName) {
      this.toastr.error(this._translateService.instant('PRODUCT_CHECKOUT_STORE.VALIDATION'));
      return;
    }
    this.viewState.loading = true;
    const params = {
      storeInformation: {
        storeName: this.viewState.storeName,
        logo: this.imageUrl,
      },
    };
    this._updateProfileUseCase
      .execute(params)
      .pipe(
        finalize(() => {
          this.viewState.loading = false;
        }),
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            this._translateService.instant('PRODUCT_CHECKOUT_STORE.STORE_UPDATED'),
          );
        },
      });
  }

  discardStoreChanges(): void {
    this.viewState.storeName = this.storeDetails?.storeName! || null!;
    this.imageUrl = this.storeDetails?.logo || this.removeUpload();
  }
}
