import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { QuestionnaireBannerRepository } from '../repositories/questionnaire-banner.repository';

export class GetQuestionnaireEligibilityUseCase implements UseCase<string, { enabled: boolean }> {
  constructor(private bannerRepository: QuestionnaireBannerRepository) {}

  execute(questionnaireName: string): Observable<{ enabled: boolean }> {
    return this.bannerRepository.getQuestionnaireElgibility(questionnaireName);
  }
}
