import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TopicModel } from '../../domain/learning-center.model';
import { LearningCenterRepository } from '../../repositories/learning-center.repository';

export class GetTopicsUseCase implements UseCase<void, TopicModel[]> {
  constructor(private learningCenterRepository: LearningCenterRepository) {}

  execute(): Observable<TopicModel[]> {
    return this.learningCenterRepository.getTopics();
  }
}
