import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AvailabilityFeatures } from '../../domain/market-availability.model';
import { MarketAvailabilityRepository } from '../../repositories/market-availability.repository';

export class GetMarketClosingReasonUseCase implements UseCase<string, string | undefined> {
  constructor(private _marketAvailabilityRepository: MarketAvailabilityRepository) {}

  execute(feature: AvailabilityFeatures): Observable<string | undefined> {
    return this._marketAvailabilityRepository.getMarketClosingReason(feature);
  }
}
