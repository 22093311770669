import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { ListenToProductsPageExperienceChangesUseCase } from 'src/app/core/usecases/products/listen-to-products-page-experience-change.usecase';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  FEATURE_FLAGS,
  LOYALTY_PROGRAM_FEATURE,
  MERCHANT_INSIGHTS_USER_FEATURE,
  PRE_ORDER_USER_FEATURE,
  REFERRAL_PROGRAM_FEATURE,
  STATICS_FEATURE,
} from 'src/app/presentation/shared/constants';
import {
  BULK_UPLOAD_TAB,
  LOWER_HEADER_TABS,
  MERCHANT_INSIGHTS_TAB,
  ORDER_SUMMARY_TAB,
  PRE_ORDER_TAB,
  PRODUCTS_TAB,
  STATISTICS_TAB,
} from 'src/app/presentation/shared/constants/header-tabs';
import { HeaderTab } from 'src/app/presentation/shared/interfaces/header.interface';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';
import { CountriesDropdownComponent } from '../countries-dropdown/countries-dropdown.component';
import { ProductsSearchBarComponent } from '../products-search-bar/products-search-bar.component';
import { StickyHeaderComponent } from '../sticky-header/sticky-header.component';
import { AchieveEarnMenuComponent } from './achieve-earn-menu/achieve-earn-menu.component';
import { MegaMenuActionButtonComponent } from './mega-menu-action-button/mega-menu-action-button.component';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  standalone: true,
  imports: [
    StickyHeaderComponent,
    NgClass,
    MegaMenuActionButtonComponent,
    NgFor,
    NgIf,
    RouterLink,
    RouterLinkActive,
    CountriesDropdownComponent,
    ProductsSearchBarComponent,
    TranslateModule,
    NgClass,
    AsyncPipe,
    AchieveEarnMenuComponent,
  ],
})
export class MainHeaderComponent implements OnInit {
  public headerLowerPartNavigationElements = LOWER_HEADER_TABS;

  isViewScrolled = false;

  isMobileBannerVisible = false;

  public isDiscoverabilityFunnelEnabled = false;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  darkModeClass$: Observable<string>;

  public isTieringSystemEnabled = false;

  public isReferralProgramEnabled = false;

  constructor(
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
    private _listenToProductsPageExperienceChanges: ListenToProductsPageExperienceChangesUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _themeService: ThemeService,
  ) {
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  @HostListener('window:scroll', ['$event']) getScrollHeight(): void {
    if (window.pageYOffset >= 100) {
      this.isViewScrolled = true;
    } else {
      this.isViewScrolled = false;
    }
  }

  ngOnInit(): void {
    this.isMobileBannerVisible = !!document.querySelector('.smartbanner--android');

    this.updateTabsBasedOnUserFeatures();

    this.checkUserEligibilityForDiscoverabilityFunnel();

    this.checkTieringSystemEnabled();

    this.checkReferralProgramEnabled();
  }

  updateTabsBasedOnUserFeatures(): void {
    if (this._checkUserFeatureExistsUseCase.execute(STATICS_FEATURE)) {
      this.headerLowerPartNavigationElements = [
        ...this.headerLowerPartNavigationElements,
        STATISTICS_TAB,
      ];
    }
    this.headerLowerPartNavigationElements = [PRODUCTS_TAB, BULK_UPLOAD_TAB];
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3).subscribe({
      next: (flag) => {
        const selectedCountry = country.code;
        const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
        );
        if (flag && userHasPreOrder) {
          this.headerLowerPartNavigationElements.push(PRE_ORDER_TAB);
        }
      },
    });

    /**
     * TODO
     *
     * Move the feature flag subscriptons above here, so that we have a bit of an order
     */
    combineLatest({
      orderSummaryFeature: this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.ORDER_SUMMARY_FEATURE),
      merchantInsightsFeature: this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.MERCHANT_INSIGHTS),
    }).subscribe({
      next: ({ orderSummaryFeature, merchantInsightsFeature }) => {
        const userHasMerchantInsights = this._checkUserFeatureExistsUseCase.execute(
          MERCHANT_INSIGHTS_USER_FEATURE,
        );
        if (merchantInsightsFeature && userHasMerchantInsights) {
          this.headerLowerPartNavigationElements.push(MERCHANT_INSIGHTS_TAB);
        } else if (orderSummaryFeature) {
          this.headerLowerPartNavigationElements.push(ORDER_SUMMARY_TAB);
        }
      },
    });
  }

  checkUserEligibilityForDiscoverabilityFunnel(): void {
    this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    this._listenToProductsPageExperienceChanges.execute().subscribe(() => {
      this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
      this.updateTabsBasedOnUserFeatures();
    });
  }

  onNavigateToTab(tab: HeaderTab): void {
    this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName! });
  }

  public checkTieringSystemEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.LOYALTY_PROGRAM).subscribe({
      next: (flag) => {
        const selectedCountry = country.code;
        this.isTieringSystemEnabled =
          flag &&
          country.is('KSA') &&
          this._checkUserFeatureExistsUseCase.execute(LOYALTY_PROGRAM_FEATURE);
      },
    });
  }

  public checkReferralProgramEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.REFERRAL_PROGRAM).subscribe({
      next: (flag) => {
        const userHasReferralProgram =
          this._checkUserFeatureExistsUseCase.execute(REFERRAL_PROGRAM_FEATURE);
        this.isReferralProgramEnabled = flag && country.is('KSA') && userHasReferralProgram;
      },
    });
  }
}
