import { trans } from '@mongez/localization';
import { Country } from '../../domain';
import type { CountryModel } from '../models/country.model';
import { getCountryFlag } from '../utils';

export function mapCountry(country: CountryModel): Country {
  return {
    currencyCode: country.currencyIsoCode,
    isoCode2: country.countryIsoCode2,
    isoCode3: country.countryIsoCode3,
    flag: getCountryFlag(country.countryIsoCode3),
    name: trans(country.countryIsoCode3),
    vat: country.vat,
  };
}
