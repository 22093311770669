import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { CategoryItemModel } from '../domain/category-item.model';
import { CommercialCategoryRepository } from '../repositories/commercial-categories.repository';

export class GetBottomLevelCategoriesUseCase implements UseCase<void, CategoryItemModel[]> {
  constructor(private _commercialCategoryRepository: CommercialCategoryRepository) {}

  execute(): Observable<CategoryItemModel[]> {
    return this._commercialCategoryRepository.getBottomLevelCategories();
  }
}
