import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OrderModel } from '../../domain/order-model';
import { OrderRepository } from '../../repositories/order.repository';

export class MakeOrderByCartUseCase implements UseCase<OrderModel, { order: { orderID: string } }> {
  constructor(private _orderRepository: OrderRepository) {}

  execute(order: OrderModel): Observable<{ order: { orderID: string } }> {
    return this._orderRepository.makeOrderByCart(order);
  }
}
