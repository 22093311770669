import * as Merchant from '@taager-experience-shared/merchant';
import ApiInjector = Merchant.com.taager.experience.api.di.ApiInjector;
import ProvincesJsInjector = Merchant.com.taager.experience.provinces.di.ProvincesJsInjector;
import GetAllProvincesUseCaseAsync = Merchant.com.taager.experience.provinces.domain.interactor.GetAllProvincesUseCaseAsync;

const ProvincesJsInjectorFactory = (apiInjector: ApiInjector) =>
  new ProvincesJsInjector(apiInjector.client);
const ProvincesJsInjectorProvider = {
  provide: ProvincesJsInjector,
  useFactory: ProvincesJsInjectorFactory,
  deps: [ApiInjector],
};
const GetAllProvincesUseCaseFactory = (injector: ProvincesJsInjector) =>
  injector.getAllProvincesUseCaseAsync;
const GetAllProvincesUseCaseProvider = {
  provide: GetAllProvincesUseCaseAsync,
  useFactory: GetAllProvincesUseCaseFactory,
  deps: [ProvincesJsInjector],
};
export const ExperienceProvincesProviders = [
  ProvincesJsInjectorProvider,
  GetAllProvincesUseCaseProvider,
];
