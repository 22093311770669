import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { LeadListFilters, LeadListPaginatedModel } from '../../domain/lead.model';
import { LeadsRepository } from '../../repositories/lead.repository';

export class GetLeadsListUseCase implements UseCase<LeadListFilters, LeadListPaginatedModel> {
  constructor(private _leadsRepository: LeadsRepository) {}

  execute(params: LeadListFilters): Observable<LeadListPaginatedModel> {
    return this._leadsRepository.getLeadsList(params);
  }
}
