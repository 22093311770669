export interface OrdersTracking {
  orders: OrderProgress[];
}

export interface OrderProgress {
  createdAt: Date;
  steps: OrderStep[];
  currentStep: number;
}

export enum OrderStep {
  ProductSelected = 0,
  AddedToCart = 1,
  Placed = 2,
  Confirmed = 3,
  Delivered = 4,
}
