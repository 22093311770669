<button
  class="user-dropdown-btn"
  mat-button
  [matMenuTriggerFor]="accountMenu"
  [ngClass]="darkModeClass$ | async"
>
  <i class="user-dropdown-btn__icon icon icon-header-icons-profile"></i>
  <p class="user-dropdown-btn__welcome-text body2--medium">
    {{ 'HEADER.PROFILE_DROPDOWN.SALUTATION' | translate }}
    <bdi dir="auto" class="body2--medium">{{ userName }}</bdi>
  </p>

  <i class="icon icon-header-icons-chevron-down user-dropdown-btn__dropdown-icon"></i>
  <mat-menu #accountMenu="matMenu" xPosition="before">
    <button
      mat-menu-item
      *ngFor="let tab of tabs"
      (click)="onMenuItemClick(tab)"
      class="tab"
      [ngClass]="{ tab__ltr: isLTR }"
    >
      <img
        loading="lazy"
        src="{{ tab.iconPath }}"
        alt="{{ tab.tabName | translate }} 's image"
        class="tab__icon"
      />
      <span class="tab__name body2--bold">{{ tab.tabName | translate }}</span>
    </button>
  </mat-menu>
</button>
