import { Injectable } from '@angular/core';
import { LearningRemoteEntity } from './entities/learning-remote-entity';

const LEARNING = {
  courses: [
    {
      id: 'youcan',
      name: {
        en: 'ابدأ متجرك من خلال (Youcan)',
        ar: 'ابدأ متجرك من خلال (Youcan)',
      },
      iconUrl: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/youcan.svg',
      description: {
        en: 'كل ما تحتاج معرفته للوصول إلي طلبك الأول مع تاجر وبدء رحلة الأرباح في السعودية والإمارات ومصر',
        ar: 'كل ما تحتاج معرفته للوصول إلي طلبك الأول مع تاجر وبدء رحلة الأرباح في السعودية والإمارات ومصر',
      },
      lessons: [
        {
          id: 'youcan1',
          title: {
            en: 'ابدأ متجرك من خلال (Youcan)',
            ar: 'ابدأ متجرك من خلال (Youcan)',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/wi-OL6UWhrE',
        },
        {
          id: 'youcan2',
          title: {
            en: 'تعلم بناء صفحة هبوط احترافية لمنتجك من خلال منصة (Youcan)',
            ar: 'تعلم بناء صفحة هبوط احترافية لمنتجك من خلال منصة (Youcan)',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/COD0TvvpFoM',
        },
      ],
    },
    {
      id: 'tiktok',
      name: {
        en: 'إزاي تعمل إعلان (Lead generation) من خلال تيك توك',
        ar: 'إزاي تعمل إعلان (Lead generation) من خلال تيك توك',
      },
      iconUrl: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/tiktok.svg',
      description: {
        en: '',
        ar: '',
      },
      lessons: [
        {
          id: 'tiktok1',
          title: {
            en: 'إزاي تعمل إعلان (Lead generation) من خلال تيك توك',
            ar: 'إزاي تعمل إعلان (Lead generation) من خلال تيك توك',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/Ue_rsIjTV1U',
        },
      ],
    },
    {
      id: 'taager_plugin',
      name: {
        en: 'Taager plugin',
        ar: 'إدارة متجرك مع إضافة تاجر',
      },
      iconUrl: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/taager_plugin.svg',
      description: {
        en: 'اعرف إزاي تسهل شغلك وتزود مبيعاتك وارباحك من خلال إضافة تاجر على الووردبريس وتستورد كل المنتجات على متجرك، وتحدث متجرك مع موقع تاجر لحظة بلحظة.',
        ar: 'اعرف إزاي تسهل شغلك وتزود مبيعاتك وارباحك من خلال إضافة تاجر على الووردبريس وتستورد كل المنتجات على متجرك، وتحدث متجرك مع موقع تاجر لحظة بلحظة.',
      },
      lessons: [
        {
          id: 'taager_plugin1',
          title: {
            en: 'إزاي تثبت البلجن من داخل الوردبريس',
            ar: 'إزاي تثبت البلجن من داخل الوردبريس',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/55CEE2zdFHY',
        },
        {
          id: 'taager_plugin2',
          title: {
            en: 'إزاي تثبت البلجن من على موقع تاجر',
            ar: 'إزاي تثبت البلجن من على موقع تاجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/r4Rvcki0Dy0',
        },
        {
          id: 'taager_plugin3',
          title: {
            en: 'تفعيل التحديث التلقائي للبلجن',
            ar: 'تفعيل التحديث التلقائي للبلجن',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/1JCb5HFcCI8',
        },
        {
          id: 'taager_plugin4',
          title: {
            en: 'إعدادات الووكميرس مع البلجن',
            ar: 'إعدادات الووكميرس مع البلجن',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/yzO2KlXmOPo',
        },
        {
          id: 'taager_plugin5',
          title: {
            en: 'اختيار البلد اللي هتشتغل عليها',
            ar: 'اختيار البلد اللي هتشتغل عليها',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/_UiNoIHD_Q4',
        },
        {
          id: 'taager_plugin6',
          title: {
            en: 'تحديث أسعار الشحن',
            ar: 'تحديث أسعار الشحن',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/kKEn9kzxYQM',
        },
        {
          id: 'taager_plugin7',
          title: {
            en: 'استيراد المنتجات',
            ar: 'استيراد المنتجات',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/PD95_uQJ32c',
        },
        {
          id: 'taager_plugin8',
          title: {
            en: 'تعديل منتج متعدد الأصناف',
            ar: 'تعديل منتج متعدد الأصناف',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/L6A7qac1vaQ',
        },
        {
          id: 'taager_plugin9',
          title: {
            en: 'تعديل منتج فردي',
            ar: 'تعديل منتج فردي',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/IJXl5vaIWok',
        },
        {
          id: 'taager_plugin10',
          title: {
            en: 'تفعيل الشحن المجاني لمنتج',
            ar: 'تفعيل الشحن المجاني لمنتج',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/HfUV2Uc1gQo',
        },
        {
          id: 'taager_plugin11',
          title: {
            en: 'مراحل إتمام الطلب',
            ar: 'مراحل إتمام الطلب',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/Ix2UbC6QfIA',
        },
      ],
    },
    {
      id: 'create_online_store',
      name: {
        en: 'متجرك من الصفر إلى الإحتراف',
        ar: 'متجرك من الصفر إلى الإحتراف',
      },
      iconUrl: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/create_store.svg',
      description: {
        en:
          'في الكورس ده هتعرف إزاي تقدر تبني متجر إلكتروني بشكل إحترافي' +
          'على شهبندر وتربطه بموقع تاجر للوصول إلى متجر متكامل، وإزاي تقدر تستفيد منه في' +
          'التسويق وإدارة تجارتك الإلكترونية.',
        ar:
          'في الكورس ده هتعرف إزاي تقدر تبني متجر إلكتروني بشكل إحترافي' +
          'على شهبندر وتربطه بموقع تاجر للوصول إلى متجر متكامل، وإزاي تقدر تستفيد منه في' +
          'التسويق وإدارة تجارتك الإلكترونية.',
      },
      lessons: [
        {
          id: 'create_online_store1',
          title: {
            en: 'مقدمة',
            ar: 'مقدمة',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/CYaNtkWKHRs',
        },
        {
          id: 'create_online_store2',
          title: {
            en: 'مميزات تاجر',
            ar: 'مميزات تاجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/AxvIadYi2N8',
        },
        {
          id: 'create_online_store3',
          title: {
            en: 'مميزات شهبندر',
            ar: 'مميزات شهبندر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/q4_-w7Gjsgk',
        },
        {
          id: 'create_online_store4',
          title: {
            en: 'خطوات إنشاء متجر',
            ar: 'خطوات إنشاء متجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/q3s_fFevGw0',
        },
        {
          id: 'create_online_store5',
          title: {
            en: 'اختيار التخصص في التجارة',
            ar: 'اختيار التخصص في التجارة',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/XcNg3xPJ4vY',
        },
        {
          id: 'create_online_store6',
          title: {
            en: 'إنشاء البراند',
            ar: 'إنشاء البراند',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/3F5XfDsBSkU',
        },
        {
          id: 'create_online_store7',
          title: {
            en: 'إنشاء المتجر',
            ar: 'إنشاء المتجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/SPYKd-T1r5w',
        },
        {
          id: 'create_online_store8',
          title: {
            en: 'شرح لوحة التحكم',
            ar: 'شرح لوحة التحكم',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/-oNim-dJxG8',
        },
        {
          id: 'create_online_store9',
          title: {
            en: 'الربط بمنصة تاجر',
            ar: 'الربط بمنصة تاجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/3raECYbxyAk',
        },
        {
          id: 'create_online_store10',
          title: {
            en: 'استيراد المنتجات من تاجر',
            ar: 'استيراد المنتجات من تاجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/sq15hzLm2x8',
        },
        {
          id: 'create_online_store11',
          title: {
            en: 'تخصيص المنتجات والأقسام',
            ar: 'تخصيص المنتجات والأقسام',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/whz1sf3Z3UM',
        },
        {
          id: 'create_online_store12',
          title: {
            en: 'تخصيص المتجر',
            ar: 'تخصيص المتجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/9YmrGFLYnOM',
        },
        {
          id: 'create_online_store13',
          title: {
            en: 'اختيار قالب للمتجر',
            ar: 'اختيار قالب للمتجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/yDVTX7OuxrQ',
        },
        {
          id: 'create_online_store14',
          title: {
            en: 'تعديل قالب المتجر',
            ar: 'تعديل قالب المتجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/fve2K35bu80',
        },
        {
          id: 'create_online_store15',
          title: {
            en: 'ربط المتجر بإسم النطاق',
            ar: 'ربط المتجر بإسم النطاق',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/xKfchxybMs4',
        },
        {
          id: 'create_online_store16',
          title: {
            en: 'ربط المتجر مع بيكسل فيسبوك',
            ar: 'ربط المتجر مع بيكسل فيسبوك',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/7D5I8Wp5RWQ',
        },
        {
          id: 'create_online_store17',
          title: {
            en: 'ربط المتجر مع بيكسل تيك توك',
            ar: 'ربط المتجر مع بيكسل تيك توك',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/bvadK3X0NpA',
        },
        {
          id: 'create_online_store18',
          title: {
            en: 'ربط المتجر بجوجل',
            ar: 'ربط المتجر بجوجل',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/-BsvmjovkgM',
        },
        {
          id: 'create_online_store19',
          title: {
            en: 'الباقات',
            ar: 'الباقات',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/RZumnpS915M',
        },
        {
          id: 'create_online_store20',
          title: {
            en: 'الإضافات',
            ar: 'الإضافات',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/zM4fZY6nFk4',
        },
      ],
    },
    {
      id: 'conversion_course',
      name: {
        en: 'إزاي تعمل إعلان conversion على تيك توك وسناب شات',
        ar: 'إزاي تعمل إعلان conversion على تيك توك وسناب شات',
      },
      iconUrl:
        'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/snapchat_tiktok.svg',
      description: {
        en: '',
        ar: '',
      },
      lessons: [
        {
          id: 'conversion_course1_new',
          title: {
            en: 'إزاي تعمل إعلان conversion على تيك توك',
            ar: 'إزاي تعمل إعلان conversion على تيك توك',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/3JKNn20deWA',
        },
        {
          id: 'conversion_course2_new',
          title: {
            en: 'إزاي تعمل إعلان conversion على سناب شات',
            ar: 'إزاي تعمل إعلان conversion على سناب شات',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/qd1D-kpHQJw',
        },
      ],
    },
    {
      id: 'facebook',
      name: {
        en: 'إزاي تحقق أول بيعة ليك مع تاجر من خلال فيسبوك (message campaign)',
        ar: 'إزاي تحقق أول بيعة ليك مع تاجر من خلال فيسبوك (message campaign)',
      },
      iconUrl: 'https://taager-public-media.s3.eu-west-1.amazonaws.com/learning/facebook.svg',
      description: {
        en: 'إزاي  تعمل أول بيعة ليك مع تاجر في خطوات بسيطة.... فيديو للمبتدئين يوضح ازاي تعمل أول حملة اعلانية ناجحة على الفيسبوك وتسجل اول اوردر ليك.',
        ar: 'إزاي  تعمل أول بيعة ليك مع تاجر في خطوات بسيطة.... فيديو للمبتدئين يوضح ازاي تعمل أول حملة اعلانية ناجحة على الفيسبوك وتسجل اول اوردر ليك.',
      },
      lessons: [
        {
          id: 'facebook1',
          title: {
            en: 'Facebook',
            ar: 'ازاي تحط أول اوردر على تاجر',
          },
          description: {
            en: '',
            ar: '',
          },
          videoUrl: '//www.youtube.com/embed/oCJ__HiTobI',
        },
      ],
    },
  ],
};

@Injectable({
  providedIn: 'root',
})
export class LearningProvider {
  getLearning(): LearningRemoteEntity {
    return LEARNING;
  }
}
