import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from 'app/presentation/base/base.component';
import { RequestUnavailableProductDialogComponent } from 'src/app/presentation/products/no-search-results/request-unavailable-product/request-unavailable-product-dialog/request-unavailable-product-dialog.component';
import { KycPopupComponent } from '../../../kyc/kyc-popup/kyc-popup.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { CLICK_FROM_NAV_BAR } from '../../constants/mixpanel';
import { CountriesDropdownComponent } from './header/countries-dropdown/countries-dropdown.component';
import { HeaderComponent } from './header/header.component';
import { TaagerSidenavButtonComponent } from './header/taager-sidenav-button/taager-sidenav-button.component';
import { DefaultLayoutPresenter } from './presenter/default-layout.presenter';
import { DefaultLayoutSideEffect } from './presenter/default-layout.sideEffects';
import { DefaultLayoutViewEvents } from './presenter/default-layout.viewEvents';
import { DefaultLayoutViewState } from './presenter/default-layout.viewState';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    NgClass,
    NgIf,
    CountriesDropdownComponent,
    NgFor,
    TaagerSidenavButtonComponent,
    HeaderComponent,
    RouterOutlet,
    TranslateModule,
    FooterComponent,
    AsyncPipe,
    KycPopupComponent,
  ],
  providers: [DefaultLayoutPresenter],
})
export class DefaultLayoutComponent extends BaseComponent<
  DefaultLayoutPresenter,
  DefaultLayoutViewState,
  DefaultLayoutViewEvents,
  DefaultLayoutSideEffect
> {
  private _dialog: MatDialog = inject(MatDialog);

  public presenter: DefaultLayoutPresenter = inject(DefaultLayoutPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  onSideEffect(sideEffect: DefaultLayoutSideEffect): void {
    switch (sideEffect.type) {
      case 'OpenRequestProductDialog': {
        const dialogRefOrderChat = this._dialog.open(RequestUnavailableProductDialogComponent, {
          width: '800px',
          height: '500px',
          data: {
            clickedFrom: CLICK_FROM_NAV_BAR,
          },
        });
        dialogRefOrderChat.afterClosed().subscribe({ next: () => {} });
        break;
      }
    }
  }
}
