<a [routerLink]="[appURLs.STORES_URL]" (click)="onStoresClick()">
  <img
    loading="lazy"
    src="{{ assetsPath + 'shop-basket-dark.svg' }}"
    alt="store icon"
    class="stores-icon"
    *ngIf="darkMode$ | async; else light"
  />
  <ng-template #light>
    <img
      loading="lazy"
      src="{{ assetsPath + 'shop-basket.svg' }}"
      alt="store icon"
      class="stores-icon"
    />
  </ng-template>
</a>
