import { Observable } from 'rxjs';

import { UseCase } from '../../base/use-case';
import { ProvinceZoneFilterModel, ProvinceZoneModel } from '../../domain/provinces.model';
import { ProvinceRepository } from '../../repositories/province.repository';

export class GetProvinceZonesUseCase
  implements UseCase<ProvinceZoneFilterModel, ProvinceZoneModel[]>
{
  constructor(private _provinceRepository: ProvinceRepository) {}

  execute(zoneFilter: ProvinceZoneFilterModel): Observable<ProvinceZoneModel[]> {
    return this._provinceRepository.getProvinceZoneList(zoneFilter);
  }
}
