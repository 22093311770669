import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

export class LogMixpanelEventUseCase implements UseCase<{ eventName: string; payload: any }, any> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute(params: { eventName: string; payload?: any }): void {
    this._analyticsRepository.logMixpanelEvent(params.eventName, params.payload);
  }
}
