import { Injectable } from '@angular/core';
import { StoreModel } from 'src/app/core/domain/store.model';
import {
  DUKAN_PROVIDER_NAME,
  EASY_ORDERS_PROVIDER_NAME,
  WOOCOMMERCE_INTEGRATION_URL,
  WOOCOMMERCE_PROVIDER_NAME,
  YOUCAN_INTEGRATION_URL,
  YOUCAN_PROVIDER_NAME,
  ZID_INTEGRATION_URL,
  ZID_PROVIDER_NAME,
} from 'src/app/presentation/shared/constants';

export const easyOrdersStore: StoreModel = {
  name: 'STORES.EASY_ORDERS.NAME',
  description: 'STORES.EASY_ORDERS.DESCRIPTION',
  icon: 'https://storage.googleapis.com/taager-public-media/learning/easy-orders.svg',
  provider: EASY_ORDERS_PROVIDER_NAME,
  redirectUri: '',
  howToLinkVideo: 'https://www.youtube.com/embed/Sc8xaCReSYI?si=Ot9NTLCkUiupYmIr',
  supportedCountries: ['EGY', 'SAU', 'IRQ', 'ARE', 'OMN'],
};

const STORES_DATA: StoreModel[] = [
  easyOrdersStore,
  {
    name: 'STORES.YOUCAN.NAME',
    description: 'STORES.YOUCAN.DESCRIPTION',
    icon: 'https://storage.googleapis.com/taager-public-media/learning/you-can.svg',
    provider: YOUCAN_PROVIDER_NAME,
    redirectUri: YOUCAN_INTEGRATION_URL,
    howToLinkVideo: 'https://www.youtube.com/embed/lFC15nJs_-A?si=3DEubwXnzXrxzRwJ',
    supportedCountries: ['EGY', 'SAU', 'ARE', 'IRQ', 'OMN'],
  },
  {
    name: 'STORES.ZID.NAME',
    description: 'STORES.ZID.DESCRIPTION',
    icon: 'https://storage.googleapis.com/taager-public-media/learning/zid.svg',
    provider: ZID_PROVIDER_NAME,
    redirectUri: ZID_INTEGRATION_URL,
    supportedCountries: ['SAU'],
  },
  {
    name: 'STORES.WOOCOMMERCE.NAME',
    description: 'STORES.WOOCOMMERCE.DESCRIPTION',
    icon: 'https://storage.googleapis.com/taager-public-media/learning/woo-commerce.svg',
    provider: WOOCOMMERCE_PROVIDER_NAME,
    redirectUri: WOOCOMMERCE_INTEGRATION_URL,
    supportedCountries: ['EGY', 'SAU', 'IRQ', 'ARE', 'OMN'],
    howToLinkVideo: 'https://www.youtube.com/embed/ahN6iXS3M-0?si=yQO8Wp_4qCGWmAZr',
  },
];

export const DUKAN_STORE: StoreModel = {
  name: 'STORES.DUKAN.NAME',
  description: 'STORES.DUKAN.DESCRIPTION',
  icon: 'https://storage.googleapis.com/taager-public-media/learning/dukan.svg',
  provider: DUKAN_PROVIDER_NAME,
  redirectUri: '',
  supportedCountries: ['EGY', 'ARE'],
};

@Injectable({
  providedIn: 'root',
})
export class StoresLocalDataSource {
  getStores(): StoreModel[] {
    return STORES_DATA;
  }
}
