import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { OnboardingStoryGuideState } from '../interfaces';

/**
 * common method for writing to storage
 */
const actionUpdateStorage = (
  localStorageService: LocalStorageService,
  storageKey: string,
  nextState: any,
) => {
  localStorageService.setStorage(storageKey, nextState);
};

/**
 * Common method to get the state draft from storage
 */
const getStateDraft = (storageKey: string, localStorageService: LocalStorageService) => {
  return localStorageService.getStorage(storageKey);
};

/**
 * Actions to update the store
 */

/**
 *
 * @param state
 * @param step
 *
 * Action to set the active step.
 */
export const actionSetActiveStep = (
  storageKey: string,
  localStorageService: LocalStorageService,
  state: OnboardingStoryGuideState,
  step: number,
): void => {
  state.currentActiveUserStepIndex.next(step);
  const stateDraft = getStateDraft(storageKey, localStorageService);
  const lastUserStep = stateDraft.userSteps[stateDraft.userSteps.length - 1];
  if (step > lastUserStep) {
    /**
     * The user has completed the onboarding story
     */
    stateDraft.hasCompletedOnboardingStory = true;
    step = -1;
  }
  stateDraft.currentActiveUserStepIndex = step;
  actionUpdateStorage(localStorageService, storageKey, stateDraft);
};

/**
 *
 * @param state
 * @param status
 *
 * Sets the status on whether the user has completed the onboarding
 * story.
 */
export const actionSetHasCompletedOnboardingStory = (
  storageKey: string,
  localStorageService: LocalStorageService,
  state: OnboardingStoryGuideState,
  status: boolean,
) => {
  state.hasCompletedOnboardingStory.next(status);
  const stateDraft = getStateDraft(storageKey, localStorageService);
  stateDraft.hasCompletedOnboardingStory = status;
  actionUpdateStorage(localStorageService, storageKey, stateDraft);
};
