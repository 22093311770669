/* eslint-disable @typescript-eslint/no-use-before-define */
import * as arabicTranslation from 'src/assets/translations/ar.json';

const getArabicTranslation = (translationKey: string): string => {
  const keySegments = translationKey.split('.');
  let result: any = arabicTranslation;
  while (keySegments.length && result) {
    result = result[keySegments.shift()!] || translationKey;
  }
  return result.toString();
};

const translateArrayComponents = ({
  arrayToTranslate,
}: {
  arrayToTranslate: Array<any>;
}): Array<any> => {
  return arrayToTranslate.map((item) => {
    if (Array.isArray(item)) {
      return translateArrayComponents({ arrayToTranslate: item });
    }
    if (typeof item === 'string') {
      return getArabicTranslation(item);
    }
    if (typeof item === 'object') {
      return translateObjectValues({ objectToTranslate: item });
    }
    return item;
  });
};

export const translateObjectValues = ({
  objectToTranslate,
}: {
  objectToTranslate: Object;
}): Object => {
  try {
    const translatedObject: any = {};
    for (const [key, value] of Object.entries(objectToTranslate)) {
      if (Array.isArray(value)) {
        translatedObject[key] = translateArrayComponents({
          arrayToTranslate: value,
        });
      } else if (typeof value === 'object') {
        translatedObject[key] = translateObjectValues({
          objectToTranslate: value,
        });
      } else if (typeof value === 'string') {
        translatedObject[key] = getArabicTranslation(value);
      } else {
        translatedObject[key] = value;
      }
    }
    return translatedObject;
  } catch (e) {
    return objectToTranslate;
  }
};
