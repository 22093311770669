import { Observable } from 'rxjs';
import { PhoneDataModel } from '../domain/auth/auth.model';
import { UpdateProfileModel } from '../domain/auth/update-profile.model';
import { MerchantStore } from '../domain/merchant-store.model';
import { OrderSummaryModel } from '../domain/order-summary.model';
import { ReferralsModel } from '../domain/referrals.model';
import { UserModel } from '../domain/user/user.model';

export abstract class UserRepository {
  abstract getPhoneData(): PhoneDataModel;
  abstract updateSwitchedSelectedMarket(selectedMarket: string): Observable<void>;
  abstract getOrderSummary(startDate: string, endDate: string): Observable<OrderSummaryModel>;
  abstract getUser(): UserModel;
  abstract setUser(user: UserModel): void;
  abstract setUserStores(stores: MerchantStore[]): void;
  abstract getTaagerId(): string;
  abstract getUserSuperProperties(): any;
  abstract updateProfilePicture(picture: any, fileName?: string): Observable<string>;
  abstract getReferrals(): Observable<ReferralsModel | Error>;
  abstract updateProfile(form: UpdateProfileModel): Observable<void>;
  abstract checkUserFeatureExists(feature: string): boolean;
  abstract deleteAccount(password: string): Observable<void>;
  abstract getUserProfile(): Observable<{ data: UserModel }>;
}
