import { Mapper } from 'src/app/core/base/mapper';
import { FinalIncentiveProgramModel } from 'src/app/core/domain/new-incentive-program.model';
import { DateWrapperUtility } from 'src/app/presentation/shared/utilities/date-wrapper.utility';
import { FinalIncentiveProgramEntity } from '../entities/new-incentive-program-entity';
import { CurrentIncentiveProgramMapper } from './current-incentive-program.mapper';

export class NewIncentiveProgramMapper extends Mapper<
  FinalIncentiveProgramEntity,
  FinalIncentiveProgramModel
> {
  mapFrom(incentiveProgram: FinalIncentiveProgramEntity): FinalIncentiveProgramModel {
    const currentIncentiveMapper = new CurrentIncentiveProgramMapper();
    return {
      currentIncentive: incentiveProgram.currentIncentive
        ? currentIncentiveMapper.mapFrom(incentiveProgram.currentIncentive!)
        : undefined,
      prevIncentiveSummary: incentiveProgram.prevIncentiveSummary
        ? {
            ...incentiveProgram.prevIncentiveSummary,
            deliveryCalculationDate: DateWrapperUtility.dateToISOString(
              DateWrapperUtility.stringToDate(
                incentiveProgram.prevIncentiveSummary.deliveryCalculationDate,
              ),
            ),
          }
        : undefined,
    };
  }

  mapTo(incentiveProgram: FinalIncentiveProgramModel): FinalIncentiveProgramEntity {
    throw new Error('Method not implemented.');
  }
}
