import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AdsIntegration, DukanShopModel } from '../../domain/dukan-shop/dukan-shop.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class UpdateDukanAdsIntegrationUseCase implements UseCase<AdsIntegration, DukanShopModel> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(ids: AdsIntegration): Observable<DukanShopModel> {
    return this._dukanShopRepository.updateAdsIntegration(ids);
  }
}
