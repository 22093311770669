import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { user } from '@features/user/data';
import { hardNavigate } from '@presentation/shared/router';
import { Observable } from 'rxjs';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { LANDING_URL, PRODUCTS_V2_URL } from '../../../constants';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';

/**
 * So, ideally, what makes it possible for one to see the questionnaire module? Well,
 * at the time of this writing is that a user cannot be able to see a questionnaire
 * if they have already interacted with it, or if they are not logged in.
 * At the end of the day, remember that the resolver is just here to
 * ADMIT or DENY (with graceful redirection) entry.
 *
 * Side note: who knows, we may have need for logged out access to
 * questionnaire module. Ideally, it is the backend which makes the decision
 * on whether the request (authenticated or not) can be able to access
 * the questionnaire.
 *
 * Example link: http://localhost:4200/sa/questionnaire/questionnaireName
 */
export const questionnaireResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  return new Observable((subscriber) => {
    if (!user.isLoggedIn || !user.fullName) {
      // subscriber.next(stringToUrlTree(LANDING_URL, router));
      hardNavigate(LANDING_URL);
    } else if (user.isSignedUpUsingV3) {
      subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
    } else {
      const questionnaire = router.parseUrl(router.getCurrentNavigation()!.extractedUrl.toString())
        .root.children.primary.segments[1].path;
      getQuestionnaireEligibilityUseCase.execute(questionnaire).subscribe({
        next: ({ enabled }) => {
          if (enabled) {
            subscriber.next(true);
          } else {
            subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
          }
        },
        error: () => {
          subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
        },
      });
    }
  });
};
