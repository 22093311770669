import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { LoginCredentialsModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class LoginAuthUserUseCase implements UseCase<LoginCredentialsModel, void> {
  constructor(private _authRepository: AuthRepository) {}

  execute(userModel: LoginCredentialsModel): Observable<void> {
    return this._authRepository.login(userModel);
  }
}
