import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { PayoneerTransferModel } from '../../domain/payment/payoneer-transfer.model';
import { WalletRepository } from '../../repositories/wallet.repository';

/**
 * @deprecated Use CreateSecurePayoneerUseCase to require OTP during withdrawal request creation
 */
export class WithdrawPayoneerUseCase implements UseCase<PayoneerTransferModel, boolean | Error> {
  constructor(private walletRepository: WalletRepository) {}

  execute(params: any): Observable<any | Error> {
    return this.walletRepository.withdrawViaPayoneer(params);
  }
}
