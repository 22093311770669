import { WithdrawalMethods as DomainWithdrawalMethods } from 'src/app/core/domain/payment/payment-methods.model';
import { WithdrawalMethods as DataWithdrawalMethods } from '../entities/withdrawal-methods.entity';

export function mapWithdrawalMethods(
  withdrawalMethods: DomainWithdrawalMethods,
): DataWithdrawalMethods {
  let withdrawalMethod: DataWithdrawalMethods;

  switch (withdrawalMethods) {
    case DomainWithdrawalMethods.VODAFONE_CASH_METHOD:
      withdrawalMethod = DataWithdrawalMethods.VODAFONE_CASH_METHOD;
      break;
    case DomainWithdrawalMethods.ORANGE_CASH_METHOD:
      withdrawalMethod = DataWithdrawalMethods.ORANGE_CASH_METHOD;
      break;
    case DomainWithdrawalMethods.ETISALAT_CASH_METHOD:
      withdrawalMethod = DataWithdrawalMethods.ETISALAT_CASH_METHOD;
      break;
    case DomainWithdrawalMethods.WE_PAY_METHOD:
      withdrawalMethod = DataWithdrawalMethods.WE_PAY_METHOD;
      break;
    case DomainWithdrawalMethods.EGP_BANK_TRANSFER_METHOD:
      withdrawalMethod = DataWithdrawalMethods.EGP_BANK_TRANSFER_METHOD;
      break;
    case DomainWithdrawalMethods.INTL_BANK_TRANSFER_METHOD:
      withdrawalMethod = DataWithdrawalMethods.INTL_BANK_TRANSFER_METHOD;
      break;
    case DomainWithdrawalMethods.PAYONEER_TRANSFER_METHOD:
      withdrawalMethod = DataWithdrawalMethods.PAYONEER_TRANSFER_METHOD;
      break;
  }

  return withdrawalMethod;
}
