import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { hardNavigate } from '../../../../../../v3/presentation/shared/router';
import { LOGIN_URL, REGISTER_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { isUserLoggedIn, stringToUrlTree, userHasFullySignedUp } from './shared';

/**
 *
 * So, a user should only be able to view the orders module if:
 *
 * 1. The user is logged in.
 * 2. The user has completed both their mobile and data verification
 */
export const ordersResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const localStorageService = inject(LocalStorageService);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
            next: (hasFullySignedUp) => {
              if (hasFullySignedUp) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(REGISTER_URL, router));
              }
            },
          });
        } else {
          // subscriber.next(stringToUrlTree(AUTH_URL, router));

          hardNavigate(LOGIN_URL);
        }
      },
    });
  });
};
