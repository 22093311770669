import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { FailedOrdersResponseModel } from '../../domain/order/failed-orders.model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetFailedOrdersUseCase
  implements UseCase<{ page: number; pageSize: number }, FailedOrdersResponseModel>
{
  constructor(private _orderRepository: OrderRepository) {}

  execute(params: { page: number; pageSize: number }): Observable<FailedOrdersResponseModel> {
    return this._orderRepository.getFailedOrders(params.page, params.pageSize);
  }
}
