import { Mapper } from 'src/app/core/base/mapper';
import { CalculateOrderCostRequestModel } from 'src/app/core/domain/order-model';
import { CalculateOrderCostRequestEntity } from '../entities/order.entity';

export class CalculateOrderCostRequestMapper extends Mapper<
  CalculateOrderCostRequestEntity,
  CalculateOrderCostRequestModel
> {
  mapFrom(param: CalculateOrderCostRequestEntity): CalculateOrderCostRequestModel {
    return {
      province: param.province,
      products: param.products,
      productQuantities: param.productQuantities,
      productPrices: param.productPrices,
      productIds: param.productIds,
      orderLines: param.productIds.map((productId, index) => ({
        productId,
        quantity: param.productQuantities[index],
        totalPrice: param.productPrices[index],
      })),
    };
  }

  mapTo(param: CalculateOrderCostRequestModel): CalculateOrderCostRequestEntity {
    return {
      province: param.province,
      products: param.products,
      productQuantities: param.productQuantities,
      productPrices: param.productPrices,
      productIds: param.productIds,
      orderLines: param.orderLines.map((orderLine) => ({
        productId: orderLine.productId,
        quantity: orderLine.quantity,
        totalPrice: orderLine.totalPrice,
      })),
    };
  }
}
