import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ArticleModel,
  SubTopicModel,
  TopicContentModel,
  TopicModel,
  VideoModel,
} from 'src/app/core/domain/learning-center.model';
import { LearningCenterRepository } from 'src/app/core/repositories/learning-center.repository';
import { LearningCenterApi } from './learning-center-api';
import { LearningCenterMapper } from './mappers/learning-center.mapper';

@Injectable({
  providedIn: 'root',
})
export class LearningCenterRepositoryImpl extends LearningCenterRepository {
  private _learningCenterMapper = new LearningCenterMapper();

  constructor(private _learningCenterApi: LearningCenterApi) {
    super();
  }

  getTopics(): Observable<TopicModel[]> {
    return this._learningCenterApi.getTopics().pipe(
      map((entities) => {
        return entities.map((entity) => this._learningCenterMapper.mapFrom(entity));
      }),
    );
  }

  getSubTopics(topicId: string): Observable<SubTopicModel[]> {
    return this._learningCenterApi.getSubTopics(topicId);
  }

  getContentByTopicId(subTopicId: string): Observable<TopicContentModel> {
    return this._learningCenterApi.getSubTopicContent(subTopicId);
  }

  getItemByContentId(contentId: string): Observable<VideoModel | ArticleModel> {
    return this._learningCenterApi.getSubTopicContentItem(contentId);
  }

  getTopicFromSubTopicId(subTopicId: string): Observable<TopicModel> {
    return this._learningCenterApi.getTopicFromSubTopicId(subTopicId);
  }
}
