import { OTPVerificationModes } from 'app/core/domain/payment/intl-bank-transfer.model';
import { Observable } from 'rxjs';
import { Currency } from '../../../presentation/shared/interfaces/countries';
import { OtpCheckModel } from '../../domain/payment/otp-check.model';
import { WithdrawalMethods } from '../../domain/payment/payment-methods.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class CreateSecureWithdrawalRequestUseCase {
  constructor(private _walletRepository: WalletRepository) {}

  execute(
    currency: Currency,
    amount: number,
    paymentMethod: WithdrawalMethods,
    phoneNum: string,
    otpCheckModel: OtpCheckModel,
    otpVerificationMode: OTPVerificationModes,
  ): Observable<void> {
    return this._walletRepository.createSecureWithdrawalRequest(
      currency,
      amount,
      paymentMethod,
      phoneNum,
      otpCheckModel,
      otpVerificationMode,
    );
  }
}
