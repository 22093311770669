export const preOrderAPI = {
  listPreOrders: '/preorder',
  externalProductRequest: '/preorder/external-request',
  preOrderProducts: '/preorder/products',
  preOrderRequests: '/v2/preorder/requests',
};

export const preOrderTrackEvents = {
  viewPage: 'Preorder-page-load',
  clickScrollToPreOrderProducts: 'new_pre_order_top_banner_button_clicked',
  clickScrollToPreOrderExternalProducts: 'click_scroll_to_pre_order_external_products',
  clickPreOrderProductsTab: 'click_pre_order_products_tab',
  clickPreOrderExternalProductsTab: 'click_pre_order_external_products_tab',
  clickPreOrderExternalProductOpenForm: 'click_pre_order_external_product_open_form',
  submitPreOrderExternalProductForm: 'submit_pre_order_external_product_form',
};
