import { BaseRepository } from '@features/shared/data';
import { LoginResponse } from 'app/data/repositories/auth/remote/entities/login.entity';
import {
  AuthRepositoryContract,
  AuthUser,
  EmailLoginData,
  ForgetPasswordData,
  GoogleLoginData,
  PhoneNumberLoginData,
  RegisterData,
  ResetPasswordData,
  RotateRefreshTokenData,
  UpdateJwtData,
  VerifyEmailData,
  VerifyNationalIdData,
  VerifyPhoneNumberData,
} from '../../domain';
import { userApiUrl } from '../constants';
import { mapUserData } from '../mappers';
import { user } from '../services';

export class AuthRepository extends BaseRepository implements AuthRepositoryContract {
  /**
   * Login by email and password
   */
  public async loginByEmail(data: EmailLoginData): Promise<AuthUser> {
    // Make the HTTP post request to the server with the login form data
    const response = await this.http.post<EmailLoginData, LoginResponse>(userApiUrl.login, data);

    // Map the response data to an AuthUser object
    const authUser = mapUserData(response.data);

    // Set user cache
    user.login(authUser);

    // Return the authenticated user
    return authUser;
  }

  /**
   * Login by phone and password
   */
  public async loginByPhone(data: PhoneNumberLoginData): Promise<AuthUser> {
    // Make the HTTP post request to the server with the login form data
    const response = await this.http.post<PhoneNumberLoginData, LoginResponse>(
      userApiUrl.login,
      data,
    );

    // Map the response data to an AuthUser object
    const authUser = mapUserData(response.data);

    // Set user cache
    user.login(authUser);

    // Return the authenticated user
    return authUser;
  }

  /**
   * Login By Google
   */
  public async loginByGoogle(data: GoogleLoginData): Promise<AuthUser> {
    // Make the HTTP post request to the server with the user token
    const response = await this.http.post<GoogleLoginData, AuthUser>(userApiUrl.socialLogin, data);

    // Map the response data to an AuthUser object
    const authUser = mapUserData(response.data);

    // Set user cache
    user.login(authUser);

    // Return the authenticated user
    return authUser;
  }

  /**
   * Forget Password
   */
  public async forgetPassword(data: ForgetPasswordData): Promise<void> {
    // Make the HTTP post request to the server with the forget password form data
    await this.http.patch<ForgetPasswordData, void>(userApiUrl.forgetPassword, data);
  }

  /**
   * Reset Password
   */
  public async resetPassword(data: ResetPasswordData): Promise<void> {
    // Make the HTTP post request to the server with the reset password form data
    await this.http.patch<ResetPasswordData, void>(userApiUrl.changePassword, data);
  }

  /**
   * Create new user
   */
  public async register(data: RegisterData): Promise<AuthUser> {
    // Make the HTTP post request to the server with the register form data
    const response = await this.http.post<RegisterData, AuthUser>(userApiUrl.register, data);

    // Map the response data to an AuthUser object
    const authUser = mapUserData(response.data);

    // Set user cache
    user.login(authUser);

    // Return the authenticated user
    return authUser;
  }

  /**
   * Verify Email
   */
  public async verifyEmail(data: VerifyEmailData): Promise<void> {
    throw new Error('Method not implemented.');
  }

  /**
   * Verify Phone Number
   */
  public async verifyPhoneNumber(data: VerifyPhoneNumberData): Promise<void> {
    // Make the HTTP post request to the server with the register form data
    await this.http.post<VerifyPhoneNumberData, void>(userApiUrl.verifyPhoneNumber, data);
  }

  /**
   * Verify National Id
   */
  public async verifyNationalId(data: VerifyNationalIdData): Promise<void> {
    throw new Error('Method not implemented.');
  }

  /**
   * Log out
   */
  public async logout(): Promise<void> {
    // Remove user data from cache
    user.logout();
    // TODO: make logout api request
  }

  /**
   * Update JWT token using refresh token
   */
  public async updateJwt(data: UpdateJwtData): Promise<string> {
    throw new Error('Method not implemented.');
  }

  /**
   * Rotate refresh token by generating a new one
   */
  public async rotateRefreshToken(data: RotateRefreshTokenData): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
