import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadListFilters } from 'src/app/core/domain/lead.model';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { LeadListResponseEntity } from './entity/lead-entity';

@Injectable({
  providedIn: 'root',
})
export class LeadAPIService {
  constructor(private http: HttpClient) {}

  getLeadList(filter: LeadListFilters): Observable<LeadListResponseEntity> {
    const params = this.filterQuery(filter);
    return this.http.get<LeadListResponseEntity>(API_URLS.GET_LEAD_LIST, { params });
  }

  filterQuery(filter: LeadListFilters): {} {
    const updatedFilter = Object.entries(filter)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
    return updatedFilter;
  }
}
