import {
  QuestionnaireModel,
  QuestionnaireQuestionModel,
} from 'src/app/core/domain/questionnaire.model';
import {
  CESQuestionnaire,
  CESQuestionnaireQuestionTypes,
  CESQuestionnaireRecurring,
} from './interfaces';

/**
 *
 * @param question
 * @returns
 *
 * This is based on the current v1 iteration of CES. We will only be having
 */
const returnQuestionType = (
  question: QuestionnaireQuestionModel,
): CESQuestionnaireQuestionTypes => {
  return question.answers?.length ? 'rating' : 'freeText';
};

export const CESQuestionnaireMapFrom = (questionnaire: QuestionnaireModel): CESQuestionnaire => ({
  name: questionnaire.name,
  questions: questionnaire.questions.map((question) => ({
    id: question.id,
    textArabic: question.textArabic,
    textEnglish: question.textEnglish,
    iconUrl: question.iconUrl,
    type: returnQuestionType(question),
    maxAllowedAnswers: question.maxAllowedAnswers,
    required: question.required!,
    options: question.answers.map((answer) => ({
      id: answer.id,
      textArabic: answer.textArabic,
      textEnglish: answer.textEnglish,
      iconUrl: answer.iconUrl,
    })),
  })),
  recurring: questionnaire.recurring as CESQuestionnaireRecurring,
});
