/**
 * For one to be able to view incentive program, they must:
 *
 * - be logged in
 * - be fully signed up
 * - be onboarded already
 *      - the incentive has to be supported for the country
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import {
  AUTH_URL,
  FEATURE_FLAGS,
  ONBOARDING_URL,
  PRODUCTS_V2_URL,
  REGISTER_URL,
} from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { loggedInFullySignedUpOnboardedMixinResolver, stringToUrlTree } from './shared';

export const incentiveProgramResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  const userResolutionService = inject(UserResolutionService);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  return new Observable((subscriber) => {
    loggedInFullySignedUpOnboardedMixinResolver({
      router,
      localStorageService,
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
      getOnboardingFeatureUseCase,
      isUserEligibleForOnboardingUseCase,
      getQuestionnaireEligibilityUseCase,
      onboardingStoryGuideStateManager,
      userResolutionService,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
        ifNotOnboarded: ONBOARDING_URL,
      },
    }).subscribe({
      next: (response) => {
        if (response === true) {
          getFeatureFlagUseCase.execute(FEATURE_FLAGS.INCENTIVE_PROGRAM_V3).subscribe({
            next: (newIncentiveProgram) => {
              getFeatureFlagUseCase.execute(FEATURE_FLAGS.KSA_INCENTIVE_PROGRAM).subscribe({
                next: (ksaIncentiveProgram) => {
                  const selectedCountry = country.code;
                  if (
                    newIncentiveProgram &&
                    (country.is('EGY') || (country.is('KSA') && ksaIncentiveProgram))
                  ) {
                    subscriber.next(true);
                  } else {
                    subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
                  }
                },
              });
            },
          });
        } else {
          subscriber.next(response);
        }
      },
    });
  });
};
