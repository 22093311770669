import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { RateOrderModel } from '../../domain/order/rate-order.model';
import { OrderRepository } from '../../repositories/order.repository';

export class RateOrderUseCase implements UseCase<RateOrderModel, void> {
  constructor(private _orderRepository: OrderRepository) {}

  execute(rateOrder: RateOrderModel): Observable<void> {
    return this._orderRepository.rateOrder(rateOrder);
  }
}
