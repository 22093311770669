import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { VariantModel } from '../../domain/variant-group.model';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetUpsellableProductsUseCase implements UseCase<string, VariantModel[]> {
  constructor(private _productRepository: ProductsRepository) {}

  execute(productId: string): Observable<VariantModel[]> {
    return this._productRepository.getUpSellableProducts(productId);
  }
}
