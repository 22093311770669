/**
 * So, this utility function will help us in getting the user's IP address.
 *
 * We don't want to be doing this all the time, we want to be storing the IP
 * and then we send it if it there.
 */

let OBTAINED_USER_IP: string;

export const getUserIPAddressUtility = async (): Promise<string> => {
  return new Promise((resolve) => {
    if (OBTAINED_USER_IP) {
      resolve(OBTAINED_USER_IP);
    } else {
      fetch('https://api.ipify.org?format=json')
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          return { ip: 'Unknown' };
        })
        .then((ipAddress) => {
          OBTAINED_USER_IP = ipAddress.ip;
          resolve(OBTAINED_USER_IP);
        });
    }
  });
};
