import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserRepository } from '../../repositories/user.repository';

export class DeleteAccountUseCase implements UseCase<string, void> {
  constructor(private _userRepository: UserRepository) {}

  execute(param: string): Observable<void> {
    return this._userRepository.deleteAccount(param);
  }
}
