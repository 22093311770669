import { Mapper } from 'src/app/core/base/mapper';
import { LoginCredentialsModel } from 'src/app/core/domain/auth/auth.model';
import { LoginRequest } from '../remote/entities/login.entity';

export class LoginCredentialsMapper extends Mapper<LoginCredentialsModel, LoginRequest> {
  mapFrom(modelParam: LoginCredentialsModel): LoginRequest {
    return {
      password: modelParam.password,
      callingCode: modelParam.callingCode,
      phoneNumber: modelParam.phoneNumber,
      email: modelParam.email,
    };
  }

  mapTo(entityParam: LoginRequest): LoginCredentialsModel {
    return {
      password: entityParam.password,
      callingCode: entityParam.callingCode,
      phoneNumber: entityParam.phoneNumber,
      email: entityParam.email,
    };
  }
}
