import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { HistoryDynamicIncentiveProgramModel } from '../../domain/dynamic-incentive-program.model';
import { DynamicIncentiveProgramRepository } from '../../repositories/dynamic-incentive-program.repository';

export class GetDynamicIncentiveProgramHistoryUseCase
  implements UseCase<void, HistoryDynamicIncentiveProgramModel[]>
{
  constructor(private _repository: DynamicIncentiveProgramRepository) {}

  execute(): Observable<HistoryDynamicIncentiveProgramModel[]> {
    return this._repository.getDynamicIncentiveProgramHistory();
  }
}
