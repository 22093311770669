import { Mapper } from '../../../../core/base/mapper';
import { UserModel } from '../../../../core/domain/user/user.model';
import { SocialUserEntity } from '../remote/entities/social-user-entity';

export class SocialLoginMapper extends Mapper<SocialUserEntity, UserModel> {
  mapFrom(param: SocialUserEntity): UserModel {
    return {
      taagerId: param.user.TagerID,
      selectedMarket: param.user.selectedMarket,
      stores: param.user.stores,
      features: param.user.features,
      email: param.user.email,
      username: param.user.username,
      fullName: param.user.fullName,
      callingCode: param.user.callingCode,
      phoneNumber: param.user.phoneNum,
      verificationState: param.user.verificationState,
      actualVerificationState: param.user.actualVerificationState,
      token: param.data,
    };
  }

  mapTo(param: UserModel): SocialUserEntity {
    throw new Error('Method not implemented.');
  }
}
