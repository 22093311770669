import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { EmailOTPDataModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class RequestEmailOTPUseCase implements UseCase<string, EmailOTPDataModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(context: 'login' | 'withdrawal'): Observable<EmailOTPDataModel> {
    return this._authRepository.requestEmailOTP(context);
  }
}
