import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TopicContentModel } from '../../domain/learning-center.model';
import { LearningCenterRepository } from '../../repositories/learning-center.repository';

export class GetContentBySubTopicIdUseCase implements UseCase<string, TopicContentModel> {
  constructor(private learningCenterRepository: LearningCenterRepository) {}

  execute(subTopicId: string): Observable<TopicContentModel> {
    return this.learningCenterRepository.getContentByTopicId(subTopicId);
  }
}
