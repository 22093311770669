/* eslint-disable @typescript-eslint/naming-convention */
interface complaint {
  complaintId: number;
  complaintTranslationKey: string;
  complaintInEnglish: string;
  complaintInArabic: string;
}

export const COMPLAINTS: complaint[] = [
  {
    complaintId: 0,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.INVALID',
    complaintInEnglish: '',
    complaintInArabic: '',
  },
  {
    complaintId: 1,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.SERVICE_LEVEL',
    complaintInEnglish: 'ServiceLevel',
    complaintInArabic: 'مستوى الخدمة',
  },
  {
    complaintId: 2,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.SHIPMENT_SERVICE_LEVEL',
    complaintInEnglish: 'ShipmentServiceLevel',
    complaintInArabic: 'مستوى خدمة الشحن',
  },
  {
    complaintId: 3,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.PRODUCTS',
    complaintInEnglish: 'Products',
    complaintInArabic: 'المنتجات',
  },
  {
    complaintId: 4,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.WEBSITE_SERVICES',
    complaintInEnglish: 'WebsiteServices',
    complaintInArabic: 'خدمات و امكانيات الموقع',
  },
  {
    complaintId: 5,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.CUSTOMER_SERVICE',
    complaintInEnglish: 'CustomerService',
    complaintInArabic: 'خدمة العملاء',
  },
  {
    complaintId: 6,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.WALLET',
    complaintInEnglish: 'Wallet',
    complaintInArabic: 'صفحة المحفظة',
  },
  {
    complaintId: 7,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.ORDERS',
    complaintInEnglish: 'Orders',
    complaintInArabic: 'صفحة الطلبات',
  },
  {
    complaintId: 8,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.LIVE_CHAT',
    complaintInEnglish: 'LiveChat',
    complaintInArabic: 'خدمة اللايف شات',
  },
  {
    complaintId: 9,
    complaintTranslationKey: 'COMPLAIN_SUGGEST.OPTIONS.OTHER',
    complaintInEnglish: 'Other',
    complaintInArabic: 'اخرى',
  },
];

export const COMPLAINT_ACTION = 'complain';
export const SUGGESTION_ACTION = 'suggestion';

export const COMPLAIN_SUGGEST_MAIN_PAGE = 'complain-suggest-main';
export const COMPLAINT_FORM_PAGE = 'complaint-form';
export const SUGGESTION_FORM_PAGE = 'suggestion-form';
export const COMPLAINT_SUBMITTED_PAGE = 'complaint-submitted';
export const SUGGESTION_SUBMITTED_PAGE = 'suggestion-submitted';
