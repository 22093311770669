import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { SubTopicModel } from '../../domain/learning-center.model';
import { LearningCenterRepository } from '../../repositories/learning-center.repository';

export class GetSubTopicsUseCase implements UseCase<string, SubTopicModel[]> {
  constructor(private learningCenterRepository: LearningCenterRepository) {}

  execute(topicId: string): Observable<SubTopicModel[]> {
    return this.learningCenterRepository.getSubTopics(topicId);
  }
}
