import { groupedTranslations } from '@mongez/localization';

groupedTranslations('auth', {
  resetPasswordSuccess: {
    en: 'Password changed successfully, please login again',
    ar: 'تم تغيير كلمة المرور بنجاح، يرجى إعادة تسجيل الدخول',
  },
  loginToAccount: {
    en: 'Login to your account',
    ar: 'تسجيل الدخول إلى حسابك',
    eg: 'ادخل لحسابك',
  },
  unauthorizedToAccess: {
    en: 'Your are unauthorized to access this page',
    ar: 'غير مصرح لك بالدخول هنا',
  },
  somethingWentWrongWithBp: {
    // keep in mind that the !! is to tell us the reason of the failure is the bot poison
    en: 'Something went wrong!!, please try again',
    ar: 'حدث خطأ ما!!, يرجى المحاولة مرة أخرى',
  },
  invalidEmail: {
    en: 'Invalid email address',
    ar: 'البريد الالكتروني غير صحيح',
  },
  exceededLoginAttempts: {
    en: 'You have exceeded the allowed number of login attempts, please try again later',
    ar: 'لقد تجاوزت الحد المسموح من محاولات تسجيل الدخول, يرجى المحاولة مرة اخرى لاحقا',
  },
  loginViaGoogle: {
    en: 'Login via Google',
    ar: 'تسجيل الدخول عبر جوجل',
  },
  forgotPasswordHeader: {
    en: 'Forgot Password',
    ar: 'طلب تغيير كلمة المرور',
  },
  sendViaEmail: {
    en: 'Send via Email',
    ar: 'إرسال عبر البريد الالكتروني',
  },
  sendViaPhone: {
    ar: 'إرسال عبر رقم الهاتف',
    en: 'Send via Phone',
  },
});

groupedTranslations('user', {
  sessionTokenCopied: {
    en: 'Session token copied',
    ar: 'تم نسخ كود الجلسة',
  },
  invalidPassword: {
    en: 'Invalid password',
    ar: 'كلمة مرور غير صحيحة',
  },
  passwordChangedSuccessfully: {
    en: 'Password changed successfully',
    ar: 'تم تغيير كلمة المرور بنجاح',
  },
  currentPasswordRequired: {
    en: 'Current password is required',
    ar: 'كلمة المرور الحالية مطلوبة',
  },
  newPasswordRequired: {
    en: 'New password is required',
    ar: 'كلمة المرور الجديدة مطلوبة',
  },
  passwordsDoNotMatch: {
    en: 'Passwords do not match',
    ar: 'يجب أن تتطابق كلمة المرور الجديدة و تأكيد كلمة المرور',
  },
  confirmPasswordRequired: {
    en: 'Confirm new password is required',
    ar: 'تأكيد كلمة السر الجديدة مطلوبة',
  },
});
