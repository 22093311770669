import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthModel, OTPModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class VerifyPhoneNumberUseCase implements UseCase<OTPModel, AuthModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(userOTP: OTPModel): Observable<AuthModel> {
    return this._authRepository.verifyPhoneNumber(userOTP);
  }
}
