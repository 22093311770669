import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ReferralStepsEntity } from './entities/referral-steps.entity';
import { ReferralsProgramEntity } from './entities/referrals-program.entity';
import { REFERRAL_PROGRAM_APIS_ENDPOINTS } from './referral-program-apis-endpoints';
import { ReferralStepsDataSource } from './referral-program-data-source';

@Injectable({
  providedIn: 'root',
})
export class ReferralProgramApisService {
  constructor(
    private _referralStepsDataSource: ReferralStepsDataSource,
    private _http: HttpClient,
  ) {}

  getReferralStepsData(): Observable<ReferralStepsEntity[]> {
    return of(this._referralStepsDataSource.getReferralSteps());
  }

  getReferralsProgram(page: number, pageSize: number): Observable<ReferralsProgramEntity> {
    return this._http.get<ReferralsProgramEntity>(
      REFERRAL_PROGRAM_APIS_ENDPOINTS.GET_REFERRALS_PROGRAM,
      {
        params: { page, pageSize },
      },
    );
  }
}
