/**
 * For a user to access pre-orders requests module, they must:
 *
 * - be logged in.
 * - be fully signed up.
 * - already be onboarded.
 * - met the required specifics of authorized access to pre orders requests
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import {
  AUTH_URL,
  FEATURE_FLAGS,
  ONBOARDING_URL,
  PRE_ORDER_USER_FEATURE,
  PRODUCTS_V2_URL,
  REGISTER_URL,
} from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';
import { loggedInFullySignedUpOnboardedMixinResolver } from './shared/mixins/logged-in-fully-signed-up-onboarderd.mixin.shared.resolver';

export const preOrdersRequestsResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  const userResolutionService = inject(UserResolutionService);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  return new Observable((subscriber) => {
    loggedInFullySignedUpOnboardedMixinResolver({
      router,
      localStorageService,
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
      getOnboardingFeatureUseCase,
      isUserEligibleForOnboardingUseCase,
      getQuestionnaireEligibilityUseCase,
      onboardingStoryGuideStateManager,
      userResolutionService,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
        ifNotOnboarded: ONBOARDING_URL,
      },
    }).subscribe({
      next: (response) => {
        if (response === true) {
          getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3).subscribe({
            next: (bulkPreorderIsEnabled) => {
              const selectedCountry = country.code;
              const userHasPreOrder = checkUserFeatureExistsUseCase.execute(
                PRE_ORDER_USER_FEATURE + selectedCountry.toLowerCase(),
              );
              if (!bulkPreorderIsEnabled || !userHasPreOrder) {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              } else {
                subscriber.next(true);
              }
            },
          });
        } else {
          subscriber.next(response);
        }
      },
    });
  });
};
