import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { CountriesRepository } from '../repositories/countries.repository';

export class GetCountryVATByIsoCode3UseCase implements UseCase<string, Observable<number>> {
  constructor(private _countriesRepository: CountriesRepository) {}

  execute(isoCode3: string): Observable<number> {
    return this._countriesRepository.getCountryVATByIsoCode3(isoCode3);
  }
}
