import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Duration } from '@js-joda/core';
import { parseError } from '@presentation/shared/error';
import { catchError, map, Observable, throwError } from 'rxjs';
import { WithdrawalMethods } from 'src/app/core/domain/payment/payment-methods.model';
import {
  ChangeWalletPasswordModel,
  CreateWithdrawalRequestModel,
  GetWithdrawalsListRequestModel,
  ResetWalletPasswordModel,
  WalletModel,
  WalletTransactionHistoryModel,
  WalletTransactionRequestFilterModel,
  WithdrawalsListModel,
} from 'src/app/core/domain/wallet/wallet.model';
import { WalletRepository } from 'src/app/core/repositories/wallet.repository';
import {
  IntlBankTransferModel,
  OTPVerificationModes,
  SecureIntlBankTransferModel,
} from '../../../core/domain/payment/intl-bank-transfer.model';
import { OtpCheckModel } from '../../../core/domain/payment/otp-check.model';
import { OtpRequestInfoModel } from '../../../core/domain/payment/otp-request-info.model';
import {
  PayoneerTransferModel,
  SecurePayoneerTransferModel,
} from '../../../core/domain/payment/payoneer-transfer.model';
import {
  WithdrawalError,
  WithdrawalOTPError,
} from '../../../core/domain/payment/withdrawal-errors';
import { Currency } from '../../../presentation/shared/interfaces/countries';
import { ChangeWalletPasswordMapper } from './mappers/change-wallet-password.mapper';
import { WalletTransactionHistoryFilterMapper } from './mappers/wallet-transaction-history-filter.mapper';
import { WalletTransactionHistoryMapper } from './mappers/wallet-transaction-history.mapper';
import { WalletMapper } from './mappers/wallet.mapper';
import { mapWithdrawalMethods } from './mappers/withdrawal-method.mapper';
import { WithdrawalRequestMapper } from './mappers/withdrawal-request.mapper';
import { Mapper } from './mappers/withdrawals.mapper';
import { WalletApisService } from './wallet-apis.service';

@Injectable({
  providedIn: 'root',
})
export class WalletRepositoryImplementation implements WalletRepository {
  constructor(private _walletApisService: WalletApisService) {}

  private _walletTransactionHistoryMapper = new WalletTransactionHistoryMapper();

  private _walletTransactionHistoryFilterMapper = new WalletTransactionHistoryFilterMapper();

  private _walletMapper = new WalletMapper();

  private _withdrawalRequestMapper = new WithdrawalRequestMapper();

  private _changeWalletPasswordMapper = new ChangeWalletPasswordMapper();

  walletLogin(params: { username: string; password: string }): Observable<{ code: string }> {
    return this._walletApisService.walletLogin(params);
  }

  forgotWalletPassword(params: { email: string }): Observable<{ code: string }> {
    return this._walletApisService.forgotWalletPassword(params);
  }

  changeWalletPassword(
    params: ChangeWalletPasswordModel,
  ): Observable<{ code: number } | { msg: string }> {
    return this._walletApisService.changeWalletPassword(
      this._changeWalletPasswordMapper.mapTo(params),
    );
  }

  resetWalletPassword(params: ResetWalletPasswordModel): Observable<void> {
    return this._walletApisService.resetWalletPassword(params.data, params.id, params.resetToken);
  }

  getWalletTransactionHistory(
    params: WalletTransactionRequestFilterModel,
  ): Observable<WalletTransactionHistoryModel> {
    return this._walletApisService
      .getWalletTransactionHistory(this._walletTransactionHistoryFilterMapper.mapTo(params))
      .pipe(map(this._walletTransactionHistoryMapper.mapFrom));
  }

  getTransactionsSheet(params: WalletTransactionRequestFilterModel): Observable<Blob> {
    return this._walletApisService.getTransactionsSheet(
      this._walletTransactionHistoryFilterMapper.mapTo(params),
    );
  }

  getWallets(): Observable<WalletModel[]> {
    return this._walletApisService
      .getWallets()
      .pipe(map((walletEntityArray) => walletEntityArray.map(this._walletMapper.mapFrom)));
  }

  createWithdrawalRequest(params: CreateWithdrawalRequestModel): Observable<void> {
    return this.catchWithdrawalError(
      this._walletApisService.createWithdrawalRequest(
        this._withdrawalRequestMapper.mapFrom(params),
      ),
    );
  }

  createSecureWithdrawalRequest(
    currency: Currency,
    amount: number,
    paymentMethod: WithdrawalMethods,
    phoneNum: string,
    otpCheckModel: OtpCheckModel,
    otpVerificationMode: OTPVerificationModes,
  ): Observable<void> {
    return this.catchWithdrawalError(
      this._walletApisService.createWithdrawalRequest({
        currency: currency.iso4217Code,
        amount,
        paymentMethod: mapWithdrawalMethods(paymentMethod),
        phoneNum,
        checkCode: otpCheckModel.checkCode,
        otpPasscode: otpCheckModel.otpCode,
        otpVerificationMode,
      }),
    );
  }

  getWithdrawalsList(params: GetWithdrawalsListRequestModel): Observable<WithdrawalsListModel> {
    return this._walletApisService.getWithdrawalsList(params);
  }

  requestWithdrawalOtp(): Observable<OtpRequestInfoModel> {
    return this._walletApisService.requestWithdrawalOtp().pipe(
      map((otpRequestInfoEntity) => {
        return {
          checkCode: otpRequestInfoEntity.checkCode,
          timeUntilNextRequest: Duration.ofSeconds(
            otpRequestInfoEntity.timeUntilNextRequestInSeconds,
          ),
        };
      }),
      catchError((response: HttpErrorResponse) => {
        const { errorCode, retryAfterSeconds } = response.error;
        let timeUntilNextRequest: Duration | undefined;

        if (retryAfterSeconds === undefined) {
          timeUntilNextRequest = undefined;
        } else {
          timeUntilNextRequest = Duration.ofSeconds(retryAfterSeconds);
        }
        let error: WithdrawalOTPError;
        if (errorCode === 'otp_rate_limit_exceeded') {
          error = {
            type: 'OTPRequestLimitExceeded',
            timeUntilNextRequest,
          };
        } else {
          error = parseError(response);
        }

        return throwError(() => error);
      }),
    );
  }

  withdrawViaIntlBankTransfer(paymentDetails: IntlBankTransferModel): Observable<void> {
    return this.catchWithdrawalError(
      this._walletApisService.withdrawIntlBankTransfer(paymentDetails),
    );
  }

  withdrawViaPayoneer(paymentDetails: PayoneerTransferModel): Observable<void> {
    return this.catchWithdrawalError(this._walletApisService.withdrawPayoneer(paymentDetails));
  }

  createSecureIntlBankWithdrawal(
    secureIntlBankTransferModel: SecureIntlBankTransferModel,
  ): Observable<void> {
    return this.catchWithdrawalError(
      this._walletApisService.withdrawIntlBankTransfer(
        Mapper.secureIntlBankTransferModelToData(secureIntlBankTransferModel),
      ),
    );
  }

  createSecurePayoneerWithdrawal(
    securePayoneerTransferModel: SecurePayoneerTransferModel,
  ): Observable<void> {
    return this.catchWithdrawalError(
      this._walletApisService.withdrawPayoneer(
        Mapper.securePayoneerTransferModelToData(securePayoneerTransferModel),
      ),
    );
  }

  private catchWithdrawalError(withdrawalObservable: Observable<void>): Observable<void> {
    return withdrawalObservable.pipe(
      catchError((response: HttpErrorResponse) => {
        const { errorCode } = response.error;
        let error: WithdrawalError | string;
        if (errorCode === 'withdrawals-0001') {
          error = WithdrawalError.InsufficientFunds;
        } else if (errorCode === 'wrong_otp') {
          error = WithdrawalError.WrongOTP;
        } else if (errorCode === 'otp_already_verified') {
          error = WithdrawalError.OTPAlreadyVerified;
        } else if (errorCode === 'otp_expired') {
          error = WithdrawalError.OTPExpired;
        } else {
          error = parseError(response);
        }
        return throwError(() => error);
      }),
    );
  }
}
