import { Mapper } from 'src/app/core/base/mapper';
import { DukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { DukanProduct } from '../entities/dukan-product.entity';

export class DukanProductMapper extends Mapper<DukanProduct, DukanProductModel> {
  mapFrom(param: DukanProduct): DukanProductModel {
    return {
      id: param.id,
      sku: param.sku,
      availability: param.availability,
      name: param.name,
      description: param.description,
      media: param.images,
      status: param.status,
      pricingOptions: param.pricingOptions?.map(
        ({ name, description, totalPrice, quantity, isDefault }) => ({
          name: name?.ar!,
          description: description?.ar!,
          totalPrice,
          quantity,
          isDefault,
          isEditMode: false,
        }),
      ),
    };
  }

  mapTo(param: DukanProductModel): DukanProduct {
    return {
      id: param.id,
      sku: param.sku,
      availability: param.availability,
      name: param.name,
      description: param.description,
      images: param.media,
      status: param.status,
      pricingOptions: param.pricingOptions?.map(
        ({ name, description, totalPrice, quantity, isDefault }) => ({
          name: {
            ar: name,
            en: undefined,
          },
          description: {
            ar: description,
            en: undefined,
          },
          totalPrice,
          quantity,
          isDefault,
        }),
      ),
    };
  }
}
