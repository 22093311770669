import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  GetWithdrawalsListRequestModel,
  WithdrawalsListModel,
} from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class GetWithdrawalsListUseCase
  implements UseCase<GetWithdrawalsListRequestModel, WithdrawalsListModel>
{
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: GetWithdrawalsListRequestModel): Observable<WithdrawalsListModel> {
    return this._walletRepository.getWithdrawalsList(params);
  }
}
