/* eslint-disable prefer-destructuring */
import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import * as Merchant from '@taager-experience-shared/merchant';
import { LocalStorageService } from '../../../presentation/shared/services/local-storage.service';
import AccessTokenProvider = Merchant.com.taager.experience.api.authentication.AccessTokenProvider;
import AccessTokenUpdater = Merchant.com.taager.experience.api.authentication.AccessTokenUpdater;
import AccessTokens = Merchant.com.taager.experience.api.authentication.model.AccessTokens;

@Injectable({
  providedIn: 'root',
})
export class ExperienceAccessTokenHandler implements AccessTokenProvider, AccessTokenUpdater {
  constructor(private _localStorageService: LocalStorageService) {}

  public provideTokens(): AccessTokens | null {
    return new AccessTokens(user.accessToken, user.refreshToken);
  }

  public updateTokens(accessTokens: AccessTokens): void {
    user.updateAccessToken(accessTokens.accessToken);
    user.updateRefreshToken(accessTokens.refreshToken);
  }
}
