import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanProductModel } from '../../domain/dukan-shop/dukan-product.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class GetDukanProductsUseCase implements UseCase<void, DukanProductModel[]> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(): Observable<DukanProductModel[]> {
    return this._dukanShopRepository.getProducts();
  }
}
