<a
  class="sidenav-button"
  [routerLink]="tab.routerLink"
  (click)="onTabClicked(tab)"
  *ngIf="!tab.isExternalLink"
>
  <i class="sidenav-button__icon icon {{ tab.mobileIconPath }}"></i>
  <p class="sidenav-button__text body1--medium">{{ tab.tabName | translate }}</p>
  <p class="sidenav-button__announcement body1--medium">{{ tab?.announcement | translate }}</p>
  <i
    class="sidenav-button__chevron-icon icon"
    [ngClass]="isLTR ? 'icon-right-chevron' : 'icon-left-chevron'"
  ></i>
</a>

<a
  [href]="tab.href"
  class="sidenav-button"
  target="_blank"
  rel="noopener noreferrer"
  (click)="onTabClicked(tab)"
  *ngIf="tab.isExternalLink"
>
  <i class="sidenav-button__icon icon {{ tab.mobileIconPath }}"></i>
  <p class="sidenav-button__text body1--medium">{{ tab.tabName | translate }}</p>
</a>
