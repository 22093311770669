<div class="dialog">
  <img class="dialog__img" [src]="assetsPath + 'apple.svg'" alt="onboarding-announcement" />
  <p class="dialog__title">
    <span class="dialog__title__a">{{
      'ONBOARDING.GETTING_STARTED_DIALOG.TITLE.a' | translate
    }}</span>
    <span class="dialog__title__b">{{
      'ONBOARDING.GETTING_STARTED_DIALOG.TITLE.b' | translate
    }}</span>
    <span class="dialog__title__c">{{
      'ONBOARDING.GETTING_STARTED_DIALOG.TITLE.c' | translate
    }}</span>
  </p>
  <p class="dialog__body">
    {{ 'ONBOARDING.GETTING_STARTED_DIALOG.SUB_TITLE' | translate }}
  </p>
  <button (click)="onGetStartedClicked()" class="dialog__action-btn body1--medium">
    {{ 'ONBOARDING.GETTING_STARTED_DIALOG.BUTTON_LABEL' | translate }}
  </button>
</div>
