import { CategoryProductsUserActions } from '../interfaces/category-products-actions';

export const SORT_BY_INTRODUCED_AT = 'introducedAt';
export const SORT_BY_CLOSEST_MATCH = 'closestMatch';
export const SORT_BY_ORDER_COUNT = 'orderCount';
export const SORT_BY_UPDATED_AT = 'updatedAt';
export const SORT_BY_PRODUCT_PRICE = 'productPrice';
export const SORT_BY_PRODUCT_PROFIT = 'productProfit';

export const CATEGORY_PRODUCTS_USER_ACTIONS = {
  navigateToCategory: CategoryProductsUserActions.navigate_to_category,
  navigateToFeaturedGroup: CategoryProductsUserActions.navigate_to_featured_group,
  productSearch: CategoryProductsUserActions.product_search,
};

export const SORT_BY_CLOSEST_MATCH_OPTION = {
  value: SORT_BY_CLOSEST_MATCH,
  translationKey: 'CATEGORY_PAGE.SORTING_OPTIONS.CLOSEST_MATCH',
};
export const DEFAULT_SORTING_OPTION = {
  value: SORT_BY_INTRODUCED_AT,
  translationKey: 'CATEGORY_PAGE.SORTING_OPTIONS.INTRODUCED_AT',
};
export const OLD_DEFAULT_SORTING_OPTION = {
  value: SORT_BY_UPDATED_AT,
  translationKey: 'CATEGORY_PAGE.SORTING_OPTIONS.UPDATED_AT',
};

export const CATEGORY_PRODUCTS_SORTING_OPTIONS = [
  DEFAULT_SORTING_OPTION,
  OLD_DEFAULT_SORTING_OPTION,
  {
    value: SORT_BY_PRODUCT_PRICE,
    translationKey: 'CATEGORY_PAGE.SORTING_OPTIONS.PRODUCT_PRICE_DSC',
  },
  {
    value: SORT_BY_PRODUCT_PROFIT,
    translationKey: 'CATEGORY_PAGE.SORTING_OPTIONS.PRODUCT_PROFIT_DSC',
  },
];
