import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { country } from '@features/country/data';
import { hardNavigate } from '@presentation/shared/router';
import { Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { LOGIN_URL, PRODUCTS_V2_URL, REFERRAL_PROGRAM_FEATURE } from '../../../constants';
import { REFERRAL_PROGRAM } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { isUserLoggedIn, stringToUrlTree } from './shared';

export const ReferralResolver = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          getFeatureFlagUseCase.execute(REFERRAL_PROGRAM).subscribe({
            next: (flagIsOn) => {
              const isKsaSelected = country.is('KSA');
              const userHasReferralProgram =
                checkUserFeatureExistsUseCase.execute(REFERRAL_PROGRAM_FEATURE);
              if (flagIsOn && isKsaSelected && userHasReferralProgram) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              }
            },
          });
        } else {
          // subscriber.next(stringToUrlTree(LOGIN_URL, router));
          hardNavigate(LOGIN_URL);
        }
      },
    });
  });
};
