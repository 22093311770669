import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartModel } from '../../domain/cart.model';
import { CartRepository } from '../../repositories/cart.repository';

export class GetUserCartUseCase implements UseCase<void, Observable<CartModel>> {
  constructor(private _cartRepository: CartRepository) {}

  execute(): Observable<CartModel> {
    return this._cartRepository.getUserCart();
  }
}
