// PRE_ORDER_REQUESTS_TRACKING //
export const VIEW_PREORDERABLE_PRODUCTS_CAROUSEL = 'view_preorderable_products_carousel';
export const VIEW_PREORDERABLE_PRODUCTS_PAGE = 'view_preorderable_products_page';
export const CLICKS_PLACE_PREORDER_REQUEST_BUTTON = 'clicks_place_preorder_request_button';
export const SUBMIT_PREORDER_REQUEST = 'submit_preorder_request';
export const VIEW_PREORDER_REQUESTS_PAGE = 'view_preoder_requests_page';
export const VIEW_RULES_DIALOG = 'view_rules_dialog';

// Featured Products //
export const GO_TO_CATEGORY = 'go_to_category';
export const FEATURED_PRODUCTS = 'Featured Products';

// Request Product
export const REQUEST_PRODUCT_CLICK = 'request_product_click';
export const REQUEST_PRODUCT_SUBMIT = 'request_product_submit';
export const CLICK_FROM_NAV_BAR = 'nav_bar';
export const CLICK_FROM_NO_SEARCH_RESULTS = 'no_results_page';

// Product Checkout //
export const PRODUCT_CREATE_CHECKOUT_LINK = 'merchant-landing-create-link';
export const PRODUCT_CREATE_CHECKOUT_LINK_ERROR = 'merchant-landing-create-link-error';
export const PRODUCT_UPDATE_CHECKOUT_LINK = 'merchant-landing-update-link';
export const PRODUCT_UPDATE_CHECKOUT_LINK_ERROR = 'merchant-landing-update-link-error';
export const PRODUCT_COPY_CHECKOUT_LINK = 'merchant-landing-copy-link';
export const PRODUCT_REDIRECT_CHECKOUT_LINK = 'merchant-landing-redirect-link';
export const PRODUCT_CHECKOUT_LINK_ENABLE = 'merchant-landing-enable-link';
export const PRODUCT_CHECKOUT_LINK_DISABLE = 'merchant-landing-disable-link';
