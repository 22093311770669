import { Mapper } from 'src/app/core/base/mapper';
import { ResetPasswordModel } from 'src/app/core/domain/auth/reset-password.model';
import { ResetPasswordRequest } from '../remote/entities/reset-password.entity';

export class ResetPasswordMapper extends Mapper<ResetPasswordRequest, ResetPasswordModel> {
  mapFrom(param: ResetPasswordRequest): ResetPasswordModel {
    return {
      newPassword: param.form.password,
      confirmPassword: param.form.confirmPassword,
      id: param.id,
      resetToken: param.resetToken,
    };
  }

  mapTo(param: ResetPasswordModel): ResetPasswordRequest {
    return {
      form: {
        password: param.newPassword,
        confirmPassword: param.confirmPassword,
      },
      id: param.id,
      resetToken: param.resetToken,
    };
  }
}
