import { inject } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { PRODUCTS_V2_URL } from '../../../constants';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree, userHasFullySignedUp } from './shared';

export const authResolver = (route: Route): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  return new Observable((subscriber) => {
    userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
      next: (hasFullySignedUp) => {
        if (hasFullySignedUp) {
          subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
        } else {
          subscriber.next(true);
        }
      },
    });
  });
};
