import { Router } from '@angular/router';
import { hardNavigate } from '@presentation/shared/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { UserResolutionService } from 'src/app/presentation/shared/services/user-resolution.service';
import { UserRegistrationStepUtility } from 'src/app/presentation/shared/utilities/user-registration-step.utility';
import { LOGIN_URL } from '../../../../../constants';
import { RouteAccessResolutionTypes } from '../../interfaces';
import { isUserLoggedIn } from '../is-user-logged-in.shared.resolver';
import { stringToUrlTree } from '../string-to-url-tree.shared.resolver';
import { userHasFullySignedUp } from '../user-has-fully-signed-up.shared.resolver';
import { userNeedsToFillOnboardingQuestionnaire } from '../user-needs-to-fill-onboarding-questionnaire.shared.resolver';

/**
 * This is some sort of a mixin, for having shared logic, instead of duplicating the
 * below checks for a user, when trying to determine if user can access a given
 * path.
 *
 * IMPORTANT thing is that this mixin should be completely agnostic to the requested
 * user route.
 *
 * IT SHOULD NOT BE AWARE THAT IT IS BEING USED BY SPECIFIC MODULES OR
 * COMPONENTS, MEANING IT SHOULD BE A PURE FUNCTION.
 */
export const loggedInFullySignedUpOnboardedMixinResolver = ({
  router,
  localStorageService,
  userRegistrationStepUtility,
  getRegisterTokenUseCase,
  getOnboardingFeatureUseCase,
  isUserEligibleForOnboardingUseCase,
  getQuestionnaireEligibilityUseCase,
  onboardingStoryGuideStateManager,
  nextPath,
}: {
  router: Router;
  localStorageService: LocalStorageService;
  userRegistrationStepUtility: UserRegistrationStepUtility;
  getRegisterTokenUseCase: GetRegisterTokenUseCase;
  getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase;
  isUserEligibleForOnboardingUseCase: IsUserEligibleForOnboardingUseCase;
  getQuestionnaireEligibilityUseCase: GetQuestionnaireEligibilityUseCase;
  onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager;
  userResolutionService: UserResolutionService;
  nextPath: {
    ifNotLoggedIn: string;
    ifNotFullySignedUp: string;
    ifNotOnboarded: string;
  };
}): Observable<RouteAccessResolutionTypes> => {
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
            next: (hasFullySignedUp) => {
              if (hasFullySignedUp) {
                userNeedsToFillOnboardingQuestionnaire({
                  getOnboardingFeatureUseCase,
                  isUserEligibleForOnboardingUseCase,
                  getQuestionnaireEligibilityUseCase,
                  onboardingStoryGuideStateManager,
                }).subscribe({
                  next: (needsToFillInQuestionnaire) => {
                    if (needsToFillInQuestionnaire) {
                      subscriber.next(stringToUrlTree(nextPath.ifNotOnboarded, router));
                    } else {
                      subscriber.next(true);
                    }
                  },
                });
              } else {
                subscriber.next(stringToUrlTree(nextPath.ifNotFullySignedUp, router));
              }
            },
          });
        } else {
          // subscriber.next(stringToUrlTree(nextPath.ifNotLoggedIn, router));
          hardNavigate(LOGIN_URL);
        }
      },
    });
  });
};
