import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
import { CountryModel } from 'src/app/core/domain/country.model';
import { GetCountriesUseCase } from 'src/app/core/usecases/get-countries.usecase';
import { country } from '../../../../v3/features/country/data';
import { SELECTED_COUNTRY_ISO_CODE_2 } from '../constants';
import { Country } from '../interfaces/countries';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MultitenancyService {
  private supportedCountries: Country[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private _getCountriesUseCase: GetCountriesUseCase,
  ) {}

  private fetchCountriesCodes(): Observable<CountryModel[]> {
    return this._getCountriesUseCase.execute();
  }

  getCurrentCountry(): Country {
    return CountryResolver.getCountryFromIsoCode2(country.shortCode.toUpperCase());
  }

  setCurrentCountry(isoCode2: string): void {
    this.localStorageService.setStorage(SELECTED_COUNTRY_ISO_CODE_2, isoCode2.toLowerCase());
  }

  getSupportedCountries(): Promise<Country[]> {
    return new Promise<Country[]>((resolve) => {
      if (this.supportedCountries.length) {
        resolve(this.supportedCountries);
      } else {
        this.fetchCountriesCodes()
          .pipe()
          .subscribe({
            next: (countries) => {
              const availableCountries = countries.map((c) => c.isoCode2);
              const supportedCountries = availableCountries.map((countryiso) => {
                const data = CountryResolver.getCountryFromIsoCode2(countryiso);

                data.flag = data.flagUrl;

                return data;
              });

              this.supportedCountries = supportedCountries;
              resolve(supportedCountries);
            },
          });
      }
    });
  }
}
