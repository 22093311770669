import { groupedTranslations } from '@mongez/localization';

groupedTranslations('pre-order', {
  exclusiveProducts: {
    en: 'Exclusive Products Service',
    ar: 'خدمة المنتجات الحصرية',
  },
  internalProducts: {
    en: 'Taager Products',
    ar: 'منتجات تاجر',
  },
  yourProducts: {
    en: 'Your Products',
    ar: 'منتجاتك الخاصة',
  },
  externalProductCreated: {
    en: 'Your request has been sent successfully, we will contact you soon',
    ar: 'تم إرسال طلبك بنجاح، سنتواصل معك قريبا',
  },
});

groupedTranslations('pre-order.request-card', {
  headingP1: {
    en: 'Now you can order any product from',
    ar: 'دلوقتي تقدر تطلب توفير',
  },
  headingP2: {
    en: 'anywhere',
    ar: 'أي منتج من أي مكان',
  },
  headingP3: {
    en: 'in the quantity you need',
    ar: 'بالكمية اللي محتاجها',
  },
  description: {
    en: 'We can provide you with any product of your choice in the required quantity and in the fastest time, depending on the selling price and the country of import',
    ar: 'نقدر نوفرلك أي منتج من اختيارك بالكمية المطلوبة وفي اسرع وقت حسب سعر البيع وبلد الاستيراد',
  },
  cta: {
    en: 'Request your product now',
    ar: 'اطلب منتج خاص الآن',
  },
});

groupedTranslations('pre-order.availability-card', {
  heading: {
    en: 'Duration to provide products',
    ar: 'المدة لتوفير المنتجات',
  },
  cnTitle: {
    en: 'China',
    ar: 'الصين',
  },
  cnDuration: {
    en: 'Up to 21 days',
    ar: 'مدة تصل إلى ٢١ يوم',
  },
  uaeTitle: {
    en: 'UAE',
    ar: 'الامارات',
  },
  uaeDuration: {
    en: 'Up to 10 days',
    ar: 'مدة تصل إلى ١٠ أيام',
  },
  ksaTitle: {
    en: 'KSA',
    ar: 'السعودية',
  },
  ksaDuration: {
    en: 'Up to 2 days',
    ar: 'مدة تصل إلى يومان',
  },
});

groupedTranslations('pre-order.external-products-empty', {
  tab: {
    en: 'Your special products',
    ar: 'منتجاتك الخاصة',
  },
  heading: {
    en: 'There is no special product in stock yet.',
    ar: 'لا يوجد أي منتج خاص في المخزون حتى الآن',
  },
  description: {
    en: 'Request any product you want from anywhere and we will provide it to you as quickly as possible',
    ar: 'قدم طلب بأي منتج تريده من أي مكان وسنقوم بتوفيره لك في أسرع وقت ممكن',
  },
});

groupedTranslations('pre-order.external-dialog', {
  heading: {
    en: 'Request Special Product',
    ar: 'أطلب منتج خاص',
  },
  description: {
    en: 'You can order products from any place or platform such as AliExpress, AliBaba, etc',
    ar: 'يمكنك طلب المنتجات من أي مكان أو منصة مثل AliExpress, AliBaba, etc',
  },
  productLink: {
    en: 'Product Link',
    ar: 'رابط المنتج',
  },
  productCategory: {
    en: 'Category',
    ar: 'تصنيف المنتج',
  },
  contactNumber: {
    en: 'Whatsapp Number',
    ar: 'رقم الواتساب',
  },
  sectionHeading: {
    en: 'Details about your expected sales',
    ar: 'تفاصيل عن مبيعاتك المتوقعة',
  },
  requiredQuantity: {
    en: 'Required quantity',
    ar: 'الكمية المطلوبة',
  },
  dailyOrders: {
    en: 'Expected number of daily orders',
    ar: 'عدد الطلبات اليومي المتوقع',
  },
  expectedSellingPrice: {
    en: 'Expected selling price',
    ar: 'سعر البيع المتوقع',
  },
  notes: {
    en: 'Notes',
    ar: 'ملاحظات',
  },
  submit: {
    en: 'Confirm',
    ar: 'تأكيد ارسال الطلب',
  },
  close: {
    en: 'Cancel',
    ar: 'إلغاء',
  },
  footnote: {
    en: 'We will contact you to confirm the request details and inform you of the pricing quotations within 48 hours',
    ar: 'سيتم التواصل معك لتأكيد تفاصيل الطلب وإعلامك بالتسعير في خلال ٤٨ ساعة من إرسال الطلب',
  },
  optional: {
    en: 'Optional',
    ar: 'اختياري',
  },
});
