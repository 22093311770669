import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthModel, EmailOTPVerifyModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class VerifyEmailOTPUseCase implements UseCase<EmailOTPVerifyModel, AuthModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(userOTP: EmailOTPVerifyModel): Observable<AuthModel> {
    return this._authRepository.verifyEmailOTP(userOTP);
  }
}
