import { Mapper } from 'src/app/core/base/mapper';
import { UserStoreModel } from 'src/app/core/domain/user-store.model';
import { UserStoreEntity } from '../entities/user-store.entity';

export class UserStoreMapper extends Mapper<UserStoreEntity, UserStoreModel> {
  mapFrom(param: UserStoreEntity): UserStoreModel {
    return {
      storeId: param.storeId,
      provider: param.provider.toUpperCase(),
      market: param.market,
    };
  }

  mapTo(param: UserStoreModel): UserStoreEntity {
    return {
      storeId: param.storeId,
      provider: param.provider,
      market: param.market,
    };
  }
}
