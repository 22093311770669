import { environment } from 'src/environments/environment';

export const DUKAN_SHOP_APIS_ENDPOINTS = {
  DUKAN_URL: `${environment.BACKEND_URL}api/dukan`,
  CREATE_SHOP: (taagerId: number, slug: string): string =>
    `${environment.BACKEND_URL}api/dukan/${taagerId}/${slug}`,
  PRODUCTS: `${environment.BACKEND_URL}api/dukan/products`,
  PRODUCT: (sku: string): string => `${environment.BACKEND_URL}api/dukan/products/${sku}`,
  DELETE_PRODUCT_MEDIA: (sku: string, url: string): string =>
    `${environment.BACKEND_URL}api/dukan/products/${sku}/images?imageUrl=${url}`,
  ADD_PRODUCT_MEDIA: (sku: string): string =>
    `${environment.BACKEND_URL}api/dukan/products/${sku}/upload`,
};
