import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { CartEntity, CartProductUpdateEntity } from './entities/cart-entity';

@Injectable({
  providedIn: 'root',
})
export class CartApisService {
  constructor(private http: HttpClient) {}

  getUserCart(): Observable<CartEntity> {
    return this.http
      .get<{ data: CartEntity }>(API_URLS.GET_USER_CART)
      .pipe(map(({ data }) => data));
  }

  removeProductFromCart(id: string): Observable<void> {
    return this.http.patch<void>(API_URLS.REMOVE_FROM_CART_URL(id), {});
  }

  updateProductInCart(params: CartProductUpdateEntity): Observable<void> {
    const updatedParams = { ...params, shouldOverwriteQuantity: true };
    return this.http.patch<void>(API_URLS.ADD_TO_CART_WITH_CUSTOM_PRICE_URL, updatedParams);
  }

  updateCart(params: Array<CartProductUpdateEntity>): Observable<void> {
    return this.http.patch<void>(API_URLS.UPDATE_CART, params);
  }

  addToCart(params: { productId: string; quantity: number }): Observable<void> {
    const queryParams = { ...params, overwriteQuantity: false };
    return this.http.patch<void>(API_URLS.ADD_TO_CART_URL(queryParams), {});
  }
}
