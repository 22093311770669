import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

const toString = (cause: any) => {
  return cause.stack ? cause.stack : cause;
};

export class TrackErrorUseCase implements UseCase<{ message: string; cause?: any }, void> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute(params: { message: string; cause?: any }) {
    this._analyticsRepository.logMixpanelEvent('error', {
      message: params.cause ? `${params.message}: ${toString(params.cause)}` : params.message,
    });
  }
}
