<div class="dialog">
  <div class="dialog__header">
    <img
      class="dialog__close"
      src="{{ assetsPath + 'close.svg' }}"
      alt="'close'"
      (click)="dismissOverlay()"
    />
  </div>
  <img src="{{ assetsPath + 'auth/verification-succeeded.svg' }}" alt="" class="dialog__img" />
  <p class="dialog__title heading2--bold">نت جاهز للاستمرار الآن و سحب ارباحك!</p>
  <p class="dialog__body body1--medium">
    سنعاود محاولة تأكيد رقم هاتفك مره اخرى عند إتاحة عملية التأكيد بشكل كامل في بلدك
  </p>
  <button (click)="dismissOverlay()" class="dialog__action-btn body1--medium">حسناً</button>
</div>
