import { Mapper } from 'src/app/core/base/mapper';
import { CartUpdateModel } from 'src/app/core/domain/cart.model';
import { CartProductUpdateEntity } from '../entities/cart-entity';

export class CartUpdateDataMapper extends Mapper<Array<CartProductUpdateEntity>, CartUpdateModel> {
  mapFrom(param: Array<CartProductUpdateEntity>): CartUpdateModel {
    return {
      products: param.map((product) => ({
        productId: product.productId,
        desiredPrice: product.preferredMerchantPrice,
        quantity: product.quantity,
      })),
    };
  }

  mapTo(param: CartUpdateModel): Array<CartProductUpdateEntity> {
    return param.products.map((product) => ({
      productId: product.productId,
      preferredMerchantPrice: product.desiredPrice,
      quantity: product.quantity,
    }));
  }
}
