import { Mapper } from 'src/app/core/base/mapper';
import { CartProductUpdateModel } from 'src/app/core/domain/cart.model';
import { CartProductUpdateEntity } from '../entities/cart-entity';

export class CartProductUpdateDataMapper extends Mapper<
  CartProductUpdateEntity,
  CartProductUpdateModel
> {
  mapFrom(param: CartProductUpdateEntity): CartProductUpdateModel {
    return {
      id: param.productId,
      desiredPrice: param.preferredMerchantPrice,
      quantity: param.quantity,
    };
  }

  mapTo(param: CartProductUpdateModel): CartProductUpdateEntity {
    return {
      productId: param.id,
      preferredMerchantPrice: param.desiredPrice,
      quantity: param.quantity,
    };
  }
}
