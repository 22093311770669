/**
 * For a user to access product-checkout-store requests module, they must:
 *
 * - be logged in.
 * - be fully signed up.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { AUTH_URL, FEATURE_FLAGS, PRODUCTS_V2_URL, REGISTER_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { featureAttributeAssign } from '../../../utilities/feature-attribute-assign.utility';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';
import { loggedInFullySignedUpMixinResolver } from './shared/mixins/logged-in-fully-signed-up.mixin.shared.resolver';

export const productCheckoutStoreRequestsResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const getFeatureAttributeUseCase = inject(GetFeatureAttributeUsecase);
  const userResolutionService = inject(UserResolutionService);

  return new Observable((subscriber) => {
    loggedInFullySignedUpMixinResolver({
      router,
      localStorageService,
      userResolutionService,
      nextPath: {
        ifNotLoggedIn: AUTH_URL,
        ifNotFullySignedUp: REGISTER_URL,
      },
      userRegistrationStepUtility,
      getRegisterTokenUseCase,
    }).subscribe({
      next: (response) => {
        if (response === true) {
          getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_CUSTOM_CHECKOUT).subscribe({
            next: (attribute) => {
              const selectedCountry = country.code;
              const shouldShowCustomCheckout = featureAttributeAssign(
                attribute,
                user.id,
                selectedCountry,
              );
              if (!shouldShowCustomCheckout) {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              } else {
                subscriber.next(true);
              }
            },
          });
        } else {
          subscriber.next(response);
        }
      },
    });
  });
};
