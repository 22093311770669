import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanProductStatus } from '../../domain/dukan-shop/dukan-product.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class SetDukanProductsUseCase
  implements UseCase<{ sku: string; status: DukanProductStatus }[], void>
{
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(products: { sku: string; status: DukanProductStatus }[]): Observable<void> {
    return this._dukanShopRepository.setProducts(products);
  }
}
