import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { WalletRepository } from '../../repositories/wallet.repository';

export class WalletLoginUseCase
  implements UseCase<{ username: string; password: string }, { code: string }>
{
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: { username: string; password: string }): Observable<{ code: string }> {
    return this._walletRepository.walletLogin(params);
  }
}
