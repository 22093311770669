import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { QuestionnaireSubmissionParamsType } from 'src/app/presentation/shared/constants/questionnaire-params-type';
import { QuestionnaireEntity } from './entities/questionnaire-entity';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireAPIservice {
  constructor(private http: HttpClient) {}

  getQuestionnaireQuestions(questionnaireName: string): Observable<QuestionnaireEntity> {
    return this.http.get<QuestionnaireEntity>(
      API_URLS.GET_QUESTIONNAIRE_QUESTIONS(questionnaireName),
    );
  }

  submitQuestionnaireAnswers(
    params: QuestionnaireSubmissionParamsType,
  ): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(
      API_URLS.SUBMIT_QUESTIONNAIRE_QUESTIONS(params.questionnaireName),
      params.data,
    );
  }
}
