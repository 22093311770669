import { user } from '../../../user/data';
import { featureManager } from '../feature-manager';
import { firebaseFeatureProvider } from '../providers';

export async function isFeatureEnabled(feature: string): Promise<boolean> {
  return featureManager.isEnabled(feature);
}

export function isUserFeatureEnabled(feature: string): boolean {
  return user.features.includes(feature);
}

export async function isFirebaseFeatureEnabled(feature: string): Promise<boolean> {
  return firebaseFeatureProvider.isEnabled(feature);
}
