import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  OrderActivityWithStatusModel,
  OrderActivityWithStatusResponseModel,
} from '../../domain/order/order-activity-with-status.model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetOrderActivityWithStatusUseCase
  implements UseCase<OrderActivityWithStatusModel, OrderActivityWithStatusResponseModel>
{
  constructor(private _orderRepository: OrderRepository) {}

  execute(filter: OrderActivityWithStatusModel): Observable<OrderActivityWithStatusResponseModel> {
    return this._orderRepository.getOrderActivityWithStatus(filter);
  }
}
