import events, { EventSubscription } from '@mongez/events';
import type {
  HttpBeforeSendingEventCallback,
  HttpEventsContract,
  HttpEventType,
  HttpOnCompleteEventCallback,
  HttpOnErrorEventCallback,
  HttpOnSuccessEventCallback,
} from '../../domain/types/http';

export class HttpEvents implements HttpEventsContract {
  /**
   * Events subscriptions
   */
  protected events: Record<HttpEventType, EventSubscription[]> = {
    beforeSending: [],
    onSuccess: [],
    onError: [],
    onComplete: [],
  };

  public beforeSending(callback: HttpBeforeSendingEventCallback): void {
    this.events.beforeSending.push(events.subscribe('http.events.beforeSending', callback));
  }

  public onError(callback: HttpOnErrorEventCallback): void {
    this.events.onError.push(events.subscribe('http.events.onError', callback));
  }

  public onSuccess(callback: HttpOnSuccessEventCallback): void {
    this.events.onSuccess.push(events.subscribe('http.events.onSuccess', callback));
  }

  public onComplete(callback: HttpOnCompleteEventCallback): void {
    this.events.onComplete.push(events.subscribe('http.events.onComplete', callback));
  }

  public trigger(event: HttpEventType, data: any): void {
    events.trigger(`http.events.${event}`, data);
  }

  public dispose(): void {
    Object.values(this.events).forEach((event) => {
      event.forEach((subscription) => subscription.unsubscribe());
    });
  }
}
