import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  OrderProductListModel,
  ProductPriceOrderModel,
} from '../../domain/order/product-price.model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetOrderProductPricesUseCase
  implements UseCase<OrderProductListModel, ProductPriceOrderModel>
{
  constructor(private _orderRepository: OrderRepository) {}

  execute(productList: OrderProductListModel): Observable<ProductPriceOrderModel> {
    return this._orderRepository.getOrderProductPrices(productList);
  }
}
