import { Duration } from '@js-joda/core';

export enum WithdrawalError {
  WrongOTP,
  OTPAlreadyVerified,
  OTPExpired,
  InsufficientFunds,
  Generic,
}

export type WithdrawalOTPError =
  | {
      type: 'OTPRequestLimitExceeded';
      timeUntilNextRequest?: Duration;
    }
  | string;
