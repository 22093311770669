enum FailedReasons {
  INVALID_PROVINCE = 'invalid_province',
  INVALID_COUNTRY = 'invalid_country',
  INVALID_ADDRESS = 'invalid_address',
  PRICE_ERROR = 'price_error',
  PRODUCT_NOT_FOUND = 'product_not_found',
  PRODUCT_NOT_AVAILABLE = 'product_not_available',
  PRODUCT_STOCK_NOT_AVAILABLE = 'product_stock_not_available',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  INVALID_SHIPPING_METHOD = 'invalid_shipping_method',
  PROVINCE_NOT_COVERED = 'province_not_covered',
  INVALID_SKU = 'some_skus_not_belongs_to_taager',
  NO_SKU_SUPPORTED = 'no-sku-supported',
  MISSING_REQUIRED_FIELD = 'missing-required-field',
  PROVINCE_NOT_FOUND = 'province_not_found',
  PROVINCE_SHIPPING_MAPPING_NOT_FOUND = 'province_shipping_mapping_not_found',
  PHONE_NUMBER_NOT_FOUND = 'phone_number_not_found',
  PRICE_ERROR_MAXIMUM = 'price_error_maximum',
  TOO_HIGH_QUANTITY = 'too_high_quantity',
  MARKET_CLOSED = 'market_closed',
  SPAMMER_END_CUSTOMER = 'spammer_end_customer',
  SPAMMER_MERCHANT = 'spammer_merchant',
}

export const FAILED_ORDERS_REASON_MAP = new Map<string, string>([
  [FailedReasons.INVALID_PROVINCE, 'FAILED_ORDERS.REASONS.INVALID_PROVINCE'],
  [FailedReasons.INVALID_COUNTRY, 'FAILED_ORDERS.REASONS.INVALID_COUNTRY'],
  [FailedReasons.INVALID_ADDRESS, 'FAILED_ORDERS.REASONS.INVALID_ADDRESS'],
  [FailedReasons.PRICE_ERROR, 'FAILED_ORDERS.REASONS.PRICE_ERROR'],
  [FailedReasons.PRODUCT_NOT_FOUND, 'FAILED_ORDERS.REASONS.PRODUCT_NOT_FOUND'],
  [FailedReasons.PRODUCT_NOT_AVAILABLE, 'FAILED_ORDERS.REASONS.PRODUCT_NOT_AVAILABLE'],
  [FailedReasons.PRODUCT_STOCK_NOT_AVAILABLE, 'FAILED_ORDERS.REASONS.PRODUCT_STOCK_NOT_AVAILABLE'],
  [FailedReasons.INVALID_PHONE_NUMBER, 'FAILED_ORDERS.REASONS.INVALID_PHONE_NUMBER'],
  [FailedReasons.INVALID_SHIPPING_METHOD, 'FAILED_ORDERS.REASONS.INVALID_SHIPPING_METHOD'],
  [FailedReasons.PROVINCE_NOT_COVERED, 'FAILED_ORDERS.REASONS.PROVINCE_NOT_COVERED'],
  [FailedReasons.INVALID_SKU, 'FAILED_ORDERS.REASONS.INVALID_SKU'],
  [FailedReasons.NO_SKU_SUPPORTED, 'FAILED_ORDERS.REASONS.NO_SKU_SUPPORTED'],
  [FailedReasons.MISSING_REQUIRED_FIELD, 'FAILED_ORDERS.REASONS.MISSING_REQUIRED_FIELD'],
  [FailedReasons.PROVINCE_NOT_FOUND, 'FAILED_ORDERS.REASONS.PROVINCE_NOT_FOUND'],
  [
    FailedReasons.PROVINCE_SHIPPING_MAPPING_NOT_FOUND,
    'FAILED_ORDERS.REASONS.PROVINCE_SHIPPING_MAPPING_NOT_FOUND',
  ],
  [FailedReasons.PHONE_NUMBER_NOT_FOUND, 'FAILED_ORDERS.REASONS.PHONE_NUMBER_NOT_FOUND'],
  [FailedReasons.PRICE_ERROR_MAXIMUM, 'FAILED_ORDERS.REASONS.PRICE_ERROR_MAXIMUM'],
  [FailedReasons.TOO_HIGH_QUANTITY, 'FAILED_ORDERS.REASONS.TOO_HIGH_QUANTITY'],
  [FailedReasons.MARKET_CLOSED, 'FAILED_ORDERS.REASONS.MARKET_CLOSED'],
  [FailedReasons.SPAMMER_END_CUSTOMER, 'FAILED_ORDERS.REASONS.SPAMMER_END_CUSTOMER'],
  [FailedReasons.SPAMMER_MERCHANT, 'FAILED_ORDERS.REASONS.SPAMMER_MERCHANT'],
]);
