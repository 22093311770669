import { groupedTranslations } from '@mongez/localization';

groupedTranslations('validation', {
  required: {
    en: 'This field is required',
    ar: 'هذا الحقل مطلوب',
  },
  minLength: {
    en: 'This field must be at least :minLength characters',
    ar: 'يجب أن يحتوي هذا الحقل على :minLength حرف على الأقل',
  },
  maxLength: {
    en: 'This field must be at most :maxLength characters',
    ar: 'يجب أن يحتوي هذا الحقل على :maxLength حرف على الأكثر',
  },
  min: {
    en: 'This field must be at least :min',
    ar: 'يجب أن يكون هذا الحقل على الأقل :min',
  },
  max: {
    en: 'This field must be at most :max',
    ar: 'يجب أن يكون هذا الحقل على الأكثر :max',
  },
  invalidPhoneNumber: {
    en: 'Invalid phone number',
    ar: 'رقم هاتف غير صالح',
  },
  url: {
    en: 'Invalid URL',
    ar: 'رابط غير صحيح',
  },
  int: {
    en: 'This field must be an integer',
    ar: 'يجب أن يكون هذا الحقل عدد صحيح',
  },
  email: {
    en: 'Invalid email address',
    ar: 'بريد الكتروني غير صحيح',
  },
  maxFileSize: {
    en: 'The file size must not exceed :size',
    ar: 'حجم الملف جيب ان لا يزيد عن :size',
  },
});
