import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CESQuestionTypesCore } from '../ces-question-types-core';

@Component({
  selector: 'app-ces-free-text-question',
  standalone: true,
  imports: [ReactiveFormsModule, NgTemplateOutlet, NgIf, TranslateModule],
  templateUrl: './ces-free-text-question.component.html',
  styleUrls: ['./ces-free-text-question.component.scss'],
})
export class CesFreeTextQuestionComponent extends CESQuestionTypesCore {
  constructor() {
    super();
    this.controlName = 'text';
  }

  ngOnInit(): void {
    this.instantiateForm(this.controlName);
    this.optionallyPatchForm({ [this.controlName]: this.currentAnswer });
  }
}
