import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ChangeWalletPasswordModel } from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class ChangeWalletPasswordUseCase
  implements UseCase<ChangeWalletPasswordModel, { code: number } | { msg: string }>
{
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: ChangeWalletPasswordModel): Observable<any> {
    return this._walletRepository.changeWalletPassword(params);
  }
}
