import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { StockAvailabilityRepository } from 'src/app/core/repositories/stock-availability.repository';
import { StockAvailabilityEntity } from './entities/stock-availability-entity';
import { StockAvailabilityMapper } from './mappers/stock-availability-repository.mapper';
import { StockAvailabilityApisService } from './stock-availability-apis.services';

@Injectable({
  providedIn: 'root',
})
export class StockAvailabilityRepositoryImplementation implements StockAvailabilityRepository {
  public stockAvailabilityMapper = new StockAvailabilityMapper();

  constructor(private _stockAvailabilityApisService: StockAvailabilityApisService) {}

  getStockAvailability(productId: string): Observable<StockAvailabilityEntity> {
    return this._stockAvailabilityApisService
      .getStockAvailability(productId)
      .pipe(map((stockAvailability) => this.stockAvailabilityMapper.mapFrom(stockAvailability)));
  }

  sendNotifyMeRequests(productId: string): Observable<void> {
    return this._stockAvailabilityApisService.sendNotifyMeRequests(productId);
  }
}
