import { Mapper } from 'src/app/core/base/mapper';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { LoginResponse } from '../../auth/remote/entities/login.entity';

export class UserMapper extends Mapper<LoginResponse, UserModel> {
  mapFrom(param: LoginResponse): UserModel {
    return {
      taagerId: param.taagerId,
      selectedMarket: param.selectedMarket,
      stores: param.stores,
      features: param.features,
      email: param.email,
      username: param.username,
      fullName: param.fullName,
      callingCode: param.callingCode,
      phoneNumber: param.phoneNumber,
      verificationState: param.verificationState,
      actualVerificationState: param.actualVerificationState,
      token: param.jwtToken,
    };
  }

  mapTo(param: UserModel): LoginResponse {
    throw new Error('Method not implemented.');
  }
}
