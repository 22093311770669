import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OtpRequestInfoModel } from '../../domain/payment/otp-request-info.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class RequestWithdrawalOtpUseCase implements UseCase<void, OtpRequestInfoModel> {
  constructor(private _walletRepository: WalletRepository) {}

  execute(): Observable<OtpRequestInfoModel> {
    return this._walletRepository.requestWithdrawalOtp();
  }
}
