import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetProductCreativesEligibilityUseCase
  implements UseCase<string, { isAllowed: boolean }>
{
  constructor(private _productRepository: ProductsRepository) {}

  execute(productId: string): Observable<{ isAllowed: boolean }> {
    return this._productRepository.getProductCreativesEligibility(productId);
  }
}
