export enum ReferredMerchantProfitStatuses {
  PENDING_ACHIEVEMENT = 'PENDING_ACHIEVEMENT',
  PROFIT_IN_PROGRESS = 'PROFIT_IN_PROGRESS',
  NO_PROFIT_DESERVED = 'NO_PROFIT_DESERVED',
  PROFIT_RECEIVED = 'PROFIT_RECEIVED',
}

export const REFERRED_MERCHANT_PROFIT_STATUS_MAPPER = new Map<string, string>([
  [ReferredMerchantProfitStatuses.NO_PROFIT_DESERVED, 'REFERRAL_PROGRAM.STATUS.NO_PROFIT_DESERVED'],
  [
    ReferredMerchantProfitStatuses.PENDING_ACHIEVEMENT,
    'REFERRAL_PROGRAM.STATUS.PENDING_ACHIEVEMENT',
  ],
  [ReferredMerchantProfitStatuses.PROFIT_IN_PROGRESS, 'REFERRAL_PROGRAM.STATUS.PROFIT_IN_PROGRESS'],
  [ReferredMerchantProfitStatuses.PROFIT_RECEIVED, 'REFERRAL_PROGRAM.STATUS.PROFIT_RECEIVED'],
]);
