// it is important to not calling the repository from the top level of the feature i.e `@features/user` or `@features/shared`
// why? because it will create a circular dependency, instead, we should call the repository from the nearest directory of the file
// i.e `data/repositories` or `domain/types` to avoid circular dependency
import { CountriesRepository } from '@features/country/data';
import { CountriesRepositoryContract } from '@features/country/domain';
import {
  FirebaseRemoteConfigRepository,
  UserFeaturesRepository,
} from '@features/feature-manager/data';
import { FeaturesListRepositoryContract } from '@features/feature-manager/domain';
import { AuthRepository } from '@features/user/data/repositories';
import { type AuthRepositoryContract } from '@features/user/domain/types';
import { KYCRepository } from '../features/kyc/data';
import { KYCRepositoryContract } from '../features/kyc/domain';
import { LockedTestableProductsRepository } from '../features/locked-testable-products/data';
import { LockedTestableProductsRepositoryContract } from '../features/locked-testable-products/domain';
import { PreOrderRepository } from '../features/pre-order/data/repositories/pre-order-repository';
import { PreOrderRepositoryContract } from '../features/pre-order/domain';
import { ProductLockRequestRepository } from '../features/product-lock-request/data';
import { ProductLockRequestRepositoryContract } from '../features/product-lock-request/domain';
import { singleton } from './utils';

// Application resolvers should be listed here like
export function resolveCountriesRepository(): CountriesRepositoryContract {
  return singleton('countries', () => new CountriesRepository());
}

/**
 * Resolve auth repository
 */
export function resolveAuthRepository(): AuthRepositoryContract {
  return singleton('auth', () => new AuthRepository());
}

/**
 * Resolve user features repository
 */
export function resolveUserFeaturesRepository(): FeaturesListRepositoryContract {
  return new UserFeaturesRepository();
}

/**
 * Resolve firebase features repository
 */
export function resolveFirebaseRemoteConfigRepository(): FeaturesListRepositoryContract {
  return new FirebaseRemoteConfigRepository();
}

export function resolveKYCRepository(): KYCRepositoryContract {
  return new KYCRepository();
}

export function resolvePreOrderRepository(): PreOrderRepositoryContract {
  return new PreOrderRepository();
}

export function resolveProductLockRequestRepository(): ProductLockRequestRepositoryContract {
  return new ProductLockRequestRepository();
}

export function resolveLockedTestableProductRepository(): LockedTestableProductsRepositoryContract {
  return new LockedTestableProductsRepository();
}
