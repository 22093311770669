import { Observable } from 'rxjs';

import { UseCase } from '../../base/use-case';
import { ProvinceModel } from '../../domain/provinces.model';
import { ProvinceRepository } from '../../repositories/province.repository';

export class GetProvinceListUseCase implements UseCase<string, ProvinceModel[]> {
  constructor(private _provinceRepository: ProvinceRepository) {}

  execute(country: string): Observable<ProvinceModel[]> {
    return this._provinceRepository.getAllProvinceZoneDistrictList(country);
  }
}
