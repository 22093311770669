import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { FAQModel } from '../../domain/faq.model';
import { LoyaltyProgramRepository } from '../../repositories/loyalty-program.repository';

export class GetLoyaltyFAQsDataUseCase implements UseCase<void, FAQModel[]> {
  constructor(private _loyaltyProgramRepository: LoyaltyProgramRepository) {}

  execute(): Observable<FAQModel[]> {
    return this._loyaltyProgramRepository.getLoyaltyFAQssData();
  }
}
