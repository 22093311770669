import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import { LOGIN_URL, ONBOARDING_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { RouteAccessResolutionTypes } from './interfaces';
import { isUserLoggedIn, stringToUrlTree, userNeedsToFillOnboardingQuestionnaire } from './shared';

/**
 * So, what are the rules which decide whether the user should be able to see the
 * onboarding?
 *
 * 1. I have to be logged in, and if I am not logged in, then
 * I have to be redirected to LOGIN_V2_URL.
 *
 * 2. Is not a candidate for onboarding
 */
export const onboardingResolver = (): Observable<RouteAccessResolutionTypes> => {
  const localStorageService = inject(LocalStorageService);
  const isUserEligibleForOnboardingUseCase = inject(IsUserEligibleForOnboardingUseCase);
  const getOnboardingFeatureUseCase = inject(GetOnboardingFeatureUseCase);
  const getQuestionnaireEligibilityUseCase = inject(GetQuestionnaireEligibilityUseCase);
  const router = inject(Router);
  const onboardingStoryGuideStateManager = inject(OnboardingStoryGuideStateManager);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          userNeedsToFillOnboardingQuestionnaire({
            getOnboardingFeatureUseCase,
            isUserEligibleForOnboardingUseCase,
            getQuestionnaireEligibilityUseCase,
            onboardingStoryGuideStateManager,
          }).subscribe({
            next: (needsToBeOnboarded) => {
              if (needsToBeOnboarded) {
                subscriber.next(stringToUrlTree(ONBOARDING_URL, router));
              } else {
                subscriber.next(true);
              }
            },
          });
        } else {
          subscriber.next(stringToUrlTree(LOGIN_URL, router));
        }
      },
    });
  });
};
