import { resolveCountriesRepository } from '@di/app';
import {
  listCountriesUseCase,
  updateSelectedMarketUseCase,
} from '../use-cases/countries-.use-case';

/**
 * Wrap all related use cases in one resolver class
 * This would be clear in the auth resolver because it has multiple use cases
 */
export class CountriesUseCaseResolver {
  /**
   * Resolve list countries use case dependencies and execute it
   */
  public static async list(): ReturnType<typeof listCountriesUseCase> {
    return listCountriesUseCase(resolveCountriesRepository());
  }

  public static async updateSelectedMarketUseCase(
    iso3: string,
  ): ReturnType<typeof updateSelectedMarketUseCase> {
    return updateSelectedMarketUseCase(resolveCountriesRepository(), iso3);
  }
}
