import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CPARequestModel, CPAResponseModel } from '../../domain/pricing-calculator.model';
import { PricingCalculatorRepository } from '../../repositories/pricing-calculator-repository';

export class CPACalculatorUseCase implements UseCase<CPARequestModel, CPAResponseModel> {
  constructor(private _pricingCalculatorRepository: PricingCalculatorRepository) {}

  execute(cpaRequestModel: CPARequestModel): Observable<CPAResponseModel> {
    return this._pricingCalculatorRepository.cpaCalculator(cpaRequestModel);
  }
}
