import { kycPopupAtom } from '@presentation/kyc/atoms';
import { BasePresenter, ViewEventHandler } from '../../../base/base.presenter';
import { KYCPopupSideEffects } from './kyc-popup.side-effects';
import { KYCPopupViewEvent } from './kyc-popup.view-events';
import { KYCPopupViewState } from './kyc-popup.view-state';

export class KYCPopupPresenter extends BasePresenter<
  KYCPopupViewState,
  KYCPopupViewEvent,
  KYCPopupSideEffects
> {
  protected defaultViewState(): KYCPopupViewState {
    return {
      opened: false,
    };
  }

  protected viewEvents: ViewEventHandler<KYCPopupViewEvent> = {
    Init: this.initPopup.bind(this),
  };

  protected initPopup(): void {
    kycPopupAtom.onChange((opened) => {
      this.set('opened', opened);
      if (opened) {
        this.emitSideEffect({ type: 'Opened' });
      }
    });
  }
}
