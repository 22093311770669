import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  ProvinceModel,
  ProvinceZoneDistrictFilterModel,
  ProvinceZoneDistrictModel,
  ProvinceZoneFilterModel,
  ProvinceZoneModel,
} from 'src/app/core/domain/provinces.model';
import { ProvinceRepository } from 'src/app/core/repositories/province.repository';
import { ProvinceRepositoryMapper } from './mappers/province.mapper';
import { ProvinceZoneDistrictsRepositoryMapper } from './mappers/provinceZoneDistrcits.mapper';
import { ProvinceZonesRepositoryMapper } from './mappers/provinceZones.mapper';
import { ProvinceAPIservice } from './province-apis.service';

@Injectable({
  providedIn: 'root',
})
export class ProvinceRepositoryImplementation extends ProvinceRepository {
  public provinceMapper = new ProvinceRepositoryMapper();

  public provinceZonesMapper = new ProvinceZonesRepositoryMapper();

  public provinceZoneDistrictsMapper = new ProvinceZoneDistrictsRepositoryMapper();

  constructor(private _provinceAPIService: ProvinceAPIservice) {
    super();
  }

  getAllProvinceZoneDistrictList(country: string): Observable<ProvinceModel[]> {
    return this._provinceAPIService
      .getAllProvinceZoneDistrictList(country)
      .pipe(map((response) => response.data.map(this.provinceMapper.mapFrom)));
  }

  getProvinceZoneList(zoneFilter: ProvinceZoneFilterModel): Observable<ProvinceZoneModel[]> {
    return this._provinceAPIService
      .getProvinceZones(zoneFilter)
      .pipe(map((response) => response.data.map(this.provinceZonesMapper.mapFrom)));
  }

  getProvinceZoneDistrictList(
    distrcitFilter: ProvinceZoneDistrictFilterModel,
  ): Observable<ProvinceZoneDistrictModel[]> {
    return this._provinceAPIService
      .getProvinceZoneDistricts(distrcitFilter)
      .pipe(map((response) => response.data.map(this.provinceZoneDistrictsMapper.mapFrom)));
  }
}
