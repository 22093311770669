import { Injectable } from '@angular/core';
import { getLocalizedValue } from '@features/drivers/data';
import { from, map, Observable } from 'rxjs';
import {
  AvailabilityFeatures,
  MarketAvailabilityModel,
} from 'src/app/core/domain/market-availability.model';
import { MarketAvailabilityRepository } from 'src/app/core/repositories/market-availability.repository';
import { MarketAvailabilityApiService } from './market-availability.api-service';

@Injectable({
  providedIn: 'root',
})
export class MarketAvailabilityRepositoryImplementation implements MarketAvailabilityRepository {
  constructor(private _marketAvailabilityApiService: MarketAvailabilityApiService) {}

  getMarketAvailability(): Observable<MarketAvailabilityModel[]> {
    return from(this._marketAvailabilityApiService.getMarketAvailability());
  }

  isFeatureEnabled(feature: AvailabilityFeatures): Observable<boolean> {
    return this.getMarketAvailability().pipe(
      map((marketAvailability: MarketAvailabilityModel[]) => {
        const today = new Date();

        const featureNonAvailability = marketAvailability.find((item) => {
          return (
            item.closedFeature === feature &&
            this.isDateBetween(today, item.closingFromDate, item.closingToDate)
          );
        });
        return !featureNonAvailability;
      }),
    );
  }

  getMarketClosingReason(feature: AvailabilityFeatures): Observable<string | undefined> {
    return this.getMarketAvailability().pipe(
      map((marketAvailability: MarketAvailabilityModel[]) => {
        const today = new Date();
        const featureAvailability = marketAvailability.find(
          (item) =>
            item.closedFeature === feature &&
            this.isDateBetween(today, item.notifyDate, item.closingToDate),
        );
        return featureAvailability;
      }),
      map((featureAvailability) => {
        return getLocalizedValue(featureAvailability?.closingMessage);
      }),
    );
  }

  private isDateBetween(date: Date, fromDate: Date, toDate: Date): boolean {
    return date >= new Date(fromDate) && date <= new Date(toDate);
  }
}
