/* eslint-disable guard-for-in */
import { BaseRepository } from '@features/shared';
import { ProductLockRequestRepositoryContract } from '../../domain';
import { ProductLockRequestListData } from '../../domain/types/data.types';
import { lockRequestAPI } from '../constants';
import {
  ProductLockRequestCreateModel,
  ProductLockRequestModel,
  UserOrderFulfilmentModel,
} from '../models';
/* eslint-disable-next-line */
import { country } from '@/v3/features/country/data';
/* eslint-disable-next-line */
import { user } from '@/v3/features/user/data';

export class ProductLockRequestRepository
  extends BaseRepository
  implements ProductLockRequestRepositoryContract
{
  public async productLockRequestsList(): Promise<ProductLockRequestModel[]> {
    const params = {
      page: 1,
      pageSize: 999,
    };
    const response = await this.http.get<ProductLockRequestListData>(
      lockRequestAPI.PRODUCT_REQUESTS,
      {
        params,
      },
    );
    return response.data.results;
  }

  public async productLockRequestCreate(data: ProductLockRequestCreateModel): Promise<any> {
    const response = await this.http.post(lockRequestAPI.PRODUCT_REQUESTS, data);
    return response;
  }

  public async userFulfilmentRate(): Promise<UserOrderFulfilmentModel> {
    const userId = user.id;
    const response = await this.http.get<UserOrderFulfilmentModel>(
      lockRequestAPI.FULFILMENT_RATES(userId),
      {
        params: {
          country: country.code,
        },
      },
    );
    return response.data;
  }
}
