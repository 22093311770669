import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { BulkPreOrderRequestModel } from '../../domain/bulk-pre-order/bulk-pre-order-request.model';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class CreateBulkPreOrderRequestUseCase implements UseCase<BulkPreOrderRequestModel, any> {
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(request: BulkPreOrderRequestModel): Observable<any> {
    return this._bulkPreOrderRepository.createPreOrderRequest(request);
  }
}
