import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URLS } from 'app/presentation/shared/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesApisService {
  constructor(private http: HttpClient) {}

  uploadImageCheckout(file: FormData): Observable<{ filePath: string }> {
    return this.http.post<{ filePath: string }>(API_URLS.PRODUCT_CHECKOUT_IMAGES_URL, file);
  }
}
