import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { EASY_ORDERS_PROVIDER_NAME } from '@features/stores/data';
import { combineLatest, map, Observable, of, shareReplay, tap } from 'rxjs';
import {
  APIKeyGenerateModel,
  APIKeysGetModel,
} from 'src/app/core/domain/api-keys-integration.model';
import { MerchantStore, MerchantStoreAuth } from 'src/app/core/domain/merchant-store.model';
import { StoreModel } from 'src/app/core/domain/store.model';
import { UserStoreModel } from 'src/app/core/domain/user-store.model';
import { MerchantStoreRepository } from 'src/app/core/repositories/merchant-store.repository';
import { easyOrdersStore } from '../../api/mappers/stores-local-data-source';
import { MerchantStoreApiService } from '../../api/merchant-store-api.service';
import { UserStoreMapper } from './mappers/user-store.mapper';

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreRepositoryImplementation implements MerchantStoreRepository {
  private _userStoreMapper = new UserStoreMapper();

  private _cachedStores$: Observable<UserStoreModel[]> | null;

  constructor(private merchantStoreApi: MerchantStoreApiService) {}

  generateAPIKey(expiresAt: string): Observable<APIKeyGenerateModel> {
    return this.merchantStoreApi.generateAPIKey(expiresAt);
  }

  getAPIKeys(): Observable<APIKeysGetModel[]> {
    return this.merchantStoreApi.getAPIKeys();
  }

  linkStore(storeAuth: MerchantStoreAuth): Observable<MerchantStore> {
    return this.merchantStoreApi.linkStore(storeAuth).pipe(
      tap(() => {
        this._cachedStores$ = null;
      }),
    );
  }

  unlinkStore(storeId: string): Observable<void> {
    return this.merchantStoreApi.unlinkStore(storeId).pipe(
      tap(() => {
        this._cachedStores$ = null;
      }),
    );
  }

  addProductToStore(merchantStore: MerchantStore, productId: string): Observable<null> {
    return this.merchantStoreApi.addProductToStore(merchantStore, productId);
  }

  /**
   * Determine if the given store is supported by the current country
   * In our new update, all stores are supported for all countries.
   * If something went unexpected, then we will uncomment the line
   */
  protected isStoreSupported(store: StoreModel): boolean {
    // return true;
    return store.supportedCountries.includes(country.code);
  }

  getStores(): Observable<StoreModel[]> {
    return combineLatest({
      stores: this.merchantStoreApi.getStores(),
    }).pipe(
      map(({ stores }) => {
        return stores.filter((store) => {
          return this.isStoreSupported(store);
        });
      }),
      shareReplay(1),
    );
  }

  getUserStores(): Observable<UserStoreModel[]> {
    if (!this._cachedStores$) {
      this._cachedStores$ = this.merchantStoreApi.getUserStores().pipe(
        map((userStores) => userStores.map(this._userStoreMapper.mapFrom)),
        tap((stores) => {
          if (this.isStoreSupported(easyOrdersStore)) {
            stores.unshift({
              market: '',
              provider: EASY_ORDERS_PROVIDER_NAME,
              // please note that we need to add it because we check if store is connected or not bty store id
              // This will have no effect though
              storeId: 'EASY_ORDERS_STORE_ID',
            });
          }

          this._cachedStores$ = of(stores);

          return stores;
        }),
        shareReplay(1),
      );
    }
    return this._cachedStores$;
  }
}
