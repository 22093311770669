import { NgIf } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { user } from '@features/user/data';
import { map } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { CUSTOMER_IO_FEATURE } from '../../constants/feature-flags';
import { CustomerIoService } from '../../services/customer-io.service';
import { PlainLayoutService } from '../../services/plain-layout.service';
import { ConfirmExitDialogComponent } from './components/confirm-exit-dialog/confirm-exit-dialog.component';

@Component({
  selector: 'app-plain-layout',
  templateUrl: './plain-layout.component.html',
  styleUrls: ['./plain-layout.component.scss'],
  standalone: true,
  imports: [NgIf, RouterOutlet, RouterModule],
})
export class PlainLayoutComponent {
  public showCloseButton = false;

  public confirmationDialogOpened = false;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private router: Router,
    private plainLayoutService: PlainLayoutService,
    private dialog: MatDialog,
    private _customerIoService: CustomerIoService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    const closeButtonState = this.plainLayoutService.getShowCloseButton();
    closeButtonState.subscribe({
      next: (closeButton: boolean) => {
        this.showCloseButton = closeButton;
      },
    });
    const confirmationDialogState = this.plainLayoutService.getConfirmationDialogState();
    confirmationDialogState.subscribe({
      next: (confirmationDialog: boolean) => {
        this.confirmationDialogOpened = confirmationDialog;
      },
    });
    this._getFeatureFlagUseCase
      .execute(CUSTOMER_IO_FEATURE)
      .pipe(
        map((flag) => {
          if (flag) {
            this._customerIoService.loadWidget();
          }
        }),
      )
      .subscribe();
  }

  onClose(): void {
    if (this.router.url === this._appURLs.USER_VERIFICATION_URL && !this.confirmationDialogOpened) {
      this._logMixpanelEventUseCase.execute({ eventName: 'opt-in-quit-clicked' });
      this._openDialog();
    } else {
      this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
    }
  }

  public get isLoggedIn(): boolean {
    return user.isLoggedIn;
  }

  private _openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmExitDialogComponent, {
      width: '450px',
      position: {
        top: '100px',
        left: '40px',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (close) => {
        if (close) {
          this._logMixpanelEventUseCase.execute({ eventName: 'opt-in-confirm-quit-clicked' });
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
        }
      },
    });
  }
}
