import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { COMMERCIAL_CATEGORIES_APIS_ENDPOINTS } from './commercial-categories-apis-endpoints';
import { CategoryItemEntity } from './entities/category-item-entity';
import { CommercialCategoryEntity } from './entities/commercial-category-entity';

@Injectable({
  providedIn: 'root',
})
export class CommercialCategoriesApisService {
  constructor(private http: HttpClient) {}

  getCommercialCategories(countryCode: string): Observable<CommercialCategoryEntity[]> {
    return this.http.get<CommercialCategoryEntity[]>(
      COMMERCIAL_CATEGORIES_APIS_ENDPOINTS.GET_COMMERCIAL_CATEGORIES_URL,
      {
        params: { country: countryCode },
      },
    );
  }

  getBottomLevelCategories(): Observable<CategoryItemEntity[]> {
    return this.http
      .get<{ data: CategoryItemEntity[] }>(
        COMMERCIAL_CATEGORIES_APIS_ENDPOINTS.GET_BOTTOM_LEVEL_CATEGORIES,
      )
      .pipe(map(({ data }) => data));
  }

  getCommercialCategoryDataById(categoryId: string): Observable<CategoryItemEntity> {
    return this.http
      .get<{ data: CategoryItemEntity }>(
        COMMERCIAL_CATEGORIES_APIS_ENDPOINTS.GET_CATEGORY_DATA_BY_ID(categoryId),
      )
      .pipe(map(({ data }) => data));
  }
}
