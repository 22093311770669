<div class="confirm-exit">
  <p class="confirm-exit__title body1--bold">عملية التحقق مهمة من اجلك</p>
  <p class="confirm-exit__body body2--regular">
    لن تتمكن من سحب ارباحك إلا عند إستكمال عملية التحقق
  </p>
  <p class="confirm-exit__body body2--regular">هل ما زلت ترغب في الإغلاق؟</p>
  <div class="confirm-exit__actions">
    <button
      [mat-dialog-close]="true"
      class="confirm-exit__actions__button confirm-exit__actions__button--accept body2--regular"
    >
      نعم، اغلق النافذة
    </button>
    <button
      (click)="onCancelClick()"
      class="confirm-exit__actions__button confirm-exit__actions__button--reject body2--regular"
    >
      لا، سأقوم بالتحقق
    </button>
  </div>
</div>
