import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CancelOrderModel } from '../../domain/order/cancel-order.model';
import { OrderRepository } from '../../repositories/order.repository';

export class CancelOrderUseCase implements UseCase<CancelOrderModel, void> {
  constructor(private _orderRepository: OrderRepository) {}

  execute(cancelOrder: CancelOrderModel): Observable<void> {
    return this._orderRepository.cancelOrder(cancelOrder);
  }
}
