import { NgModule } from '@angular/core';
import * as Merchant from '@taager-experience-shared/merchant';
import packageInfo from 'package.json';
import { environment } from '../../../../environments/environment';
import { ExperienceAccessTokenHandler } from '../auth/experience-access-token.handler';
import { ExperienceConfigurationHandler } from '../configuration/experience-configuration.handler';
import { ExperienceApiMonitor } from '../monitoring/experience-api.monitor';
import ApiInjector = Merchant.com.taager.experience.api.di.ApiInjector;
import AppInfo = Merchant.com.taager.experience.api.configuration.model.AppInfo;
import ApiDomain = Merchant.com.taager.experience.api.configuration.model.ApiDomain;
import SessionIdProvider = Merchant.com.taager.experience.api.configuration.SessionIdProvider;
import CountryIdProvider = Merchant.com.taager.experience.api.configuration.CountryIdProvider;
import AccessTokenProvider = Merchant.com.taager.experience.api.authentication.AccessTokenProvider;
import AccessTokenUpdater = Merchant.com.taager.experience.api.authentication.AccessTokenUpdater;
import ApiLogMonitor = Merchant.com.taager.experience.api.logging.ApiLogMonitor;
import ApiExceptionMonitor = Merchant.com.taager.experience.api.exception.ApiExceptionMonitor;

const isDevelopmentMode = !environment.production;

const ApiInjectorFactory = (
  configurationHandler: ExperienceConfigurationHandler,
  accessTokenHandler: ExperienceAccessTokenHandler,
  apiMonitor: ExperienceApiMonitor,
) =>
  new ApiInjector(
    new AppInfo(isDevelopmentMode, packageInfo.version),
    ApiDomain.Merchant,
    configurationHandler as SessionIdProvider,
    configurationHandler as CountryIdProvider,
    accessTokenHandler as AccessTokenProvider,
    accessTokenHandler as AccessTokenUpdater,
    apiMonitor as ApiLogMonitor,
    apiMonitor as ApiExceptionMonitor,
  );
const ApiInjectorProvider = {
  provide: ApiInjector,
  useFactory: ApiInjectorFactory,
  deps: [ExperienceConfigurationHandler, ExperienceAccessTokenHandler, ExperienceApiMonitor],
};

@NgModule({
  providers: [ApiInjectorProvider],
})
export class ExperienceDataModule {}
