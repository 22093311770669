import { Mapper } from 'src/app/core/base/mapper';
import { StockAvailabilityModel } from 'src/app/core/domain/stock-availability.model';
import { StockAvailabilityEntity } from '../entities/stock-availability-entity';

export class StockAvailabilityMapper extends Mapper<
  StockAvailabilityEntity,
  StockAvailabilityModel
> {
  mapFrom(param: StockAvailabilityEntity): StockAvailabilityModel {
    return {
      stockRange: param.stockRange,
      runRate: param?.runRate,
      detailedStockRange: param.detailedStockRange,
      detailedRunRate: param?.detailedRunRate,
    };
  }

  mapTo(param: StockAvailabilityModel): StockAvailabilityEntity {
    return {
      stockRange: param.stockRange,
      runRate: param?.runRate,
      detailedStockRange: param.detailedStockRange,
      detailedRunRate: param?.detailedRunRate,
    };
  }
}
