<div class="layout-header">
  <div class="taager-logo">
    <a [routerLink]="['/']">
      <img
        loading="lazy"
        src="assets/img/header-icons/taager-logo-2022.svg"
        title="Taager Logo"
        alt="The logo of Taager website"
        class="taager-logo__image-desktop"
      />
      <img
        loading="lazy"
        src="assets/img/header-icons/LogoIcon.svg"
        title="Taager Logo"
        alt="The logo of Taager website"
        class="taager-logo__image-mobile"
      />
    </a>
  </div>
</div>
<router-outlet></router-outlet>
