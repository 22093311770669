import { map, Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { PreOrderRequestsSummaryModel } from '../../domain/bulk-pre-order/pre-order-request-summary.model';
import { PreOrderStatuses } from '../../domain/bulk-pre-order/pre-order-status.model';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class GetPreOrderRequestsSummaryUseCase
  implements UseCase<void, PreOrderRequestsSummaryModel>
{
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(): Observable<PreOrderRequestsSummaryModel> {
    const ALLOWED_PRE_ORDER_STATUSES = [
      PreOrderStatuses.MONEY_TRANSFERRED_APPROVED,
      PreOrderStatuses.AWAITING,
      PreOrderStatuses.AWAITING_EXTERNAL_PRODUCT_APPROVAL,
      PreOrderStatuses.STARTED,
      PreOrderStatuses.COMPLETED,
    ];
    return this._bulkPreOrderRepository.getPreOrderRequestsSummary().pipe(
      map((res) => ({
        ...res,
        preorderStatusSummary: res.preorderStatusSummary.filter((item) =>
          ALLOWED_PRE_ORDER_STATUSES.includes(item.status),
        ),
      })),
    );
  }
}
