import { environment } from 'src/environments/environment';

export const PRODUCTS_ENDPOINTS = {
  GET_PRODUCT_CREATIVES_ELIGIBILITY: (sku: string): string =>
    `${environment.BACKEND_URL}api/creative-requests/${sku}/is-allowed`,
  CREATE_PRODUCT_CHECKOUT: `${environment.BACKEND_URL}api/product-checkouts`,
  GET_PRODUCT_CHECKOUT_DETAIL: (productId: string): string =>
    `${environment.BACKEND_URL}api/product-checkouts?productId=${productId}`,
  UPDATE_PRODUCT_CHECKOUT_DETAIL: (uuid: string): string =>
    `${environment.BACKEND_URL}api/product-checkouts/${uuid}`,
};
