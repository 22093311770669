import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { PossibleIndicatorSteps } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { UserRegistrationStepUtility } from 'src/app/presentation/shared/utilities/user-registration-step.utility';

/**
 *
 * @param userRegistrationStepUtility
 * @param getRegisterTokenUseCase
 *
 * This function will check whether the user is has fully signed up.
 */
export const userHasFullySignedUp = (
  userRegistrationStepUtility: UserRegistrationStepUtility,
  getRegisterTokenUseCase: GetRegisterTokenUseCase,
): Observable<boolean> => {
  return new Observable((subscriber) => {
    getRegisterTokenUseCase.execute().subscribe({
      next: (token) => {
        if (
          userRegistrationStepUtility.currentUserStep(token) ===
          PossibleIndicatorSteps.signupFullyVerified
        ) {
          subscriber.next(true);
        } else {
          subscriber.next(false);
        }
      },
    });
  });
};
