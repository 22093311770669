import { Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { map, Observable, Subject } from 'rxjs';
import {
  ProductCheckoutCreateModel,
  ProductCheckoutModel,
  ProductCheckoutUpdateModel,
} from 'src/app/core/domain/products/product-checkout';
import { ProductReviewModel, VariantModel } from 'src/app/core/domain/variant-group.model';
import { ProductsRepository } from 'src/app/core/repositories/products.repository';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import {
  DISCOVERABILITY_FUNNEL_USER_FEATURE,
  FEATURE_FLAGS,
} from 'src/app/presentation/shared/constants';
import { ProductsPageExperienceCache } from './local/products.cache';
import { ProductsApisService } from './products-apis.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsImplementationRepository extends ProductsRepository {
  private _shouldDisplayNewHomePage: Subject<void> = new Subject();

  constructor(
    private _productsApisService: ProductsApisService,
    private _productsPageExperienceCache: ProductsPageExperienceCache,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
  ) {
    super();
  }

  getProductVAT(params: {
    countryIsoCode3: string;
    productId: string;
    productSellingPrice: number;
    quantity: number;
    isOnSale: boolean;
  }): Observable<number> {
    return this._productsApisService.getProductVAT(params);
  }

  getBulkPreorderableProducts(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    commercialCategoryId?: string;
  }): Observable<any> {
    return this._productsApisService.getPreorderableProducts({
      page: params?.page,
      pageSize: params?.pageSize,
      sortBy: params?.sortBy,
      commercialCategoryId: params?.commercialCategoryId,
    });
  }

  setProductReview(params: { data: ProductReviewModel; productId: string }): Observable<void> {
    return this._productsApisService.setProductReview(params);
  }

  getUpSellableProducts(productId: string): Observable<VariantModel[]> {
    return this._productsApisService.getUpSellableProductsByProdId(productId);
  }

  getVariantsByProductIds(productIds: string[]): Observable<VariantModel[]> {
    return this._productsApisService.getVariantsByProductIds(productIds);
  }

  shouldDisplayNewHomePageUseCase(): boolean {
    return this._productsPageExperienceCache.get();
  }

  listenToProductsPageExperienceChanges(): Observable<void> {
    return this._shouldDisplayNewHomePage.asObservable();
  }

  setSelectedProductsPageExperience(displayNewProductsPage: boolean): void {
    this._productsPageExperienceCache.put(displayNewProductsPage);
    this._shouldDisplayNewHomePage.next();
  }

  checkUserNewExperienceEligibility(): boolean {
    let userIsEligible = false;
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.DISCOVERABILITY_FUNNEL).subscribe({
      next: (flag) => {
        const isKsaSelected = country.is('KSA');
        const userHasDiscoverabilityFunnel = this._checkUserFeatureExistsUseCase.execute(
          DISCOVERABILITY_FUNNEL_USER_FEATURE,
        );
        const shouldDisplay = flag && isKsaSelected && userHasDiscoverabilityFunnel;
        userIsEligible = shouldDisplay;
      },
    });
    return userIsEligible;
  }

  getProductCreativesEligibility(productId: string): Observable<{ isAllowed: boolean }> {
    return this._productsApisService.getProductCreativesEligibility(productId);
  }

  productCheckoutCreate(data: ProductCheckoutCreateModel): Observable<void> {
    return this._productsApisService.createProductCheckout(data);
  }

  getProductCheckoutDetail(productId: string): Observable<ProductCheckoutModel> {
    return this._productsApisService
      .getProductCheckoutDetail(productId)
      .pipe(map((response) => response.data));
  }

  updateProductCheckout(data: ProductCheckoutUpdateModel): Observable<void> {
    return this._productsApisService.updateProductCheckout(data);
  }
}
