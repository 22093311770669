<div *ngIf="instantiate" class="wrapper">
  <img
    src="{{ assetsPath + 'trigger.svg' }}"
    alt="ces-trigger-button"
    class="wrapper__trigger"
    (click)="triggerFeedbackSubmit()"
    *ngIf="showCesTriggerButton"
    #cesTriggerButtonRef
  />
  <app-ces-questionnaire-overlay
    *ngIf="showCESQuestionnaire"
    [marginBottom]="cesComponentsMarginBottom"
    [questionnaire]="questionnaire"
    (closeQuestionnaire$)="closeQuestionnaire($event)"
  ></app-ces-questionnaire-overlay>
  <div *ngIf="showSignOff && !showCESQuestionnaire" class="wrapper__sign-off">
    <div class="wrapper__sign-off__overlay"></div>
    <div [style]="{ bottom: cesComponentsMarginBottom }" class="wrapper__sign-off__content">
      <img src="{{ assetsPath + 'sign-off.svg' }}" alt="thank you" />
      <span class="body2--bold">{{ 'CUSTOMER_EFFORT_SUPPORT.SIGNING_OFF' | translate }}</span>
    </div>
  </div>
</div>
