import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  QuestionnaireModel,
  QuestionnaireSubmissionModel,
} from 'src/app/core/domain/questionnaire.model';
import { QuestionnaireRepository } from 'src/app/core/repositories/questionnaire.repository';
import { GetQuestionnaireQuestionsRepositoryMapper } from './mappers/get-questionnaire.mapper';
import { QuestionnaireSubmissionRepositoryMapper } from './mappers/questionnaire-submission.mapper';
import { QuestionnaireAPIservice } from './questionnaire-apis.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireRepositoryImplementation extends QuestionnaireRepository {
  public questionnaireMapper = new GetQuestionnaireQuestionsRepositoryMapper();

  public questionnaireSubmissionMapper = new QuestionnaireSubmissionRepositoryMapper();

  constructor(private questionnaireAPIService: QuestionnaireAPIservice) {
    super();
  }

  getQuestionnaireQuestions(questionnaireName: string): Observable<QuestionnaireModel> {
    return this.questionnaireAPIService
      .getQuestionnaireQuestions(questionnaireName)
      .pipe(map((response) => this.questionnaireMapper.mapFrom(response)));
  }

  submitQuestionnaire(params: {
    questionnaireName: string;
    data: QuestionnaireSubmissionModel;
  }): Observable<{ status: string }> {
    return this.questionnaireAPIService.submitQuestionnaireAnswers({
      ...params,
      // data: this.questionnaireSubmissionMapper.mapTo(params.data),
      data: params.data,
    });
  }
}
