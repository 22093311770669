import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicIncentiveFeedback } from 'src/app/core/domain/dynamic-incentive-program.model';
import { DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS } from './dynamic-incentive-program-apis-endpoints';
import {
  DynamicIncentiveProgramEntity,
  HistoryDynamicIncentiveProgramEntity,
} from './entities/dynamic-incentive-program-entity';

@Injectable({
  providedIn: 'root',
})
export class DynamicIncentiveProgramApisService {
  constructor(private _http: HttpClient) {}

  getDynamicIncentiveProgram(): Observable<DynamicIncentiveProgramEntity[]> {
    return this._http.get<DynamicIncentiveProgramEntity[]>(
      DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS.GET_DYNAMIC_INCENTIVE_PROGRAM,
    );
  }

  getDynamicIncentiveProgramHistory(): Observable<HistoryDynamicIncentiveProgramEntity[]> {
    return this._http.get<HistoryDynamicIncentiveProgramEntity[]>(
      DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS.GET_DYNAMIC_INCENTIVE_PROGRAM_HISTORY,
    );
  }

  acceptDynamicIncentiveProgram(id: string): Observable<DynamicIncentiveProgramEntity> {
    return this._http.patch<DynamicIncentiveProgramEntity>(
      DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS.ACCEPT_DYNAMIC_INCENTIVE_PROGRAM(id),
      {},
    );
  }

  declineDynamicIncentiveProgram(id: string): Observable<void> {
    return this._http.patch<void>(
      DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS.DECLINE_DYNAMIC_INCENTIVE_PROGRAM(id),
      {},
    );
  }

  feedbackDynamicIncentiveProgram(data: DynamicIncentiveFeedback): Observable<void> {
    return this._http.patch<void>(
      DYNAMIC_INCENTIVE_PROGRAM_APIS_ENDPOINTS.FEEDBACK_DYNAMIC_INCENTIVE_PROGRAM(data.id),
      { rate: data.rate, feedback: data.feedback },
    );
  }
}
