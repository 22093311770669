import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Tracking } from '../tracking.interface';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsTracker implements Tracking {
  constructor(private _angularFireAnalytics: AngularFireAnalytics) {}

  initialize(): void {}

  identifyUser(properties: { [key: string]: string }): void {
    this._angularFireAnalytics.setUserProperties(properties);
  }

  setUserProperty(propery: { [key: string]: string }): void {}

  sendEvent(eventName: string, payload: { [key: string]: string }): void {
    this._angularFireAnalytics.logEvent(eventName, payload);
  }
}
