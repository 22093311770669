import { Router } from '@angular/router';
import { user } from '@features/user/data';
import cache from '@mongez/cache';
import { Observable } from 'rxjs';
import { REDIRECT_URL } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';

/**
 *
 * @param redirectAfterLogin
 * @param localStorageService
 *
 * Okay, so here, we will be checking whether the user is logged in or not. If the user
 * is logged in, then we will return an observable of true.
 *
 * However, if the user is not logged in, then in that case, we will be returning observable
 * of false, but before that, we set the url which the user wanted to access, so that when
 * they are logged in finally, we can send them to that URL. And we don't consider an empty
 * URL or / as a navigable path.
 */
export const isUserLoggedIn = (
  router: Router,
  localStorageService: LocalStorageService,
): Observable<boolean> => {
  const targetUrl = router.getCurrentNavigation()!.extractedUrl.toString();
  return new Observable((subscriber) => {
    if (!user.isLoggedIn) {
      if (targetUrl && targetUrl !== '/') {
        cache.set(REDIRECT_URL, targetUrl);
      }
      subscriber.next(false);
    } else {
      subscriber.next(true);
    }
  });
};
