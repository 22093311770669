import { Injectable } from '@angular/core';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { Observable, of } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { catchError, map } from 'rxjs/operators';
import { RemoteConfigRepository } from 'src/app/core/repositories/remote-config.repository';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigRepositoryImpl extends RemoteConfigRepository {
  private featureFlags: any;

  private featureAttributes: any;

  private subject = new ReplaySubject(1);

  constructor(private remoteConfig: AngularFireRemoteConfig) {
    super();

    // initialize the feature flag values
    this.remoteConfig.booleans.subscribe({
      next: (remoteConfigParams) => {
        if (Object.keys(remoteConfigParams).length > 0) {
          this.featureFlags = remoteConfigParams;
          this.remoteConfig.lastFetchStatus.then(() => this.subject.next(true));
        }
      },
      error: (err) => {},
    });

    this.remoteConfig.strings
      .pipe(
        map((attributes) => {
          const nonBooleanAttributes: any = {};
          for (const attr in attributes) {
            if (attr in attributes) {
              if (!['true', 'false'].includes(attributes[attr]!)) {
                nonBooleanAttributes[attr] = attributes[attr];
              }
            }
          }
          return nonBooleanAttributes;
        }),
      )
      .subscribe({
        next: (featureAttributes) => {
          this.featureAttributes = featureAttributes;
        },
      });
  }

  getFeatureFlag(featureFlagName: string): Observable<boolean> {
    return this.subject.pipe(
      map(() => this.featureFlags[featureFlagName]),
      catchError((err, caught) => caught),
    );
  }

  getRemoteConfig(): Observable<any> {
    return of(this.featureFlags);
  }

  getFeatureAttribute(featureAttributeName: string): Observable<any> {
    return this.subject.pipe(
      map(() => {
        return this.featureAttributes[featureAttributeName];
      }),
      catchError((err, caught) => caught),
    );
  }
}
