<div class="dialog">
  <div class="dialog__header">
    <img
      class="dialog__close"
      src="{{ assetsPath + 'close.svg' }}"
      alt="'close'"
      (click)="dismissOverlay()"
    />
  </div>
  <img src="{{ assetsPath + 'auth/verification-succeeded.svg' }}" alt="" class="dialog__img" />
  <p class="dialog__title">تهانينا! تم استكمال بيانات حسابك</p>
  <button (click)="dismissOverlay()" class="dialog__action-btn">حسناً</button>
</div>
