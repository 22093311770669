import { CacheDriverInterface } from '@mongez/cache';
import {
  EndpointOptions,
  HttpClientContract,
  HttpEventsContract,
  HttpResponse,
} from '../../domain';

// Currently the mockup adapter will just mock the get request

export class MockupAdapter implements HttpClientContract {
  public events: HttpEventsContract = {} as any;

  public constructor(protected data: any, protected delay: number = 1000) {}

  public async get<O>(
    path: string,
    options?: EndpointOptions | undefined,
  ): Promise<HttpResponse<O>> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: this.data,
          status: 200,
          statusText: 'OK',
          headers: {},
        });
      }, this.delay);
    });
  }

  post<I, O>(
    path: string,
    data: I,
    options?: EndpointOptions | undefined,
  ): Promise<HttpResponse<O>> {
    throw new Error('Method not implemented.');
  }

  put<I, O>(
    path: string,
    data: I,
    options?: EndpointOptions | undefined,
  ): Promise<HttpResponse<O>> {
    throw new Error('Method not implemented.');
  }

  delete<O>(path: string, options?: EndpointOptions | undefined): Promise<HttpResponse<O>> {
    throw new Error('Method not implemented.');
  }

  patch<I, O>(path: string, data: I, options?: EndpointOptions | undefined): Promise<O> {
    throw new Error('Method not implemented.');
  }

  setBaseUrl(url: string): HttpClientContract {
    throw new Error('Method not implemented.');
  }

  setCacheDriver(cacheDriver: CacheDriverInterface): HttpClientContract {
    throw new Error('Method not implemented.');
  }
}
