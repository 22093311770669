import { NgFor, NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';
import { SiteTranslateService } from '../../shared/services/translate.service';
import {
  CESQuestionModel,
  CESQuestionnaire,
  QuestionPosition,
  SubmittedCESQuestion,
} from '../interfaces';
import { QUESTION_TYPES_MAPPING } from './ces-question-types/question-types.mapping';

@Component({
  selector: 'app-ces-questionnaire',
  templateUrl: './ces-questionnaire.component.html',
  styleUrls: ['./ces-questionnaire.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, TranslateModule],
})
export class CesQuestionnaireComponent {
  @Input() questionnaire: CESQuestionnaire;

  private _questionsAnswers: { [questionId: string]: any } = {};

  @ViewChild('questionOutlet', { read: ViewContainerRef, static: true })
  private _questionOutlet: ViewContainerRef;

  @Output() private submitAnswer$: EventEmitter<any> = new EventEmitter<any>();

  @Output() public setActiveQuestion$: EventEmitter<CESQuestionModel> = new EventEmitter();

  public questionIndex = 0;

  public siteTranslateService: SiteTranslateService = inject(SiteTranslateService);

  public translationLanguage: string;

  ngOnInit(): void {
    this._setTranslationLanguage();
    this._renderQuestionAtIndex(this.questionIndex);
  }

  private _setTranslationLanguage(): void {
    this.translationLanguage = this.siteTranslateService.getCurrentLanguage();
  }

  private _renderQuestionAtIndex(index: number): void {
    const question = this.questionnaire.questions[index];
    const component = QUESTION_TYPES_MAPPING[question.type];
    if (!component) {
      throw new Error(
        `${
          component ? 'Unsupported question type' : 'No question type provided'
        }. Please add it to QUESTION_TYPES_MAPPING`,
      );
    }
    if (this._questionOutlet) {
      this._questionOutlet.clear();
    }
    const componentInstance: any = this._questionOutlet.createComponent(component);
    componentInstance.instance.blueprint = question;
    componentInstance.instance.position = this._getQuestionPosition(index);
    componentInstance.instance.submitAnswer$.pipe(take(1)).subscribe({
      next: (answerObject: any) => this._saveAnswer(answerObject),
    });
  }

  private _getQuestionPosition(index: number): QuestionPosition {
    if (index === this.questionnaire.questions.length - 1) {
      return 'last';
    }
    if (index === 0) {
      return 'first';
    }
    return 'middle';
  }

  private _saveAnswer(answerObject: SubmittedCESQuestion): void {
    this._questionsAnswers[answerObject.questionId] = answerObject.answer;
    if (this._getQuestionPosition(this.questionIndex) === 'last') {
      this._postAnswers();
    } else {
      this.questionIndex += 1;
      this._renderQuestionAtIndex(this.questionIndex);
    }
  }

  private _postAnswers(): void {
    this.submitAnswer$.next(this._questionsAnswers);
  }
}
