import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { FinalIncentiveProgramEntity } from './entities/new-incentive-program-entity';

@Injectable({
  providedIn: 'root',
})
export class NewIncentiveProgramApi {
  constructor(private http: HttpClient) {}

  getNewUserIncentiveProgram(): Observable<FinalIncentiveProgramEntity> {
    return this.http.get<FinalIncentiveProgramEntity>(API_URLS.GET_MERCHANT_NEW_INCENTIVE_PROGRAM);
  }
}
