import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { IntlBankTransferEntity } from './entities/intl-bank-transfer.entity';
import { OtpRequestInfoEntity } from './entities/otp-request-info.entity';
import { PayoneerTransferEntity } from './entities/payoneer-transfer.entity';
import {
  ChangeWalletPasswordEntity,
  CreateWithdrawalRequestEntity,
  GetWithdrawalsListRequestEntity,
  WalletEntity,
  WalletTransactionHistoryEntity,
  WalletTransactionRequestFilterEntity,
  WithdrawalsListEntity,
} from './entities/wallet.entity';

@Injectable({
  providedIn: 'root',
})
export class WalletApisService {
  constructor(private _http: HttpClient) {}

  walletLogin(params: { username: string; password: string }): Observable<{ code: string }> {
    return this._http.post<{ code: string }>(API_URLS.WALLET_LOGIN_URL, params);
  }

  forgotWalletPassword(params: { email: string }): Observable<{ code: string }> {
    return this._http.patch<{ code: string }>(API_URLS.FORGOT_WALLET_PASSWORD_URL, params);
  }

  changeWalletPassword(
    params: ChangeWalletPasswordEntity,
  ): Observable<{ code: number } | { msg: string }> {
    return this._http.patch<{ code: number } | { msg: string }>(
      API_URLS.CHANGE_WALLET_PASSWORD_URL,
      params,
    );
  }

  resetWalletPassword(
    data: { password: string; confirmPassword: string },
    id: string,
    resetToken: string,
  ): Observable<void> {
    return this._http.patch<void>(API_URLS.RESET_WALLET_PASSWORD_URL(id, resetToken), data);
  }

  getWalletTransactionHistory(
    params: WalletTransactionRequestFilterEntity,
  ): Observable<WalletTransactionHistoryEntity> {
    const filteredParams = this._getFilteredObject(params);
    return this._http
      .get<{ data: WalletTransactionHistoryEntity }>(API_URLS.GET_WALLET_TRANSACTION_HISTORY_URL, {
        params: filteredParams,
      })
      .pipe(map((res) => res.data));
  }

  getTransactionsSheet(params: WalletTransactionRequestFilterEntity): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'text/csv');

    const filteredParams = this._getFilteredObject(params);

    return this._http.get(API_URLS.GET_WALLET_TRANSACTION_HISTORY_URL, {
      params: filteredParams,
      headers,
      responseType: 'blob',
    }) as Observable<Blob>;
  }

  public getWallets(): Observable<Array<WalletEntity>> {
    return this._http
      .get<{ data: WalletEntity[] }>(API_URLS.GET_WALLETS_URL)
      .pipe(map(({ data }) => data));
  }

  public createWithdrawalRequest(body: CreateWithdrawalRequestEntity): Observable<undefined> {
    return this._http.post(API_URLS.CREATE_WITHDRAWAL_REQUEST_URL, body).pipe(map(() => undefined));
  }

  public getWithdrawalsList(
    params: GetWithdrawalsListRequestEntity,
  ): Observable<WithdrawalsListEntity> {
    return this._http
      .post<{ data: WithdrawalsListEntity }>(API_URLS.GET_WITHDRAWLS_LIST_REQUESTS_URL, params)
      .pipe(map(({ data }) => data));
  }

  public requestWithdrawalOtp(): Observable<OtpRequestInfoEntity> {
    return this._http.post<OtpRequestInfoEntity>(API_URLS.WITHDRAWAL_REQUEST_OTP_REQUEST_URL, {});
  }

  private _getFilteredObject(params: any): HttpParams {
    return Object.keys(params)
      .filter((key) => params[key] || params[key] === 0)
      .reduce((resultObject: any, key) => {
        resultObject[key] = params[key];
        return resultObject;
      }, {}) as HttpParams;
  }

  withdrawIntlBankTransfer(request: IntlBankTransferEntity): Observable<any> {
    const withdrawURL =
      request.bankType === 'international'
        ? API_URLS.WITHDRAW_INTL_BANK_TRANSFER
        : API_URLS.WITHDRAW_EGP_BANK_TRANSFER;
    delete request.bankType;
    return this._http.post(withdrawURL, request);
  }

  withdrawPayoneer(request: PayoneerTransferEntity): Observable<any> {
    return this._http.post(API_URLS.WITHDRAW_PAYONEER_TRANSFER, request);
  }
}
