import { only } from '@mongez/reinforcements';
import jwtDecode from 'jwt-decode';
import { AuthUser } from '../../domain/entities';

export function mapUserData(data: any): AuthUser {
  const finalData: AuthUser = only(data, [
    'email',
    'phoneNumber',
    'name',
    'avatar',
    'callingCode',
    'verificationState',
    'actualVerificationState',
    'username',
    'features',
    'selectedMarket',
    'stores',
    'fullName',
    'loyaltyProgram',
    'profilePicture',
    'provider',
    'referralCode',
    'refreshToken',
    'firstName',
    'preferredCategories',
    'lastName',
    'defaultMarket',
    'createdAt',
    'verification',
  ]);

  // In some APIs it is sent as `TaggerID` so we need to normalize it to `taagerId`
  if (data.TagerID) {
    finalData.id = data.TagerID;
  } else if (data.taggerId) {
    finalData.id = data.taggerId;
  } else if (data.taagerId) {
    finalData.id = data.taagerId;
  }

  // we map the `_id` to be the main id
  if (data._id) {
    finalData._id = data._id;
  }

  // sometimes _id is sent as `userId`
  // @see login API
  if (data.userId) {
    finalData._id = data.userId;
  }

  // make sure the id is a number
  finalData.id = Number(finalData.id);

  // because access token is sent in different keys in different APIs, we need to check them all.
  finalData.accessToken =
    data.jwtToken || data.idToken || data.accessToken || data.data || data.token;

  // we need to map it to the correct key for ease of use
  finalData.verificationState = data.actualVerificationState;

  if (finalData.accessToken) {
    const tokenData: any = jwtDecode<any>(finalData.accessToken) || {};

    const { actualVerificationState, verificationState } = tokenData.user;

    if (verificationState) {
      finalData.verificationState = actualVerificationState || verificationState;
    }
  }

  if (!finalData.phoneNumber) {
    finalData.phoneNumber =
      data.phoneNum || data.verification?.phoneNumberVerification?.phoneNumber;
  }

  if (data.verification?.legacyVerification) {
    finalData.isLegacyUser = true;
  }

  return finalData;
}
