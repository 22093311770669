import { Component } from '@angular/core';
import { dialogAtom } from '@presentation/shared/dialog';
import { navigateTo } from '@presentation/shared/router';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { KYC_URL } from '../../../../shared/constants';

@Component({
  selector: 'app-kyc-popup-content',
  templateUrl: './kyc-popup-content.component.html',
  styleUrls: ['./kyc-popup-content.component.scss'],
  standalone: true,
})
export class KycPopupContentComponent extends LocalizedComponent {
  public closeDialog(): void {
    dialogAtom.close('kyc');
  }

  public goToKYC(): void {
    navigateTo(KYC_URL);
  }
}
