/**
 * This utility function will decode the user's country code of visit, for example
 * ke for Kenya, eg for Egypt etc.
 *
 * And these are ISO supported country alpha code 2.
 */

import { DEFAULT_LANDING_COUNTRY } from '../constants/country-language-codes-mapping.constants';
import { TIMEZONES } from '../constants/location.constants';

let DECODED_COUNTRY: string;

export const decodeUserCountryCodeUtility = (): string => {
  if (DECODED_COUNTRY) {
    return DECODED_COUNTRY;
  }
  try {
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (detectedTimeZone === '' || !detectedTimeZone) {
      DECODED_COUNTRY = DEFAULT_LANDING_COUNTRY;
    } else {
      DECODED_COUNTRY = TIMEZONES[detectedTimeZone].c[0].toLowerCase();
    }
  } catch (err) {
    DECODED_COUNTRY = DEFAULT_LANDING_COUNTRY;
  }

  return DECODED_COUNTRY;
};
