import jwtDecode from 'jwt-decode';

export function decodeAccessToken(accessToken: string): any {
  const data = jwtDecode<any>(accessToken);

  const { user } = data;

  const { verificationState, actualVerificationState } = user;

  if (actualVerificationState || verificationState) {
    user.verificationState = actualVerificationState || verificationState;
  }

  return user;
}
