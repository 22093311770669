import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserLocationRepository } from '../../repositories/user-location.repository';

export class GetUserIPAddressUseCase implements UseCase<void, string> {
  constructor(private _userLocationRepository: UserLocationRepository) {}

  execute(): Observable<string> {
    return this._userLocationRepository.getUserIPAddress();
  }
}
