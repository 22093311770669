import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

export class SetUserAcquisitionChannelUseCase implements UseCase<string, void> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute(params: string): void | Observable<void> {
    return this._analyticsRepository.setUserAcquisitionChannel(params);
  }
}
