import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IncentiveProgram,
  OperationRateEntity,
} from 'src/app/data/repositories/incentive-program/entities/incentive-program-entity';
import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class IncentiveProgramApi {
  constructor(private http: HttpClient) {}

  getUserIncentiveProgram(): Observable<IncentiveProgram> {
    return this.http
      .get<{ data: IncentiveProgram; msg: string }>(API_URLS.GET_MERCHANT_INCENTIVE_PROGRAM)
      .pipe(map((response: { data: IncentiveProgram; msg: string }) => ({ ...response.data })));
  }

  getOperationRate(): Observable<OperationRateEntity> {
    return this.http.get<OperationRateEntity>(API_URLS.GET_OPERATION_RATES);
  }
}
