import { Observable, throwError } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserRepository } from '../../repositories/user.repository';

export class UpdateProfilePictureUseCase
  implements UseCase<{ picture: any; pictureName?: string }, string>
{
  constructor(private _userRepository: UserRepository) {}

  execute(params: any): Observable<string> {
    if (!params.picture) {
      return throwError(new Error('Please provide a picture'));
    }
    return this._userRepository.updateProfilePicture(params.picture, params.pictureName);
  }
}
