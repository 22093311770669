import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CPARequestModel, CPAResponseModel } from 'src/app/core/domain/pricing-calculator.model';
import { PricingCalculatorRepository } from 'src/app/core/repositories/pricing-calculator-repository';
import { PricingCalculatorApisService } from './pricing-calculator-api.services';

@Injectable({
  providedIn: 'root',
})
export class PricingCalculatorRepositoryImplementation implements PricingCalculatorRepository {
  constructor(private _pricingCalculatorApisService: PricingCalculatorApisService) {}

  cpaCalculator(cpaRequestModel: CPARequestModel): Observable<CPAResponseModel> {
    return this._pricingCalculatorApisService.cpaCalculator(cpaRequestModel);
  }
}
