import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  CalculateOrderCostRequestModel,
  CalculateOrderCostResponseModel,
} from '../../domain/order-model';
import { OrderRepository } from '../../repositories/order.repository';

export class CalculateOrderCostUseCase
  implements UseCase<CalculateOrderCostRequestModel, CalculateOrderCostResponseModel>
{
  constructor(private _orderRepository: OrderRepository) {}

  execute(orderData: CalculateOrderCostRequestModel): Observable<CalculateOrderCostResponseModel> {
    return this._orderRepository.calculateOrderPrices(orderData);
  }
}
