/**
 * Here, we will be mapping a detected language with the direction
 *
 * For example, we know that Arabic is rtl, English is ltr and so
 * forth
 */
const DIRECTIONALITY_MAPPING: any = {
  ar: 'rtl',
  en: 'ltr',
};

/**
 * Return the directionality of the current user agend
 */
export const getUserAgentDirectionalityUtility = (): 'ltr' | 'rtl' => {
  return DIRECTIONALITY_MAPPING[document.documentElement.lang];
};
