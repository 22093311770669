import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { APIKeyGenerateModel } from '../../domain/api-keys-integration.model';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class GenerateAPIKeyUseCase implements UseCase<string, Observable<APIKeyGenerateModel>> {
  constructor(private _merchantStoreRepository: MerchantStoreRepository) {}

  execute(expiresAt: string): Observable<APIKeyGenerateModel> {
    return this._merchantStoreRepository.generateAPIKey(expiresAt);
  }
}
