import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { createAtom } from '@mongez/atom';
import events, { EventSubscription } from '@mongez/events';
import { GenericObject, ltrim } from '@mongez/reinforcements';
import { country } from '../../../features/country/data';
import { getCountryCodeFromUrl } from '../../country/utils';

type RouterAtom = {
  route: string;
  queryParams: GenericObject;
  router: Router;
  location: Location;
  activatedRoute: ActivatedRoute;
};

export const routerAtom = createAtom<RouterAtom>({
  key: 'router',
  default: {
    route: '',
    queryParams: {},
  },
});

/**
 * Navigate to the given route
 */
export function navigateTo(route: string, queryParams?: GenericObject): void {
  routerAtom.merge({
    route,
    queryParams: queryParams || {},
  });
}

/**
 * Get the current route
 */
export function currentRoute(): string {
  return routerAtom.get('router')?.url || '/';
}

/**
 * Watch current route after changed
 */
export function onRouteChanged(callback: (route: string) => void): EventSubscription {
  return events.subscribe('router.changed', callback);
}

/**
 * Watch current route on start changing
 */
export function onRouteChanging(callback: (route: string) => void): EventSubscription {
  return events.subscribe('router.changing', callback);
}

/**
 * Make a hard navigate
 * This utility should be used with routes that going to be replaced with React routes (New project)
 */
export function hardNavigate(path: string): void {
  const countryCode = country.shortCode.toLocaleLowerCase() || getCountryCodeFromUrl();
  const url = new URL(window.location.href);
  url.pathname = `/${countryCode}/${ltrim(path, '/')}`;
  window.location.href = url.toString();
}
