<form
  [formGroup]="productSearchForm"
  (ngSubmit)="onSearchProducts()"
  class="product-search-form"
  [ngClass]="darkModeClass$ | async"
>
  <input
    type="text"
    class="product-search-form__input body2--medium"
    placeholder="{{ 'HEADER.SEARCH_BAR.PLACEHOLDER' | translate }}"
    formControlName="searchKey"
  />
  <button
    class="product-search-form__submit-button"
    type="submit"
    [disabled]="productSearchForm.invalid"
  >
    <i class="product-search-form__submit-icon icon icon-magnifying-glass"></i>
  </button>
</form>
