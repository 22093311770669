import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import {
  ACHIEVE_EARN_MENU_TABS,
  ACHIEVE_EARN_TAB,
} from 'src/app/presentation/shared/constants/header-tabs';
import { HeaderTab } from 'src/app/presentation/shared/interfaces/header.interface';
import { AchieveEarnMenuPresenter } from './presentational/achieve-earn-menu.presenter';
import { AchieveEarnMenuSideEffect } from './presentational/achieve-earn-menu.side-effects';
import { AchieveEarnMenuViewEvent } from './presentational/achieve-earn-menu.view-events';
import { AchieveEarnMenuViewState } from './presentational/achieve-earn-menu.view-state';

@Component({
  selector: 'app-achieve-earn-menu',
  templateUrl: './achieve-earn-menu.component.html',
  styleUrls: ['./achieve-earn-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatButtonModule,
    TranslateModule,
    LottieComponent,
    NgClass,
    NgIf,
    NgFor,
    RouterLink,
    AsyncPipe,
  ],
  providers: [AchieveEarnMenuPresenter],
})
export class AchieveEarnMenuComponent extends BaseComponent<
  AchieveEarnMenuPresenter,
  AchieveEarnMenuViewState,
  AchieveEarnMenuViewEvent,
  AchieveEarnMenuSideEffect
> {
  ACHIEVE_EARN_TAB = ACHIEVE_EARN_TAB;

  tabs = ACHIEVE_EARN_MENU_TABS;

  options: AnimationOptions = {
    path: '/assets/animations/coin.json',
  };

  public presenter: AchieveEarnMenuPresenter = inject(AchieveEarnMenuPresenter);

  constructor(
    private _router: Router,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  checkTabFeatureFlag(flagName: string): Observable<boolean> {
    return this._getFeatureFlagUseCase.execute(flagName);
  }

  checkTabUserFeature(userFeature: string): boolean {
    return this._checkUserFeatureExistsUseCase.execute(userFeature);
  }

  onMenuItemClick(tab: HeaderTab): void {
    this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName! });
    this._router.navigate(tab.routerLink!);
  }
}
