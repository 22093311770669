<div class="main-header-wrapper" [ngClass]="darkModeClass$ | async">
  <app-sticky-header
    class="sticky-header"
    [ngClass]="{
      'sticky-header--shifted': isMobileBannerVisible,
      'sticky-header--drop-shadow': isViewScrolled
    }"
  ></app-sticky-header>
  <div class="navigation-lower-section" [ngClass]="{ scrolled: isViewScrolled }">
    <section
      class="navigation-lower-section__right-section"
      [ngClass]="{ 'right-gap': isDiscoverabilityFunnelEnabled }"
    >
      <app-mega-menu-action-button
        *ngIf="!isDiscoverabilityFunnelEnabled"
      ></app-mega-menu-action-button>
      <a
        class="navigation-lower-section__link"
        *ngFor="let tab of headerLowerPartNavigationElements"
        [routerLink]="tab.routerLink"
        (click)="onNavigateToTab(tab)"
        [attr.data-test-id]="tab.dataTestId"
        [queryParams]="tab.queryParams"
        [routerLinkActive]="
          tab.showActiveClass === undefined || tab.showActiveClass
            ? 'navigation-lower-section__link__active'
            : ''
        "
        [routerLinkActiveOptions]="{ exact: true }"
        queryParamsHandling="{{ tab.queryParamsHandling }}"
      >
        <i class="navigation-lower-section__icon icon {{ tab.iconPath }}"></i>
        <img src="{{ tab.iconPath }}" *ngIf="tab.tabName === 'PRE_ORDER_PAGE.TAB_NAME'" />
        <p class="navigation-lower-section__text caption1--medium">{{ tab.tabName | translate }}</p>
        <p class="navigation-lower-section__announcement caption1--medium">
          {{ tab?.announcement | translate }}
        </p>
      </a>
      <app-achieve-earn-menu
        *ngIf="isTieringSystemEnabled || isReferralProgramEnabled"
      ></app-achieve-earn-menu>
    </section>
    <app-countries-dropdown></app-countries-dropdown>
  </div>
  <app-products-search-bar
    class="mobile-search-bar"
    [ngClass]="{
      scrolled: isViewScrolled,
      'mobile-search-bar--shifted': isMobileBannerVisible
    }"
  ></app-products-search-bar>
</div>
