import { Mapper } from 'src/app/core/base/mapper';
import { WalletModel } from 'src/app/core/domain/wallet/wallet.model';
import { WalletEntity } from '../entities/wallet.entity';

export class WalletMapper implements Mapper<WalletEntity, WalletModel> {
  mapFrom(param: WalletEntity): WalletModel {
    return {
      currency: param.currency,
      eligibleAmount: Number(param.eligibleAmount.toFixed(2)),
      expectedAmount: param.expectedAmount,
      ordersCountForExpectedAmount: param.ordersCountForExpectedAmount,
      amountsOwedToTaager: param.amountsOwedToTaager,
    };
  }

  mapTo(param: WalletModel): WalletEntity {
    return {
      currency: param.currency,
      eligibleAmount: param.eligibleAmount,
      expectedAmount: param.expectedAmount,
      ordersCountForExpectedAmount: param.ordersCountForExpectedAmount,
      amountsOwedToTaager: param.amountsOwedToTaager,
    };
  }
}
