import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OTPDataModel, PhoneDataModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class RequestOTPUseCase implements UseCase<PhoneDataModel, OTPDataModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(phoneData: PhoneDataModel): Observable<OTPDataModel> {
    return this._authRepository.requestOTP(phoneData);
  }
}
