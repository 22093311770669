import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthModel, InitialSignupUserModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class RegisterUseCase implements UseCase<InitialSignupUserModel, AuthModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(userData: InitialSignupUserModel): Observable<AuthModel> {
    return this._authRepository.register(userData);
  }
}
