import { Direction } from '@angular/cdk/bidi';
import { Injectable } from '@angular/core';
import { updateLocaleCode } from '@features/drivers/data';
import cache from '@mongez/cache';
import { getCurrentLocaleCode } from '@mongez/localization';
import { TranslateService } from '@ngx-translate/core';
import { changePageDirectionByLocaleCode } from '@presentation/shared/utils';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { LOCALE_CODE_CACHE_KEY } from '../../../../v3/features/drivers/data/constants';
import {
  ARABIC_LANGUAGE,
  ENGLISH_LANGUAGE,
} from '../constants/country-language-codes-mapping.constants';

@Injectable({
  providedIn: 'root',
})
export class SiteTranslateService {
  languageSubject: BehaviorSubject<string>;

  constructor(private _translateService: TranslateService) {
    this.languageSubject = new BehaviorSubject(_translateService.currentLang);
    this._translateService.onLangChange.subscribe({
      next: (language: any) => this.languageSubject.next(language.lang),
    });
  }

  public defaultLanguageValue: string;

  public currentDir = document.documentElement.dir as Direction;

  public languageOptions = [
    { text: 'عربي', value: ARABIC_LANGUAGE },
    { text: 'English', value: ENGLISH_LANGUAGE },
  ];

  public checkInitialLanguage(override?: any): void {
    if (override) {
      this.defaultLanguageValue = override;
    } else {
      this.defaultLanguageValue = getCurrentLocaleCode();
    }

    this.updatePageProperties(this.defaultLanguageValue);
  }

  public setUserLanguage(value: string): void {
    cache.set(LOCALE_CODE_CACHE_KEY, value);
    this.updatePageProperties(value);
    updateLocaleCode(value);
  }

  public updatePageProperties(value: any): void {
    this._setDocumentLanguageAndDirection(value);
    this._translateService.setDefaultLang(value);
    this._translateService.use(value);
  }

  private _setDocumentLanguageAndDirection(value: string): void {
    this.currentDir = changePageDirectionByLocaleCode(value);
  }

  public getCurrentLanguage(): string {
    return getCurrentLocaleCode();
  }

  public onLanguageChange(): Observable<string> {
    return new Observable((subscriber) => {
      this._translateService.onLangChange.subscribe({
        next: (language: any) => subscriber.next(language.lang),
      });
    });
  }

  public onTextDirectionChange(): Observable<Direction> {
    return this.languageSubject
      .asObservable()
      .pipe(map(() => document.documentElement.dir as Direction));
  }
}
