import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import {
  ChildOrdersFilterModel,
  ChildOrdersResultModel,
} from '../../domain/order/child-orders-filter.model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetChildOrdersUseCase
  implements UseCase<ChildOrdersFilterModel, ChildOrdersResultModel>
{
  constructor(private _orderRepository: OrderRepository) {}

  execute(filter: ChildOrdersFilterModel): Observable<ChildOrdersResultModel> {
    return this._orderRepository.getChildOrders(filter);
  }
}
