import { PreOrderProductsListData } from '../../domain';
import { PreOrderProductsListModel } from '../models/pre-order-products-list-data-model';

export function mapPreOrderProductsListData(
  response: PreOrderProductsListModel,
): PreOrderProductsListData {
  return {
    count: response.count,
    products: response.results,
  };
}
