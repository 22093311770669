import { Mapper } from 'src/app/core/base/mapper';
import { CartModel } from 'src/app/core/domain/cart.model';
import { CartEntity } from '../entities/cart-entity';

export class UserCartMapper extends Mapper<CartEntity, CartModel> {
  mapFrom(param: CartEntity): CartModel {
    const totalQuantity = param.products
      .filter((product) => product.isAvailableToSeller)
      .reduce((sum, product) => sum + product.qty, 0);
    return {
      totalPrice: param.totalPrice,
      totalProfit: param.totalProfit,
      totalVat: param.totalVat,
      totalNetProfit: param.totalNetProfit,
      totalQuantity,
      products: param.products.map((product) => ({
        appliedDiscount: product.appliedDiscount,
        attributes: product.attributes,
        name: product.name,
        id: product.id,
        picture: product.picture,
        prodId: product.prodId,
        qty: product.qty,
        availability: product.availability,
        isAvailableToSeller: product.isAvailableToSeller,
        validity: product.validity,
        desiredPrice: product.desiredPrice,
        sale: product.sale,
        discountedAsSecondInPlacement: product.discountedAsSecondInPlacement,
        pricingRange: product.pricingRange,
        originalPricePerItem: product.originalPricePerItem,
        originalProfitPerItem: product.originalProfitPerItem,
        finalPricePerItem: product.finalPricePerItem,
        finalProfitPerItem: product.finalProfitPerItem,
        netProfitPerItem: product.netProfitPerItem,
        vatPerItem: product.vatPerItem,
        discountForAdditionalQuantity: product.discountForAdditionalQuantity,
        availabilityInfo: product.availabilityInfo,
        productMaxPrice: product.productMaxPrice,
      })),
    };
  }

  mapTo(param: CartModel): CartEntity {
    return {
      totalPrice: param.totalPrice,
      totalProfit: param.totalProfit,
      totalVat: param.totalVat,
      totalNetProfit: param.totalNetProfit,
      products: param.products.map((product) => ({
        appliedDiscount: product.appliedDiscount,
        attributes: product.attributes,
        name: product.name,
        id: product.id,
        picture: product.picture,
        prodId: product.prodId,
        qty: product.qty,
        availability: product.availability,
        isAvailableToSeller: product.isAvailableToSeller,
        validity: product.validity,
        desiredPrice: product.desiredPrice,
        sale: product.sale,
        discountedAsSecondInPlacement: product.discountedAsSecondInPlacement,
        pricingRange: product.pricingRange,
        originalPricePerItem: product.originalPricePerItem,
        originalProfitPerItem: product.originalProfitPerItem,
        finalPricePerItem: product.finalPricePerItem,
        finalProfitPerItem: product.finalProfitPerItem,
        netProfitPerItem: product.netProfitPerItem,
        vatPerItem: product.vatPerItem,
        discountForAdditionalQuantity: product.discountForAdditionalQuantity,
        availabilityInfo: product.availabilityInfo,
        productMaxPrice: product.productMaxPrice,
      })),
    };
  }
}
