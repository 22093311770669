import { Mapper } from 'src/app/core/base/mapper';
import { QuestionnaireAnswerModel } from 'src/app/core/domain/questionnaire.model';
import { QuestionnaireAnswerEntity } from '../entities/questionnaire-entity';

export class QuestionnaireAnswerRepositoryMapper extends Mapper<
  QuestionnaireAnswerEntity,
  QuestionnaireAnswerModel
> {
  mapFrom(param: QuestionnaireAnswerEntity): QuestionnaireAnswerModel {
    return {
      id: param.id,
      textArabic: param.textAr,
      textEnglish: param.textEn,
      iconUrl: param.iconUrl,
    };
  }

  mapTo(param: QuestionnaireAnswerModel): QuestionnaireAnswerEntity {
    return {
      id: param.id,
      textAr: param.textArabic,
      textEn: param.textEnglish,
      iconUrl: param.iconUrl,
    };
  }
}
