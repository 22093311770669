import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class ShouldDisplayNewHomePageUseCase implements UseCase<void, boolean> {
  constructor(private _productsRepository: ProductsRepository) {}

  execute(): boolean {
    return this._productsRepository.shouldDisplayNewHomePageUseCase();
  }
}
