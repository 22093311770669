<div class="wrapper">
  <div class="wrapper__section-one">
    <img src="{{ assetsPath + 'onboarding-done.svg' }}" alt="Completed onboarding journey" />
  </div>
  <div class="wrapper__section-two">
    <h2 class="heading2--bold wrapper__section-two__text-1">
      {{ 'ONBOARDING.ONBOARDING_LAST_STEP_DIALOG.SECTION_TWO.TEXT_1' | translate }}
    </h2>
    <p class="body2--regular wrapper__section-two__text-2">
      {{ 'ONBOARDING.ONBOARDING_LAST_STEP_DIALOG.SECTION_TWO.TEXT_2' | translate }}
    </p>
    <button class="body1--medium wrapper__section-two__button" (click)="startUsingTaager()">
      {{ 'ONBOARDING.ONBOARDING_LAST_STEP_DIALOG.SECTION_TWO.BUTTON' | translate }}
    </button>
  </div>
</div>
