import { AsyncPipe, NgClass } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatRipple, MatRippleModule } from '@angular/material/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';
import { MegaMenuComponent } from '../../../../../../mega-menu/mega-menu.component';

@Component({
  selector: 'app-mega-menu-action-button',
  templateUrl: './mega-menu-action-button.component.html',
  styleUrls: ['./mega-menu-action-button.component.scss'],
  standalone: true,
  imports: [MatRippleModule, MatMenuModule, MegaMenuComponent, TranslateModule, NgClass, AsyncPipe],
})
export class MegaMenuActionButtonComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @ViewChild(MatRipple) ripple: MatRipple;

  disabled = false;

  darkModeClass$: Observable<string>;

  constructor(private _themeService: ThemeService) {
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  onMenuButtonClick() {
    this.ripple.launch({ centered: true, persistent: true });
    this.trigger.openMenu();
  }

  onMenuClosed() {
    this.ripple.fadeOutAll();
    this.disabled = false;
  }

  onMenuOpen() {
    this.disabled = true;
  }
}
