import { SecureIntlBankTransferModel } from '../../../../core/domain/payment/intl-bank-transfer.model';
import { SecurePayoneerTransferModel } from '../../../../core/domain/payment/payoneer-transfer.model';
import { IntlBankTransferEntity } from '../entities/intl-bank-transfer.entity';
import { PayoneerTransferEntity } from '../entities/payoneer-transfer.entity';

export class Mapper {
  static secureIntlBankTransferModelToData(
    secureIntlBankTransferModel: SecureIntlBankTransferModel,
  ): IntlBankTransferEntity {
    return {
      amount: secureIntlBankTransferModel.amount,
      beneficiaryBank: {
        name: secureIntlBankTransferModel.beneficiaryBank.name,
        address: secureIntlBankTransferModel.beneficiaryBank.address,
        swiftNumber: secureIntlBankTransferModel.beneficiaryBank.swiftNumber,
      },
      beneficiary: {
        name: secureIntlBankTransferModel.beneficiary.name,
        address: secureIntlBankTransferModel.beneficiary.address,
        contactNumber: secureIntlBankTransferModel.beneficiary.contactNumber,
      },
      beneficiaryAccount: {
        iban: secureIntlBankTransferModel.beneficiaryAccount.iban,
        currency: secureIntlBankTransferModel.beneficiaryAccount.currency,
      },
      currency: secureIntlBankTransferModel.currency.iso4217Code,
      checkCode: secureIntlBankTransferModel.otpCheckModel.checkCode,
      otpPasscode: secureIntlBankTransferModel.otpCheckModel.otpCode,
      bankType: secureIntlBankTransferModel.bankType,
      otpVerificationMode: secureIntlBankTransferModel.otpVerificationMode,
    };
  }

  static securePayoneerTransferModelToData(
    securePayoneerTransferModel: SecurePayoneerTransferModel,
  ): PayoneerTransferEntity {
    return {
      amount: securePayoneerTransferModel.amount,
      beneficiary: {
        email: securePayoneerTransferModel.beneficiary.email,
      },
      currency: securePayoneerTransferModel.currency.iso4217Code,
      checkCode: securePayoneerTransferModel.otpCheckModel.checkCode,
      otpPasscode: securePayoneerTransferModel.otpCheckModel.otpCode,
      otpVerificationMode: securePayoneerTransferModel.otpVerificationMode,
    };
  }
}
