import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';

export const COMMERCIAL_CATEGORY_TREE_ROOT: BaseCategoryModel = {
  id: 'commercial-categories-root',
  name: {
    arabicName: 'جميع المنتجات',
    englishName: 'All Categories',
  },
  featured: false,
  icon: '',
};
