import { Mapper } from 'src/app/core/base/mapper';
import { DukanShopModel } from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { DukanShopEntity } from '../entities/dukan-shop.entity';

export class DukanShopDataMapper extends Mapper<DukanShopEntity, DukanShopModel> {
  mapFrom(param: DukanShopEntity): DukanShopModel {
    return {
      slug: param.slug!,
      url: param.url,
      state: param.state,
      name: param.name!,
      contactInfo: param.contactInfo,
      integrations: {
        tiktokPixel: {
          ids: param?.integrations?.tiktokPixel?.ids || [],
        },
        googleTagCode: {
          ids: param?.integrations?.googleTagCode?.ids || [],
        },
      },
      hasEmbeddedCheckoutEnabled: param.hasEmbeddedCheckoutEnabled,
    };
  }

  mapTo(param: DukanShopModel): DukanShopEntity {
    return {
      slug: param.slug,
      url: param.url,
      state: param.state,
      name: param.name,
      contactInfo: param.contactInfo,
      integrations: param.integrations,
      hasEmbeddedCheckoutEnabled: param.hasEmbeddedCheckoutEnabled,
    };
  }
}
