import { Injectable } from '@angular/core';
import { AnalyticsRepository } from 'src/app/core/repositories/analytics.repository';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { MixpanelService } from 'src/app/presentation/shared/services/mixpanel.service';
import { GetUserAcquisitionUtility } from 'src/app/presentation/shared/utilities/get-user-acquisition.utility';

declare let gtag: (command: string, eventName: string, payload: {}) => void;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsRepositoryImplementation implements AnalyticsRepository {
  constructor(
    private _mixpanelService: MixpanelService,
    private _getUserAcquisitionUtility: GetUserAcquisitionUtility,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
  ) {}

  logGtagEvent(eventName: string, payload: {}): void {
    gtag('event', eventName, payload);
  }

  initializeMixpanel(): void {
    this._mixpanelService.init();
  }

  identifyMixpanelInstance(): void {
    this._mixpanelService.identify();
  }

  logMixpanelEvent(eventName: string, payload: {}): void {
    const discoverabilityFunnelExperienceEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    payload = {
      ...payload,
      DISCOVERABILITY_FUNNEL_EXPERIENCE_ENABLED: discoverabilityFunnelExperienceEnabled,
    };

    this._mixpanelService.track(eventName, payload);
  }

  resetMixpanelInstance(): void {
    this._mixpanelService.reset();
  }

  setUserAcquisitionChannel(referrer: string): void {
    this._getUserAcquisitionUtility.getUserAcquisitionUtility(referrer);
  }

  setUserReferral(referral: string): void {
    this.logMixpanelEvent('sign_up_with_referral_success', { referral });
  }
}
