import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartRepository } from '../../repositories/cart.repository';

export class EmptyCartUseCase implements UseCase<string[], void> {
  constructor(private _cartRepository: CartRepository) {}

  execute(productIds: string[]): Observable<void> {
    return this._cartRepository.emptyCart(productIds);
  }
}
