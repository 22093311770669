import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  DukanProductModel,
  DukanProductStatus,
  UpdateDukanProductModel,
} from 'src/app/core/domain/dukan-shop/dukan-product.model';
import {
  AdsIntegration,
  DukanShopModel,
  StateStatuses,
} from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { DukanShopRepository } from 'src/app/core/repositories/dukan-shop.repository';
import { DukanShopApisService } from './dukan-shop-apis.services';
import { DukanProductMapper } from './mappers/dukan-product.mapper';
import { DukanShopDataMapper } from './mappers/dukan-shop.mapper';
import { DukanUpdateProductMapper } from './mappers/dukan-update-product.mapper';

@Injectable({
  providedIn: 'root',
})
export class DukanShopRepositoryImplementation implements DukanShopRepository {
  private _dukanShopDataMapper = new DukanShopDataMapper();

  private _dukanProductMapper = new DukanProductMapper();

  private _dukanUpdateProductMapper = new DukanUpdateProductMapper();

  constructor(private _dukanShopApisService: DukanShopApisService) {}

  create(name: string, slug: string): Observable<DukanShopModel> {
    return this._dukanShopApisService
      .createShop(name, slug)
      .pipe(map((shop) => this._dukanShopDataMapper.mapFrom({ ...shop, slug })));
  }

  get(): Observable<DukanShopModel> {
    return this._dukanShopApisService.getShop().pipe(map(this._dukanShopDataMapper.mapFrom));
  }

  deactivate(): Observable<DukanShopModel> {
    return this._dukanShopApisService
      .editShop({ state: StateStatuses.INACTIVE })
      .pipe(map(this._dukanShopDataMapper.mapFrom));
  }

  reactivate(): Observable<DukanShopModel> {
    return this._dukanShopApisService
      .editShop({ state: StateStatuses.ACTIVE })
      .pipe(map(this._dukanShopDataMapper.mapFrom));
  }

  getProducts(): Observable<DukanProductModel[]> {
    return this._dukanShopApisService
      .getProducts()
      .pipe(map((products) => products.map(this._dukanProductMapper.mapFrom)));
  }

  getProductDetails(sku: string): Observable<DukanProductModel> {
    return this.getProducts().pipe(
      map((products) => products.filter((product) => product.sku === sku)[0]),
    );
  }

  updateInfo(dukanData: DukanShopModel): Observable<DukanShopModel> {
    return this._dukanShopApisService
      .editShop({
        email: dukanData.contactInfo.email,
        phoneNumber: dukanData.contactInfo.phoneNumber || '',
        hasEmbeddedCheckoutEnabled: dukanData.hasEmbeddedCheckoutEnabled,
      })
      .pipe(map(this._dukanShopDataMapper.mapFrom));
  }

  updateAdsIntegration(ids: AdsIntegration): Observable<DukanShopModel> {
    return this._dukanShopApisService
      .editShop({ integrations: ids })
      .pipe(map(this._dukanShopDataMapper.mapFrom));
  }

  setProducts(products: { sku: string; status: DukanProductStatus }[]): Observable<void> {
    return this._dukanShopApisService.setProducts(products.map(this._dukanProductMapper.mapTo));
  }

  pushProduct(sku: string): Observable<void> {
    return this._dukanShopApisService.pushProduct(sku);
  }

  deleteProduct(sku: string): Observable<void> {
    return this._dukanShopApisService.deleteProduct(sku);
  }

  updateProductDetails(product: UpdateDukanProductModel, sku: string): Observable<void> {
    return this._dukanShopApisService.updateProductDetails(
      this._dukanUpdateProductMapper.mapTo(product),
      sku,
    );
  }

  deleteProductMedia(sku: string, url: string): Observable<void> {
    return this._dukanShopApisService.deleteProductMedia(sku, url);
  }

  addProductMedia(sku: string, media: File): Observable<{ imageUrl: string }> {
    return this._dukanShopApisService.addProductMedia(sku, media);
  }
}
