<info-header></info-header>
<app-main-header></app-main-header>
<div class="message-container" *ngIf="shouldShowMessage">
  <div class="message-text" *ngIf="isMessageShown">
    <button class="close-btn" (click)="toggleMessage()">
      <span>&times;</span>
    </button>
    <p class="content-black-color heading2--bold">{{ 'MESSAGE.SORRY_MESSAGE' | translate }}</p>
    <p class="content-black-color body1--medium">{{ 'MESSAGE.CONTACT_MESSAGE' | translate }}</p>
    <a [href]="cs_url" target="_blank" class="btn-send body1--medium">
      {{ 'MESSAGE.SEND' | translate }}
    </a>
  </div>
  <button class="message-button" (click)="toggleMessage()">
    <img
      src="{{ isMessageShown ? assetsPath + 'shown-message.svg' : assetsPath + 'message.svg' }}"
    />
  </button>
</div>
