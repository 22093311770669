import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { MerchantStore } from '../../domain/merchant-store.model';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class AddProductToStoreUseCase
  implements UseCase<{ merchantStore: MerchantStore; productId: string }, Observable<null>>
{
  constructor(private _repository: MerchantStoreRepository) {}

  execute(params: { merchantStore: MerchantStore; productId: string }): Observable<null> {
    return this._repository.addProductToStore(params.merchantStore, params.productId);
  }
}
