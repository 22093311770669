import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { SecureIntlBankTransferModel } from '../../domain/payment/intl-bank-transfer.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class CreateSecureIntlBankTransferUseCase
  implements UseCase<SecureIntlBankTransferModel, void>
{
  constructor(private walletRepository: WalletRepository) {}

  execute(secureIntlBankTransferModel: SecureIntlBankTransferModel): Observable<void> {
    return this.walletRepository.createSecureIntlBankWithdrawal(secureIntlBankTransferModel);
  }
}
