import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { AUTH_URL, REGISTER_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { loggedInFullySignedUpMixinResolver } from './shared/mixins/logged-in-fully-signed-up.mixin.shared.resolver';

/**
 * For a user to access the complaint suggestion, they must:
 *
 * 1. Be logged in.
 * 2. Be fully signed up.
 */
export const complaintSuggestionResolver = (): Observable<RouteAccessResolutionTypes> => {
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  const userResolutionService = inject(UserResolutionService);
  return loggedInFullySignedUpMixinResolver({
    router,
    localStorageService,
    userRegistrationStepUtility,
    getRegisterTokenUseCase,
    userResolutionService,
    nextPath: {
      ifNotLoggedIn: AUTH_URL,
      ifNotFullySignedUp: REGISTER_URL,
    },
  });
};
