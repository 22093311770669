import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IncentiveProgramModel,
  OperationRateModel,
} from 'src/app/core/domain/incentive-program.model';
import { IncentiveProgramRepository } from 'src/app/core/repositories/incentive-program.repository';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { IncentiveProgramApi } from './incentive-program-api';

@Injectable({
  providedIn: 'root',
})
export class IncentiveProgramRepositoryImpl extends IncentiveProgramRepository {
  constructor(
    private incentiveProgramApi: IncentiveProgramApi,
    private getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  getIncentiveProgramDetails(): Observable<IncentiveProgramModel> {
    const program$ = this.incentiveProgramApi.getUserIncentiveProgram();
    const programName$ = this.getFeatureAttributeUsecase.execute(
      FEATURE_FLAGS.INCENTIVE_PROGRAM_NAME,
    );
    const programStartDate$ = this.getFeatureAttributeUsecase.execute(
      FEATURE_FLAGS.INCENTIVE_PROGRAM_START_DATE,
    );
    const programEndDate$ = this.getFeatureAttributeUsecase.execute(
      FEATURE_FLAGS.INCENTIVE_PROGRAM_END_DATE,
    );

    return combineLatest([program$, programName$, programStartDate$, programEndDate$]).pipe(
      map(([program, name, startDate, endDate]) => ({
        ...program,
        name,
        startDate,
        endDate,
      })),
    );
  }

  getOperationRateDetails(): Observable<OperationRateModel> {
    return this.incentiveProgramApi.getOperationRate().pipe(map((response) => response.data));
  }
}
