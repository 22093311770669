import { Observable } from 'rxjs';
import {
  ArticleModel,
  SubTopicModel,
  TopicContentModel,
  TopicModel,
  VideoModel,
} from '../domain/learning-center.model';

export abstract class LearningCenterRepository {
  abstract getTopics(): Observable<TopicModel[]>;

  abstract getSubTopics(topicId: string): Observable<SubTopicModel[]>;

  abstract getContentByTopicId(subTopicId: string): Observable<TopicContentModel>;

  abstract getItemByContentId(contentId: string): Observable<VideoModel | ArticleModel>;

  abstract getTopicFromSubTopicId(subTopicId: string): Observable<TopicModel>;
}
