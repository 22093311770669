import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { MerchantLoyaltyProgressModel } from '../../domain/loyalty-program/loyalty-program.model';
import { LoyaltyProgramRepository } from '../../repositories/loyalty-program.repository';

export class GetLoyaltyProgressUseCase implements UseCase<void, MerchantLoyaltyProgressModel> {
  constructor(private _loyaltyProgramRepository: LoyaltyProgramRepository) {}

  execute(): Observable<MerchantLoyaltyProgressModel> {
    return this._loyaltyProgramRepository.getLoyaltyProgress();
  }
}
