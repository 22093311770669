export const COUNTRY_LANGUAGE_CODES_MAPPING: any = {
  eg: 'ar-eg',
  sa: 'ar-sa',
  ae: 'ar-ae',
  kw: 'ar-kw',
  om: 'ar-om',
  iq: 'ar-iq',
};

/**
 * The default landing country will be iso code 2 for KSA
 */
export const DEFAULT_LANDING_COUNTRY = 'sa';

export const ENGLISH_LANGUAGE = 'en';
export const ARABIC_LANGUAGE = 'ar';
