/* eslint-disable guard-for-in */
import { BaseRepository } from '@features/shared';
import { objectToFormData } from '@features/shared/data';
import { user } from '@features/user/data';
import type { IdVerification, KYCRepositoryContract, VerificationIdData } from '../../domain';
import { kycAPI } from '../constants';
import { mapKYCVerificationIdModelData } from '../mappers';
import type { VerificationIdDataModel } from '../models';

export class KYCRepository extends BaseRepository implements KYCRepositoryContract {
  // protected mockupOptions = {
  //   data: kycVerificationIdMockup,
  //   delay: 500,
  // };

  public async getVerificationIdStatus(): Promise<IdVerification> {
    const response = await this.adapter.get<VerificationIdDataModel>(kycAPI.verificationIdStatus);

    // Make sure to update the current user verification state
    // as it will be the single resource for validation account verification state
    if (response.data.currentVerificationStatus) {
      user.set('verificationState', response.data.currentVerificationStatus);
    }

    return mapKYCVerificationIdModelData(response.data);
  }

  public async submitIDVerification(payload: VerificationIdData): Promise<void> {
    await this.http.post<FormData, void>(kycAPI.submitVerificationId, objectToFormData(payload));
  }
}
