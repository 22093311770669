import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class AddDukanProductMediaUseCase
  implements UseCase<{ sku: string; media: File }, { imageUrl: string }>
{
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(data: { sku: string; media: any }): Observable<{ imageUrl: string }> {
    return this._dukanShopRepository.addProductMedia(data.sku, data.media);
  }
}
