<div class="shopping-navigation-wrapper" [ngClass]="darkModeClass$ | async">
  <div *ngFor="let tab of shoppingNavigationTabs" class="shopping-element">
    <a
      data-test-id="go-to-cart-page"
      class="shopping-element__link"
      [routerLink]="tab.routerLink"
      (click)="onNavigateToTab(tab)"
    >
      <i class="shopping-element__icon icon {{ tab.iconPath }}"></i>
    </a>
    <p class="shopping-element__count body1--bold">{{ tab.productsCount || 0 }}</p>
  </div>
</div>
