/**
 *
 * @param itemA
 * @param itemB
 * @param attribute
 * @returns number
 */
export const sortByAttribute = (itemA: any, itemB: any, attribute: string): number => {
  if (itemA[attribute] < itemB[attribute]) {
    return 1;
  }
  if (itemA[attribute] > itemB[attribute]) {
    return -1;
  }
  return 0;
};
