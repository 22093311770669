import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class UnlinkMerchantStoreUseCase implements UseCase<string, void> {
  constructor(private _merchantStoreRepository: MerchantStoreRepository) {}

  execute(storeId: string): Observable<void> {
    return this._merchantStoreRepository.unlinkStore(storeId);
  }
}
