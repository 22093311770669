export interface DukanShopModel {
  state: StateStatuses;
  url: string;
  slug: string;
  name: string;
  contactInfo: ContactInfoModel;
  integrations: AdsIntegration;
  hasEmbeddedCheckoutEnabled: boolean;
}

export enum StateStatuses {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export interface ContactInfoModel {
  email: string;
  phoneNumber: string;
}

export interface AdsIntegration {
  tiktokPixel: {
    ids: string[];
  };
  googleTagCode: {
    ids: string[];
  };
}
