import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UpdateDukanProductModel } from '../../domain/dukan-shop/dukan-product.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class UpdateDukanProductDetailsUseCase
  implements UseCase<{ product: UpdateDukanProductModel; sku: string }, void>
{
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(data: { product: UpdateDukanProductModel; sku: string }): Observable<void> {
    return this._dukanShopRepository.updateProductDetails(data.product, data.sku);
  }
}
