<div class="div__container">
  <div class="div__dialogHeader">
    <h4 class="h4__dialogTitle">{{ 'SUGGEST_PRODUCT.TITLE' | translate }}</h4>
    <button (click)="closeDialog()" class="btn__close" mat-dialog-close>
      <span>&times;</span>
    </button>
  </div>

  <form [formGroup]="requestProductForm" (ngSubmit)="onSubmit()">
    <div class="div__productCategory">
      <label for="category"> {{ 'SUGGEST_PRODUCT.CATEGORY' | translate }} </label>
      <select
        matInput
        required
        id="category"
        formControlName="category"
        [ngClass]="{ submitted: formSubmitted }"
      >
        <option *ngFor="let categoryName of categoryNames" [ngValue]="categoryName">
          {{ categoryName }}
        </option>
      </select>
    </div>

    <div class="div__productDetails">
      <label for="productDetails">
        {{ 'SUGGEST_PRODUCT.ENTER_NAME_AND_DETAILS_LABEL' | translate }}
      </label>
      <textarea
        matInput
        required
        id="productDetails"
        formControlName="productDetails"
        [ngClass]="{ submitted: formSubmitted }"
      ></textarea>
    </div>

    <div class="div__dialogActions">
      <button class="btn__submit" type="submit">{{ 'SUGGEST_PRODUCT.SUBMIT' | translate }}</button>
      <button mat-dialog-close class="btn__close" type="button" (click)="closeDialog()">
        {{ 'SUGGEST_PRODUCT.BACK' | translate }}
      </button>
    </div>
  </form>
</div>
