import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { createAtom } from '@mongez/atom';
import events, { EventSubscription } from '@mongez/events';
import { onRouteChanging } from '../router';

type AtomDialogOptions = {
  dialog: MatDialog;
};

// Please If you are going to add any new dialog, add the dialog id here for auto complete support
export type DialogId = 'kyc' | 'pre-order-external-product-request' | 'get-session-token-dialog';

export type DialogComponent = Parameters<MatDialog['open']>[0];
export type DialogOptions = Parameters<MatDialog['open']>[1] & {
  id: DialogId;
};

type OpenType = (
  component: DialogComponent,
  options: DialogOptions,
) => ReturnType<MatDialog['open']>;

type AtomActions = {
  open: OpenType;
  close: (popupId: DialogId) => void;
  getDialog: (popupId: DialogId) => MatDialogRef<any> | undefined;
  closeAll: () => void;
};

type DialogEvent = 'close';

export const dialogAtomEvents = {
  trigger(event: DialogEvent, data: any): void {
    events.trigger(`dialogs.${event}`, data);
  },
  on(event: DialogEvent, callback: (dialogId: DialogId) => void): EventSubscription {
    return events.subscribe(`dialogs.${event}`, callback);
  },
};

/**
 * Use this atom to open any dialog from any where in the project
 * It is always better to have a dialog id so we can close the dialog by the id easily from anywhere
 * that's where the `dialogAtom.close` needs to close certain dialog by its id
 *
 * @note Make sure the dialog is closed when component is being displayed because it is centered in the app component
 *
 * @example
 * ```ts
 * dialogAtom.open(MyComponent, {
 * // any dialog config
 * id: 'my-dialog',
 * })
 *
 * // to close any dialog:
 * dialogAtom.close('my-dialog');
 *
 * // to close all dialogs
 * dialogAtom.closeAll();
 *
 * // to get the dialog ref
 * dialogAtom.getDialog('my-dialog');
 * ```
 */
export const dialogAtom = createAtom<AtomDialogOptions, AtomActions>({
  key: 'dialog',
  default: {},
  actions: {
    getDialog(popupId): MatDialogRef<any> | undefined {
      const dialog = dialogAtom.get('dialog');

      return dialog.getDialogById(popupId);
    },
    closeAll() {
      const dialog = dialogAtom.get('dialog');

      dialog.closeAll();
    },
    open(component, config): any {
      const popup = dialogAtom.get('dialog').open(component, config);
      popup.afterClosed().subscribe(() => {
        dialogAtomEvents.trigger('close', popup.id);
      });
    },
    close(popupId: DialogId) {
      dialogAtom.getDialog(popupId)?.close();
    },
  },
});

onRouteChanging(() => {
  // close all dialogs when route is changing
  dialogAtom.closeAll();
});
