import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class DeleteDukanProductUseCase implements UseCase<string, void> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(sku: string): Observable<void> {
    return this._dukanShopRepository.deleteProduct(sku);
  }
}
