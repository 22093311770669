import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { PreOrderBenefitsModel } from 'src/app/core/domain/bulk-pre-order/pre-order-benefits.model';
import { PreOrderRequestsSummaryModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request-summary.model';
import { PreOrderRequestModel } from 'src/app/core/domain/bulk-pre-order/pre-order-request.model';
import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { COMMERCIAL_CATEGORY_TREE_ROOT } from 'src/app/presentation/shared/constants/commercial-categories';
import { PREORDER_V3 } from 'src/app/presentation/shared/constants/feature-flags';
import { CommercialCategoryTree } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { Tree } from 'src/app/presentation/shared/utilities/tree.utility';
import { BulkPreOrderRequestModel } from '../../../core/domain/bulk-pre-order/bulk-pre-order-request.model';
import { BulkPreOrderRepository } from '../../../core/repositories/bulk-pre-order.repository';
import { CommercialCategoryRepositoryMapper } from '../commercial-categories/mappers/commercial-category-repository.mapper';
import { BulkPreOrdersApisService } from './bulk-pre-orders-apis.service';
import { PreOrderRequestMapper } from './mappers/pre-order-request.mapper';
import { PreOrderRequestStatusMapper } from './mappers/pre-order-summary-request.mapper';

@Injectable({
  providedIn: 'root',
})
export class BulkPreOrderImplementationRepository extends BulkPreOrderRepository {
  private _preOrderRequestDataMapper = new PreOrderRequestMapper();

  private _preOrderRequestStatusMapper = new PreOrderRequestStatusMapper();

  public commercialCategoryMapper = new CommercialCategoryRepositoryMapper();

  private _preOrderV3Flag = false;

  private _cachedPreOrderSummary$: Observable<PreOrderRequestsSummaryModel>;

  constructor(
    private _bulkPreOrdersApisService: BulkPreOrdersApisService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
  ) {
    super();
    this._getFeatureFlagUseCase.execute(PREORDER_V3).subscribe((flag) => {
      this._preOrderV3Flag = flag;
    });
  }

  createPreOrderRequest(request: BulkPreOrderRequestModel): Observable<void> {
    return this._bulkPreOrdersApisService.createPreOrderRequest(request, this._preOrderV3Flag);
  }

  getPreOrderRequests(): Observable<PreOrderRequestModel[]> {
    return this._bulkPreOrdersApisService
      .getPreOrderRequests(this._preOrderV3Flag)
      .pipe(map((requests) => requests.map(this._preOrderRequestDataMapper.mapFrom)));
  }

  acceptPreOrderDownPayment(requestId: string): Observable<void> {
    return this._bulkPreOrdersApisService.postAcceptPreOrderDownPayment(requestId);
  }

  rejectPreOrderDownPayment(requestId: string): Observable<void> {
    return this._bulkPreOrdersApisService.putRejectPreOrderDownPayment(requestId);
  }

  getProOrderBenefitsData(): Observable<PreOrderBenefitsModel[]> {
    return this._bulkPreOrdersApisService.getProOrderBenefitsData();
  }

  getPreOrderRequestsSummary(): Observable<PreOrderRequestsSummaryModel> {
    if (!this._cachedPreOrderSummary$) {
      this._cachedPreOrderSummary$ = this._bulkPreOrdersApisService
        .getPreOrderRequestsSummary()
        .pipe(
          map((res) => {
            return {
              preorderStatusSummary: res.preorderStatusSummary.map((status) => {
                return this._preOrderRequestStatusMapper.mapFrom(status);
              }),
            };
          }),
          shareReplay(1),
        );
    }
    return this._cachedPreOrderSummary$;
  }

  getPreOrderCommercialCategories(countryCode: string): Observable<CommercialCategoryTree> {
    return this._bulkPreOrdersApisService.getPreOrderCategories(countryCode).pipe(
      map((categories) =>
        categories.map((category) => this.commercialCategoryMapper.mapFrom(category)),
      ),
      map((categoryModels) => {
        const categoryTree: CommercialCategoryTree = new Tree<BaseCategoryModel>(
          COMMERCIAL_CATEGORY_TREE_ROOT.id!,
          COMMERCIAL_CATEGORY_TREE_ROOT,
        );
        this.insertNodesRecursivelyInTree(categoryTree, categoryTree.root.key, categoryModels);
        return categoryTree;
      }),
      shareReplay(1),
    );
  }

  insertNodesRecursivelyInTree(
    tree: Tree<BaseCategoryModel>,
    parentId: string,
    categories: BaseCategoryModel[],
  ): void {
    const categoriesNodeList = categories.map((category) => ({
      key: category.id!,
      value: { name: category.name, featured: category.featured, icon: category.icon },
    }));
    tree.insertMany(parentId!, categoriesNodeList);
    categories.forEach((category) => {
      if (category.children?.length) {
        this.insertNodesRecursivelyInTree(tree, category.id!, category.children);
      }
    });
  }
}
