import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink } from '@angular/router';
import { user } from '@features/user/data';
import { trans } from '@mongez/localization';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isLTR } from '@presentation/shared/utils';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { RequestUnavailableProductDialogComponent } from 'src/app/presentation/products/no-search-results/request-unavailable-product/request-unavailable-product-dialog/request-unavailable-product-dialog.component';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { ARABIC_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { INFO_HEADER_TABS } from 'src/app/presentation/shared/constants/header-tabs';
import {
  CLICK_FROM_NAV_BAR,
  REQUEST_PRODUCT_CLICK,
} from 'src/app/presentation/shared/constants/mixpanel';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';

@Component({
  selector: 'info-header',
  templateUrl: './info-header.component.html',
  styleUrls: ['./info-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgStyle,
    NgFor,
    RouterLink,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    TranslateModule,
    NgClass,
    AsyncPipe,
  ],
})
export class InfoHeaderComponent implements OnInit, OnDestroy {
  public infoHeaderTabs = INFO_HEADER_TABS;

  public shoudShowRequestProduct = false;

  public shouldShowTranslationOption = false;

  public googlePlayButtonPath: string;

  public isLTR: boolean;

  public trans = trans;

  private _languageChangeSubscription: Subscription;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  darkModeClass$: Observable<string>;

  constructor(
    private _dialog: MatDialog,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    public siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _themeService: ThemeService,
  ) {
    this._translateService.setDefaultLang(ARABIC_LANGUAGE);
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  ngOnInit(): void {
    combineLatest({
      requestProductFlag: this._getFeatureFlagUseCase.execute(
        FEATURE_FLAGS.REQUEST_PRODUCT_MENU_FLAG,
      ),
      translationFlag: this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.TRANSLATION_FLAG),
    }).subscribe({
      next: ({ requestProductFlag, translationFlag }) => {
        this.shoudShowRequestProduct = requestProductFlag;
        translationFlag = JSON.parse(translationFlag);
        this.shouldShowTranslationOption =
          translationFlag.status === 'true' || translationFlag.taagerIds.includes(user.id);
      },
    });
    this.siteTranslateService.checkInitialLanguage(
      !this.shouldShowTranslationOption ? ARABIC_LANGUAGE : null,
    );
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: () => {
        this.isLTR = isLTR();
      },
    });
  }

  onTrackMixpanelEvent(eventName: string): void {
    this._logMixpanelEventUseCase.execute({ eventName });
  }

  openRequestProductDialog(): void {
    const eventBody = {
      Language: 'ar',
      'Interface Version': 'v2',
      'Clicked From': CLICK_FROM_NAV_BAR,
    };
    this._logMixpanelEventUseCase.execute({ eventName: REQUEST_PRODUCT_CLICK, payload: eventBody });
    const dialogRefOrderChat = this._dialog.open(RequestUnavailableProductDialogComponent, {
      width: '800px',
      height: '500px',
      data: {
        clickedFrom: CLICK_FROM_NAV_BAR,
      },
      direction: this.siteTranslateService.currentDir,
    });
    dialogRefOrderChat.afterClosed().subscribe({ next: () => {} });
  }

  ngOnDestroy(): void {
    this._languageChangeSubscription.unsubscribe();
  }
}
