import { Inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { orderRepositoryInjectionToken } from 'src/app/data/injection-tokens/order-repository.injection-token';
import {
  ONBOARDING_EXPERIMENT_START_DATE,
  ONBOARDING_QUERY_START_DATE,
} from '../../presentation/shared/constants/onboarding';
import { UseCase } from '../base/use-case';
import { OrderRepository } from '../repositories/order.repository';

@Injectable({
  providedIn: 'any',
})
export class IsUserEligibleForOnboardingUseCase implements UseCase<void, Observable<boolean>> {
  constructor(@Inject(orderRepositoryInjectionToken) private _orderRepository: OrderRepository) {}

  execute(): Observable<boolean> {
    const filter = {
      pageSize: 1, // We don't need more than one for this
      country: country.data,
      fromDate: ONBOARDING_QUERY_START_DATE,
      toDate: ONBOARDING_EXPERIMENT_START_DATE,
    };
    return this._orderRepository
      .getOrdersTracking(filter)
      .pipe(map((ordersTracking) => ordersTracking.orders.length === 0));
  }
}
