export const USER_LOGIN_CACHE_KEY = 'usr';
export const USER_SESSION_KEY = 'usr-s';

export const userApiUrl = {
  /* Auth Services */
  login: `users/login`,
  forgetPassword: `auth/forgotPassword`,
  changePassword: `auth/changePassword`,
  register: `users/register`,
  requestOTP: `users/phone-number`,
  verifyPhoneNumber: `users/phone-number/verify`,
  socialLogin: `auth/socialAuthSignIn`,
};
