import { environment } from 'src/environments/environment';

export const ORDER_APIS_ENDPOINTS = {
  RATE_ORDER_URL: `${environment.BACKEND_URL}api/order/rateOrder`,
  ORDER_CART_URL: `${environment.BACKEND_URL}api/order/makeOrderByCart`,
  CALCULATE_ORDER_COST_URL: `${environment.BACKEND_URL}api/order/calculate-cost`,
  GET_ORDER_ACTIVITY_WITH_STATUS_URL: `${environment.BACKEND_URL}api/order/getOrderActivityWithStatus`,
  CANCEL_ORDER_URL: `${environment.BACKEND_URL}api/order/cancelOrder`,
  GET_MERCHANT_ORDER_SUMMARY_URL: (startDate: string, endDate: string): string =>
    `${environment.BACKEND_URL}api/engagement/orders-summary?fromDate=${startDate}&toDate=${endDate}`,
  GET_REFERRALS_ORDERS_URL: `${environment.BACKEND_URL}api/order/getReferralsOrders`,
  GET_CHILD_ORDERS_URL: `${environment.BACKEND_URL}api/child-order`,
  GET_PRODUCT_PRICES_URL: `${environment.BACKEND_URL}admin/orders/product-prices`,
  GET_FAILED_ORDERS_URL: `${environment.BACKEND_URL}api/failed-orders`,
  GET_ORDERS_LIST_URL: `${environment.BACKEND_URL}api/orders`,
  GET_ORDER_STATUS_LIST_URL: `${environment.BACKEND_URL}admin/orders/order-statuses`,
  TRACK_ORDER_ACTIVITY_URL: (orderId: string): string =>
    `${environment.BACKEND_URL}admin/orders/${encodeURIComponent(orderId)}/status-tracking`,
};
