import { Mapper } from 'src/app/core/base/mapper';
import { UpdateDukanProductModel } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { DukanUpdateProductEntity } from '../entities/dukan-product.entity';

export class DukanUpdateProductMapper extends Mapper<
  DukanUpdateProductEntity,
  UpdateDukanProductModel
> {
  mapFrom(param: DukanUpdateProductEntity): UpdateDukanProductModel {
    return {
      title: param.title,
      description: param.description,
      pricingOptions: param.pricingOptions.map(
        ({ name, description, totalPrice, quantity, isDefault }) => ({
          name: name.ar!,
          description: description.ar!,
          totalPrice,
          quantity,
          isDefault,
          isEditMode: false,
        }),
      ),
    };
  }

  mapTo(param: UpdateDukanProductModel): DukanUpdateProductEntity {
    return {
      title: param.title,
      description: param.description,
      pricingOptions: param.pricingOptions.map(
        ({ name, description, totalPrice, quantity, isDefault }) => ({
          name: {
            ar: name,
            en: '',
          },
          description: {
            ar: description,
            en: '',
          },
          totalPrice,
          quantity,
          isDefault,
        }),
      ),
    };
  }
}
