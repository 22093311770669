<div class="overlay-background">
  <ng-container
    *ngTemplateOutlet="showOverlay ? showOverlayTemplate : noOverlayTemplate"
  ></ng-container>
</div>

<ng-template #showOverlayTemplate>
  <div class="overlay-background__enable">
    <div class="overlay-background__enable__outlet">
      <ng-template #outlet></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #noOverlayTemplate>
  <div class="overlay-background__disable">no overlay here!</div>
</ng-template>
