import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProvinceZoneDistrictFilterModel,
  ProvinceZoneFilterModel,
} from 'src/app/core/domain/provinces.model';
import { API_URLS } from 'src/app/presentation/shared/constants';
import {
  ProvinceListEntity,
  ProvinceZoneDistrictResponseEntity,
  ProvinceZoneResponseEntity,
} from './entities/province-entity';

@Injectable({
  providedIn: 'root',
})
export class ProvinceAPIservice {
  constructor(private http: HttpClient) {}

  getAllProvinceZoneDistrictList(country: string): Observable<ProvinceListEntity> {
    return this.http.get<ProvinceListEntity>(API_URLS.PROVINCE_LIST_URL_ALL(country));
  }

  getProvinceZones(filter: ProvinceZoneFilterModel): Observable<ProvinceZoneResponseEntity> {
    const finalProvince = encodeURIComponent(filter.provinceId);
    return this.http.get<ProvinceZoneResponseEntity>(
      API_URLS.PROVINCE_ZONES_LIST_URL(filter, finalProvince),
    );
  }

  getProvinceZoneDistricts(
    filter: ProvinceZoneDistrictFilterModel,
  ): Observable<ProvinceZoneDistrictResponseEntity> {
    const finalProvince = encodeURIComponent(filter.provinceId);
    const finalZone = encodeURIComponent(filter.zoneId);
    return this.http.get<ProvinceZoneDistrictResponseEntity>(
      API_URLS.PROVINCE_ZONE_DISTRICTS_LIST_URL(filter, finalProvince, finalZone),
    );
  }
}
