import { resolveAuthRepository } from '@di/app';
import type {
  AuthRepositoryContract,
  EmailLoginData,
  ForgetPasswordData,
  GoogleLoginData,
  PhoneNumberLoginData,
  RegisterData,
  ResetPasswordData,
  RotateRefreshTokenData,
  UpdateJwtData,
  VerifyPhoneNumberData,
} from '../types';
import {
  forgetPasswordUseCase,
  loginByEmailUseCase,
  loginByGoogleUseCase,
  loginByPhoneUseCase,
  logoutUseCase,
  registerPasswordUseCase,
  resetPasswordUseCase,
  rotateRefreshTokenUseCase,
  updateJwtUseCase,
  verifyPhoneNumberUseCase,
} from '../use-cases/auth.use-cases';

export class AuthUseCaseResolver {
  /**
   * Resolve login by email use case
   */
  public static loginByEmail(data: EmailLoginData): ReturnType<typeof loginByEmailUseCase> {
    return loginByEmailUseCase(this.repository, data);
  }

  /**
   * Resolve login by phone use case
   */
  public static loginByPhone(data: PhoneNumberLoginData): ReturnType<typeof loginByPhoneUseCase> {
    return loginByPhoneUseCase(this.repository, data);
  }

  /**
   * Resolve login by google use case
   */
  public static loginByGoogleUseCase(
    data: GoogleLoginData,
  ): ReturnType<typeof loginByGoogleUseCase> {
    return loginByGoogleUseCase(this.repository, data);
  }

  /**
   * Resolve forget password use case
   */
  public static forgetPasswordUseCase(
    data: ForgetPasswordData,
  ): ReturnType<typeof forgetPasswordUseCase> {
    return forgetPasswordUseCase(this.repository, data);
  }

  /**
   * Resolve reset password use case
   */
  public static resetPasswordUseCase(
    data: ResetPasswordData,
  ): ReturnType<typeof resetPasswordUseCase> {
    return resetPasswordUseCase(this.repository, data);
  }

  /**
   * Resolve register use case
   */
  public static registerPasswordUseCase(
    data: RegisterData,
  ): ReturnType<typeof registerPasswordUseCase> {
    return registerPasswordUseCase(this.repository, data);
  }

  /**
   * Resolve verify  phone number use case
   */
  public static verifyPhoneNumberUseCase(
    data: VerifyPhoneNumberData,
  ): ReturnType<typeof verifyPhoneNumberUseCase> {
    return verifyPhoneNumberUseCase(this.repository, data);
  }

  /**
   * Resolve rotate refresh token  use case
   */
  public static rotateRefreshTokenUseCase(
    data: RotateRefreshTokenData,
  ): ReturnType<typeof rotateRefreshTokenUseCase> {
    return rotateRefreshTokenUseCase(this.repository, data);
  }

  /**
   * Resolve update jwt use case
   */
  public static updateJwtUseCase(data: UpdateJwtData): ReturnType<typeof updateJwtUseCase> {
    return updateJwtUseCase(this.repository, data);
  }

  /**
   * Resolve logout use case
   */
  public static logoutUseCase(): ReturnType<typeof logoutUseCase> {
    return logoutUseCase(this.repository);
  }

  /**
   * Resolve auth repository
   */
  protected static get repository(): AuthRepositoryContract {
    return resolveAuthRepository();
  }
}
