import { Mapper } from 'src/app/core/base/mapper';
import { QuestionnaireQuestionModel } from 'src/app/core/domain/questionnaire.model';
import { QuestionnaireQuestionEntity } from '../entities/questionnaire-entity';
import { QuestionnaireAnswerRepositoryMapper } from './questionnaire-answer.mapper';

export class QuestionnaireQuestionRepositoryMapper extends Mapper<
  QuestionnaireQuestionEntity,
  QuestionnaireQuestionModel
> {
  mapFrom(param: QuestionnaireQuestionEntity): QuestionnaireQuestionModel {
    const questionnaireAnswerMapper = new QuestionnaireAnswerRepositoryMapper();
    let type: string;
    if (param.type) {
      type = param.type;
    } else {
      type = param.maxAllowedAnswers > 1 || !param.maxAllowedAnswers ? 'multiple' : 'single';
    }
    return {
      id: param.id,
      textArabic: param.textAr,
      textEnglish: param.textEn,
      iconUrl: param.iconUrl,
      type,
      category: param.category,
      maxAllowedAnswers: param.maxAllowedAnswers,
      answers: param.answers.map(questionnaireAnswerMapper.mapFrom),
      required: param.required,
    };
  }

  mapTo(param: QuestionnaireQuestionModel): QuestionnaireQuestionEntity {
    const questionnaireAnswerMapper = new QuestionnaireQuestionRepositoryMapper();
    return {
      id: param.id,
      textAr: param.textArabic,
      textEn: param.textEnglish,
      iconUrl: param.iconUrl,
      type: param.type,
      category: param.category,
      maxAllowedAnswers: param.maxAllowedAnswers,
      answers: param.answers.map(questionnaireAnswerMapper.mapTo),
    };
  }
}
