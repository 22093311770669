import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PRODUCTS_V2_URL } from '../../../constants';
import { LocalStorageService } from '../../../services/local-storage.service';
import { RouteAccessResolutionTypes } from './interfaces';
import { isUserLoggedIn, stringToUrlTree } from './shared';

/**
 * For a user to access the logged out path:
 *
 * 1. Must be logged out
 */
export const loggedOutResolver = (): Observable<RouteAccessResolutionTypes> => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
        } else {
          subscriber.next(true);
        }
      },
    });
  });
};
