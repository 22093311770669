import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeadListFilters, LeadListPaginatedModel } from 'src/app/core/domain/lead.model';
import { LeadsRepository } from 'src/app/core/repositories/lead.repository';
import { LeadAPIService } from './leads-apis.service';
import { LeadRepositoryMapper } from './mapper/lead.mapper';

@Injectable({
  providedIn: 'root',
})
export class LeadsRepositoryImplementation implements LeadsRepository {
  public leadsListMapper = new LeadRepositoryMapper();

  constructor(private _leadsAPIservice: LeadAPIService) {}

  getLeadsList(params: LeadListFilters): Observable<LeadListPaginatedModel> {
    return this._leadsAPIservice.getLeadList(params).pipe(
      map((response) => ({
        count: response.count,
        result: response.data.map(this.leadsListMapper.mapFrom),
      })),
    );
  }
}
