import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  DynamicIncentiveFeedback,
  DynamicIncentiveProgramModel,
  HistoryDynamicIncentiveProgramModel,
} from 'src/app/core/domain/dynamic-incentive-program.model';
import { DynamicIncentiveProgramRepository } from 'src/app/core/repositories/dynamic-incentive-program.repository';
import { DynamicIncentiveProgramApisService } from './dynamic-incentive-program-api.services';
import { DynamicIncentiveProgramHistoryMapper } from './mappers/dynamic-incentive-program-history.mapper';
import { DynamicIncentiveProgramMapper } from './mappers/dynamic-incentive-program.mapper';

@Injectable({
  providedIn: 'root',
})
export class DynamicIncentiveProgramRepositoryImplementation
  implements DynamicIncentiveProgramRepository
{
  private _dynamicIncentiveProgramMapper = new DynamicIncentiveProgramMapper();

  private _dynamicIncentiveProgramHistoryMapper = new DynamicIncentiveProgramHistoryMapper();

  constructor(private _dynamicIncentiveProgramApisService: DynamicIncentiveProgramApisService) {}

  getDynamicIncentiveProgram(): Observable<DynamicIncentiveProgramModel[]> {
    return this._dynamicIncentiveProgramApisService
      .getDynamicIncentiveProgram()
      .pipe(map((response) => response.map(this._dynamicIncentiveProgramMapper.mapFrom)));
  }

  getDynamicIncentiveProgramHistory(): Observable<HistoryDynamicIncentiveProgramModel[]> {
    return this._dynamicIncentiveProgramApisService
      .getDynamicIncentiveProgramHistory()
      .pipe(map((response) => response.map(this._dynamicIncentiveProgramHistoryMapper.mapFrom)));
  }

  acceptDynamicIncentiveProgram(id: string): Observable<DynamicIncentiveProgramModel> {
    return this._dynamicIncentiveProgramApisService
      .acceptDynamicIncentiveProgram(id)
      .pipe(map(this._dynamicIncentiveProgramMapper.mapFrom));
  }

  declineDynamicIncentiveProgram(id: string): Observable<void> {
    return this._dynamicIncentiveProgramApisService.declineDynamicIncentiveProgram(id);
  }

  feedbackDynamicIncentiveProgram(data: DynamicIncentiveFeedback): Observable<void> {
    return this._dynamicIncentiveProgramApisService.feedbackDynamicIncentiveProgram(data);
  }
}
