import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DynamicIncentiveFeedback } from '../../domain/dynamic-incentive-program.model';
import { DynamicIncentiveProgramRepository } from '../../repositories/dynamic-incentive-program.repository';

export class FeedbackDynamicIncentiveProgramUseCase
  implements UseCase<DynamicIncentiveFeedback, void>
{
  constructor(private _repository: DynamicIncentiveProgramRepository) {}

  execute(data: DynamicIncentiveFeedback): Observable<void> {
    return this._repository.feedbackDynamicIncentiveProgram(data);
  }
}
