import { Mapper } from 'src/app/core/base/mapper';
import { LeadModel } from 'src/app/core/domain/lead.model';
import { LeadEntity } from '../entity/lead-entity';

export class LeadRepositoryMapper extends Mapper<LeadEntity, LeadModel> {
  mapFrom(param: LeadEntity): LeadModel {
    return {
      id: param.id,
      leadId: param.leadId,
      platform: param.platform,
      status: param.status,
      taagerId: param.taagerId,
      assignedAdminName: param.assignedAdminName,
      minutesSinceCreated: param.minutesSinceCreated,
      conversionAttempts: param.conversionAttempts,
      conversionFailedAttemptsNum: param.conversionFailedAttemptsNum,
      conversionFailedAttempts: param.conversionFailedAttempts,
      orderId: param.orderId,
      productId: param.productId,
      ad: param.ad,
      country: param.country,
      adGroup: param.adGroup,
      campaign: param.campaign,
      leadDate: param.leadDate,
      leadDetails: param.leadDetails,
      rejectedReason: param.rejectedReason,
      suspendedReason: param.suspendedReason,
    };
  }

  mapTo(param: LeadModel): LeadEntity {
    return {
      id: param.id,
      leadId: param.leadId,
      platform: param.platform,
      status: param.status,
      taagerId: param.taagerId,
      assignedAdminName: param.assignedAdminName,
      minutesSinceCreated: param.minutesSinceCreated,
      conversionAttempts: param.conversionAttempts,
      conversionFailedAttemptsNum: param.conversionFailedAttemptsNum,
      conversionFailedAttempts: param.conversionFailedAttempts,
      orderId: param.orderId,
      productId: param.productId,
      ad: param.ad,
      country: param.country,
      adGroup: param.adGroup,
      campaign: param.campaign,
      leadDate: param.leadDate,
      leadDetails: param.leadDetails,
      rejectedReason: param.rejectedReason,
      suspendedReason: param.suspendedReason,
    };
  }
}
