import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { DEVICE_TYPES } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private isMobile: boolean;

  private isTablet: boolean;

  public screenWidth: string;

  constructor() {
    this.checkWidth();
  }

  onMobileChange(status: boolean): void {
    this.isMobile = status;
  }

  getMobileStatus(): Observable<any> {
    return of(this.isMobile);
  }

  ontabletChange(status: boolean) {
    this.isTablet = status;
  }

  gettabletStatus(): Observable<any> {
    return of(this.isTablet);
  }

  getScreenWidth() {
    return this.screenWidth;
  }

  public checkWidth() {
    const width = window.innerWidth;
    if (width <= 767) {
      this.screenWidth = 'sm';
      this.onMobileChange(true);
    } else if (width > 767 && width <= 991) {
      this.screenWidth = 'md';
      this.onMobileChange(false);
      this.ontabletChange(true);
    } else if (width > 991 && width <= 1200) {
      this.screenWidth = 'lg';
      this.onMobileChange(false);
    } else {
      this.screenWidth = 'xl';
      this.onMobileChange(false);
    }
  }

  /**
   * return the category of the device, which can be either
   */
  public returnDeviceCategory(): DEVICE_TYPES {
    switch (this.screenWidth) {
      case 'sm':
        return 'mobile';
      case 'md':
        return 'tablet';
      case 'lg':
      case 'xl':
        return 'desktop';
      default:
        throw new Error();
    }
  }
}
