import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ArticleModel, VideoModel } from '../../domain/learning-center.model';
import { LearningCenterRepository } from '../../repositories/learning-center.repository';

export class GetItemByContentIdUseCase implements UseCase<string, VideoModel | ArticleModel> {
  constructor(private learningCenterRepository: LearningCenterRepository) {}

  execute(contentId: string): Observable<VideoModel | ArticleModel> {
    return this.learningCenterRepository.getItemByContentId(contentId);
  }
}
