import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CategoryItemModel } from 'src/app/core/domain/category-item.model';
import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { CommercialCategoryRepository } from 'src/app/core/repositories/commercial-categories.repository';
import { COMMERCIAL_CATEGORY_TREE_ROOT } from 'src/app/presentation/shared/constants/commercial-categories';
import {
  CommercialCategoryTree,
  CommercialCategoryTreeNode,
} from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { Tree } from 'src/app/presentation/shared/utilities/tree.utility';
import { CommercialCategoriesApisService } from './commercial-categories-apis.service';
import { CommercialCategoryRepositoryMapper } from './mappers/commercial-category-repository.mapper';

@Injectable({
  providedIn: 'root',
})
export class CommercialCategoryImplementationRepository implements CommercialCategoryRepository {
  public commercialCategoryMapper = new CommercialCategoryRepositoryMapper();

  private _cachedCommercialCategories$: {
    [countryIsoCode: string]: Observable<CommercialCategoryTree>;
  } = {};

  constructor(private commercialCategoriesApisService: CommercialCategoriesApisService) {}

  getBottomLevelCategories(): Observable<CategoryItemModel[]> {
    return this.commercialCategoriesApisService.getBottomLevelCategories();
  }

  getCommercialCategoryDataById(categoryId: string): Observable<CategoryItemModel> {
    return this.commercialCategoriesApisService.getCommercialCategoryDataById(categoryId);
  }

  getCommercialCategories(countryCode: string): Observable<CommercialCategoryTree> {
    if (!this._cachedCommercialCategories$[countryCode]) {
      this._cachedCommercialCategories$[countryCode] = this.commercialCategoriesApisService
        .getCommercialCategories(countryCode)
        .pipe(
          map((categories) =>
            categories.map((category) => this.commercialCategoryMapper.mapFrom(category)),
          ),
          map((categoryModels) => {
            const categoryTree: CommercialCategoryTree = new Tree<BaseCategoryModel>(
              COMMERCIAL_CATEGORY_TREE_ROOT.id!,
              COMMERCIAL_CATEGORY_TREE_ROOT,
            );
            this.insertNodesRecursivelyInTree(categoryTree, categoryTree.root.key, categoryModels);
            return categoryTree;
          }),
          shareReplay(1),
        );
    }
    return this._cachedCommercialCategories$[countryCode];
  }

  insertNodesRecursivelyInTree(
    tree: Tree<BaseCategoryModel>,
    parentId: string,
    categories: BaseCategoryModel[],
  ): void {
    const categoriesNodeList = categories.map((category) => ({
      key: category.id!,
      value: { name: category.name, featured: category.featured, icon: category.icon },
    }));
    tree.insertMany(parentId!, categoriesNodeList);
    categories.forEach((category) => {
      if (category.children?.length) {
        this.insertNodesRecursivelyInTree(tree, category.id!, category.children);
      }
    });
  }

  getCategoryHierarchy(
    countryCode: string,
    categoryId: string,
  ): Observable<CommercialCategoryTreeNode[]> {
    return this.getCommercialCategories(countryCode).pipe(
      map((categories) => {
        let currentNode = categories.find(categoryId);
        if (currentNode) {
          const categoryNodeParents = [currentNode];
          while (currentNode.parent) {
            categoryNodeParents.push(currentNode.parent);
            currentNode = currentNode.parent;
          }
          return categoryNodeParents;
        }
        return [];
      }),
    );
  }
}
