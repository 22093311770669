import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

export interface EventWithType {
  type: string;
  [key: string]: unknown;
}

export type ViewEventHandler<Event extends EventWithType> = {
  [K in Event['type']]: (event: Extract<Event, { type: K }>) => void;
};

/**
 * A Presenter is responsible for the Presentation logic
 * Here you will apply all the validations needs, invoke the Use Cases, prepare the View State and decide of any Side Effects
 * The Presenter is Framework Agnostic
 */
export abstract class BasePresenter<ViewState, ViewEvent extends EventWithType, SideEffect> {
  /**
   * Events that the View can emit
   * If declared and `onViewEvent` is not implemented, it will automatically listen to the events
   */
  protected viewEvents?: ViewEventHandler<ViewEvent>;

  /**
   * View state of the Presenter
   */
  protected viewState: ViewState;

  /**
   * BehaviorSubject to emit the ViewState changes
   * It's a private property to avoid external changes
   */
  private _viewState: BehaviorSubject<ViewState>;

  /**
   * Subject to emit the Side Effects
   */
  private __sideEffect = new Subject<SideEffect>();

  /**
   * Any Subscription that the Presenter needs to keep track of
   */
  private subscription = new Subscription();

  /**
   * Constructor of the Presenter
   */
  public constructor() {
    this.viewState = this.defaultViewState();
    this._viewState = new BehaviorSubject<ViewState>(this.viewState);
  }

  /**
   * Emit view event
   * For now it's just a proxy to `onViewEvent`
   */
  public emitViewEvent(event: ViewEvent): void {
    this.onViewEvent(event);
  }

  /**
   * Listen to the View Events
   */
  protected onViewEvent(event: ViewEvent): void {
    if (!this.viewEvents) {
      throw new Error(
        'You need to implement the `onViewEvent` method or declare the `viewEvents` property',
      );
    }

    const handler = this.viewEvents?.[event.type as ViewEvent['type']];

    if (!handler) {
      throw new Error(`No handler for event type ${event.type}`);
    }

    // we will not call the handler directly, why?
    // because we want to keep the context of the presenter to be used when the handler uses `this` inside itself.
    handler.call(this, event);
  }

  /**
   * Listen to the ViewState changes
   */
  public onViewStateChange(): Observable<ViewState> {
    return this._viewState.asObservable();
  }

  /**
   * Listen to the Side Effects
   */
  public onSideEffect(): Observable<SideEffect> {
    return this.__sideEffect.asObservable();
  }

  /**
   * Destroy the Presenter
   * Typically called when the component is destroyed
   */
  public destroy(): void {
    this.subscription.unsubscribe();
    this._viewState.complete();
    this.__sideEffect.complete();
  }

  /**
   * Emit a new Side Effect
   */
  protected emitSideEffect(sideEffect: SideEffect): void {
    this.__sideEffect.next(sideEffect);
  }

  /**
   * Update the ViewState with a new data
   */
  protected updateViewState(viewState: ViewState): void {
    this.viewState = viewState;
    this._viewState.next(this.viewState);
  }

  /**
   * Merge the given state data with the current ViewState
   */
  protected merge(viewState: Partial<ViewState>): void {
    this.updateViewState({ ...this.viewState, ...viewState });
  }

  /**
   * Change only the value of the given key in the ViewState
   * This will trigger the ViewState change
   */
  protected set<K extends keyof ViewState>(key: K, value: ViewState[K]): void {
    this.updateViewState({ ...this.viewState, [key]: value });
  }

  /**
   * Get value from the ViewState
   * This could be useful so we can avoid direct access to the ViewState
   */
  protected get<K extends keyof ViewState>(key: K): ViewState[K] {
    return this.viewState[key];
  }

  /**
   * Default ViewState of the Presenter
   */
  protected abstract defaultViewState(): ViewState;

  /**
   * Add new Subscription to the Presenter
   */
  protected add(subscription: Subscription): void {
    this.subscription.add(subscription);
  }
}
