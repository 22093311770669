import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { FAQModel } from 'src/app/core/domain/faq.model';
import {
  MerchantLoyaltyProgressModel,
  TierModel,
} from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { LoyaltyProgramRepository } from 'src/app/core/repositories/loyalty-program.repository';
import { LoyaltyProgramApisService } from './loyalty-program.api-service';

@Injectable({
  providedIn: 'root',
})
export class TierRepositoryImplementation implements LoyaltyProgramRepository {
  private cachedTiersData$: Observable<TierModel[]>;

  // eslint-disable-next-line prettier/prettier
  constructor(private _loyaltyProgramApisService: LoyaltyProgramApisService) { }

  getLoyaltyTiersData(): Observable<TierModel[]> {
    if (!this.cachedTiersData$) {
      this.cachedTiersData$ = this._loyaltyProgramApisService.getTieringData().pipe(
        shareReplay(1),
        map((array) => array.sort((v1, v2) => v1.index - v2.index)),
      );
    }
    return this.cachedTiersData$;
  }

  getLoyaltyFAQssData(): Observable<FAQModel[]> {
    return this._loyaltyProgramApisService.getFAQssData();
  }

  getLoyaltyProgress(): Observable<MerchantLoyaltyProgressModel> {
    return this._loyaltyProgramApisService.getProgress();
  }
}
