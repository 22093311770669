import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { StockAvailabilityModel } from '../../domain/stock-availability.model';
import { StockAvailabilityRepository } from '../../repositories/stock-availability.repository';

export class GetStockAvailabilityUseCase implements UseCase<string, StockAvailabilityModel> {
  constructor(private _getStockAvailabilityRepository: StockAvailabilityRepository) {}

  execute(productId: string): Observable<StockAvailabilityModel> {
    return this._getStockAvailabilityRepository.getStockAvailability(productId);
  }
}
