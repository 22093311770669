import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OrderListParamModel, OrdersListModel } from '../../domain/order-model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetOrdersListUseCase implements UseCase<OrderListParamModel, OrdersListModel> {
  constructor(private _orderRepository: OrderRepository) {}

  execute(filter: OrderListParamModel): Observable<OrdersListModel> {
    return this._orderRepository.getOrdersList(filter);
  }
}
