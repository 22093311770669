import { Injectable } from '@angular/core';
import { Mapper } from '../../../../core/base/mapper';
import { LearningLesson } from '../../../../core/domain/learning.model';
import { LocalizableStringEntityMapper } from '../../../api/mappers/localizable-string-entity-mapper';
import { LearningLessonLocalEntity } from '../local/entities/learning-local-entity';
import { LearningLessonRemoteEntity } from '../remote/entities/learning-remote-entity';

type Params = { remote: LearningLessonRemoteEntity; local?: LearningLessonLocalEntity };

@Injectable({
  providedIn: 'root',
})
export class LearningLessonMapper extends Mapper<Params, LearningLesson> {
  constructor(private _localizableMapper: LocalizableStringEntityMapper) {
    super();
  }

  mapFrom(params: Params): LearningLesson {
    return {
      id: params.remote.id,
      title: this._localizableMapper.map(params.remote.title),
      description: this._localizableMapper.map(params.remote.description),
      videoUrl: params.remote.videoUrl,
      isCompleted: params.local?.isCompleted || false,
    };
  }

  mapTo(domain: LearningLesson): Params {
    throw new Error('Unsupported');
  }
}
