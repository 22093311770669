import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';

export class SetUserReferralUseCase implements UseCase<string, void> {
  constructor(private _analyticsRepository: AnalyticsRepository) {}

  execute(referral: string): void | Observable<void> {
    this._analyticsRepository.setUserReferral(referral);
  }
}
