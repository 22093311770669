import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { RemoteConfigRepository } from '../repositories/remote-config.repository';

export class GetRemoteConfigUsecase implements UseCase<void, { [property: string]: any }> {
  constructor(private remoteConfigRepository: RemoteConfigRepository) {}

  execute(): Observable<{ [property: string]: any }> {
    return this.remoteConfigRepository.getRemoteConfig();
  }
}
