import { Component } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  template: '',
  standalone: true,
})
export class BaseOverlayDialogComponent {
  public dismissOverlay$: Subject<boolean> = new Subject();

  public dismissOverlay(): void {
    this.dismissOverlay$.next(true);
  }
}
