import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, firstValueFrom, map, mergeMap, Observable, of, take, takeWhile } from 'rxjs';
import { SubmittedAnswersModel } from 'src/app/core/domain/questionnaire.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { GetQuestionnaireQuestionsUseCase } from 'src/app/core/usecases/get-questionnaire-questions.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { SubmitQuestionnaireAnswersUseCase } from 'src/app/core/usecases/submit-questionnaire-answers.usecase';
import { CURRENT_LAST_ONBOARDING_STEP } from '../onboarding/constants';
import { OnboardingStoryGuideStateManager } from '../onboarding/state-manager/onboarding-story-guide.state-manager';
import { SelectCurrentActiveUserStepIndex } from '../onboarding/state-manager/selectors/selector-names';
import { CUSTOMER_EFFORT_SCORE } from '../shared/constants';
import { userNeedsToFillOnboardingQuestionnaire } from '../shared/guards/v2/resolvers/shared';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { SharedOverlayService } from '../shared/services/shared-overlay.service';
import { cesLocationNotifierUtility } from '../shared/utilities/ces-location-notifier.utility';
import { DateWrapperUtility } from '../shared/utilities/date-wrapper.utility';
import { translateInstantUtility } from '../shared/utilities/translate-instant.utility';
import { CesQuestionnaireOverlayComponent } from './ces-questionnaire-overlay/ces-questionnaire-overlay.component';
import { QUESTIONNAIRE_LOCATION_MAP } from './constants';
import {
  CESQuestionnaire,
  CESQuestionnaireRecurring,
  CESQuestionnaireRemindOptions,
  CESQuestionnaireTimeUnits,
  CloseQuestionnaireMeta,
} from './interfaces';
import { CESQuestionnaireMapFrom } from './mappers';

@Component({
  selector: 'app-customer-effort-support',
  standalone: true,
  imports: [NgIf, CesQuestionnaireOverlayComponent, TranslateModule],
  templateUrl: './customer-effort-support.component.html',
  styleUrls: ['./customer-effort-support.component.scss'],
})
export class CustomerEffortSupportComponent implements OnInit {
  public assetsPath = 'assets/img/customer-effort-support/';

  private _sharedOverlayService: SharedOverlayService = inject(SharedOverlayService);

  private _onBoardingStoryGuideStateManager: OnboardingStoryGuideStateManager = inject(
    OnboardingStoryGuideStateManager,
  );

  private _getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase = inject(
    GetOnboardingFeatureUseCase,
  );

  private _isUserEligibleForOnboardingUseCase: IsUserEligibleForOnboardingUseCase = inject(
    IsUserEligibleForOnboardingUseCase,
  );

  private _getQuestionnaireEligibilityUseCase: GetQuestionnaireEligibilityUseCase = inject(
    GetQuestionnaireEligibilityUseCase,
  );

  private _getQuestionnaireQuestionsUseCase: GetQuestionnaireQuestionsUseCase = inject(
    GetQuestionnaireQuestionsUseCase,
  );

  private _localStorageService: LocalStorageService = inject(LocalStorageService);

  private _submitQuestionnaireAnswersUseCase: SubmitQuestionnaireAnswersUseCase = inject(
    SubmitQuestionnaireAnswersUseCase,
  );

  public showCESQuestionnaire = false;

  public showSignOff = false;

  private _signOffTimer = 2000;

  public instantiate = false;

  public showCesTriggerButton = false;

  public questionnaire: CESQuestionnaire;

  private _toastrService: ToastrService = inject(ToastrService);

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  private _translateInstantUtility = translateInstantUtility();

  private _questionnaireName: string;

  private _fetchingQuestionnaire: boolean;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  @ViewChild('cesTriggerButtonRef', { static: false })
  private _cesTriggerButtonRef: ElementRef<HTMLImageElement>;

  public cesComponentsMarginBottom: string;

  private _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  private _delayToShowQuestionnaire = 3000;

  ngOnInit(): void {
    this._optionallyEvokeUserFeedbackBasedOnLocation();
  }

  @HostListener('window:resize', [])
  onPageSizeChange(): void {
    this._calculateTriggerPosition();
  }

  private _calculateTriggerPosition(): void {
    if (!this._cesTriggerButtonRef) {
      return;
    }
    this._calculateCESComponentPosition(
      this._cesTriggerButtonRef.nativeElement.getBoundingClientRect(),
    );
  }

  private _calculateCESComponentPosition(position: DOMRect): void {
    const { innerHeight } = window;
    const { y } = position;
    const offsetFromTrigger = 12;
    this.cesComponentsMarginBottom = `${innerHeight - y + offsetFromTrigger}px`;
  }

  /**
   * This being an app-wide listener, there will be no need to unsubscribe, because
   * as it stands, it will never be "destroyed" from the view.
   */
  private _optionallyEvokeUserFeedbackBasedOnLocation(): void {
    this._cesLocationNotifierUtility({ action: 'read' }).subscribe((currentLocation) => {
      if (currentLocation !== ' ') {
        this._questionnaireName = QUESTIONNAIRE_LOCATION_MAP[currentLocation];
        if (this._questionnaireName) {
          const remindMode = this._shouldUserBeReminded(this._questionnaireName);
          switch (remindMode) {
            case 'full':
              setTimeout(() => {
                this._getQuestionnaire(this._questionnaireName);
              }, this._delayToShowQuestionnaire);
              break;
            case 'partial':
              this._didUserPreviouslyCancelThisQuestionnaire();
              break;
          }
        } else {
          throw new Error('Please register a questionnaire under QUESTIONNAIRE_LOCATION_MAP');
        }
      } else {
        this._toggleUIElements({
          instantiate: false,
          showCesTriggerButton: false,
          showCESQuestionnaire: false,
          showSignOff: false,
        });
      }
    });
  }

  private _didUserPreviouslyCancelThisQuestionnaire(): void {
    this.instantiate = true;
    this._checkUserQuestionnaireEligibility(this._questionnaireName).subscribe({
      next: ({ enabled }) => {
        this.showCesTriggerButton = enabled;
      },
      error: () => {
        this.showCesTriggerButton = false;
      },
    });
  }

  private _shouldUserBeReminded(questionnaireName: string): CESQuestionnaireRemindOptions {
    const cesStore = this.readCESStoreData();
    if (cesStore && cesStore[questionnaireName]) {
      const { remindOn, userCancelled } = cesStore[questionnaireName];
      if (userCancelled !== null && userCancelled) {
        // we only present to the user a partial meaning button to click to fill questionnaire
        return 'partial';
      }
      const now = new Date();
      if (remindOn) {
        // we have a remind on
        if (
          DateWrapperUtility.dateAIsSameOrAfterDateB(
            DateWrapperUtility.dateToISOString(now),
            remindOn,
          )
        ) {
          // same or after so we remind
          return 'full';
        }
        // before, we don't remind
        return 'none';
      }
      // the user has the questionnaire object, however, they don't have remindOn, so we show them
      return 'full';
    }
    // there is no record of user interacting with the questionnaire, so we show them i.e remind them the first time
    return 'full';
  }

  private _toggleUIElements(flags: {
    instantiate: boolean;
    showCesTriggerButton: boolean;
    showCESQuestionnaire: boolean;
    showSignOff: boolean;
  }): void {
    this.instantiate = flags.instantiate;
    this.showCesTriggerButton = flags.showCesTriggerButton;
    this.showCESQuestionnaire = flags.showCESQuestionnaire;
    if (flags.showSignOff) {
      this.showSignOff = true;
      setTimeout(() => {
        this.showSignOff = false;
        this.showCesTriggerButton = false;
      }, this._signOffTimer);
    }
  }

  /**
   * Here, we are cheking for any possible elements which can come
   * above the CES components.
   *
   * So, to have a good user experience, let's check for any possible
   * obstruction of the view, before instantiation.
   */
  private async _checkForObsuringElements() {
    const onboardingIsOnscuring = await this.onboardingIsOnscuring();
    this.instantiate = !onboardingIsOnscuring;
    this._toggleUIElements({
      instantiate: this.instantiate,
      showCesTriggerButton: true,
      showCESQuestionnaire: true,
      showSignOff: false,
    });
    this._changeDetectorRef.detectChanges();
    this._calculateTriggerPosition();
  }

  private async onboardingIsOnscuring() {
    await firstValueFrom(
      userNeedsToFillOnboardingQuestionnaire({
        getOnboardingFeatureUseCase: this._getOnboardingFeatureUseCase,
        isUserEligibleForOnboardingUseCase: this._isUserEligibleForOnboardingUseCase,
        getQuestionnaireEligibilityUseCase: this._getQuestionnaireEligibilityUseCase,
        onboardingStoryGuideStateManager: this._onBoardingStoryGuideStateManager,
      }).pipe(take(1)),
    );
    return new Promise((resolve, reject) => {
      this._onBoardingStoryGuideStateManager
        .selectStatePiece(SelectCurrentActiveUserStepIndex)
        .pipe()
        .subscribe((step) => {
          if (step === null || step === CURRENT_LAST_ONBOARDING_STEP) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }

  private _getQuestionnaire(questionnaireName: string): void {
    this._checkUserQuestionnaireEligibility(questionnaireName)
      .pipe(
        take(1),
        mergeMap((isEligible) =>
          isEligible
            ? this._getQuestionnaireQuestionsUseCase.execute(questionnaireName).pipe(
                map((questionnaire) => CESQuestionnaireMapFrom(questionnaire)),
                take(1),
              )
            : of(false),
        ),
        finalize(() => {
          this._fetchingQuestionnaire = false;
        }),
      )
      .subscribe((result) => {
        if (result && typeof result !== 'boolean') {
          this.questionnaire = result;
          this._checkForObsuringElements();
        }
      });
  }

  private _checkUserQuestionnaireEligibility(questionnaireName: string): Observable<{
    enabled: boolean;
  }> {
    return this._getQuestionnaireEligibilityUseCase.execute(questionnaireName);
  }

  public triggerFeedbackSubmit(): void {
    if (this._fetchingQuestionnaire || this.showCESQuestionnaire) {
      return;
    }
    this._fetchingQuestionnaire = true;
    this._injectQuestionnaireOverlay();
  }

  private _injectQuestionnaireOverlay(): void {
    this._sharedOverlayService
      .getOverlayType()
      .pipe(takeWhile((overlayType) => overlayType === 'noOverlay'))
      .subscribe({
        next: () => {
          this._getQuestionnaire(this._questionnaireName);
        },
      });
  }

  public closeQuestionnaire(payload: CloseQuestionnaireMeta): void {
    if (payload.action === 'cancel') {
      this.cancelQuestionnaire();
    } else {
      this.fillQuestionnaire(payload);
    }
  }

  public cancelQuestionnaire(): void {
    const cesStoreData = this.readCESStoreData();
    if (!cesStoreData[this.questionnaire.name]) {
      cesStoreData[this.questionnaire.name] = {};
    }
    this._setOrClearCancelOnQuestionnaire('set', cesStoreData);
    this._setReminder(cesStoreData, this._calculateWhenToRemind('day', 'day', 2));
    this._dismissCESQuestionnaireOverlay({
      instantiate: true,
      showCesTriggerButton: true,
      showCESQuestionnaire: false,
      showSignOff: false,
    });
    this._logMixpanelEventUseCase.execute({
      eventName: 'customer_effort_score_cancelled_questionnaire',
      payload: {
        questionnaire: this._questionnaireName,
      },
    });
  }

  public fillQuestionnaire(payload: { [answerId: string]: any }): void {
    const { answersToSubmit, answersToSkip } = this._createQuestionnaireAnswers(payload.answers);
    this._submitQuestionnaireAnswersUseCase
      .execute({
        questionnaireName: this.questionnaire.name,
        data: { answers: answersToSubmit },
      })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this._setWhenToRemindUserNext();
          this._dismissCESQuestionnaireOverlay({
            instantiate: true,
            showCesTriggerButton: true,
            showCESQuestionnaire: false,
            showSignOff: true,
          });
          this._optionallyLogSkippedAnswers(answersToSkip);
        },
        error: () => {
          this._dismissCESQuestionnaireOverlay({
            instantiate: false,
            showCesTriggerButton: true,
            showCESQuestionnaire: false,
            showSignOff: false,
          });
          this._setWhenToRemindUserNext('daily');
          this._toastrService.error(
            this._translateInstantUtility('CUSTOMER_EFFORT_SUPPORT.ERROR_MESSAGE'),
            this._translateInstantUtility('CUSTOMER_EFFORT_SUPPORT.ERROR_TITLE'),
            { timeOut: 5000 },
          );
        },
      });
  }

  private _optionallyLogSkippedAnswers(answerIds: Array<string>): void {
    const eventName = 'customer_effort_score_submitted';
    if (answerIds.length === 0) {
      this._logMixpanelEventUseCase.execute({
        eventName,
        payload: {
          questionnaire: this._questionnaireName,
          skipped: false,
        },
      });
      return;
    }
    const skippedQuestions: Array<{ id: string; textArabic: string; textEnglish: string }> =
      this.questionnaire.questions
        .filter((questionObject) => answerIds.includes(questionObject.id))
        .map((question) => ({
          id: question.id,
          textArabic: question.textArabic,
          textEnglish: question.textEnglish,
        }));
    this._logMixpanelEventUseCase.execute({
      eventName,
      payload: {
        questionnaire: this._questionnaireName,
        skippedQuestions,
        skipped: true,
      },
    });
  }

  private _createQuestionnaireAnswers(answers: { [questionId: string]: any }): {
    answersToSubmit: Array<SubmittedAnswersModel>;
    answersToSkip: Array<string>;
  } {
    const answersToSubmit: Array<SubmittedAnswersModel> = [];
    const answersToSkip: Array<string> = [];
    for (const questionId in answers) {
      if (questionId in answers) {
        let comment;
        let answerIds: Array<string> = [];
        if (typeof answers[questionId] === 'object') {
          answerIds = answers[questionId];
        } else {
          comment = answers[questionId];
        }
        answersToSubmit.push({
          answerIds,
          questionId,
          ...(comment ? { comment } : {}),
        });

        if (!answers[questionId]) {
          answersToSkip.push(questionId);
        }
      }
    }
    return { answersToSubmit, answersToSkip };
  }

  private _setWhenToRemindUserNext(forcedRecur?: CESQuestionnaireRecurring): void {
    let whenToRemind: Date;
    const recur = forcedRecur || this.questionnaire.recurring;
    switch (recur) {
      case 'daily':
        whenToRemind = this._calculateWhenToRemind('day', 'day', 1);
        break;
      case 'weekly':
        whenToRemind = this._calculateWhenToRemind('week', 'week', 1);
        break;
      case 'biweekly':
        whenToRemind = this._calculateWhenToRemind('week', 'week', 2);
        break;
      case 'monthly':
        whenToRemind = this._calculateWhenToRemind('day', 'month', 1);
        break;
      default:
        throw new Error('Please add a recurring cycle handle!');
    }
    const cesStoreData = this.readCESStoreData();
    if (!cesStoreData[this.questionnaire.name]) {
      cesStoreData[this.questionnaire.name] = {};
    }
    this._setOrClearCancelOnQuestionnaire('clear', cesStoreData);
    this._setReminder(cesStoreData, whenToRemind);
  }

  private _calculateWhenToRemind(
    refTimeUnit: CESQuestionnaireTimeUnits,
    endTimeUnit: CESQuestionnaireTimeUnits,
    timeDiff: number,
  ): Date {
    return DateWrapperUtility.getTheStartOfATimeUnit(
      DateWrapperUtility.calculateTimeUnitsFromGivenPoint(
        refTimeUnit,
        DateWrapperUtility.getTheEndOfATimeUnit(new Date(), endTimeUnit),
        timeDiff,
        'forward',
      ),
      refTimeUnit,
    );
  }

  private _dismissCESQuestionnaireOverlay(params: {
    instantiate: boolean;
    showCesTriggerButton: boolean;
    showCESQuestionnaire: boolean;
    showSignOff: boolean;
  }): void {
    this._toggleUIElements(params);
  }

  private _setOrClearCancelOnQuestionnaire(
    action: 'set' | 'clear',
    cesStoreData: {
      [questionnaire: string]: { [prop: string]: any };
    },
  ): void {
    if (action === 'set') {
      cesStoreData[this.questionnaire.name].userCancelled = true;
    } else {
      delete cesStoreData[this.questionnaire.name].userCancelled;
    }
  }

  private _setReminder(
    cesStoreData: { [questionnaire: string]: { [prop: string]: any } },
    remindOn: Date,
  ): void {
    cesStoreData[this.questionnaire.name].remindOn = remindOn;
    this._updateStore(cesStoreData);
  }

  private readCESStoreData(): any {
    return this._localStorageService.getStorage(CUSTOMER_EFFORT_SCORE) || {};
  }

  private _updateStore(cesStoreData: { [questionnaire: string]: { [prop: string]: any } }): void {
    this._localStorageService.setStorage(CUSTOMER_EFFORT_SCORE, cesStoreData);
  }
}
