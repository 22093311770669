import { Component, inject, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';

@Component({
  selector: 'app-confirm-exit-dialog',
  templateUrl: './confirm-exit-dialog.component.html',
  styleUrls: ['./confirm-exit-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule],
})
export class ConfirmExitDialogComponent {
  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    public dialogRef: MatDialogRef<ConfirmExitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: boolean,
  ) {}

  onCancelClick(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt-in-cancel-quit-clicked' });
    this.dialogRef.close();
  }
}
