import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthModel, UserInfoModel } from '../../domain/auth/auth.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class CompleteUserProfileUseCase implements UseCase<UserInfoModel, AuthModel> {
  constructor(private _authRepository: AuthRepository) {}

  execute(userInfo: UserInfoModel): Observable<AuthModel> {
    return this._authRepository.completeUserProfile(userInfo);
  }
}
