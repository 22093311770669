import events, { EventSubscription } from '@mongez/events';
import { GenericObject } from '@mongez/reinforcements';

export const mixPanelEvents = {
  trigger: (eventName: string, data?: GenericObject): void => {
    return events.trigger(`mixpanel`, {
      eventName,
      data,
    });
  },
  // Mixpanel Subscription MUST BE done in the root component or an entry point after the mixpanel service is initialized
  subscribe: (
    callback: ({ eventName, data }: { eventName: string; data?: GenericObject }) => void,
  ): EventSubscription => {
    return events.subscribe(`mixpanel`, callback);
  },
};

export function mixPanelEvent(eventName: string, data?: GenericObject): void {
  mixPanelEvents.trigger(eventName, data);
}
