import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartUpdateModel } from '../../domain/cart.model';
import { CartRepository } from '../../repositories/cart.repository';

export class UpdateCartUseCase implements UseCase<CartUpdateModel, void> {
  constructor(private _cartRepository: CartRepository) {}

  execute(params: CartUpdateModel): Observable<void> {
    return this._cartRepository.updateCart(params);
  }
}
