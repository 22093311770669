import { Injectable } from '@angular/core';
import { PreOrderBenefitsModel } from 'src/app/core/domain/bulk-pre-order/pre-order-benefits.model';

const PRE_ORDER_BENEFITS_DATA: PreOrderBenefitsModel[] = [
  {
    icon: 'assets/img/pre-order-banner/card1-icon.svg',
    title: 'PRE_ORDER_PAGE.BANNER.CARD1_TITLE',
    subTitle: 'PRE_ORDER_PAGE.BANNER.CARD1_SUBTITLE',
    image: 'assets/img/pre-order-banner/card1-img.svg',
    header: 'PRE_ORDER_PAGE.BANNER.CARD1_HEADER',
    description: 'PRE_ORDER_PAGE.BANNER.CARD1_DESCRIPTION',
  },
  {
    icon: 'assets/img/pre-order-banner/card2-icon.svg',
    title: 'PRE_ORDER_PAGE.BANNER.CARD2_TITLE',
    subTitle: 'PRE_ORDER_PAGE.BANNER.CARD2_SUBTITLE',
    image: 'assets/img/pre-order-banner/card2-img.svg',
    header: 'PRE_ORDER_PAGE.BANNER.CARD2_HEADER',
    description: 'PRE_ORDER_PAGE.BANNER.CARD2_DESCRIPTION',
  },
  {
    icon: 'assets/img/pre-order-banner/card3-icon.svg',
    title: 'PRE_ORDER_PAGE.BANNER.CARD3_TITLE',
    subTitle: 'PRE_ORDER_PAGE.BANNER.CARD3_SUBTITLE',
    image: 'assets/img/pre-order-banner/card3-img.svg',
    header: 'PRE_ORDER_PAGE.BANNER.CARD3_HEADER',
    description: 'PRE_ORDER_PAGE.BANNER.CARD3_DESCRIPTION',
  },
];

@Injectable({
  providedIn: 'root',
})
export class PreOrderBenefitsLocalDataSource {
  getPreOrderBenefitsData(): PreOrderBenefitsModel[] {
    return PRE_ORDER_BENEFITS_DATA;
  }
}
