import { Mapper } from 'src/app/core/base/mapper';
import { CommercialCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { CommercialCategoryEntity } from '../entities/commercial-category-entity';
import { CommercialSubCategoryRepositoryMapper } from './commercial-sub-category-repository.mapper';

export class CommercialCategoryRepositoryMapper extends Mapper<
  CommercialCategoryEntity,
  CommercialCategoryModel
> {
  mapFrom(param: CommercialCategoryEntity): CommercialCategoryModel {
    const commercialSubCategoryMapper = new CommercialSubCategoryRepositoryMapper();
    return {
      id: param.categoryId,
      name: {
        arabicName: param.arabicName,
        englishName: param.englishName,
      },
      sorting: param.sorting,
      country: param.country,
      icon: param.icon,
      featured: param.featured,
      children: param.children?.map(commercialSubCategoryMapper.mapFrom),
    };
  }

  mapTo(param: CommercialCategoryModel): CommercialCategoryEntity {
    const commercialSubCategoryMapper = new CommercialSubCategoryRepositoryMapper();
    return {
      categoryId: param.id,
      arabicName: param.name.arabicName,
      englishName: param.name.englishName,
      sorting: param.sorting,
      country: param.country,
      icon: param.icon,
      featured: param.featured,
      children: param.children?.map(commercialSubCategoryMapper.mapTo),
    };
  }
}
