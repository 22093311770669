import { type HttpClientContract } from '@features/drivers/domain';
import cache from '@mongez/cache';
import { http } from '../../../drivers/data';
import { MockupAdapter } from '../../../drivers/data/mockup';

export abstract class BaseRepository {
  /**
   * If the mockup options property is present and `adapter` property is called, then we will use the mockup adapter
   * otherwise, we will use the real http adapter
   */
  protected mockupOptions?: {
    data: any;
    delay?: number;
  };

  /**
   * Get http service instance
   */
  protected get http(): HttpClientContract {
    return http;
  }

  /**
   * Cache the result of the given callback function
   * If the cache is available, it will return the cached data
   * If first argument is a string, then it will be treated as cache key with indefinite ttl
   * If first argument is an array, it should have first value as cache key and second value as ttl
   */

  protected async cacheable<T>(
    key: string | [string, number],
    callback: () => Promise<T>,
  ): Promise<T> {
    let ttl: undefined | number;

    if (Array.isArray(key)) {
      [key, ttl] = key;
    }

    const cachedData = cache.get(key);

    if (cachedData) {
      return cachedData;
    }

    const data = await callback();

    await cache.set(key, data);

    return data;
  }

  /**
   * Get proper adapter
   */
  protected get adapter(): HttpClientContract {
    if (this.mockupOptions) return this.mockup;

    return this.http;
  }

  /**
   * Get mockup adapter
   */
  protected get mockup(): HttpClientContract {
    return new MockupAdapter(this.mockupOptions?.data, this.mockupOptions?.delay);
  }

  protected async mock<Data>({
    data,
    callback,
    enabled,
    delay = 1000,
  }: {
    data: Data;
    callback: () => Promise<Data>;
    enabled: boolean;
    delay?: number;
  }): Promise<Data> {
    if (!enabled) {
      return callback();
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, delay);
    });
  }
}
