<div class="verification">
  <img [src]="authURL + '/verify-account.svg'" alt="" class="verification__img" />
  <p class="verification__title heading2--bold">يرجى استكمال بيانات حسابك للتمكن من سحب ارباحك</p>
  <p class="verification__body body2--regular">
    لآمان اكثر، يمكنك فقط سحب ارباحك عند استكمال بيانات حسابك و تأكيد رقم الهاتف الخاص بك
  </p>
  <p class="verification__body body2--regular">لنبدأ عملية التحقق؟</p>
  <div class="verification__actions">
    <button
      class="verification__actions__button verification__actions__button--reject body1--medium"
      (click)="dismissOverlay()"
    >
      لاحقاً
    </button>
    <button
      (click)="onStartVerification()"
      class="verification__actions__button verification__actions__button--accept body1--regular"
    >
      نعم، لنبدأ
    </button>
  </div>
</div>
