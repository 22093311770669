import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopModel } from '../../domain/dukan-shop/dukan-shop.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class ReactivateDukanShopUseCase implements UseCase<void, DukanShopModel> {
  constructor(private _repository: DukanShopRepository) {}

  execute(): Observable<DukanShopModel> {
    return this._repository.reactivate();
  }
}
