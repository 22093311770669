import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';

@Component({
  selector: 'app-stores-navigation',
  templateUrl: './stores-navigation.component.html',
  styleUrls: ['./stores-navigation.component.scss'],
  standalone: true,

  imports: [RouterLink, AsyncPipe, NgIf],
})
export class StoresNavigationComponent {
  public assetsPath = 'assets/img/header-icons/';

  darkMode$: Observable<boolean>;

  constructor(
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _themeService: ThemeService,

    @Inject(appUrlsConstantsInjectionToken) public appURLs: { [url: string]: string },
  ) {
    this.darkMode$ = this._themeService.isDarkMode();
  }

  onStoresClick(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'click_stores_icon' });
  }
}
