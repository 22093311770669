<div
  class="sub-categories-list-container"
  *ngIf="commercialSubCategoriesList?.length"
  [dir]="isEnglishLanguage ? 'ltr' : 'rtl'"
>
  <a
    class="show-all-button body1--bold"
    [routerLink]="['/', 'products', 'category', hoveredCategoryId]"
  >
    {{ 'HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL' | translate }}
    <img loading="lazy" src="assets/img/{{ isEnglishLanguage ? 'right' : 'left' }}-chevron.svg"
  /></a>
  <div class="sub-categories-list">
    <div
      class="level-two-subcategory"
      *ngFor="let levelTwoSubCategoryNode of commercialSubCategoriesList"
    >
      <a
        class="level-two-subcategory__name body1--bold"
        [routerLink]="['/', 'products', 'category', levelTwoSubCategoryNode.key]"
      >
        {{
          isEnglishLanguage
            ? levelTwoSubCategoryNode.value.name.englishName
            : levelTwoSubCategoryNode.value.name.arabicName
        }}
      </a>
      <a
        *ngFor="let levelThreeSubCategoryNode of levelTwoSubCategoryNode.children"
        class="level-three-subcategory__name body2--medium"
        [routerLink]="['/', 'products', 'category', levelThreeSubCategoryNode.key]"
      >
        {{
          isEnglishLanguage
            ? levelThreeSubCategoryNode.value.name.englishName
            : levelThreeSubCategoryNode.value.name.arabicName
        }}
      </a>
    </div>
  </div>
</div>
