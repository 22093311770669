import { IdVerification } from '../../domain';
import { VerificationIdDataModel } from '../models';

export function mapKYCVerificationIdModelData(data: VerificationIdDataModel): IdVerification {
  return {
    isVerified: data.currentVerificationStatus.merchantIdVerified,
    isDuplicate: data.isDuplicateAccount,
    status: data.activeVerificationRequest?.status,
    rejectionReason: data.activeVerificationRequest?.rejectionReason,
  };
}
