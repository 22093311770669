import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CartProductUpdateModel } from '../../domain/cart.model';
import { CartRepository } from '../../repositories/cart.repository';

export class UpdateProductInCartUseCase
  implements UseCase<CartProductUpdateModel, Observable<void>>
{
  constructor(private _cartRepository: CartRepository) {}

  execute(params: CartProductUpdateModel): Observable<void> {
    return this._cartRepository.updateProductInCart(params);
  }
}
