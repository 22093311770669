export const ORDER_TYPE_TAAGER = 'Orders on Taager';
export const ORDER_TYPE_TIKTOK = 'TikTok Leads';
export const ORDER_LIST_TYPE = [
  { value: ORDER_TYPE_TAAGER, key: 'TITLE_ORDER', icon: 'assets/img/taager-orders.svg' },
  { value: ORDER_TYPE_TIKTOK, key: 'TITLE_TIKTOK', icon: 'assets/img/tiktok-leads.svg' },
];

export const DIRECT_FLOW_STATUSES_ARRAY = [
  'order_received',
  'confirmed',
  'pending_shipping_company',
  'delivery_in_progress',
  'delivered',
];

export const SUSPENDED_CANCELED_STATUSES_ARRAY = [
  'suspended',
  'customer_rejected',
  'cancel',
  'taager_cancelled',
  'delivery_suspended',
  'customer_refused',
  'order_addition_rejected',
  'order_replacement_rejected',
  'delayed',
];

export const CHILD_ORDERS_STATUSES_ARRAY = [
  'return_in_progress',
  'return_verified',
  'replacement_in_progress',
  'replacement_verified',
  'order_addition_inprogress',
  'order_addition',
  'refund_in_progress',
  'order_addition',
  'delayed',
];

export const ORDERS_FILTER_OPTIONS = [
  'order_received',
  'confirmed',
  'pending_shipping_company',
  'delayed',
  'delivery_in_progress',
  'delivered',
  'refund_in_progress',
  'refund_verified',
  'replacement_in_progress',
  'replacement_verified',
  'order_addition_inprogress',
  'order_addition',
  'delivery_suspended',
  'return_in_progress',
  'return_verified',
  'suspended',
  'taager_cancelled',
  'cancel',
];

export const CHILD_ORDERS_FILTER_OPTIONS = [
  'replacement_request_accepted',
  'order_addition_request_approved',
  'refund_request_accepted',
  'pending_shipping_company',
  'delivery_in_progress',
  'delivered',
  'item_received_in_inventory',
];

export const DELIVERY_SUSPENSION_REASONS_TRANSLATION: any = {
  // new mapping
  customer_refused: 'العميل رفض الاستلام',
  phone_busy_or_no_answer: 'العميل لا يرد أو المحمول مشغول',
  phone_unreachable: 'محمول العميل مغلق',
  rescheduled: 'العميل طلب التأجيل',
  customer_changed_address: 'العميل طلب تغير العنوان',
  wrong_address: 'العنوان خاطئ',
  wrong_phone_number: 'الرقم خاطئ',
  no_reason: 'لا يوجد حالياً',
  // old mapping
  // customer_refused: 'العميل رفض الاستلام',
  customer_didnot_order: 'العميل بيقول مطلبتش حاجة',
  customer_postponed_more_than_once: 'العميل اجل اكتر من مرة',
  customer_getting_away: 'العميل بيتهرب',
  customer_refused_to_pay_fully: 'العميل رفض دفع المبلغ كامل',
  customer_unreachable: 'المندوب لم يتمكن من الوصول للعميل',
  ordered_from_another_place: 'طلب من مكان اخر',
  customer_cancelled: 'العميل لاغي الاوردر',
  taager_cancelled: 'الغاء من التاجر',
  // wrong_address: 'العنوان غلط',
  outside_working_hours: 'الاستلام خرج موعيد العمل (من الحد الى الخميس من 11 ل 5)',
  trap: 'كمين للمندوب',
  customer_doesnot_answer: 'العميل لا يرد على المندوب',
  customer_mobile_switched_off: 'العميل موبايله مغلق دائما',
  wrong_mobile_number: 'رقم الموبايل خطأ',
  customer_refused_due_to_not_test: 'العميل رفض الاستلام بسبب عدم التجربة',
  customer_cancelled_due_to_later_order: 'العميل لغى للتأخير',
  customer_refused_due_to_product: 'العميل رفض بسبب المنتج',
  customer_purchased_from_another_place: 'العميل اشترى من مكان اخر',
  customer_getting_away_after_agreement: 'العميل بيتهرب بعد الاتفاق',
  customer_cancelled_due_to_travel: 'العميل لغى للسفر',
};

export const CHILD_ORDER_TYPE_MAP = new Map<string, string>([
  ['M', 'ORDERS_PAGE.ORDER_TYPES.PRODUCT_REPLACEMENT_TYPE'],
  ['R', 'ORDERS_PAGE.ORDER_TYPES.PRODUCT_RETURN_TYPE'],
  ['S', 'ORDERS_PAGE.ORDER_TYPES.PRODUCT_ADDITION_TYPE'],
]);

interface Status {
  statusInEnglish: string;
  statusTranslationKey: string;
  statusIcon: string;
  statusStage: string;
}

export const ORDER_RECEIVED_STATUS = 'order_received';

export const ALL_STATUSES: Status[] = [
  {
    statusStage: 'received',
    statusInEnglish: ORDER_RECEIVED_STATUS,
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_RECEIVED_STATUS',
    statusIcon: 'inbox',
  },
  {
    statusStage: 'received',
    statusInEnglish: 'merged',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.MERGED_STATUS',
    statusIcon: 'inbox',
  },
  {
    statusStage: 'confirmation',
    statusInEnglish: 'confirmed',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CONFIRMED_STATUS',
    statusIcon: 'checkmark',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'pending_shipping_company',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.PENDING_SHIPPING_COMPANY_STATUS',
    statusIcon: 'clock-half-dotted',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'delayed',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.DELAYED_STATUS',
    statusIcon: 'clock-half-dotted',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'delivery_in_progress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.DELIVERY_IN_PROGRESS_STATUS',
    statusIcon: 'fast-delivery',
  },
  {
    statusStage: 'delivered',
    statusInEnglish: 'delivered',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.DELIVERED_STATUS',
    statusIcon: 'shipped',
  },

  {
    statusStage: 'suspended',
    statusInEnglish: 'suspended',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.SUSPENDED_STATUS',
    statusIcon: 'pause',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'customer_rejected',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CUSTOMER_REJECTED_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'cancel',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CANCEL_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'delivery_cancelled',
    statusInEnglish: 'delivery_cancelled',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CANCEL_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'cancelled',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CANCELLED_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'taager_cancelled',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.TAAGER_CANCELLED_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'delivery_suspended',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.DELIVERY_SUSPENDED_STATUS',
    statusIcon: 'canceled',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'customer_refused',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.CUSTOMER_REFUSED_STATUS',
    statusIcon: 'canceled',
  },

  {
    statusStage: 'pending',
    statusInEnglish: 'return_in_progress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.RETURN_IN_PROGRESS_STATUS',
    statusIcon: 'refund-request',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'after_sales_in_progress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.AFTER_SALES_IN_PROGRESS_STATUS',
    statusIcon: 'delivery-truck',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'return_verified',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.RETURN_VERIFIED_STATUS',
    statusIcon: 'pause',
  },

  {
    statusStage: 'received',
    statusInEnglish: 'order_replacement_request',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_REPLACEMENT_REQUEST_STATUS',
    statusIcon: 'replacement-request',
  },
  {
    statusStage: 'received',
    statusInEnglish: 'replacement_request_accepted',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.REPLACEMENT_REQUEST_ACCEPTED_STATUS',
    statusIcon: 'replacement-request',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'replacement_in_progress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.REPLACEMENT_IN_PROGRESS_STATUS',
    statusIcon: 'replacement-inprogress',
  },
  {
    statusStage: 'delivered',
    statusInEnglish: 'replacement_verified',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.REPLACEMENT_VERIFIED_STATUS',
    statusIcon: 'checked',
  },
  {
    statusStage: 'received',
    statusInEnglish: 'order_replacement_rejected',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_REPLACEMENT_REJECTED_STATUS',
    statusIcon: 'canceled',
  },

  {
    statusStage: 'received',
    statusInEnglish: 'order_addition_request',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_ADDITION_REQUEST_STATUS',
    statusIcon: 'completion-request',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'order_addition_inprogress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_ADDITION_INPROGRESS_STATUS',
    statusIcon: 'completion-inprogress',
  },
  {
    statusStage: 'delivered',
    statusInEnglish: 'order_addition',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_ADDITION_STATUS',
    statusIcon: 'checked',
  },
  {
    statusStage: 'delivered',
    statusInEnglish: 'after_sales_finished',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.AFTER_SALES_FINISHED_STATUS',
    statusIcon: 'checked',
  },
  {
    statusStage: 'received',
    statusInEnglish: 'order_addition_rejected',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_ADDITION_REJECTED_STATUS',
    statusIcon: 'canceled',
  },

  {
    statusStage: 'received',
    statusInEnglish: 'order_refund_request',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ORDER_REFUND_REQUEST_STATUS',
    statusIcon: 'refund-request',
  },
  {
    statusStage: 'pending',
    statusInEnglish: 'refund_in_progress',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.REFUND_IN_PROGRESS_STATUS',
    statusIcon: 'refund-inprogress',
  },
  {
    statusStage: 'received',
    statusInEnglish: 'refund_verified',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.REFUND_VERIFIED_STATUS',
    statusIcon: 'checked',
  },

  {
    statusStage: 'received',
    statusInEnglish: 'item_received_in_inventory',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ITEM_RECEIVED_IN_INVENTORY_STATUS',
    statusIcon: 'checked',
  },
  {
    statusStage: 'cancelled',
    statusInEnglish: 'error',
    statusTranslationKey: 'ORDERS_PAGE.STATUS_LIST.ERROR_STATUS',
    statusIcon: 'canceled',
  },
];

export const ORDER_ERRORS: { description: string; translationKey: string }[] = [
  {
    description: 'One or more products is not available',
    translationKey: 'ERRORS.UNAVAILABLE_PRODUCT',
  },
  {
    description: 'spam behavior',
    translationKey: 'ERRORS.SPAMMER_BEHAVIOR',
  },
  {
    description: 'the order you have placed has a non-valid phone number',
    translationKey: 'ERRORS.INVALID_PHONE_NUMBER',
  },
  {
    description: 'below base price',
    translationKey: 'ERRORS.INVALID_PRICE',
  },
  {
    description: 'non-valid phone number',
    translationKey: 'ERRORS.WRONG_PHONE_NUMBER',
  },
  {
    description: 'expired or all the quantity has been exhausted for the merchant',
    translationKey: 'ERRORS.WRONG_QUANTITY',
  },
];

export const TAAGER_CANCELLED_STATUS = 'taager_cancelled';

export const SUSPENDED_STATUS = 'suspended';
