import { Mapper } from 'src/app/core/base/mapper';
import { ReferralsProgramModel } from 'src/app/core/domain/referrals-program.model';
import { ReferralsProgramEntity } from '../referrals-program.entity';
import { REFERRED_MERCHANT_PROFIT_STATUS_MAPPER } from './referred-merchant-profit-status.mapper';

export class ReferralProgramMapper extends Mapper<ReferralsProgramEntity, ReferralsProgramModel> {
  mapFrom(param: ReferralsProgramEntity): ReferralsProgramModel {
    return {
      totalProfit: param.totalProfit,
      pendingProfit: param.pendingProfit,
      registeredThroughYou: param.registeredThroughYou,
      achievedMerchantsCount: param.achievedMerchantsCount,
      firstTarget: param.firstTarget,
      secondTarget: param.secondTarget,
      referredMerchants: param.referredMerchants.map((data) => {
        return {
          ...data,
          profitStatus: REFERRED_MERCHANT_PROFIT_STATUS_MAPPER.get(data.profitStatus)!,
        };
      }),
    };
  }

  mapTo(param: ReferralsProgramModel): ReferralsProgramEntity {
    return {
      totalProfit: param.totalProfit,
      pendingProfit: param.pendingProfit,
      registeredThroughYou: param.registeredThroughYou,
      achievedMerchantsCount: param.achievedMerchantsCount,
      firstTarget: param.firstTarget,
      secondTarget: param.secondTarget,
      referredMerchants: param.referredMerchants,
    };
  }
}
