import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class BannerQuestionnaireAPIservice {
  constructor(private http: HttpClient) {}

  getQuestionnaireEligibility(questionnaireName: string): Observable<{ enabled: boolean }> {
    return this.http.get<{ enabled: boolean }>(
      API_URLS.GET_BANNER_QUESTIONNAIRE_ELIGIBILITY_URL(questionnaireName),
    );
  }

  postDeclineQuestionnaire(questionnaireName: string): Observable<void> {
    return this.http.post<void>(
      API_URLS.POST_BANNER_QUESTIONNAIRE_DECLINE_URL(questionnaireName),
      '',
    );
  }
}
