import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialUser } from 'angularx-social-login';
import { map, Observable } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import {
  AuthEntity,
  EmailOTPDataEntity,
  EmailOTPVerifyEntity,
  InitialSignupUserEntity,
  OTPDataEntity,
  OTPEntity,
  PhoneDataEntity,
  UserInfoEntity,
} from './entities/auth-entity';
import { ChangePasswordRequest } from './entities/change-password.entity';
import { ForgotPasswordRequest } from './entities/forgot-password.entity';
import { LoginRequest, LoginResponse } from './entities/login.entity';
import { ResetPasswordRequest } from './entities/reset-password.entity';
import { SocialUserEntity } from './entities/social-user-entity';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private _http: HttpClient) {}

  login(user: LoginRequest): Observable<LoginResponse> {
    return this._http.post<LoginResponse>(API_URLS.LOGIN_URL, user);
  }

  socialAuthSignIn(socialUser: SocialUser): Observable<SocialUserEntity> {
    return this._http
      .post<SocialUserEntity>(API_URLS.SOCIAL_AUTH_SIGN_IN_URL, socialUser, { observe: 'response' })
      .pipe(
        map((response: { body: any; status: number }) => ({
          user: response.body.user,
          data: response.body.data,
          userIsNew: response.status === 201,
        })),
      );
  }

  register(user: InitialSignupUserEntity): Observable<AuthEntity> {
    return this._http.post<AuthEntity>(API_URLS.REGISTER_URL, user);
  }

  requestOTP(phoneData: PhoneDataEntity): Observable<OTPDataEntity> {
    return this._http.patch<OTPDataEntity>(API_URLS.REQUEST_OTP_URL, phoneData);
  }

  verifyPhoneNumber(userOTP: OTPEntity): Observable<AuthEntity> {
    return this._http.post<AuthEntity>(API_URLS.VERIFY_PHONE_URL, userOTP);
  }

  requestEmailOTP(context: 'login' | 'withdrawal'): Observable<EmailOTPDataEntity> {
    const param = {
      selectedLanguage: 'ar',
      context,
    };
    return this._http.post<EmailOTPDataEntity>(API_URLS.REQUEST_EMAIL_OTP_URL, param);
  }

  verifyEmailOTP(userOTP: EmailOTPVerifyEntity): Observable<AuthEntity> {
    return this._http.post<AuthEntity>(API_URLS.VERIFY_EMAIL_URL, userOTP);
  }

  completeUserProfile(userInfo: UserInfoEntity): Observable<AuthEntity> {
    return this._http.patch<AuthEntity>(API_URLS.UPDATE_USER_PROFILE_V2_URL, userInfo);
  }

  forgotPassword(form: ForgotPasswordRequest): Observable<any> {
    return this._http.patch(API_URLS.FORGOT_PASSWORD_URL, form);
  }

  resetPassword(request: ResetPasswordRequest): Observable<any> {
    return this._http.patch(
      API_URLS.RESET_PASSWORD_URL(request.id, request.resetToken),
      request.form,
    );
  }

  changePassword(request: ChangePasswordRequest): Observable<any> {
    return this._http.patch(API_URLS.CHANGE_PASSWORD_URL, request);
  }
}
