import { Injectable } from '@angular/core';
import { ReferralStepsEntity } from './entities/referral-steps.entity';
import { ReferralsProgramEntity, TargetStatusType } from './entities/referrals-program.entity';

const REFERRAL_STEPS_DATA: ReferralStepsEntity[] = [
  {
    icon: 'assets/img/referral-program/step1.svg',
    stepOrder: 'REFERRAL_PROGRAM.STEPS.STEP1_ORDER',
    title: 'REFERRAL_PROGRAM.STEPS.STEP1_TITLE',
    text: 'REFERRAL_PROGRAM.STEPS.STEP1_TEXT',
  },
  {
    icon: 'assets/img/referral-program/step2.svg',
    stepOrder: 'REFERRAL_PROGRAM.STEPS.STEP2_ORDER',
    title: 'REFERRAL_PROGRAM.STEPS.STEP2_TITLE',
    text: 'REFERRAL_PROGRAM.STEPS.STEP2_TEXT',
  },
  {
    icon: 'assets/img/referral-program/step3.svg',
    stepOrder: 'REFERRAL_PROGRAM.STEPS.STEP3_ORDER',
    title: 'REFERRAL_PROGRAM.STEPS.STEP3_TITLE',
    text: 'REFERRAL_PROGRAM.STEPS.STEP3_TEXT',
  },
  {
    icon: 'assets/img/referral-program/step4.svg',
    stepOrder: 'REFERRAL_PROGRAM.STEPS.STEP4_ORDER',
    title: 'REFERRAL_PROGRAM.STEPS.STEP4_TITLE',
    text: 'REFERRAL_PROGRAM.STEPS.STEP4_TEXT',
  },
];

const REFERRALS_PROGRAM: ReferralsProgramEntity = {
  totalProfit: 1000,
  pendingProfit: 150,
  registeredThroughYou: 10,
  achievedMerchantsCount: 5,
  firstTarget: 5,
  secondTarget: 10,
  referredMerchants: [
    {
      taagerId: '12345',
      name: 'kafro',
      joinDate: '2024-01-03T00:00:00',
      dateOfCompletion: '2024-04-03T00:00:00',
      deliveredOrders: 2,
      dueProfit: 500,
      profitStatus: 'NoProfitDeserved',
      targetStatus: TargetStatusType.NOT_ACHIEVED,
    },
    {
      taagerId: '12345',
      name: 'kafroo',
      joinDate: '2024-02-03T00:00:00',
      dateOfCompletion: '2024-07-03T00:00:00',
      deliveredOrders: 4,
      dueProfit: 500,
      profitStatus: 'PendingAchievement',
      targetStatus: TargetStatusType.IN_PROGRESS,
    },
    {
      taagerId: '12345',
      name: 'kafrooo',
      joinDate: '2024-03-03T00:00:00',
      dateOfCompletion: '2024-08-03T00:00:00',
      deliveredOrders: 10,
      dueProfit: 500,
      profitStatus: 'ProfitInProgress',
      targetStatus: TargetStatusType.IN_PROGRESS,
    },
    {
      taagerId: '12345',
      name: 'kafroooo',
      joinDate: '2024-04-03T00:00:00',
      dateOfCompletion: '2024-02-03T00:00:00',
      deliveredOrders: 15,
      dueProfit: 500,
      profitStatus: 'ProfitReceived',
      targetStatus: TargetStatusType.ACHIEVED,
    },
    {
      taagerId: '12345',
      name: 'kafroooo',
      joinDate: '2024-04-03T00:00:00',
      dateOfCompletion: '2024-02-03T00:00:00',
      deliveredOrders: 15,
      dueProfit: 500,
      profitStatus: 'ProfitReceived',
      targetStatus: TargetStatusType.ACHIEVED,
    },
    {
      taagerId: '12345',
      name: 'kafroooo',
      joinDate: '2024-04-03T00:00:00',
      dateOfCompletion: '2024-02-03T00:00:00',
      deliveredOrders: 15,
      dueProfit: 500,
      profitStatus: 'ProfitReceived',
      targetStatus: TargetStatusType.ACHIEVED,
    },
    {
      taagerId: '12345',
      name: 'kafroooo',
      joinDate: '2024-04-03T00:00:00',
      dateOfCompletion: '2024-02-03T00:00:00',
      deliveredOrders: 15,
      dueProfit: 500,
      profitStatus: 'ProfitReceived',
      targetStatus: TargetStatusType.ACHIEVED,
    },
    {
      taagerId: '12345',
      name: 'kafroooo',
      joinDate: '2024-04-03T00:00:00',
      dateOfCompletion: '2024-02-03T00:00:00',
      deliveredOrders: 15,
      dueProfit: 500,
      profitStatus: 'ProfitReceived',
      targetStatus: TargetStatusType.ACHIEVED,
    },
  ],
};

@Injectable({
  providedIn: 'root',
})
export class ReferralStepsDataSource {
  getReferralSteps(): ReferralStepsEntity[] {
    return REFERRAL_STEPS_DATA;
  }

  getReferralsProgram(): ReferralsProgramEntity {
    return REFERRALS_PROGRAM;
  }
}
