import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environment';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { OnboardingGettingStartedDialogComponent } from 'src/app/presentation/onboarding/components/onboarding-getting-started-dialog/onboarding-getting-started-dialog.component';
import { OnboardingLastStepDialogComponent } from 'src/app/presentation/onboarding/components/onboarding-last-step-dialog/onboarding-last-step-dialog.component';
import { CountryNotCoveredDialogComponent } from 'src/app/presentation/user-verification/components/country-not-covered-dialog/country-not-covered-dialog.component';
import { VerificationSucceededDialogComponent } from 'src/app/presentation/user-verification/components/verification-succeeded-dialog/verification-succeeded-dialog.component';
import { OVERLAY_TYPES } from '../../constants';
import { LocalStorageService } from '../../services/local-storage.service';
import { SharedOverlayService } from '../../services/shared-overlay.service';
import { VerificationDialogComponent } from '../verification-dialog/verification-dialog.component';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class OverlayComponent implements OnInit {
  @ViewChild('outlet', { read: ViewContainerRef })
  private _outlet: ViewContainerRef;

  public showOverlay: boolean;

  private _dialogComponentRef: ComponentRef<any>;

  public showDismiss: boolean;

  public assetsPath: string;

  public injectableOverlayType: OVERLAY_TYPES;

  constructor(
    private _sharedOverlayService: SharedOverlayService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.showOverlay = false;
    this.showDismiss = false;
    this.assetsPath = '/assets/img/';
  }

  ngOnInit(): void {
    this._sharedOverlayService.getOverlayType().subscribe({
      next: (overlayType) => {
        if (overlayType === 'noOverlay') {
          this._clearOverlayContent();
        } else {
          this.injectableOverlayType = overlayType;
          this._doInjectOverlayContent(overlayType);
        }
      },
    });
  }

  public dismissOverlay(): void {
    this._sharedOverlayService.setOverlayType('noOverlay');
    this.showOverlay = false;
    this._clearOverlayContent();
  }

  private _doInjectOverlayContent(overlayType: OVERLAY_TYPES): void {
    // Disable onboarding getting started in dev environment
    if (overlayType === 'onboardingGettingStarted' && environment.production === false) return;

    this.showOverlay = true;
    this._changeDetectorRef.detectChanges();
    if (this._dialogComponentRef) {
      this._dialogComponentRef.destroy();
    }

    switch (overlayType) {
      case 'pleaseVerify':
        this._doInjectPleaseVerifyComponentAndListenForEvents();
        break;
      case 'success':
        this._doInjectSuccessComponentAndListenForEvents();
        break;
      case 'optInCountryNotCovered':
        this._doInjectCountryNotCoveredAndListenForEvents();
        break;
      case 'onboardingGettingStarted':
        this._doInjectOnboardingGettingStartedAndListenForEvents();
        break;
      case 'lastOnboardingStep':
        this._doInjectLastOnboardingStepAndListenForEvents();
        break;
    }
  }

  private _clearOverlayContent(): void {
    if (this._outlet) {
      this._outlet.clear();
    }
    if (this._dialogComponentRef) {
      this._dialogComponentRef.destroy();
    }
  }

  /**
   * TODO: Inject please verify component if there is time
   *
   * DONE: Only remaining with the testing on:
   *
   * 1. Wallet.
   * 2. Login: not sure why we have the dialog in login component.
   * 3. On the landing page of the app.
   */
  private _doInjectPleaseVerifyComponentAndListenForEvents(): void {
    this._dialogComponentRef = this._outlet.createComponent(VerificationDialogComponent);
    this._dialogComponentRef.changeDetectorRef.detectChanges();
    this._dialogComponentRef.instance.dismissOverlay$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
        }
      },
    });
    this._dialogComponentRef.instance.onStartVerification$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
          this._router.navigate([this._appURLs.USER_VERIFICATION_URL]);
        }
      },
    });
  }

  /**
   * TODO:
   *
   * On clicking the dismiss button action fron the injected component, redirect the user
   * to the page that they were at before. How do we get this page?
   *
   * DONE: Save the route URL before navigating to the user-verifcation page, and read this, then
   * clear this after the user has successfully verified their information.
   */
  private _doInjectSuccessComponentAndListenForEvents(): void {
    this._dialogComponentRef = this._outlet.createComponent(VerificationSucceededDialogComponent);
    this._dialogComponentRef.instance.dismissOverlay$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
          this._optionallyRedirectUserToPreviousScreenOrProducts();
        }
      },
    });
  }

  private _doInjectCountryNotCoveredAndListenForEvents(): void {
    this._dialogComponentRef = this._outlet.createComponent(CountryNotCoveredDialogComponent);
    this._dialogComponentRef.instance.dismissOverlay$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
          this._optionallyRedirectUserToPreviousScreenOrProducts();
        }
      },
    });
  }

  private _doInjectOnboardingGettingStartedAndListenForEvents(): void {
    this._dialogComponentRef = this._outlet.createComponent(
      OnboardingGettingStartedDialogComponent,
    );
    this._dialogComponentRef.instance.dismissOverlay$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
        }
      },
    });
  }

  private _doInjectLastOnboardingStepAndListenForEvents(): void {
    this._dialogComponentRef = this._outlet.createComponent(OnboardingLastStepDialogComponent);
    this._dialogComponentRef.instance.dismissOverlay$.subscribe({
      next: (status: any) => {
        if (status) {
          this.dismissOverlay();
        }
      },
    });
  }

  /**
   * This method, will optionally redirect a user to a path that they landed the application on.
   *
   * For example, if they wanted to access products, and then they chose to complete their profile
   * verification, then once they finish their verification, they will be redirected to the
   * products page.
   *
   * Then after the user has been redirected, we clear the storage the value of redirectUrl.
   */
  private _optionallyRedirectUserToPreviousScreenOrProducts(): void {
    const storageRedirectURLKey = 'redirectUrl';
    const redirectTo = this._localStorageService.getStorage(storageRedirectURLKey);
    if (redirectTo) {
      this._router.navigate([redirectTo]);
      this._localStorageService.remove(storageRedirectURLKey);
    } else {
      this._router.navigate([this._appURLs.PRODUCTS_V2_URL]);
    }
  }
}
