import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE_PREFIX, USER } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class CustomerIoService {
  public snippetInitialized = false;

  private renderer: Renderer2;

  constructor(
    private _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    this.renderer = this._rendererFactory.createRenderer(null, null);
  }

  loadWidget() {
    const scriptId = 'customer-io-snippet';
    if (!this._document.getElementById(scriptId)) {
      const script = this.renderer.createElement('script');
      script.id = scriptId;
      script.type = 'text/javascript';
      const code = `
          var _cio = _cio || [];
          (function () {
            if (!_cio || _cio.length === 0) {
              var a, b, c;
              a = function (f) {
                return function () {
                  _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
                };
              };
              b = ['load', 'identify', 'sidentify', 'track', 'page'];
              for (c = 0; c < b.length; c++) {
                _cio[b[c]] = a(b[c]);
              }
              var t = document.createElement('script'),
                s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id = 'cio-tracker';
              t.setAttribute('data-site-id', '${environment.CUSTOMER_IO_SITE_ID}');
              t.setAttribute('data-use-array-params', 'true');
              t.setAttribute('data-use-in-app', 'true');
              t.src = 'https://assets.customer.io/assets/track-eu.js';
              s.parentNode.insertBefore(t, s);
              var storedUser = JSON.parse(localStorage.getItem('${LOCAL_STORAGE_PREFIX}.${USER}'));
              if(storedUser) {
                _cio.identify({
                  email: storedUser.email,
                  id: storedUser.taagerId,
                  created_at: storedUser.createdAt,
                  first_name: storedUser.firstName,
                  last_name: storedUser.lastName,
                });
              }
            }
          })();
          `;
      script.appendChild(this._document.createTextNode(code));
      this.renderer.appendChild(this._document.body, script);
    }
  }
}
