/* eslint-disable guard-for-in */
import { BaseRepository } from '@features/shared';
import {
  PreOrderExternalProductRequestPayload,
  PreOrderExternalProductsListParams,
  PreOrderInternalProductsListParams,
  PreOrderListParams,
  PreOrderProductsListData,
  PreOrderRepositoryContract,
  PreOrderRequestPayload,
  PreOrderRequestsListData,
} from '../../domain';
import { preOrderAPI } from '../constants';
import { mapPreOrderProductsListData } from '../mappers';
import { PreOrderProductsListModel } from '../models/pre-order-products-list-data-model';

export class PreOrderRepository extends BaseRepository implements PreOrderRepositoryContract {
  public async list(params: PreOrderListParams): Promise<PreOrderRequestsListData> {
    const response = await this.http.get<PreOrderRequestsListData>(preOrderAPI.listPreOrders, {
      params,
    });

    return response.data;
  }

  public async listProducts(
    params?: PreOrderInternalProductsListParams,
  ): Promise<PreOrderProductsListData> {
    const response = await this.http.get<PreOrderProductsListModel>(preOrderAPI.preOrderProducts, {
      params: {
        ...params,
        productSource: 'INTERNAL',
      },
    });

    return mapPreOrderProductsListData(response.data);
  }

  public async listExternalProducts(
    params?: PreOrderExternalProductsListParams,
  ): Promise<PreOrderProductsListData> {
    const response = await this.http.get<PreOrderProductsListModel>(preOrderAPI.preOrderProducts, {
      params: {
        ...params,
        productSource: 'EXTERNAL',
      },
    });

    return mapPreOrderProductsListData(response.data);
  }

  public async createPreOrderRequest(data: PreOrderRequestPayload): Promise<void> {
    await this.http.post<PreOrderRequestPayload, void>(preOrderAPI.preOrderRequests, data);
  }

  public async createExternalProductPreOrderRequest(
    data: PreOrderExternalProductRequestPayload,
  ): Promise<void> {
    await this.http.post<PreOrderExternalProductRequestPayload, void>(
      preOrderAPI.externalProductRequest,
      data,
    );
  }
}
