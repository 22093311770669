<loader [loading]="viewState.loading" class="loader"></loader>
<div class="product-store-container">
  <p class="heading2--bold title">{{ 'PRODUCT_CHECKOUT_STORE.TITLE' | translate }}</p>
  <p class="sub-text">
    {{ 'PRODUCT_CHECKOUT_STORE.SUB_TEXT' | translate }}
  </p>
  <div class="logo-container">
    <p>{{ 'PRODUCT_CHECKOUT_STORE.LOGO' | translate }}</p>
    <div class="avatar-upload">
      <div class="avatar-edit">
        <input
          type="file"
          id="imageUpload"
          accept=".png, .jpg, .jpeg"
          #fileInput
          (change)="uploadFile($event)"
        />
      </div>
      <div class="avatar">
        <div
          class="avatar-preview"
          (click)="fileInput.click()"
          [ngClass]="!viewState.showInitialImage ? '' : 'placeholder'"
        >
          <div ngClass="imagePreview" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
        </div>
        <img
          *ngIf="!viewState.showInitialImage"
          src="assets/img/customer-effort-support/cancel-filling-questionnaire.svg"
          class="delete-icon"
          (click)="removeUpload()"
        />
      </div>

      <p class="info caption2--regular">PNG, JPEG, JPG - Less than 5 MB</p>
    </div>
    <div class="store-field">
      <label>{{ 'PRODUCT_CHECKOUT_STORE.STORE_NAME' | translate }}</label>
      <input type="text" [(ngModel)]="viewState.storeName" />
    </div>
    <div class="btn-container">
      <button
        class="action-btn primary-btn"
        (click)="presenter.emitViewEvent({ type: 'ClickSaveChanges' })"
      >
        {{ 'PRODUCT_CHECKOUT_STORE.APPLY' | translate }}
      </button>
      <button
        class="action-btn ghost-btn"
        (click)="presenter.emitViewEvent({ type: 'ClickDiscard' })"
      >
        {{ 'PRODUCT_CHECKOUT_STORE.DISCARD' | translate }}
      </button>
    </div>
  </div>
</div>
