import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { CreateWithdrawalRequestModel } from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

/**
 * @deprecated Use CreateSecuredWithdrawalRequestUseCase to require OTP during withdrawal request creation
 */
export class CreateWithdrawalRequestUseCase implements UseCase<CreateWithdrawalRequestModel, void> {
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: CreateWithdrawalRequestModel): Observable<void> {
    return this._walletRepository.createWithdrawalRequest(params);
  }
}
