export const lockRequestAPI = {
  PRODUCT_REQUESTS: '/locking-requests',
  FULFILMENT_RATES: (taagerId: number) => `/users/${taagerId}/rates`,
};

export const lockingRequestTrackEvents = {
  REQUEST_LOCKING: 'product_locking_request',
  TRACK_REQUEST: 'track_locking_request',
  EXPLORE_LOCK_QUANTITIES: 'track_marketplace_explore',
  FULFILMENT_RATE_DETAILS: 'track_fulfilment_rate_details',
};

export type LockRequestTypes = 'LOCK_CREATION' | 'LOCK_EXTENSION';
