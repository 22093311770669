import { Observable, of } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthRepository } from '../../repositories/auth.repository';

export class SetRegisterTokenUseCase implements UseCase<string, void> {
  constructor(private _authRepository: AuthRepository) {}

  execute(params: string): Observable<void> {
    return of(this._authRepository.setCurrentRegisterToken(params));
  }
}
