import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ProductCheckoutSideEffect } from './product-checkout.sideEffects';
import { ProductCheckoutViewEvents } from './product-checkout.viewEvents';
import { ProductCheckoutViewState } from './product-checkout.viewState';

@Injectable()
export class ProductCheckoutPresenter extends BasePresenter<
  ProductCheckoutViewState,
  ProductCheckoutViewEvents,
  ProductCheckoutSideEffect
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): ProductCheckoutViewState {
    return {
      loading: true,
      showInitialImage: true,
    };
  }

  protected onViewEvent(event: ProductCheckoutViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.viewState.loading = false;
        // Todo: load store data
        break;
      }

      case 'ClickSaveChanges': {
        this._logMixpanelEventUseCase.execute({
          eventName: 'checkout_store_save_changes',
        });
        this.emitSideEffect({ type: 'SaveChanges' });
        break;
      }

      case 'ClickDiscard': {
        this.emitSideEffect({ type: 'Discard' });
        break;
      }
    }
  }
}
