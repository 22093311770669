import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { queryString } from '@presentation/shared/router';
import { Observable } from 'rxjs';
import { PRODUCTS_V2_URL, YOUCAN_PROVIDER_NAME } from '../../../constants';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree } from './shared';

/**
 * So, what decides if a user can have access to youcan integrations.
 *
 * - must be logged in
 *      - else send them to login
 * - must be fully verified.
 *      - else send them to auth page
 * - must not be a candidate for onboarding.
 *      - else send them to onboarding questionnaire
 * - feature flag to access youcan integration must be on.
 *      - else send them to products page
 */
export const youcanIntegrationRedirectionResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);

  return new Observable((subscriber) => {
    const code = queryString.get('code');
    if (!code) {
      subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
    } else {
      subscriber.next(
        stringToUrlTree(`stores?provider=${YOUCAN_PROVIDER_NAME}&code=${code}`, router),
      );
    }
  });
};
