import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { BulkPreOrderRequestModel } from '../../domain/bulk-pre-order/bulk-pre-order-request.model';
import { PreOrderRequestModel } from '../../domain/bulk-pre-order/pre-order-request.model';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class GetBulkPreOrderRequestsUseCase implements UseCase<BulkPreOrderRequestModel, any> {
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(): Observable<PreOrderRequestModel[]> {
    return this._bulkPreOrderRepository.getPreOrderRequests();
  }
}
