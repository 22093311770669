import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DynamicIncentiveProgramModel } from '../../domain/dynamic-incentive-program.model';
import { DynamicIncentiveProgramRepository } from '../../repositories/dynamic-incentive-program.repository';

export class AcceptDynamicIncentiveProgramUseCase
  implements UseCase<string, DynamicIncentiveProgramModel>
{
  constructor(private _repository: DynamicIncentiveProgramRepository) {}

  execute(id: string): Observable<DynamicIncentiveProgramModel> {
    return this._repository.acceptDynamicIncentiveProgram(id);
  }
}
