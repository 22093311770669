import { UseCase } from '../../base/use-case';
import { ResetPasswordModel } from '../../domain/auth/reset-password.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class ResetPasswordUseCase implements UseCase<ResetPasswordModel, void> {
  constructor(private _authRepository: AuthRepository) {}

  execute(params: ResetPasswordModel) {
    return this._authRepository.resetPassword(params);
  }
}
