import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductReviewModel } from '../../domain/variant-group.model';
import { ProductsRepository } from '../../repositories/products.repository';

export class SubmitProductReviewUseCase
  implements UseCase<{ data: ProductReviewModel; productId: string }, void>
{
  constructor(private productsRepository: ProductsRepository) {}

  execute(params: { data: ProductReviewModel; productId: string }): Observable<void> {
    return this.productsRepository.setProductReview(params);
  }
}
