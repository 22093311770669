import { Observable } from 'rxjs';
import { CommercialCategoryTree } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { BulkPreOrderRequestModel } from '../domain/bulk-pre-order/bulk-pre-order-request.model';
import { PreOrderBenefitsModel } from '../domain/bulk-pre-order/pre-order-benefits.model';
import { PreOrderRequestsSummaryModel } from '../domain/bulk-pre-order/pre-order-request-summary.model';
import { PreOrderRequestModel } from '../domain/bulk-pre-order/pre-order-request.model';

export abstract class BulkPreOrderRepository {
  abstract createPreOrderRequest(request: BulkPreOrderRequestModel): Observable<void>;
  abstract getPreOrderRequests(): Observable<PreOrderRequestModel[]>;
  abstract acceptPreOrderDownPayment(requestId: string): Observable<void>;
  abstract rejectPreOrderDownPayment(requestId: string): Observable<void>;
  abstract getProOrderBenefitsData(): Observable<PreOrderBenefitsModel[]>;
  abstract getPreOrderRequestsSummary(): Observable<PreOrderRequestsSummaryModel>;
  abstract getPreOrderCommercialCategories(countryCode: string): Observable<CommercialCategoryTree>;
}
