import { Mapper } from 'src/app/core/base/mapper';
import { TopicModel } from 'src/app/core/domain/learning-center.model';
import { TopicEntity } from '../entities/learning-center.entity';

export class LearningCenterMapper extends Mapper<TopicEntity, TopicModel> {
  mapFrom(param: TopicEntity): TopicModel {
    return {
      id: param.id,
      name: param.name,
      iconUrl: param.iconUrl,
      subTopics: param.subTopics,
      level: param.level,
    };
  }

  mapTo(param: TopicModel): TopicEntity {
    return {
      id: param.id,
      name: param.name,
      iconUrl: param.iconUrl,
      subTopics: param.subTopics,
      level: param.level,
    };
  }
}
