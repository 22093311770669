import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { IsFeatureEnabledUseCase } from 'src/app/core/usecases/market-availability/is-feature-enabled.usecase';
import { CS_URL } from '../../../constants';
import { CUSTOMER_IO_FEATURE, FRESH_DESK_CHAT } from '../../../constants/feature-flags';
import { CustomerIoService } from '../../../services/customer-io.service';
import { InfoHeaderComponent } from './info-header/info-header.component';
import { MainHeaderComponent } from './main-header/main-header.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [InfoHeaderComponent, MainHeaderComponent, MatDialogModule, TranslateModule, NgIf],
})
export class HeaderComponent implements OnInit {
  public assetsPath = 'assets/img/';

  public cs_url = CS_URL;

  public isMessageShown = false;

  public shouldShowMessage = false;

  constructor(
    private _customerIoService: CustomerIoService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
  ) {}

  ngOnInit(): void {
    this._checkIfFreshDeskEnabled();
    this._getFeatureFlagUseCase
      .execute(CUSTOMER_IO_FEATURE)
      .pipe(
        map((flag) => {
          if (flag) {
            this._customerIoService.loadWidget();
          }
        }),
      )
      .subscribe();
  }

  toggleMessage(): void {
    this.isMessageShown = !this.isMessageShown;
  }

  private _checkIfFreshDeskEnabled(): void {
    combineLatest([
      this._isFeatureEnabledUseCase.execute('chat'),
      this._getFeatureFlagUseCase.execute(FRESH_DESK_CHAT),
    ]).subscribe({
      next: ([isChatEnabled, flag]) => {
        this.shouldShowMessage = !flag && isChatEnabled;
      },
    });
  }
}
