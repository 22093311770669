import { Injectable } from '@angular/core';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { USER } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from '../../../../presentation/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserCache {
  constructor(private _localStorageService: LocalStorageService) {}

  get(): UserModel {
    return this._localStorageService.getStorage(USER);
  }

  put(user: UserModel) {
    this._localStorageService.setStorage(USER, user);
  }
}
