import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { PreOrderBenefitsModel } from '../../domain/bulk-pre-order/pre-order-benefits.model';
import { BulkPreOrderRepository } from '../../repositories/bulk-pre-order.repository';

export class GetPreOrderBenefitsUseCase implements UseCase<void, PreOrderBenefitsModel[]> {
  constructor(private _bulkPreOrderRepository: BulkPreOrderRepository) {}

  execute(): Observable<PreOrderBenefitsModel[]> {
    return this._bulkPreOrderRepository.getProOrderBenefitsData();
  }
}
