import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DukanProductStatus } from 'src/app/core/domain/dukan-shop/dukan-product.model';
import { AdsIntegration } from 'src/app/core/domain/dukan-shop/dukan-shop.model';
import { DUKAN_SHOP_APIS_ENDPOINTS } from './dukan-shop-apis-endpoints';
import { DukanProduct, DukanUpdateProductEntity } from './entities/dukan-product.entity';
import { DukanShopEntity } from './entities/dukan-shop.entity';

@Injectable({
  providedIn: 'root',
})
export class DukanShopApisService {
  constructor(private _http: HttpClient) {}

  createShop(name: string, slug: string): Observable<DukanShopEntity> {
    return this._http.post<DukanShopEntity>(DUKAN_SHOP_APIS_ENDPOINTS.DUKAN_URL, {
      name,
      slug,
    });
  }

  getShop(): Observable<DukanShopEntity> {
    return this._http.get<DukanShopEntity>(DUKAN_SHOP_APIS_ENDPOINTS.DUKAN_URL);
  }

  editShop(params: {
    state?: string;
    slug?: string;
    name?: string;
    email?: string;
    phoneNumber?: string;
    integrations?: AdsIntegration;
    hasEmbeddedCheckoutEnabled?: boolean;
  }): Observable<DukanShopEntity> {
    return this._http.put<DukanShopEntity>(DUKAN_SHOP_APIS_ENDPOINTS.DUKAN_URL, params);
  }

  getProducts(): Observable<DukanProduct[]> {
    return this._http
      .get<{ products: DukanProduct[] }>(DUKAN_SHOP_APIS_ENDPOINTS.PRODUCTS)
      .pipe(map((data) => data.products));
  }

  setProducts(products: { sku: string; status: DukanProductStatus }[]): Observable<void> {
    return this._http.put<void>(DUKAN_SHOP_APIS_ENDPOINTS.PRODUCTS, { products });
  }

  pushProduct(sku: string): Observable<void> {
    return this._http.post<void>(DUKAN_SHOP_APIS_ENDPOINTS.PRODUCT(sku), {});
  }

  deleteProduct(sku: string): Observable<void> {
    return this._http.delete<void>(DUKAN_SHOP_APIS_ENDPOINTS.PRODUCT(sku));
  }

  deleteProductMedia(sku: string, url: string): Observable<void> {
    return this._http.delete<void>(DUKAN_SHOP_APIS_ENDPOINTS.DELETE_PRODUCT_MEDIA(sku, url));
  }

  addProductMedia(sku: string, media: File): Observable<{ imageUrl: string }> {
    return this._http.post<{ imageUrl: string }>(
      DUKAN_SHOP_APIS_ENDPOINTS.ADD_PRODUCT_MEDIA(sku),
      media,
    );
  }

  updateProductDetails(product: DukanUpdateProductEntity, sku: string): Observable<void> {
    return this._http.put<void>(DUKAN_SHOP_APIS_ENDPOINTS.PRODUCT(sku), product);
  }
}
