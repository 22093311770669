import { Injectable } from '@angular/core';
import { combineLatestWith, Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { Learning, LearningCourse } from 'src/app/core/domain/learning.model';
import { LearningRepository } from '../../../core/repositories/learning.repository';
import {
  LearningCourseLocalEntity,
  LearningLocalEntity,
} from './local/entities/learning-local-entity';
import { LearningCache } from './local/learning-cache';
import { LearningMapper } from './mappers/learning-mapper';
import { LearningApi } from './remote/learning-api';

@Injectable({
  providedIn: 'root',
})
export class LearningRepositoryImpl implements LearningRepository {
  constructor(
    private _api: LearningApi,
    private _cache: LearningCache,
    private _mapper: LearningMapper,
  ) {}

  getLearning(): Observable<Learning> {
    return this._cache.get().pipe(
      combineLatestWith(this._api.getLearning()),
      map(([local, remote]) =>
        this._mapper.mapFrom({
          remote,
          local,
        }),
      ),
    );
  }

  getCourseById(courseId: string): Observable<LearningCourse | undefined> {
    return this.getLearning().pipe(
      map((learning) => learning.courses.find((course) => course.id === courseId)),
    );
  }

  markLessonAsCompleted(courseId: string, lessonId: string): Observable<boolean> {
    return this._cache.get().pipe(
      take(1), // We are only interested in the latest value
      map((learning) => {
        if (learning == null) {
          this.onLearningCacheNotFound(courseId, lessonId);
        } else {
          const course = learning.courses.find((c) => c.id === courseId);
          if (course == null) {
            learning = this.onCourseCacheNotFound(learning, courseId, lessonId);
          } else {
            const lesson = course.lessons.find((l) => l.id === lessonId);
            if (lesson == null) {
              this.onLessonCacheNotFound(course, lessonId);
            } else {
              lesson.isCompleted = true;
            }
          }
          this._cache.put(learning);
        }
        return true;
      }),
    );
  }

  private onLearningCacheNotFound(courseId: string, lessonId: string): void {
    const learning = {
      courses: [{ id: courseId, lessons: [{ id: lessonId, isCompleted: true }] }],
    };
    this._cache.put(learning);
  }

  private onCourseCacheNotFound(
    learning: LearningLocalEntity,
    courseId: string,
    lessonId: string,
  ): LearningLocalEntity {
    const course = {
      id: courseId,
      lessons: [{ id: lessonId, isCompleted: true }],
    };
    learning.courses.push(course);
    return learning;
  }

  private onLessonCacheNotFound(
    course: LearningCourseLocalEntity,
    lessonId: string,
  ): LearningCourseLocalEntity {
    const lesson = {
      id: lessonId,
      isCompleted: true,
    };
    course.lessons.push(lesson);
    return course;
  }
}
