import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductCheckoutUpdateModel } from '../../domain/products/product-checkout';
import { ProductsRepository } from '../../repositories/products.repository';

export class UpdateProductCheckoutUseCase implements UseCase<ProductCheckoutUpdateModel, void> {
  constructor(private productsRepository: ProductsRepository) {}

  execute(data: ProductCheckoutUpdateModel): Observable<void> {
    return this.productsRepository.updateProductCheckout(data);
  }
}
