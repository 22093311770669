import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsightsType, MerchantInsightsModel } from 'src/app/core/domain/merchant-insights.model';
import { MerchantInsightsRepository } from 'src/app/core/repositories/merchant-insights.repository';
import { MerchantInsightsApisService } from './merchant-insights-api.services';

@Injectable({
  providedIn: 'root',
})
export class MerchantInsightsRepositoryImplementation implements MerchantInsightsRepository {
  constructor(private _merchantInsightsApisService: MerchantInsightsApisService) {}

  getInsightsUrl(type: InsightsType, id: number): Observable<{ url: string }> {
    return this._merchantInsightsApisService.getInsightsUrl(type, id);
  }

  getInsightsData(): Observable<MerchantInsightsModel[]> {
    return this._merchantInsightsApisService.getInsightsData();
  }
}
