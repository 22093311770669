import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class GetProductVATUseCase
  implements
    UseCase<
      {
        countryIsoCode3: string;
        productId: string;
        productSellingPrice: number;
        quantity: number;
        isOnSale?: boolean;
      },
      Observable<number>
    >
{
  constructor(private _productsRepository: ProductsRepository) {}

  execute(params: {
    countryIsoCode3: string;
    productId: string;
    productSellingPrice: number;
    quantity: number;
    isOnSale: boolean;
  }): Observable<number> {
    return this._productsRepository.getProductVAT(params);
  }
}
