import { Injectable } from '@angular/core';
import { Mapper } from '../../../../core/base/mapper';
import { LearningCourse, LearningLesson } from '../../../../core/domain/learning.model';
import { LocalizableStringEntityMapper } from '../../../api/mappers/localizable-string-entity-mapper';
import { LearningCourseLocalEntity } from '../local/entities/learning-local-entity';
import { LearningCourseRemoteEntity } from '../remote/entities/learning-remote-entity';
import { LearningLessonMapper } from './learning-lesson-mapper';

type Params = { remote: LearningCourseRemoteEntity; local?: LearningCourseLocalEntity };

const areAllLessonsCompleted = (lessons: Array<LearningLesson>): boolean =>
  lessons.every((lesson) => lesson.isCompleted);

@Injectable({
  providedIn: 'root',
})
export class LearningCourseMapper extends Mapper<Params, LearningCourse> {
  constructor(
    private _localizableMapper: LocalizableStringEntityMapper,
    private _lessonMapper: LearningLessonMapper,
  ) {
    super();
  }

  mapFrom(params: Params): LearningCourse {
    const lessons = this.mapLessons(params);
    return {
      id: params.remote.id,
      name: this._localizableMapper.map(params.remote.name),
      iconUrl: params.remote.iconUrl,
      description: this._localizableMapper.map(params.remote.description),
      lessons,
      isCompleted: areAllLessonsCompleted(lessons),
    };
  }

  private mapLessons(params: Params): Array<LearningLesson> {
    return params.remote.lessons.map((lesson) =>
      this._lessonMapper.mapFrom({
        remote: lesson,
        local: params.local?.lessons?.find((l) => l.id === lesson.id),
      }),
    );
  }

  mapTo(domain: LearningCourse): Params {
    throw new Error('Unsupported');
  }
}
