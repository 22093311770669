export enum PreOrderStatuses {
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  AWAITING = 'AWAITING',
  AWAITING_EXTERNAL_PRODUCT_APPROVAL = 'AWAITING_EXTERNAL_PRODUCT_APPROVAL',
  REJECTED = 'REJECTED',
  DOWN_PAYMENT_OFFERED = 'DOWN_PAYMENT_OFFERED',
  MONEY_TRANSFERRED_APPROVED = 'MONEY_TRANSFERRED_APPROVED',
  STARTED = 'STARTED',
  DOWN_PAYMENT_REJECTED_BY_MERCHANT = 'DOWN_PAYMENT_REJECTED_BY_MERCHANT',
  DOWN_PAYMENT_AGREED = 'DOWN_PAYMENT_AGREED',
}
