import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { InsightsType } from '../../domain/merchant-insights.model';
import { MerchantInsightsRepository } from '../../repositories/merchant-insights.repository';

export class GetInsightsUrlUseCase
  implements UseCase<{ type: InsightsType; id: number }, { url: string }>
{
  constructor(private _merchantInsightsRepository: MerchantInsightsRepository) {}

  execute(param: { type: InsightsType; id: number }): Observable<{ url: string }> {
    return this._merchantInsightsRepository.getInsightsUrl(param.type, param.id);
  }
}
