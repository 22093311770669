import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserStoreModel } from '../../domain/user-store.model';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class GetUserStoresUseCase implements UseCase<void, UserStoreModel[]> {
  constructor(private _merchantStoreRepository: MerchantStoreRepository) {}

  execute(): Observable<UserStoreModel[]> {
    return this._merchantStoreRepository.getUserStores();
  }
}
