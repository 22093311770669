<div
  class="notification-item"
  (click)="presenter.emitViewEvent({ type: 'ClickSpecificNotification', notification })"
>
  <!-- icon -->
  <div class="notification-item_icon">
    <img src="assets/img/header-icons/lock.svg" alt="image of a lock" />
  </div>
  <!-- content -->
  <div>
    <div class="notification-item__content">
      <div>
        <p class="caption1--bold notification-item__content_title">
          {{ localizedValue(notification.content.title) }}
        </p>
        <p class="caption2--regular notification-item__content_subtitle">
          {{ localizedValue(notification.content.message) }}
        </p>
      </div>
      <div class="notification-item__content_dot" *ngIf="!notification.isSeen"></div>
    </div>
    <div
      class="notification-item__actions"
      *ngIf="shouldShowTallyFormLink || shouldShowViewProgress"
    >
      <button
        *ngIf="shouldShowViewProgress"
        type="button"
        class="notification-item__actions_show-btn caption1--medium"
        (click)="
          presenter.emitViewEvent({
            type: 'ClickProductProgress',
            productId: notification.content.productId,
            redirectType: 'viewProduct'
          })
        "
      >
        {{ trans('HEADER.NOTIFICATIONS_DROPDOWN.SHOW_BTN') }}
      </button>
      <button
        *ngIf="shouldShowTallyFormLink"
        type="button"
        class="notification-item__actions_request-btn caption1--bold"
        (click)="
          presenter.emitViewEvent({
            type: 'ClickProductProgress',
            productId: notification.content.productId,
            redirectType: 'viewLock'
          })
        "
      >
        {{ trans('HEADER.NOTIFICATIONS_DROPDOWN.REQUEST_BTN') }}
      </button>
    </div>
  </div>
</div>
