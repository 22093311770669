import { Observable } from 'rxjs';
import {
  ProductCheckoutCreateModel,
  ProductCheckoutModel,
  ProductCheckoutUpdateModel,
} from '../domain/products/product-checkout';
import { ProductReviewModel, VariantModel } from '../domain/variant-group.model';

export abstract class ProductsRepository {
  abstract getProductVAT(params: {
    countryIsoCode3: string;
    productId: string;
    productSellingPrice: number;
    quantity: number;
    isOnSale?: boolean;
  }): Observable<number>;

  abstract getBulkPreorderableProducts(params?: {
    page?: number;
    pageSize?: number;
    sortBy?: string;
    commercialCategoryId?: string;
  }): Observable<any>;

  abstract setProductReview(params: {
    data: ProductReviewModel;
    productId: string;
  }): Observable<void>;

  abstract getUpSellableProducts(productId: string): Observable<VariantModel[]>;

  abstract getVariantsByProductIds(productIds: string[]): Observable<VariantModel[]>;

  abstract shouldDisplayNewHomePageUseCase(): boolean;

  abstract listenToProductsPageExperienceChanges(): Observable<void>;

  abstract setSelectedProductsPageExperience(displayNewProductsPage: boolean): any;

  abstract checkUserNewExperienceEligibility(): boolean;

  abstract getProductCreativesEligibility(productId: string): Observable<{ isAllowed: boolean }>;

  abstract productCheckoutCreate(data: ProductCheckoutCreateModel): Observable<void>;

  abstract getProductCheckoutDetail(productId: string): Observable<ProductCheckoutModel>;

  abstract updateProductCheckout(data: ProductCheckoutUpdateModel): Observable<void>;
}
