import { Mapper } from 'src/app/core/base/mapper';
import { QuestionnaireModel } from 'src/app/core/domain/questionnaire.model';
import { QuestionnaireEntity } from '../entities/questionnaire-entity';
import { QuestionnaireQuestionRepositoryMapper } from './questionnaire-question.mapper';

export class GetQuestionnaireQuestionsRepositoryMapper extends Mapper<
  QuestionnaireEntity,
  QuestionnaireModel
> {
  mapFrom(param: QuestionnaireEntity): QuestionnaireModel {
    const questionnaireQuestionMapper = new QuestionnaireQuestionRepositoryMapper();
    return {
      name: param.name,
      questions: param.questions.map(questionnaireQuestionMapper.mapFrom),
      recurring: param.recurring,
    };
  }

  mapTo(param: QuestionnaireModel): QuestionnaireEntity {
    const questionnaireQuestionMapper = new QuestionnaireQuestionRepositoryMapper();
    return {
      name: param.name,
      questions: param.questions.map(questionnaireQuestionMapper.mapTo),
    };
  }
}
