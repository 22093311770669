import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { TierModel } from '../../domain/loyalty-program/loyalty-program.model';
import { LoyaltyProgramRepository } from '../../repositories/loyalty-program.repository';

export class GetLoyaltyTiersDataUseCase implements UseCase<void, TierModel[]> {
  constructor(private _loyaltyProgramRepository: LoyaltyProgramRepository) {}

  execute(): Observable<TierModel[]> {
    return this._loyaltyProgramRepository.getLoyaltyTiersData();
  }
}
