/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { NgClass, NgFor } from '@angular/common';
import { Component, inject, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ENGLISH_LANGUAGE } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';
import { REQUEST_PRODUCT_SUBMIT } from 'src/app/presentation/shared/constants/mixpanel';
import { CategoryInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';

@Component({
  selector: 'app-request-unavailable-product-dialog',
  templateUrl: './request-unavailable-product-dialog.component.html',
  styleUrls: ['./request-unavailable-product-dialog.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgFor, TranslateModule],
})
export class RequestUnavailableProductDialogComponent implements OnInit {
  categoryNames: any[] = [];

  requestProductForm: UntypedFormGroup;

  formSubmitted = false;

  categories: CategoryInterface[] = [];

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  isEnglishLanguage: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog,
    private productService: ProductService,
    private toastr: ToastrService,
    private router: Router,
    private _translateService: TranslateService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _siteTranslationService: SiteTranslateService,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
  ) {}

  ngOnInit(): void {
    this.isEnglishLanguage = this._siteTranslationService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.createForm();
    this.setCategory();
  }

  public setCategory(): void {
    // eslint-disable-next-line no-new-object
    const othersCategory = new Object() as CategoryInterface;
    othersCategory.name = 'Other';
    othersCategory.text = 'أخرى';
    if (this.data.categories) {
      this.categories = this.data.categories.concat(othersCategory);
      this.categoryNames = this.categories.map((category) => category.text);
    } else {
      this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
        next: (tree) => {
          this.categories = tree.root.children.map((child) => {
            const category: CategoryInterface = {
              name: this.isEnglishLanguage
                ? child.value.name.englishName
                : child.value.name.arabicName,
              text: this.isEnglishLanguage
                ? child.value.name.englishName
                : child.value.name.arabicName,
              _id: child.key,
              featured: child.value.featured,
            };
            return category;
          });
          this.categories = this.categories.concat(othersCategory);
          this.categoryNames = this.categories.map((category) => category.text);
        },
      });
    }
  }

  createForm(): void {
    this.requestProductForm = new UntypedFormGroup({
      category: new UntypedFormControl('', [Validators.required]),
      productDetails: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.requestProductForm.valid) {
      const requestBody = this.requestProductForm.value;
      this.productService.postProductRequest(requestBody).subscribe({
        next: (success) => {
          this.trackSubmitProductRequest();
          this.dialogRef.closeAll();
          this.toastr.success(
            this._translateService.instant('REQUEST_UNAVAILABLE.REQUEST_SUBMITTED'),
          );
          this.router.navigate([this._appURLs.PRODUCTS_V2_URL]);
        },
        error: (err) => {
          this.toastr.error(this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE'));
          this.formSubmitted = false;
        },
      });
    }
  }

  trackSubmitProductRequest(): void {
    const formValue = this.requestProductForm.value;
    const filteredCategory = this.categories.filter(
      (category) => category.text === formValue.category,
    )[0];
    const eventBody = {
      Language: 'ar',
      'Interface Version': 'v2',
      'Category Id': filteredCategory._id,
      'Category Name': filteredCategory.name,
      Description: formValue.productDetails,
      'Clicked From': this.data.clickedFrom,
    };
    this._logMixpanelEventUseCase.execute({
      eventName: REQUEST_PRODUCT_SUBMIT,
      payload: eventBody,
    });
  }

  closeDialog(): void {
    this.dialogRef.closeAll();
  }
}
