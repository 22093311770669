import { UseCase } from '../../base/use-case';
import { UserRepository } from '../../repositories/user.repository';

export class CheckUserFeatureExistsUseCase implements UseCase<string, boolean> {
  constructor(private _userRepository: UserRepository) {}

  execute(params: string): boolean {
    return this._userRepository.checkUserFeatureExists(params);
  }
}
