import { createAtom } from '@mongez/atom';
import { dialogAtomEvents } from '../../shared/dialog';

type KYCPopupActions = {
  close: () => void;
  open: () => void;
};

export const kycPopupAtom = createAtom<boolean, KYCPopupActions>({
  key: 'kycPopup',
  default: false,
  actions: {
    close() {
      kycPopupAtom.update(false);
    },
    open() {
      kycPopupAtom.update(true);
    },
  },
});

dialogAtomEvents.on('close', (dialogId) => {
  if (dialogId === 'kyc') {
    kycPopupAtom.close();
  }
});
