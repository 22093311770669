import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { WalletRepository } from '../../repositories/wallet.repository';

export class ForgotWalletPasswordUseCase implements UseCase<{ email: string }, { code: string }> {
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: { email: string }): Observable<{ code: string }> {
    return this._walletRepository.forgotWalletPassword(params);
  }
}
