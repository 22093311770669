import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { AUTH_URL, PRODUCTS_V2_URL, REGISTER_URL } from '../../../constants';
import { OPT_IN_FLAG } from '../../../constants/feature-flags';
import { UserResolutionService } from '../../../services/user-resolution.service';
import { UserRegistrationStepUtility } from '../../../utilities/user-registration-step.utility';
import { RouteAccessResolutionTypes } from './interfaces';
import { stringToUrlTree, userHasFullySignedUp } from './shared';

/**
 *
 * @returns
 *
 * So, for a user to be able to access opt in module, then the rule is:
 *
 * - user must be logged in.
 * - user must not have a fully authenticated account i.e their token should be
 * allowing them to access this module
 * - feature flag for sending users to verification i.e activating has to be true
 * the optin module has to be set on.
 */
export const optInResolver = (): Observable<RouteAccessResolutionTypes> => {
  const router = inject(Router);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  const userResolutionService = inject(UserResolutionService);
  const userRegistrationStepUtility = inject(UserRegistrationStepUtility);
  const getRegisterTokenUseCase = inject(GetRegisterTokenUseCase);
  return new Observable((subscriber) => {
    userHasFullySignedUp(userRegistrationStepUtility, getRegisterTokenUseCase).subscribe({
      next: (hasFullySignedUp) => {
        if (hasFullySignedUp) {
          const userAbilities = userResolutionService.analyzeUserToken();
          if (userAbilities.nextStepForTheUser === 'reLogin') {
            subscriber.next(stringToUrlTree(AUTH_URL, router));
          } else {
            if (userAbilities.nextStepForTheUser === 'products') {
              subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
            } else {
              getFeatureFlagUseCase.execute(OPT_IN_FLAG).subscribe({
                next: (isOn) => {
                  if (isOn) {
                    subscriber.next(true);
                  } else {
                    subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
                  }
                },
                error: () => {
                  subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
                },
              });
            }
          }
        } else {
          subscriber.next(stringToUrlTree(REGISTER_URL, router));
        }
      },
    });
  });
};
