import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { LOGIN_URL, PRODUCTS_V2_URL } from '../../../constants';
import { ONBOARDING_V2 } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { isUserLoggedIn, stringToUrlTree } from './shared';

/**
 * For a user to access the learning center:
 *
 * - must be logged in.
 * - learning center specific checks need to be made:
 *      - the feature flag has to be on
 */
export const learningCenterResolver = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const getFeatureFlagUsecase = inject(GetFeatureFlagUsecase);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          getFeatureFlagUsecase.execute(ONBOARDING_V2).subscribe({
            next: (flagIsOn) => {
              if (flagIsOn) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              }
            },
          });
        } else {
          subscriber.next(stringToUrlTree(LOGIN_URL, router));
        }
      },
    });
  });
};
