<div class="container-div">
  <div class="d-flex flex-row-reverse close-btn-div">
    <span>&times;</span>
  </div>
  <div class="d-flex flex-row">
    <mat-icon class="toaster-icon" svgIcon="bell"></mat-icon>
    <p class="toaster-message">لقد تم إضافة خواص جديدة للموقع هل تود التحديث لاظهارها؟</p>
  </div>
  <div class="d-flex flex-row justify-content-center">
    <button class="btn btn-update" (click)="action($event)">تحديث</button>
    <button class="btn btn-cancel" (click)="remove()">ليس الان</button>
  </div>
</div>
