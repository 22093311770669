import { inject, Injectable } from '@angular/core';
import { country } from '@features/country/data';
import { LogMixpanelEventUseCase } from 'app/core/usecases/analytics/log-mixpanel-event.usecase';
import { LogoutUseCase } from 'app/core/usecases/auth/logout.usecase';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import {
  FEATURE_FLAGS,
  MERCHANT_INSIGHTS_USER_FEATURE,
  PRE_ORDER_USER_FEATURE,
} from 'app/presentation/shared/constants';
import {
  BULK_UPLOAD_TAB,
  INFO_HEADER_TABS,
  LOGGED_OUT_TABS,
  MERCHANT_INSIGHTS_TAB,
  NEW_INCENTIVE_BUTTON,
  ORDER_SUMMARY_TAB,
  PRE_ORDER_REQUESTS_BUTTON,
  PRODUCTS_TAB,
  PROFILE_NAVIGATION_TABS,
  SIGNOUT_BUTTON,
} from 'app/presentation/shared/constants/header-tabs';
import {
  CLICK_FROM_NAV_BAR,
  REQUEST_PRODUCT_CLICK,
} from 'app/presentation/shared/constants/mixpanel';
import { SharedService } from 'app/presentation/shared/services/shared.service';
import { ThemeService } from 'app/presentation/shared/services/theme.service';
import { combineLatest, EMPTY, map } from 'rxjs';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { DefaultLayoutSideEffect } from './default-layout.sideEffects';
import { DefaultLayoutViewEvents } from './default-layout.viewEvents';
import { DefaultLayoutViewState } from './default-layout.viewState';

@Injectable()
export class DefaultLayoutPresenter extends BasePresenter<
  DefaultLayoutViewState,
  DefaultLayoutViewEvents,
  DefaultLayoutSideEffect
> {
  private _sharedService = inject(SharedService);

  private _logoutUseCase = inject(LogoutUseCase);

  private _getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);

  private _checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);

  private _themeService = inject(ThemeService);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): DefaultLayoutViewState {
    return {
      isMenuOpen: false,
      firstSectionSidenavTabs: [],
      secondSectionSidenavTabs: [],
      loggedOutSidenavTabs: [],
      isSmartAppBannerVisible: false,
      shouldShowRequestProduct: false,
      darkModeClass$: EMPTY,
    };
  }

  private _checkThemeMode(): void {
    this.updateViewState({
      ...this.viewState,
      darkModeClass$: this._themeService
        .isDarkMode()
        .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode'))),
    });
  }

  private _observeDropdownMenuUpdate(): void {
    this._sharedService.hamburgerIconClickedListener().subscribe({
      next: (isButtonClicked) => {
        this.updateViewState({
          ...this.viewState,
          isMenuOpen: isButtonClicked,
        });
      },
    });
  }

  private _initializeProfileTabs(): void {
    const filteredProfileTabs = PROFILE_NAVIGATION_TABS.filter(
      (tab) => tab.tabName !== SIGNOUT_BUTTON.tabName,
    );
    this.updateViewState({
      ...this.viewState,
      firstSectionSidenavTabs: [...filteredProfileTabs, PRODUCTS_TAB],
      secondSectionSidenavTabs: [...INFO_HEADER_TABS, BULK_UPLOAD_TAB],
      loggedOutSidenavTabs: LOGGED_OUT_TABS,
      isSmartAppBannerVisible: !!document.querySelector('.smartbanner--android'),
    });
  }

  private _updateTabsBasedOnUserFeatures(): void {
    combineLatest({
      preOrder: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PREORDER_V3),
      newIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.INCENTIVE_PROGRAM_V3),
      ksaIncentiveProgram: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.KSA_INCENTIVE_PROGRAM),
      orderSummaryFeature: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.ORDER_SUMMARY_FEATURE),
      merchantInsightsFeature: this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.MERCHANT_INSIGHTS),
    }).subscribe({
      next: ({
        preOrder,
        newIncentiveProgram,
        ksaIncentiveProgram,
        orderSummaryFeature,
        merchantInsightsFeature,
      }) => {
        if (
          newIncentiveProgram &&
          (country.is('EGY') || (ksaIncentiveProgram && country.is('KSA')))
        ) {
          this.updateViewState({
            ...this.viewState,
            firstSectionSidenavTabs: [
              ...this.viewState.firstSectionSidenavTabs.filter(
                (tab) => tab.tabName !== PRODUCTS_TAB.tabName,
              ),
              NEW_INCENTIVE_BUTTON,
              PRODUCTS_TAB,
            ],
          });
        }
        const userHasPreOrder = this._checkUserFeatureExistsUseCase.execute(
          PRE_ORDER_USER_FEATURE + country.code.toLowerCase(),
        );
        if (preOrder && userHasPreOrder) {
          this.updateViewState({
            ...this.viewState,
            firstSectionSidenavTabs: [
              ...this.viewState.firstSectionSidenavTabs.filter(
                (tab) => tab.tabName !== PRODUCTS_TAB.tabName,
              ),
              PRE_ORDER_REQUESTS_BUTTON,
              PRODUCTS_TAB,
            ],
          });
        }
        const userHasMerchantInsights = this._checkUserFeatureExistsUseCase.execute(
          MERCHANT_INSIGHTS_USER_FEATURE,
        );
        if (merchantInsightsFeature && userHasMerchantInsights) {
          this.viewState.secondSectionSidenavTabs.push(MERCHANT_INSIGHTS_TAB);
        } else if (orderSummaryFeature) {
          this.viewState.secondSectionSidenavTabs.push(ORDER_SUMMARY_TAB);
        }
        this._filterDuplicateTabs();
      },
    });
  }

  private _filterDuplicateTabs(): void {
    const uniqueTabLinks: any = [];
    this.updateViewState({
      ...this.viewState,
      firstSectionSidenavTabs: this.viewState.firstSectionSidenavTabs.filter((element) => {
        const isDuplicate = uniqueTabLinks.includes(element.routerLink);
        if (!isDuplicate) {
          uniqueTabLinks.push(element.routerLink);
          return true;
        }
        return false;
      }),
    });
  }

  private _checkRequestProductEnabled(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.REQUEST_PRODUCT_MENU_FLAG).subscribe({
      next: (flag) => {
        this.updateViewState({
          ...this.viewState,
          shouldShowRequestProduct: flag,
        });
      },
    });
  }

  protected onViewEvent(event: DefaultLayoutViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this._checkThemeMode();
        this._observeDropdownMenuUpdate();
        this._initializeProfileTabs();
        this._updateTabsBasedOnUserFeatures();
        this._checkRequestProductEnabled();
        break;
      }
      case 'ClickRequestProductDialog': {
        const eventBody = {
          Language: 'ar',
          'Interface Version': 'v2',
          'Clicked From': CLICK_FROM_NAV_BAR,
        };
        this._logMixpanelEventUseCase.execute({
          eventName: REQUEST_PRODUCT_CLICK,
          payload: eventBody,
        });
        break;
      }
      case 'ClickCloseSideNav': {
        this._sharedService.emitOpenSideNavBar(false);
        break;
      }
      case 'ClickLogout': {
        this._sharedService.emitOpenSideNavBar(false);
        this._logoutUseCase.execute();
      }
    }
  }
}
