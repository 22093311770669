import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class DeleteDukanProductMediaUseCase implements UseCase<{ sku: string; url: string }, void> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(data: { sku: string; url: string }): Observable<void> {
    return this._dukanShopRepository.deleteProductMedia(data.sku, data.url);
  }
}
