import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CPARequestModel } from 'src/app/core/domain/pricing-calculator.model';
import { CPAResponseEntity } from './entities/pricing-calculator.entity';
import { PRICING_CALCULATOR_APIS_ENDPOINTS } from './pricing-calculator-apis-endpoints';

@Injectable({
  providedIn: 'root',
})
export class PricingCalculatorApisService {
  constructor(private _http: HttpClient) {}

  cpaCalculator(cpaRequestModel: CPARequestModel): Observable<CPAResponseEntity> {
    return this._http
      .post<{ data: CPAResponseEntity }>(
        PRICING_CALCULATOR_APIS_ENDPOINTS.CALCULATE_CPA(cpaRequestModel.productId),
        {
          price: cpaRequestModel.price,
          netDeliveryRate: cpaRequestModel.netDeliveryRate,
        },
      )
      .pipe(map(({ data }) => data));
  }
}
