import { inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ResponsiveService } from '../services/responsive.service';

interface CESLocationNotifierPayload {
  action: 'read' | 'write';
  location?: string;
}

/**
 * So, to make things easier, and more organized, we will have this utility
 * for broadcasting the current user location.
 *
 * The reason for this is that it will make it possible for us to let the
 * CES component know where a user is at, by users posting their locations.
 *
 * If the location is there, then it is a set operation, else it is a get
 * operation.
 */
const currentLocation$: BehaviorSubject<string> = new BehaviorSubject<string>(' ');

export const cesLocationNotifierUtility = (): ((
  payload: CESLocationNotifierPayload,
) => Observable<string>) => {
  const responsiveService: ResponsiveService = inject(ResponsiveService);
  const deviceCategory = responsiveService.returnDeviceCategory();

  return (payload: CESLocationNotifierPayload) => {
    if (deviceCategory === 'mobile') {
      return of('');
    }
    if (payload.action === 'write') {
      currentLocation$.next(payload.location!);
      return of('');
    }
    return currentLocation$;
  };
};
