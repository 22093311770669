import dayjs from 'dayjs';

require('dayjs/locale/ar');

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
const isBetween = require('dayjs/plugin/isBetween');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 *
 * Inside here, we will have all our small date parser utility functions,
 * instead of having these as independent utility functions
 *
 * NB: PLEASE MAKE SURE TO BE CAREFUL WITH CHANGING THE LOGIC HERE,
 * JUST LIKE IN OTHER UTILITY FUNCTIONS, BECAUSE THE LOGIC HAS BEEN
 * SHARED IN MULTIPLE PLACES IN THE APPLICATION.
 *
 * IF ANYTHING, INSTEAD OF CHANGING OR REMOVING, YOU ARE ENCOURAGED
 * TO ADD. THIS WAY, WHAT IS THERE WILL BE WORKING, HENCE BACKWARD
 * COMPATIBILITY
 */
export class DateWrapperUtility {
  public static allowedDateCharacters: Array<string> = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '/',
    '-',
  ];

  private static _wrapper(): any {
    return dayjs;
  }

  /**
   *
   * @param date
   * @param format
   *
   * Formats the date to a string, and the format will be what has been
   * passed in.
   *
   * For example YYYY-MM-DD should return a string of the format 2023-01-05.
   */
  public static formatDateToString(date: Date, format = ''): string {
    return this._wrapper()(date).format(format);
  }

  public static formatDateToUTCString(date: Date, format = ''): string {
    return this._wrapper()(date).utc().format(format);
  }

  /**
   *
   * @param monthDate
   *
   * Receives a month date, for example 01-01-2023 and returns the number of
   * days in that month
   */
  public static calculateDaysInAMonth(monthDate: Date): number {
    return this._wrapper()(monthDate).daysInMonth();
  }

  /**
   * Return the current date as an object with
   * day, month and year attributes in UTC
   */
  public static getCurrentDateObject(): {
    hour: number;
    day: number;
    month: number;
    year: number;
  } {
    const date = this._wrapper()().utc();

    return {
      hour: date.get('hours'),
      day: date.get('date'),
      month: date.get('month') + 1,
      year: date.get('year'),
    };
  }

  /**
   *
   * @param timeUnits
   *
   * Return a date format for the given time units, which will
   * be an array
   */
  public static stringToDate(dateString: string): Date {
    return this._wrapper()(dateString).toDate();
  }

  /**
   *
   * @param timeUnit
   * @param referencePointInTime
   * @param howFar
   * @param direction
   *
   * Returns the time unit as a date from a given
   * point reference in time
   */
  public static calculateTimeUnitsFromGivenPoint(
    timeUnit: any,
    referencePointInTime: Date,
    howFar: any,
    direction: 'forward' | 'backward',
  ): Date {
    if (direction === 'backward') {
      /**
       * from now going backwards
       */
      return this._wrapper()(referencePointInTime).subtract(howFar, timeUnit).toDate();
    }
    /**
     * from now going forwards
     */
    return this._wrapper()(referencePointInTime).add(howFar, timeUnit).toDate();
  }

  /**
   *
   * @param date
   * @param timeUnit
   *
   * Return the end of a given time unit, for
   * example, end of month
   */
  public static getTheEndOfATimeUnit(
    date: Date,
    timeUnit: 'day' | 'week' | 'month' | 'year',
  ): Date {
    return this._wrapper()(date)
      .endOf(timeUnit as any)
      .toDate();
  }

  /**
   *
   * @param date
   * @param timeUnit
   *
   * Return the start of a given time unit, for
   * example, end of month
   */
  public static getTheStartOfATimeUnit(
    date: Date,
    timeUnit: 'day' | 'week' | 'month' | 'year',
  ): Date {
    return this._wrapper()(date)
      .startOf(timeUnit as any)
      .toDate();
  }

  public static getFormattedDateParts(
    date: string,
    format: { day?: string; month?: string; year?: string },
    locale = 'ar',
  ): {
    day: string;
    month: string;
    year: string;
  } {
    const dateObject = this._wrapper()(date).locale(locale);

    return {
      day: dateObject.format(format?.day),
      month: dateObject.format(format?.month),
      year: dateObject.format(format?.year),
    };
  }

  public static getMonthFromNumber(number: number, locale: string, format: string): string {
    return this._wrapper()(new Date(`1970-${number}-01`))
      .locale(locale)
      .format(format);
  }

  public static formatDateToLocale(time: any, locale = 'Africa/Cairo'): void {
    return this._wrapper()(time).tz(locale).format();
  }

  public static dateAIsBeforeDateB(dateA: string, dateB: string): boolean {
    return this._wrapper()(dateA).isBefore(dateB);
  }

  public static dateAIsSameOrAfterDateB(dateA: string, dateB: string): boolean {
    return this._wrapper()(dateA).isSameOrAfter(dateB);
  }

  public static dateAIsSameAsDateB(dateA: string, dateB: string): boolean {
    return this._wrapper()(dateA).isSame(dateB);
  }

  public static dateIsBetweenTwoDates(date: string, dateA: string, dateB: string): boolean {
    return this._wrapper()(date).isBetween(dateA, dateB);
  }

  public static periodNLocaleName(
    count: number,
    unit: 'day' | 'month',
    locale: string,
    format: string,
  ): string {
    return this._wrapper()().locale(locale).add(count, unit).startOf(unit).format(format);
  }

  public static formatTimeToLocale(time: any, locale = 'Africa/Cairo'): string {
    return this._wrapper()(time).tz(locale).format();
  }

  public static dateToISOString(date: Date): string {
    return this._wrapper()(date).toISOString();
  }

  public static dayCountFromNow(date: string): number {
    return date ? Math.max(0, this._wrapper()(date).diff(this._wrapper()(), 'days') + 1) : 0;
  }

  public static dateIsValid(date: any): boolean {
    if (!date) {
      return true;
    }
    return this._wrapper()(date).isValid();
  }

  public static isDateIsAllowedFormat(value: string): boolean {
    let verdict = true;
    for (const char of value) {
      if (this.allowedDateCharacters.indexOf(char) === -1) {
        verdict = false;
        break;
      }
    }
    return verdict;
  }
}
