import { groupedTranslations } from '@mongez/localization';

groupedTranslations('kyc', {
  header: {
    en: 'Verify your Identity',
    ar: 'يرجى توثيق هويتك',
  },
  title: {
    en: 'Verify Identity',
    ar: 'توثيق الهوية',
  },
  disclaimer: {
    en: 'Identity verification is necessary to ensure the security of your account and resolve any issue easily',
    ar: 'توثيق الهوية ضروري لضمان آمان حسابك وحل أي مشكلة بسهولة',
  },
  byNationalId: {
    en: 'Verify by National ID',
    ar: 'توثيق عن طريق البطاقة',
  },
  byPassport: {
    en: 'Verify by Passport',
    ar: 'توثيق عن طريق جواز السفر',
  },
  uploadIdTitle: {
    en: 'Upload an image of your National ID',
    ar: 'يرجى رفع صورة البطاقة',
  },
  uploadPassportTitle: {
    en: 'Upload an image of your Passport',
    ar: 'يرجى رفع صورة جواز السفر',
  },
  uploadTitle: {
    en: 'Upload an image of your National ID or Passport',
    ar: 'يرجى تحميل صورة البطاقة أو جواز السفر',
  },
  uploadIdText: {
    en: 'Upload ID image',
    ar: 'ارفع صورة البطاقة',
  },
  uploadPassportText: {
    en: 'Upload Passport image',
    ar: 'ارفع صورة جواز السفر',
  },
  uploadText: {
    en: 'Upload image',
    ar: 'تحميل الصورة',
  },
  idTitle: {
    en: 'Your National ID',
    ar: 'الرقم القومي',
  },
  id: {
    en: 'National ID',
    ar: 'الرقم القومي',
  },
  submit: {
    en: 'Submit',
    ar: 'ارسال',
  },
  warning: {
    en: 'We will review your verification submission within up to 1 business day',
    ar: 'سنقوم بمراجعة معلومات التوثيق في خلال مدة تصل إلى ١ يوم عمل',
  },
  success: {
    ar: 'تم ارسال طلبك',
    en: 'Your request has been submitted',
  },
});

groupedTranslations('kyc-popup', {
  header: {
    ar: 'سحب الأرباح',
    en: 'Withdraw Profit',
  },
  title: {
    ar: 'يرجى توثيق هويتك للتمكن من سحب أرباحك',
    en: 'Please verify your identity to be able to withdraw your profit',
  },
  body: {
    ar: 'يمكنك توثيق هويتك عن طريق البطاقة الشخصية او جواز السفر الخاص بك لكي تتمكن من سحب ارباحك بأمان',
    en: 'You can verify your identity using your ID card or passport so that you can withdraw your profits safely',
  },
  button: {
    ar: 'توثيق الحساب',
    en: 'Verify Identity',
  },
});

groupedTranslations('kyc-warnings', {
  duplicateAccounts: {
    ar: 'تم تصنيف هذا الحساب كحساب مكرر، ولهذا السبب يمكنك الاستمتاع بالتحديات وبرامج المكافآت فقط على حسابك الرئيسي',
    en: 'This account is classified as a duplicate account, which is why you can only enjoy challenges and rewards programs on your main account',
  },
  pending: {
    ar: 'نقوم بمراجعة معلومات التوثيق الخاصة بك في خلال مدة تصل إلى ١ يوم عمل للتمكن من استكمال عملية سحب الارباح',
    en: 'We will review your verification information within 1 business day to be able to complete the withdrawal process.',
  },
  rejection: {
    ar: 'لم يتم قبول طلب توثيق حسابك للسبب التالي: ',
    en: 'Your account verification request was not accepted for the following reason:',
  },
  retry: {
    ar: '  يرجى المحاولة مرة اخرى للتمكن من سحب أرباحك',
    en: 'Please try again to be able to withdraw.',
  },
});
