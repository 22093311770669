import { Mapper } from 'src/app/core/base/mapper';
import { ChangeWalletPasswordModel } from 'src/app/core/domain/wallet/wallet.model';
import { ChangeWalletPasswordEntity } from '../entities/wallet.entity';

export class ChangeWalletPasswordMapper
  implements Mapper<ChangeWalletPasswordEntity, ChangeWalletPasswordModel>
{
  mapFrom(fromParams: ChangeWalletPasswordEntity): ChangeWalletPasswordModel {
    return {
      currentWalletPassword: fromParams.currentWalletPassword,
      newWalletPassword: fromParams.newWalletPassword,
      confirmWalletPassword: fromParams.confirmWalletPassword,
    };
  }

  mapTo(toParams: ChangeWalletPasswordModel): ChangeWalletPasswordEntity {
    return {
      currentWalletPassword: toParams.currentWalletPassword,
      newWalletPassword: toParams.newWalletPassword,
      confirmWalletPassword: toParams.confirmWalletPassword,
    };
  }
}
