import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { PreOrderBenefitsModel } from 'src/app/core/domain/bulk-pre-order/pre-order-benefits.model';
import { BulkPreOrderRequestModel } from '../../../core/domain/bulk-pre-order/bulk-pre-order-request.model';
import { API_URLS } from '../../../presentation/shared/constants';
import { CommercialCategoryEntity } from '../commercial-categories/entities/commercial-category-entity';
import { PRE_ORDER_APIS_ENDPOINTS } from './pre-order-apis-endpoints';
import { PreOrderBenefitsLocalDataSource } from './pre-order-benifits-data-source';
import { PreOrderRequestsSummaryEntity } from './pre-order-request-summary.entity';
import { RequestListingEntity } from './request-listing-entity';

@Injectable({
  providedIn: 'root',
})
export class BulkPreOrdersApisService {
  constructor(
    private _httpClient: HttpClient,
    private _preOrderBenefitsLocalDataSource: PreOrderBenefitsLocalDataSource,
  ) {}

  createPreOrderRequest(request: BulkPreOrderRequestModel, isUrlV2: boolean): Observable<void> {
    const url = isUrlV2 ? API_URLS.PRE_ORDER_V2_URL : API_URLS.CREATE_BULK_PRE_ORDER_REQUEST_URL;
    return this._httpClient.post<void>(url, request);
  }

  getPreOrderRequests(isUrlV2: boolean): Observable<RequestListingEntity[]> {
    const url = isUrlV2 ? API_URLS.PRE_ORDER_V2_URL : API_URLS.CREATE_BULK_PRE_ORDER_REQUEST_URL;
    return this._httpClient
      .get<{ data: { count: number; preOrderRequests: RequestListingEntity[] } }>(url)
      .pipe(map(({ data }) => data.preOrderRequests));
  }

  postAcceptPreOrderDownPayment(requestId: string): Observable<void> {
    return this._httpClient.post<void>(API_URLS.ACCEPT_PRE_ORDER_DOWN_PAYMENT_URL(requestId), null);
  }

  putRejectPreOrderDownPayment(requestId: string): Observable<void> {
    return this._httpClient.put<void>(API_URLS.REJECT_PRE_ORDER_DOWN_PAYMENT_URL(requestId), null);
  }

  getProOrderBenefitsData(): Observable<PreOrderBenefitsModel[]> {
    return of(this._preOrderBenefitsLocalDataSource.getPreOrderBenefitsData());
  }

  getPreOrderRequestsSummary(): Observable<PreOrderRequestsSummaryEntity> {
    return this._httpClient
      .get<{ data: PreOrderRequestsSummaryEntity }>(
        PRE_ORDER_APIS_ENDPOINTS.GET_PRE_ORDER_REQUESTS_SUMMARY,
      )
      .pipe(map(({ data }) => data));
  }

  getPreOrderCategories(countryCode: string): Observable<CommercialCategoryEntity[]> {
    return this._httpClient
      .get<{ data: CommercialCategoryEntity[] }>(API_URLS.GET_PRE_ORDER_CATEGORIES_URL)
      .pipe(map((res) => res.data));
  }
}
