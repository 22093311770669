import { NgClass, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ENGLISH_LANGUAGE } from '../shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../shared/services/translate.service';
import { CategoryListComponent } from './category-list/category-list.component';
import { SubcategoriesListComponent } from './subcategories-list/subcategories-list.component';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
  standalone: true,
  imports: [NgClass, CategoryListComponent, NgIf, SubcategoriesListComponent],
})
export class MegaMenuComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  hoveredCategoryId: string;

  shouldDisplaySideMenu = false;

  isLTR: boolean;

  private _languageChangeSubscription: Subscription;

  constructor(
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.isLTR = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isLTR = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  onCategoryHovered(categoryId: string) {
    this.hoveredCategoryId = categoryId;
    this.shouldDisplaySideMenu = true;
  }

  ngOnDestroy() {
    this._languageChangeSubscription.unsubscribe();
  }
}
