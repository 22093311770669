import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { SecurePayoneerTransferModel } from '../../domain/payment/payoneer-transfer.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class CreateSecurePayoneerUseCase implements UseCase<SecurePayoneerTransferModel, void> {
  constructor(private walletRepository: WalletRepository) {}

  execute(securePayoneerTransferModel: SecurePayoneerTransferModel): Observable<void> {
    return this.walletRepository.createSecurePayoneerWithdrawal(securePayoneerTransferModel);
  }
}
