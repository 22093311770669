/* eslint-disable max-classes-per-file */
import { Mapper } from 'src/app/core/base/mapper';
import { HistoryDynamicIncentiveProgramModel } from 'src/app/core/domain/dynamic-incentive-program.model';
import { mapToLocalizedData } from '../../shared';
import { HistoryDynamicIncentiveProgramEntity } from '../entities/dynamic-incentive-program-entity';

export class DynamicIncentiveProgramHistoryMapper extends Mapper<
  HistoryDynamicIncentiveProgramEntity,
  HistoryDynamicIncentiveProgramModel
> {
  mapFrom(param: HistoryDynamicIncentiveProgramEntity): HistoryDynamicIncentiveProgramModel {
    return {
      challengeReference: param.challengeReference,
      challengeName: mapToLocalizedData(param.challengeName),
      startedAt: new Date(param.startedAt),
      endedAt: new Date(param.endedAt),
      rewardsStatus: param.rewardsStatus,
      rewardsValue: param.rewardsValue,
      challengeFeedbackRate: param.challengeFeedbackRate,
      achievedOrders: param.achievedOrders,
      maxTargetOrdersCount: param.maxTargetOrdersCount,
      orderType: param.orderType,
    };
  }

  mapTo(param: HistoryDynamicIncentiveProgramModel): HistoryDynamicIncentiveProgramEntity {
    return {
      challengeReference: param.challengeReference,
      challengeName: param.challengeName,
      startedAt: param.startedAt.toString(),
      endedAt: param.endedAt.toString(),
      rewardsStatus: param.rewardsStatus,
      rewardsValue: param.rewardsValue,
      challengeFeedbackRate: param.challengeFeedbackRate,
      achievedOrders: param.achievedOrders,
      maxTargetOrdersCount: param.maxTargetOrdersCount,
      orderType: param.orderType,
    };
  }
}
