import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { QuestionnaireModel } from '../domain/questionnaire.model';
import { QuestionnaireRepository } from '../repositories/questionnaire.repository';

export class GetQuestionnaireQuestionsUseCase implements UseCase<string, QuestionnaireModel> {
  constructor(private questionnaireRepository: QuestionnaireRepository) {}

  execute(questionnaireName: string): Observable<QuestionnaireModel> {
    return this.questionnaireRepository.getQuestionnaireQuestions(questionnaireName);
  }
}
