/* eslint-disable @typescript-eslint/naming-convention */
import { AsyncPipe, DOCUMENT, Location, NgIf } from '@angular/common';
import { Component, Inject, inject, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { user } from '@features/user/data';
import events from '@mongez/events';
import { dialogAtom } from '@presentation/shared/dialog';
import { routerAtom } from '@presentation/shared/router';
import { toastAtom } from '@presentation/shared/toast';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import packageInfo from 'package.json';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mixPanelEvents } from '../v3/features/activities/data';
import { LogMixpanelEventUseCase } from './core/usecases/analytics/log-mixpanel-event.usecase';
import { SetUserAcquisitionChannelUseCase } from './core/usecases/auth/set-user-acquisition.usecase';
import { GetFeatureFlagUsecase } from './core/usecases/get-feature-flag.usecase';
import { IsFeatureEnabledUseCase } from './core/usecases/market-availability/is-feature-enabled.usecase';
import { TranslationRepositoryImplementation } from './data/repositories/translations/translation.repository.impl';
import { SubscribableComponent } from './presentation/base/subscribable.component';
import { CustomerEffortSupportComponent } from './presentation/customer-effort-support/customer-effort-support.component';
import { ActivationToastrComponent } from './presentation/shared/components/activation-toastr/activation-toastr.component';
import { LoaderComponent } from './presentation/shared/components/loader/loader.component';
import { OverlayComponent } from './presentation/shared/components/overlay/overlay.component';
import { APP_URLS_CONSTANTS, PRE_ORDERABLE_PRODUCTS } from './presentation/shared/constants';
import { FRESH_DESK_CHAT } from './presentation/shared/constants/feature-flags';
import { LocalStorageService } from './presentation/shared/services/local-storage.service';
import { MixpanelService } from './presentation/shared/services/mixpanel.service';
import { ThemeService } from './presentation/shared/services/theme.service';
import { VersionCheckService } from './presentation/shared/services/version-check.service';
import { resolveUserLandingUtility } from './presentation/shared/utilities/resolve-user-landing.utility';
import { showCustomerEffortSupportUtility } from './presentation/shared/utilities/show-customer-effort-support.utility';

declare let gtag: (config: string, id: string, options: { page_path: string }) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    LoaderComponent,
    OverlayComponent,
    MatDialogModule,
    CustomerEffortSupportComponent,
    AsyncPipe,
  ],
})
export class AppComponent extends SubscribableComponent implements OnInit {
  title = 'merchant-frontend';

  public chatModuleLoading = true;

  version = packageInfo.version;

  private options: GlobalConfig;

  private _renderer: Renderer2;

  public showCustomerEffortSupport = showCustomerEffortSupportUtility();

  private _dialog: MatDialog = inject(MatDialog);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    private versionCheckService: VersionCheckService,
    private toastr: ToastrService,
    private mixpanelService: MixpanelService,
    private localStorageService: LocalStorageService,
    private _setUserAcquisitionChannelUseCase: SetUserAcquisitionChannelUseCase,
    private _translationRepositoryImplementation: TranslationRepositoryImplementation,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _rendererFactory: RendererFactory2,
    private _themeService: ThemeService,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    super();

    this._renderer = this._rendererFactory.createRenderer(null, null);
  }

  private listenForToastAtomEvents(): void {
    toastAtom.onChange((toast) => {
      if (toast.type === 'success') {
        this.toastr.success(toast.message, toast.title);
      } else if (toast.type === 'error') {
        this.toastr.error(toast.message, toast.title);
      } else if (toast.type === 'info') {
        this.toastr.info(toast.message, toast.title);
      }
    });
  }

  ngOnInit(): void {
    this._doGetUserAcquisition();
    // TODO: remove the line below we are subscibing to the router.events twice on this file
    this.setupTracking();
    this.checkForVersionChange();
    this.setupRouterEvents();
    this.chatModuleLoading = false;
    this._setUserLanding();
    this._checkIfFreshDeskEnabled();

    this.listenForToastAtomEvents();
    this.listenForRouteChanges();

    this.fixMatSelectCrash();

    dialogAtom.silentChange('dialog', this._dialog);
  }

  /**
   * For some reason the aria-owns attribute is causing the mat-select to crash
   * specially when selecting multiple options
   * It does not happen on all machines but it happens rarely
   */
  protected fixMatSelectCrash(): void {
    document.addEventListener('DOMNodeInserted', () => {
      const elements = document.querySelectorAll('[aria-owns]');

      elements.forEach((element) => {
        element.removeAttribute('aria-owns');
      });
    });
  }

  private setupRouterEvents(): void {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.setPreOrderDarkHeader(event);
        }
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      },
    });
  }

  private listenForRouteChanges(): void {
    routerAtom.silentChange('router', this.router);
    routerAtom.silentChange('activatedRoute', this.activatedRoute);
    routerAtom.silentChange('location', this.location);

    this.subscribe(
      routerAtom.onChange((route) => {
        this.router.navigate([route.route], {
          queryParams: route.queryParams,
        });
      }),
    );

    this.subscribe(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          events.trigger('router.changing', event.url);
        }

        if (event instanceof NavigationEnd) {
          events.trigger('router.changed', event.url);
        }
      }),
    );
  }

  private setPreOrderDarkHeader(event: NavigationStart): void {
    if (
      event.url.includes(PRE_ORDERABLE_PRODUCTS) ||
      event.url.endsWith(APP_URLS_CONSTANTS.preOrder)
    ) {
      this._themeService.setDarkMode(true);
    } else {
      this._themeService.setDarkMode(false);
    }
  }

  private _doGetUserAcquisition(): void {
    this._setUserAcquisitionChannelUseCase.execute(document.referrer);
  }

  private _setUserLanding(): void {
    this._translationRepositoryImplementation.setTranslation(resolveUserLandingUtility().code);
  }

  setupTracking(): void {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-154454435-1', {
            page_path: event.urlAfterRedirects,
          });

          // if user came from login/register page, id would be bigger than 1, setupMixpanel is already called from there
          // check for user in cache so peoples is not called while user is not logged in

          this.mixpanelService.init();

          if (event.id === 1 && user.isLoggedIn) {
            this.mixpanelService.identify();
          }
        }
      },
    });

    mixPanelEvents.subscribe(({ eventName, data }) => {
      this._logMixpanelEventUseCase.execute({
        eventName,
        payload: data,
      });
    });
  }

  openActivationToast(): void {
    const opt = { ...this.options };
    opt.toastComponent = ActivationToastrComponent;
    this.toastr.show('لقد تم تحديث الويبسات هل تود تفعيل احدث التغييرات ؟!', '', {
      ...opt,
      disableTimeOut: true,
      closeButton: true,
    });
  }

  private checkForVersionChange(): void {
    const FORCE_RELOAD_VERSION_KEY = 'FORCE_RELOAD_VERSION_KEY';
    const currentForceReloadVersionInStorage =
      this.localStorageService.getStorage(FORCE_RELOAD_VERSION_KEY);
    this.versionCheckService.versionChange(environment.versionCheckURL).subscribe({
      next: (versionChangeMeta) => {
        if (versionChangeMeta.forceReload) {
          if (currentForceReloadVersionInStorage !== versionChangeMeta.versionNumber) {
            /**
             * if the current version in storage is not the same as the new
             * version to check against, then we force reload and persist the new version in storage
             */
            this.localStorageService.setStorage(
              FORCE_RELOAD_VERSION_KEY,
              versionChangeMeta.versionNumber,
            );
            location.reload();
          }
        } else if (versionChangeMeta.versionChanged) {
          this.openActivationToast();
        }
      },
    });
  }

  private _checkIfFreshDeskEnabled(): void {
    // Fresh desk is enabled for logged in users
    if (!user.isLoggedIn) return;

    // because user can be marked as logged in because of capturing JWT after first step of registration
    // we need to check if it has phone number or email is verified at least to enable chat

    if (!user.isEmailVerified && !user.isPhoneNumberVerified) return;

    combineLatest([this._getFeatureFlagUseCase.execute(FRESH_DESK_CHAT)]).subscribe({
      next: ([flag]) => {
        if (flag) {
          let { phoneNumber } = user;

          // make sure it is not null or undefined on any way as a string
          if (['null', 'undefined'].includes(phoneNumber)) {
            phoneNumber = '';
          }

          this._isFeatureEnabledUseCase.execute('chat').subscribe((isChatEnabled) => {
            if (isChatEnabled) {
              const script = this._renderer.createElement('script');
              script.setAttribute('chat', 'true');
              script.src = '//fw-cdn.com/10899000/3662069.js';
              this._renderer.appendChild(this._document.body, script);
              const userPropertiesScript = this._renderer.createElement('script');
              userPropertiesScript.text = `
              window.fcSettings = {
                onInit: function() {
                  window.fcWidget.setExternalId("${user.id}");
                  window.fcWidget.user.setFirstName("${user.fullName}");
                  window.fcWidget.user.setEmail("${user.email}");
                  window.fcWidget.user.setPhone("${phoneNumber}");
                }
              }
              `;
              this._renderer.appendChild(this._document.body, userPropertiesScript);
            }
          });
        }
      },
    });
  }
}
