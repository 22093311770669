import { Injectable } from '@angular/core';
import { Mapper } from '../../../../core/base/mapper';
import { Learning, LearningCourse } from '../../../../core/domain/learning.model';
import { LearningLocalEntity } from '../local/entities/learning-local-entity';
import { LearningRemoteEntity } from '../remote/entities/learning-remote-entity';
import { LearningCourseMapper } from './learning-course-mapper';

type Params = { remote: LearningRemoteEntity; local?: LearningLocalEntity };

@Injectable({
  providedIn: 'root',
})
export class LearningMapper extends Mapper<Params, Learning> {
  constructor(private _courseMapper: LearningCourseMapper) {
    super();
  }

  mapFrom(params: Params): Learning {
    return {
      courses: this.mapCourses(params),
    };
  }

  private mapCourses(params: Params): Array<LearningCourse> {
    return params.remote.courses.map((course) =>
      this._courseMapper.mapFrom({
        remote: course,
        local: params.local?.courses?.find((c) => c.id === course.id),
      }),
    );
  }

  mapTo(domain: Learning): Params {
    throw new Error('Unsupported');
  }
}
