import { Injectable } from '@angular/core';
import { DISPLAY_NEW_PRODUCTS_PAGE } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from '../../../../presentation/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsPageExperienceCache {
  constructor(private _localStorageService: LocalStorageService) {}

  get(): boolean {
    return this._localStorageService.getStorage(DISPLAY_NEW_PRODUCTS_PAGE);
  }

  put(oldProductsPageSelected: boolean) {
    if (oldProductsPageSelected) {
      this._localStorageService.setStorage(DISPLAY_NEW_PRODUCTS_PAGE, 'true');
    } else {
      this._localStorageService.setStorage(DISPLAY_NEW_PRODUCTS_PAGE, 'false');
    }
  }
}
