import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { map, Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import {
  CART_TAB,
  CATALOG_TAB,
  SHOPPING_NAVIGATION_TABS,
} from 'src/app/presentation/shared/constants/header-tabs';
import { ShoppingNavigationTabInterface } from 'src/app/presentation/shared/interfaces/header.interface';
import { ProfileService } from 'src/app/presentation/shared/services/profile.service';
import { SharedService } from 'src/app/presentation/shared/services/shared.service';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';

@Component({
  selector: 'app-shopping-navigation',
  templateUrl: './shopping-navigation.component.html',
  styleUrls: ['./shopping-navigation.component.scss'],
  standalone: true,
  imports: [NgFor, RouterLink, AsyncPipe, NgClass],
})
export class ShoppingNavigationComponent implements OnInit {
  shoppingNavigationTabs: ShoppingNavigationTabInterface[];

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  darkModeClass$: Observable<string>;

  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService,
    private _themeService: ThemeService,
  ) {
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  ngOnInit(): void {
    this.shoppingNavigationTabs = SHOPPING_NAVIGATION_TABS.map((tab) => ({
      ...tab,
      productsCount: 0,
    }));

    this.sharedService.shoppingItemsCountChangedListener().subscribe({
      next: () => {
        this.profileService.getMerchantShoppingSummary().subscribe({
          next: (shoppingSummary) => {
            this.assignCountToTab(
              CART_TAB.tabName,
              shoppingSummary?.merchantShoppingSummary?.cartCount,
            );
            this.assignCountToTab(
              CATALOG_TAB.tabName,
              shoppingSummary?.merchantShoppingSummary?.catalogCount,
            );
          },
        });
      },
    });
  }

  onNavigateToTab(tab: any): void {
    this._logMixpanelEventUseCase.execute({ eventName: tab.mixpanelEventName });
  }

  assignCountToTab(tabName: string, count: number): void {
    const filteredTab = this.shoppingNavigationTabs.filter((tab) => tab.tabName === tabName)[0];
    if (filteredTab) {
      filteredTab.productsCount = count;
    }
  }
}
