import { UseCase } from '../../base/use-case';
import { AnalyticsRepository } from '../../repositories/analytics.repository';
import { AuthRepository } from '../../repositories/auth.repository';

export class LogoutUseCase implements UseCase<void, void> {
  constructor(
    private _authRepository: AuthRepository,
    private _analyticsRepository: AnalyticsRepository,
  ) {}

  /**
   * So, I am not sure if it is ideal for a use case to do more than one thing,
   * however, I believe because this is the action of loging out, then in that
   * case we should 'logout user' in all aspects of being previously 'logged in'
   */
  execute(): void {
    this._analyticsRepository.resetMixpanelInstance();
    this._authRepository.logout();
  }
}
