export const ZID_PROVIDER_NAME = 'ZID';
export const YOUCAN_PROVIDER_NAME = 'YOUCAN';
export const WOOCOMMERCE_PROVIDER_NAME = 'WOOCOMMERCE';
export const EASY_ORDERS_PROVIDER_NAME = 'EASY_ORDERS';
export const DUKAN_PROVIDER_NAME = 'DUKAN';

export const storesQuestions = [
  {
    title: 'STORES.QUESTIONS.QUESTION_1_TITLE',
    content: 'STORES.QUESTIONS.QUESTION_1_CONTENT',
  },
  {
    title: 'STORES.QUESTIONS.QUESTION_2_TITLE',
    content: 'STORES.QUESTIONS.QUESTION_2_CONTENT',
  },
];

export const storesCourses = {
  [EASY_ORDERS_PROVIDER_NAME]: {
    title: 'STORES.EASY_ORDERS.COURSE_TITLE',
    course_content: [
      {
        course_title: 'STORES.EASY_ORDERS.FIRST_COURSE',
        course_link: 'https://taager.com/sa/learning-center/easy-orders1',
      },
      {
        course_title: 'STORES.EASY_ORDERS.SECOND_COURSE',
        course_link: 'https://taager.com/sa/learning-center/easy-orders2',
      },
      {
        course_title: 'STORES.EASY_ORDERS.THIRD_COURSE',
        course_link: 'https://taager.com/sa/learning-center/easy-orders3',
      },
      {
        course_title: 'STORES.EASY_ORDERS.FOURTH_COURSE',
        course_link: 'https://taager.com/sa/learning-center/easy-orders4',
      },
    ],
  },
  [YOUCAN_PROVIDER_NAME]: {
    title: 'STORES.YOUCAN.COURSE_TITLE',
    course_content: [
      {
        course_title: 'STORES.YOUCAN.FIRST_COURSE',
        course_link: 'https://taager.com/sa/learning-center/youcan1',
      },
      {
        course_title: 'STORES.YOUCAN.SECOND_COURSE',
        course_link: 'https://taager.com/sa/learning-center/youcan2 ',
      },
    ],
  },
  [WOOCOMMERCE_PROVIDER_NAME]: {
    title: 'STORES.WOOCOMMERCE.COURSE_TITLE',
    course_content: [
      {
        course_title: 'STORES.WOOCOMMERCE.FIRST_COURSE',
        course_link: 'https://taager.com/sa/learning-center/taager-wordpress-plugin1',
      },
    ],
  },
  [ZID_PROVIDER_NAME]: {
    title: 'STORES.ZID.COURSE_TITLE',
    course_content: [
      {
        course_title: 'STORES.ZID.FIRST_COURSE',
        course_link: 'https://taager.com/sa/learning-center/taager-zid-plugin1',
      },
    ],
  },
};
