import { Observable } from 'rxjs';
import { UseCase } from 'src/app/core/base/use-case';
import {
  WalletTransactionHistoryModel,
  WalletTransactionRequestFilterModel,
} from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class GetWalletTransactionHistoryUseCase
  implements UseCase<WalletTransactionRequestFilterModel, WalletTransactionHistoryModel>
{
  constructor(private _walletRepository: WalletRepository) {}

  execute(params: WalletTransactionRequestFilterModel): Observable<WalletTransactionHistoryModel> {
    return this._walletRepository.getWalletTransactionHistory(params);
  }
}
