import { Observable } from 'rxjs';
import { Learning, LearningCourse } from '../domain/learning.model';

export abstract class LearningRepository {
  abstract getLearning(): Observable<Learning>;

  abstract getCourseById(courseId: string): Observable<LearningCourse | undefined>;

  abstract markLessonAsCompleted(courseId: string, lessonId: string): Observable<boolean>;
}
