<div class="wrapper">
  <div class="wrapper__overlay"></div>
  <div #wrapperContentRef class="wrapper__content">
    <span
      appCountdownToInteractivity
      [countdownInSeconds]="countdownTimeInSeconds"
      [countdownElement]="countdownElement"
      [data]="{ showCountdown: true, currentTime: countdownTimeInSeconds }"
    ></span>
    <img
      *ngIf="activeQuestion?.iconUrl"
      class="wrapper__content__active-question-icon"
      [src]="activeQuestion?.iconUrl"
      alt="question-icon"
    />
    <h2 class="body2--bold wrapper__content__title">
      {{ 'CUSTOMER_EFFORT_SUPPORT.SALUTATION' | translate }}
    </h2>
    <app-ces-questionnaire
      [questionnaire]="questionnaire"
      (setActiveQuestion$)="onActiveQuestion($event)"
      (submitAnswer$)="submitQuestionnaireAnswers($event)"
    ></app-ces-questionnaire>
  </div>
</div>

<ng-template #countdownElement let-data>
  <ng-container
    *ngTemplateOutlet="data.showCountdown ? showCountdownActionTemplate : showCountdownTemplate"
  ></ng-container>
  <ng-template #showCountdownActionTemplate>
    <div class="wrapper__content__countdown-wrapper">
      <div class="wrapper__content__countdown-wrapper__label">
        <span class="caption2--medium">{{
          'CUSTOMER_EFFORT_SUPPORT.WAIT_BEFORE_DISMISSING' | translate
        }}</span>
      </div>
      <div class="wrapper__content__countdown-wrapper__indicator">
        <div class="wrapper__content__countdown-wrapper__indicator__timer">
          <span class="caption1--bold">{{ data.currentTime }}</span>
        </div>
        <div
          class="wrapper__content__countdown-wrapper__indicator__progress {{
            siteTranslateService.currentDir
          }}"
        >
          <div class="wrapper__content__countdown-wrapper__indicator__progress__outer">
            <div
              class="wrapper__content__countdown-wrapper__indicator__progress__outer__zero"
            ></div>
            <div
              class="wrapper__content__countdown-wrapper__indicator__progress__outer__one"
              [ngStyle]="{
                'animation-duration': countdownTimeInSeconds / 2 + 's',
                'animation-delay': countdownTimeInSeconds / 2 + 's'
              }"
            ></div>
            <div
              class="wrapper__content__countdown-wrapper__indicator__progress__outer__two"
              [ngStyle]="{ 'animation-duration': countdownTimeInSeconds / 2 + 's' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #showCountdownTemplate>
    <img
      class="wrapper__content__cancel-filling-questionnaire"
      (click)="cancelFillingQuestionnaire()"
      src="{{ assetsPath + 'cancel-filling-questionnaire.svg' }}"
      alt="cancel-filling-questionnaire"
    />
  </ng-template>
</ng-template>
