<div class="info-header-wrapper" [ngClass]="darkModeClass$ | async">
  <div class="mobile-app-banner">
    <ng-container *ngIf="!isLTR" [ngTemplateOutlet]="languageSelector"></ng-container>
    <i class="icon icon-smartphone mobile-app-banner__image"></i>
    <p class="mobile-app-banner__announcement-text caption2--medium">
      {{ trans('downloadApp') }}
    </p>
    <a
      href="https://play.google.com/store/apps/details?id=com.taager.merchant&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      class="mobile-app-banner__link"
      (click)="onTrackMixpanelEvent('nav_mobile_click')"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        loading="lazy"
        class="mobile-app-banner__google-play-button"
        src="{{ 'HEADER.INFO_HEADER.GOOGLE_PLAY_BUTTON_PATH' | translate }}"
        alt="Google Play button"
      />
    </a>
  </div>
  <section class="info-links">
    <a
      *ngIf="shoudShowRequestProduct"
      class="info-links__link"
      (click)="openRequestProductDialog()"
    >
      <p class="info-links__text caption2--medium">
        {{ 'HEADER.INFO_HEADER.SUGGEST_PRODUCT' | translate }}
      </p>
    </a>
    <div *ngFor="let tab of infoHeaderTabs">
      <a
        *ngIf="!tab.isExternalLink"
        class="info-links__link"
        [routerLink]="tab.routerLink"
        (click)="onTrackMixpanelEvent(tab.mixpanelEventName)"
      >
        <p class="info-links__text caption2--medium">{{ tab.tabName | translate }}</p>
      </a>
      <a
        *ngIf="tab.isExternalLink"
        class="info-links__link"
        href="{{ tab.href }}"
        target="_blank"
        rel="noopener noreferrer"
        (click)="onTrackMixpanelEvent(tab.mixpanelEventName)"
      >
        <p class="info-links__text caption2--medium">{{ tab.tabName | translate }}</p>
      </a>
    </div>
    <ng-container *ngIf="isLTR" [ngTemplateOutlet]="languageSelector"></ng-container>
  </section>
</div>

<ng-template #languageSelector>
  <mat-select
    *ngIf="shouldShowTranslationOption"
    [(ngModel)]="siteTranslateService.defaultLanguageValue"
    class="language-selector body2--medium"
  >
    <mat-option
      *ngFor="let languageOption of siteTranslateService.languageOptions"
      [value]="languageOption.value"
      class="body2--medium"
      (click)="siteTranslateService.setUserLanguage(languageOption.value)"
    >
      {{ languageOption.text }}
    </mat-option>
  </mat-select>
</ng-template>
