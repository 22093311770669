import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, shareReplay } from 'rxjs';

import { OrderModel } from 'src/app/core/domain/order-model';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { ShoppingSummaryInterface } from 'src/app/presentation/shared/interfaces/shopping-summary.interface';
import { DateWrapperUtility } from '../utilities/date-wrapper.utility';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _profileCache: Observable<{ data: UserModel }> | null;

  constructor(private http: HttpClient) {}

  updateProfileDeviceTokens(body: any): Observable<any> {
    return this.http.patch(API_URLS.UPDATE_PROFILE_DEVICE_TOKENS_URL, body);
  }

  getUserOrders(pageSize: number, pageNum: number, filter: any): Observable<any> {
    if (filter.toDate) {
      filter.toDate = DateWrapperUtility.getTheEndOfATimeUnit(filter.toDate, 'day');
    }
    const bodyData = { pageSize, page: pageNum, filterObj: filter };
    return this.http.post(API_URLS.GET_USER_ORDERS_URL, bodyData);
  }

  getUserOrdersExtract(filter: any): Observable<OrderModel[]> {
    const bodyData = { filterObj: filter };
    return this.http
      .post<{ data: OrderModel[] }>(API_URLS.GET_USER_ORDERS_EXTRACT_URL, bodyData)
      .pipe(map(({ data }) => data));
  }

  searchInUserOrders(pageSize: number, pageNum: number, filter: any): Observable<any> {
    const bodyData = { pageSize, page: pageNum, filter };
    return this.http.post(API_URLS.SEARCH_IN_USER_ORDERS_URL, bodyData);
  }

  searchInUserChildOrders({
    pageSize,
    pageNum,
    searchKey,
  }: {
    pageNum: number;
    pageSize: number;
    searchKey: string;
  }): Observable<any> {
    return this.http.get(
      API_URLS.SEARCH_IN_USER_CHILD_ORDERS_URL({ pageSize, pageNum, searchKey }),
    );
  }

  getMerchantShoppingSummary(): Observable<ShoppingSummaryInterface> {
    return this.http.get(
      API_URLS.GET_MERCHANT_SHOPPING_SUMMARY,
    ) as Observable<ShoppingSummaryInterface>;
  }

  public getUserProfile(): Observable<{ data: UserModel }> {
    if (!this._profileCache) {
      this._profileCache = this.http
        .get<{ data: UserModel }>(API_URLS.GET_PROFILE_URL)
        .pipe(shareReplay(1));
    }
    return this._profileCache;
  }

  public clearProfileCache(): void {
    this._profileCache = null;
  }
}
