/* eslint-disable max-classes-per-file */
export class TreeNode<Type> {
  key: string;

  value: Type;

  parent: TreeNode<Type> | null;

  children: TreeNode<Type>[];

  constructor(key: string, value: Type, parent: TreeNode<Type> | null = null) {
    this.key = key;
    this.value = value;
    this.parent = parent;
    this.children = [];
  }

  get isLeaf(): boolean {
    return this.children.length === 0;
  }

  get hasChildren(): boolean {
    return !this.isLeaf;
  }
}

export class Tree<Type> {
  root: TreeNode<Type>;

  constructor(key: string, value: Type) {
    this.root = new TreeNode<Type>(key, value);
  }

  *preOrderTraversal(node: TreeNode<Type> = this.root): any {
    yield node;
    if (node.children.length) {
      for (const child of node.children) {
        yield* this.preOrderTraversal(child);
      }
    }
  }

  *postOrderTraversal(node: TreeNode<Type> = this.root): any {
    if (node.children.length) {
      for (const child of node.children) {
        yield* this.postOrderTraversal(child);
      }
    }
    yield node;
  }

  insert(parentNodeKey: string, key: string, value: Type): boolean {
    for (const node of this.preOrderTraversal()) {
      if (node.key === parentNodeKey) {
        node.children.push(new TreeNode(key, value, node));
        return true;
      }
    }
    return false;
  }

  insertMany(parentNodeKey: string, list: { key: string; value: Type }[]): boolean {
    for (const node of this.preOrderTraversal()) {
      if (node.key === parentNodeKey) {
        node.children.push(
          ...list.map((listItem) => new TreeNode(listItem.key, listItem.value, node)),
        );
        return true;
      }
    }
    return false;
  }

  remove(key: any): boolean {
    for (const node of this.preOrderTraversal()) {
      const filtered = node.children.filter((c: any) => c.key !== key);
      if (filtered.length !== node.children.length) {
        node.children = filtered;
        return true;
      }
    }
    return false;
  }

  find(key: any): TreeNode<Type> | null {
    for (const node of this.preOrderTraversal()) {
      if (node.key === key) {
        return node;
      }
    }
    return null;
  }
}
