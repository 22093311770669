import { NgFor } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { country } from '@features/country/data';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { CommercialCategoryTreeNode } from '../../shared/interfaces/commercial-categories.interface';
import { CategoryListItemComponent } from './category-list-item/category-list-item.component';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  standalone: true,
  imports: [NgFor, CategoryListItemComponent],
})
export class CategoryListComponent implements OnInit {
  @Output() categoryHoveredEmitter = new EventEmitter<string>();

  categoriesNodes: CommercialCategoryTreeNode[];

  hoveredCategoryId: string;

  constructor(private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase) {}

  ngOnInit(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (commercialCategoriesTree) => {
        if (commercialCategoriesTree) {
          this.categoriesNodes = commercialCategoriesTree.root.children;
        }
      },
    });
  }

  onCategoryHovered(categoryId: string): void {
    this.hoveredCategoryId = categoryId;
    this.categoryHoveredEmitter.emit(categoryId);
  }
}
