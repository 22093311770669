import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { StockAvailabilityEntity } from './entities/stock-availability-entity';

@Injectable({
  providedIn: 'root',
})
export class StockAvailabilityApisService {
  constructor(private _http: HttpClient) {}

  getStockAvailability(productId: string): Observable<StockAvailabilityEntity> {
    return this._http
      .get<{ data: StockAvailabilityEntity }>(API_URLS.GET_STOCK_AVAILABILITY_URL(productId))
      .pipe(map(({ data }) => data));
  }

  sendNotifyMeRequests(productId: string): Observable<void> {
    return of();
    // return this._http.post<void>(API_URLS.SEND_NOTIFY_ME_REQUESTS_URL(productId), '');
  }
}
