import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

/**
 * For now, we will just return true, because we really have no rules or checks to
 * decide whether the user can access the reset wallet password page.
 */
export const resetWalletPasswordResolver = (): Observable<boolean | UrlTree> => {
  return of(true);
};
