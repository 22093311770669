import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BaseOverlayDialogComponent } from 'src/app/presentation/shared/components/overlay/base-overlay-dialog.component';
import { MERCHANT_OPTIN_REDIRECT } from 'src/app/presentation/shared/constants';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';

@Component({
  selector: 'app-verification-succeeded-dialog',
  templateUrl: './verification-succeeded-dialog.component.html',
  styleUrls: ['./verification-succeeded-dialog.component.scss'],
  standalone: true,
})
export class VerificationSucceededDialogComponent extends BaseOverlayDialogComponent {
  public assetsPath: string = '/assets/img/';

  private _localStorageService: LocalStorageService = inject(LocalStorageService);

  private _router: Router = inject(Router);

  public dismissOverlay(): void {
    const postVerificationRedirect = this._localStorageService.getStorage(MERCHANT_OPTIN_REDIRECT);
    this._localStorageService.remove(MERCHANT_OPTIN_REDIRECT);
    super.dismissOverlay();
    this._router.navigate([postVerificationRedirect]);
  }
}
