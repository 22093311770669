export function featureAttributeAssign(featureAttribute: any, userId: any, userCountry?: string) {
  featureAttribute = JSON.parse(featureAttribute);
  if (featureAttribute.country?.length > 0) {
    const isAllowedInCountry = featureAttribute.country.includes(userCountry);
    if (
      (featureAttribute.status === true && isAllowedInCountry) ||
      (featureAttribute.taagerIds?.includes(userId) && isAllowedInCountry)
    ) {
      return true;
    }
    if (featureAttribute.status === 'odd' && userId % 2 !== 0 && isAllowedInCountry) {
      return true;
    }
    if (featureAttribute.status === 'even' && userId % 2 === 0 && isAllowedInCountry) {
      return true;
    }
    return false;
  }
  if (featureAttribute.status === true || featureAttribute.taagerIds?.includes(userId)) {
    return true;
  }
  if (featureAttribute.status === 'odd' && userId % 2 !== 0) {
    return true;
  }
  if (featureAttribute.status === 'even' && userId % 2 === 0) {
    return true;
  }
  return false;
}
