import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { COUNTRY_LANGUAGE_CODES_MAPPING } from 'src/app/presentation/shared/constants/country-language-codes-mapping.constants';

/**
 * Here we are adding an intentional technical depbt refactoring this into Clean Architecture.
 *
 * For now, I will be using this directly in the Presentational Layer, without UseCase intervention.
 */

@Injectable({
  providedIn: 'root',
})
export class TranslationRepositoryImplementation {
  constructor(private _translateService: TranslateService) {}

  public setTranslation(userLocation: string): void {
    this._translateService.use(
      COUNTRY_LANGUAGE_CODES_MAPPING[userLocation] || COUNTRY_LANGUAGE_CODES_MAPPING.eg,
    );
  }

  public instant(key: string): string {
    return this._translateService.instant(key);
  }
}
