import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { QuestionnaireSubmissionModel } from '../domain/questionnaire.model';
import { QuestionnaireRepository } from '../repositories/questionnaire.repository';

export class SubmitQuestionnaireAnswersUseCase
  implements
    UseCase<{ questionnaireName: string; data: QuestionnaireSubmissionModel }, { status: string }>
{
  constructor(private questionnaireRepository: QuestionnaireRepository) {}

  execute(params: {
    questionnaireName: string;
    data: QuestionnaireSubmissionModel;
  }): Observable<{ status: string }> {
    return this.questionnaireRepository.submitQuestionnaire(params);
  }
}
