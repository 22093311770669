import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { MerchantStore, MerchantStoreAuth } from '../../domain/merchant-store.model';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class LinkMerchantStoreUseCase
  implements UseCase<MerchantStoreAuth, Observable<MerchantStore>>
{
  constructor(private _repository: MerchantStoreRepository) {}

  execute(storeAuth: MerchantStoreAuth): Observable<MerchantStore> {
    return this._repository.linkStore(storeAuth);
  }
}
