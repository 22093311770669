import cache from '@mongez/cache';
import { GenericObject } from '@mongez/reinforcements';
import { FeatureManagerUseCasesResolver, type FeatureProviderContract } from '../../domain';
import { USER_FEATURES_CACHE_KEY } from '../constants';

class UserFeatureProvider implements FeatureProviderContract {
  /**
   * List of features
   */
  protected featuresList: GenericObject = {};

  protected isLoading = false;

  public async init(): Promise<void> {
    await this.loadFeatures();
  }

  public async isEnabled(featureName: string): Promise<boolean> {
    if (!this.isLoading) {
      return this.featuresList[featureName] || false;
    }

    return this.waitUntilLoaded(() => this.featuresList[featureName] || false);
  }

  public getValue<T>(featureName: string): Promise<T> {
    return this.waitUntilLoaded(() => this.featuresList[featureName] as T);
  }

  /**
   * Load features from API
   */
  protected async loadFeatures(): Promise<void> {
    this.setFeaturesList(await FeatureManagerUseCasesResolver.userFeatures());
  }

  /**
   * Wait until the features list is loaded
   */
  protected async waitUntilLoaded<T>(callback: () => T): Promise<T> {
    if (this.isLoading) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.waitUntilLoaded(callback));
        }, 50);
      });
    }

    return callback();
  }

  /**
   * Set features list
   */
  public setFeaturesList(featuresList: GenericObject): void {
    this.featuresList = featuresList;

    this.isLoading = false;

    cache.set(USER_FEATURES_CACHE_KEY, featuresList);
  }
}

export const userFeatureProvider = new UserFeatureProvider();
