export type CurrentUserStep =
  | 'signupLanding'
  | 'signupOtp'
  | 'signupDetails'
  | 'signupFullyVerified'
  | 'optInDetails'
  | 'optInEnterPhoneNumber'
  | 'optInEnterOTP'
  | 'optInSuccessVerifyingOTP'
  | 'onBoardingQuestion1'
  | 'onBoardingQuestion2'
  | 'onBoardingQuestion3';

export type Scope = 'signup' | 'opt-in';

export interface SignupStepsOutput {
  stepName: 'signupLanding' | 'signupOtp' | 'signupDetails';
}

export enum PossibleIndicatorSteps {
  signupLanding,
  signupOtp,
  signupDetails,
  optInDetails,
  optInEnterPhoneNumber,
  optInEnterOTP,
  onBoardingQuestion1 = 'onBoardingQuestion1',
  onBoardingQuestion2 = 'onBoardingQuestion2',
  onBoardingQuestion3 = 'onBoardingQuestion3',
  signupFullyVerified = 'signupFullyVerified',
}
