import ar from '@assets/translations/ar.json';
import en from '@assets/translations/en.json';
import { extend, groupedTranslations } from '@mongez/localization';
import './validation';

// we will use current translation files until we properly implement the new translation system
extend('ar', ar);
extend('en', en);

// Please update
groupedTranslations({
  somethingWentWrong: {
    en: 'Something went wrong, please try again',
    ar: 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرىي',
  },
  downloadApp: {
    en: 'Download Taager App',
    ar: 'حمل تطبيق تاجر',
  },
  email: {
    en: 'Email',
    ar: 'البريد الالكتروني',
  },
  password: {
    en: 'Password',
    ar: 'كلمة المرور',
  },
  send: {
    en: 'Send',
    ar: 'إرسال',
  },
  success: {
    en: 'Success',
    ar: 'تم بنجاح',
  },
  error: {
    en: 'Error',
    ar: 'خطأ',
  },
  info: {
    en: 'Info',
    ar: 'معلومة',
  },
});
