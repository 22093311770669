import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { CountryModel } from 'src/app/core/domain/country.model';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { CountryEntity } from './entities/country-entity';
import { CountryMapper } from './mappers/country.mapper';

@Injectable({
  providedIn: 'root',
})
export class CountriesApisService {
  private _countriesCache: CountryModel[];

  private _countriesMapper = new CountryMapper();

  constructor(private _httpClient: HttpClient) {}

  getCountryVATByIsoCode3(isoCode3: string): Observable<number> {
    if (this._countriesCache?.length) {
      return of(this._countriesCache.filter((country) => country.isoCode3 === isoCode3)[0].vat);
    }
    return this._httpClient.get<{ data: CountryEntity[] }>(API_URLS.GET_COUNTRIES_URL).pipe(
      map(({ data }) => data.map(this._countriesMapper.mapFrom)),
      tap((countries) => {
        this._countriesCache = countries;
      }),
      map((countries) => countries.filter((country) => country.isoCode3 === isoCode3)[0].vat),
    );
  }

  getCountries(): Observable<CountryModel[]> {
    if (this._countriesCache?.length) {
      return of(this._countriesCache);
    }
    return this._httpClient.get<{ data: CountryEntity[] }>(API_URLS.GET_COUNTRIES_URL).pipe(
      map(({ data }) => data.map(this._countriesMapper.mapFrom)),
      tap((countries) => {
        this._countriesCache = countries;
      }),
    );
  }
}
