import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FinalIncentiveProgramModel } from 'src/app/core/domain/new-incentive-program.model';
import { NewIncentiveProgramRepository } from 'src/app/core/repositories/new-incentive-program.repository';
import { NewIncentiveProgramMapper } from './mappers/new-incentive-program.mapper';
import { NewIncentiveProgramApi } from './new-incentive-program-api';

@Injectable({
  providedIn: 'root',
})
export class NewIncentiveProgramRepositoryImpl extends NewIncentiveProgramRepository {
  private _newIncentiveProgramMapper = new NewIncentiveProgramMapper();

  constructor(private newIncentiveProgramApi: NewIncentiveProgramApi) {
    super();
  }

  getNewIncentiveProgramDetails(): Observable<FinalIncentiveProgramModel> {
    return this.newIncentiveProgramApi
      .getNewUserIncentiveProgram()
      .pipe(map((response) => this._newIncentiveProgramMapper.mapFrom(response)));
  }
}
