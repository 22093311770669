import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserRepository } from '../../repositories/user.repository';

export class UpdateSwitchedSelectedMarketUseCase implements UseCase<string, void> {
  constructor(private _userRepository: UserRepository) {}

  execute(selectedMarket: string): Observable<void> {
    return this._userRepository.updateSwitchedSelectedMarket(selectedMarket);
  }
}
