import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { ChangePasswordFormModel } from '../../domain/auth/change-password.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class ChangePasswordUseCase implements UseCase<ChangePasswordFormModel, void> {
  constructor(private _authRepository: AuthRepository) {}

  execute(params: ChangePasswordFormModel): Observable<void> {
    return this._authRepository.changePassword(params);
  }
}
