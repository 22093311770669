import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../services/local-storage.service';
import { isUserLoggedIn } from './shared';

export const orderSummaryResolver = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  return isUserLoggedIn(router, localStorageService);
};
