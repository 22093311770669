import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { AuthRepository } from '../../repositories/auth.repository';

export class GetRegisterTokenUseCase implements UseCase<void, string> {
  constructor(private _authRepository: AuthRepository) {}

  execute(params: void): Observable<string> {
    return this._authRepository.getCurrentRegisterToken();
  }
}
