import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { RemoteConfigRepository } from '../repositories/remote-config.repository';

export class GetFeatureFlagUsecase implements UseCase<string, boolean> {
  constructor(private remoteConfigRepository: RemoteConfigRepository) {}

  execute(featureFlagName: string): Observable<boolean> {
    return this.remoteConfigRepository.getFeatureFlag(featureFlagName);
  }
}
