/**
 * A logged in user will need to fill in the onboarding questionnaire on the below conditions:
 *
 * 1. I must meet the criteria for viewing the onboarding page:
 *      - There must be an onboarding feature present.
 *      - I must be eligible for being onboarded i.e have less than N orders.
 *      - I must not have filled the onboarding questionnaire before.
 *      - I should be on a device which is supported by onboarding
 *
 *  - If 2 above is false, the best place a user can immediately access is
 *      PRODUCTS_V2_URL
 *  - If it is true, then:
 *      - Then resolve with user needing onboarding
 *      - The manager should be instantiated for the onboarding.
 */

import { user } from '@features/user/data';
import { Observable } from 'rxjs';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { GetQuestionnaireEligibilityUseCase } from 'src/app/core/usecases/get-questionnaire-eligibility.usecase';
import { IsUserEligibleForOnboardingUseCase } from 'src/app/core/usecases/is-user-eligible-for-onboarding-usecase';
import { OnboardingStoryGuideStateManager } from 'src/app/presentation/onboarding/state-manager/onboarding-story-guide.state-manager';
import { ONBOARDING_QUESTIONNAIRE_NAME } from 'src/app/presentation/shared/constants/questionnaire-name';

let needsToFillOnboardingQuestionnaire: boolean;

export const userNeedsToFillOnboardingQuestionnaire = ({
  getOnboardingFeatureUseCase,
  isUserEligibleForOnboardingUseCase,
  getQuestionnaireEligibilityUseCase,
  onboardingStoryGuideStateManager,
}: {
  getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase;
  isUserEligibleForOnboardingUseCase: IsUserEligibleForOnboardingUseCase;
  getQuestionnaireEligibilityUseCase: GetQuestionnaireEligibilityUseCase;
  onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager;
}): Observable<boolean> => {
  return new Observable((subscriber) => {
    if (user.isSignedUpUsingV3) {
      subscriber.next(false);
      return;
    }

    if (needsToFillOnboardingQuestionnaire !== undefined) {
      subscriber.next(needsToFillOnboardingQuestionnaire);
    } else {
      getOnboardingFeatureUseCase.execute().subscribe({
        next: (feature) => {
          if (feature) {
            getQuestionnaireEligibilityUseCase.execute(ONBOARDING_QUESTIONNAIRE_NAME).subscribe({
              next: ({ enabled }) => {
                isUserEligibleForOnboardingUseCase.execute().subscribe({
                  next: (isEligible) => {
                    if (isEligible) {
                      needsToFillOnboardingQuestionnaire = enabled;
                      if (window.innerWidth >= 992) {
                        onboardingStoryGuideStateManager.initialize(feature);
                      }
                    } else {
                      needsToFillOnboardingQuestionnaire = false;
                    }
                    subscriber.next(needsToFillOnboardingQuestionnaire);
                  },
                });
              },
            });
          } else {
            needsToFillOnboardingQuestionnaire = false;
            subscriber.next(needsToFillOnboardingQuestionnaire);
          }
        },
      });
    }
  });
};
