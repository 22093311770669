import { Mapper } from 'src/app/core/base/mapper';
import { OTPModel } from 'src/app/core/domain/auth/auth.model';
import { OTPEntity } from '../../auth/remote/entities/auth-entity';

export class OTPMapper extends Mapper<OTPEntity, OTPModel> {
  mapFrom(param: OTPEntity): OTPModel {
    return {
      otpPasscode: param.otpPasscode,
      checkCode: param.checkCode,
    };
  }

  mapTo(param: OTPModel): OTPEntity {
    return {
      otpPasscode: param.otpPasscode,
      checkCode: param.checkCode,
    };
  }
}
