import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopModel } from '../../domain/dukan-shop/dukan-shop.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class CreateDukanShopUseCase
  implements UseCase<{ name: string; slug: string }, DukanShopModel>
{
  constructor(private _repository: DukanShopRepository) {}

  execute(data: { name: string; slug: string }): Observable<DukanShopModel> {
    return this._repository.create(data.name, data.slug);
  }
}
