import { Observable } from 'rxjs';

import { UseCase } from '../../base/use-case';
import {
  ProvinceZoneDistrictFilterModel,
  ProvinceZoneDistrictModel,
} from '../../domain/provinces.model';
import { ProvinceRepository } from '../../repositories/province.repository';

export class GetProvinceZoneDistrictsUseCase
  implements UseCase<ProvinceZoneDistrictFilterModel, ProvinceZoneDistrictModel[]>
{
  constructor(private _provinceRepository: ProvinceRepository) {}

  execute(
    districtFilter: ProvinceZoneDistrictFilterModel,
  ): Observable<ProvinceZoneDistrictModel[]> {
    return this._provinceRepository.getProvinceZoneDistrictList(districtFilter);
  }
}
