import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { country } from '@features/country/data';
import { Observable } from 'rxjs';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { CREATIVE_REQUEST_FEATURE, LOGIN_URL, PRODUCTS_V2_URL } from '../../../constants';
import { CREATIVES_FEATURE } from '../../../constants/feature-flags';
import { LocalStorageService } from '../../../services/local-storage.service';
import { isUserLoggedIn, stringToUrlTree } from './shared';

export const CreativesResolver = (): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);
  const getFeatureFlagUseCase = inject(GetFeatureFlagUsecase);
  const checkUserFeatureExistsUseCase = inject(CheckUserFeatureExistsUseCase);
  return new Observable((subscriber) => {
    isUserLoggedIn(router, localStorageService).subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          getFeatureFlagUseCase.execute(CREATIVES_FEATURE).subscribe({
            next: (flagIsOn) => {
              const selectedCountry = country.code.toLocaleLowerCase();
              const isUserFeatureExist = checkUserFeatureExistsUseCase.execute(
                `${CREATIVE_REQUEST_FEATURE}${selectedCountry}`,
              );
              if (flagIsOn && isUserFeatureExist) {
                subscriber.next(true);
              } else {
                subscriber.next(stringToUrlTree(PRODUCTS_V2_URL, router));
              }
            },
          });
        } else {
          subscriber.next(stringToUrlTree(LOGIN_URL, router));
        }
      },
    });
  });
};
