import { Observable } from 'rxjs';
import { CommercialCategoryTree } from 'src/app/presentation/shared/interfaces/commercial-categories.interface';
import { UseCase } from '../base/use-case';
import { CommercialCategoryRepository } from '../repositories/commercial-categories.repository';

export class GetCommercialCategoriesUseCase implements UseCase<string, CommercialCategoryTree> {
  constructor(private commercialCategoryRepository: CommercialCategoryRepository) {}

  execute(countryCode: string): Observable<CommercialCategoryTree> {
    return this.commercialCategoryRepository.getCommercialCategories(countryCode);
  }
}
