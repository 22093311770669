import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DukanShopModel } from '../../domain/dukan-shop/dukan-shop.model';
import { DukanShopRepository } from '../../repositories/dukan-shop.repository';

export class UpdateDukanInfoUseCase implements UseCase<DukanShopModel, DukanShopModel> {
  constructor(private _dukanShopRepository: DukanShopRepository) {}

  execute(dukanData: DukanShopModel): Observable<DukanShopModel> {
    return this._dukanShopRepository.updateInfo(dukanData);
  }
}
