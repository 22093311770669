import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, shareReplay } from 'rxjs';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { API_URLS } from 'src/app/presentation/shared/constants';
import { CountriesApisService } from '../countries/countries-apis.service';
import {
  ProductReviewEntity,
  VariantEntity,
  VariantGroupEntity,
} from '../variant-group/entities/variant-group-entity';
import {
  ProductCheckoutCreateEntity,
  ProductCheckoutEntity,
  ProductCheckoutUpdateEntity,
} from './entities/product-details.entity';
import { PRODUCTS_ENDPOINTS } from './products-apis-endpoints';

@Injectable({
  providedIn: 'root',
})
export class ProductsApisService {
  private _productsCache: { [productId: string]: Observable<VariantModel> } = {};

  constructor(
    private _httpClient: HttpClient,
    private _countriesApisService: CountriesApisService,
  ) {}

  getProductVAT(params: {
    countryIsoCode3: string;
    productId: string;
    productSellingPrice: number;
    quantity: number;
    isOnSale: boolean;
  }): Observable<number> {
    if (!this._productsCache[params.productId]) {
      this._productsCache[params.productId] = this._httpClient
        .post<{ data: VariantEntity[] }>(API_URLS.GET_PRODUCTS_BY_PROD_IDS_URL, [params.productId])
        .pipe(
          map(({ data }) => data[0]),
          shareReplay(1),
        );
    }
    return forkJoin({
      product: this._productsCache[params.productId],
      countryVatPercentage: this._countriesApisService.getCountryVATByIsoCode3(
        params.countryIsoCode3,
      ),
    }).pipe(
      map(({ product, countryVatPercentage }) => {
        const productPriceOriginalOrSale =
          product.sale?.productPrice && params.isOnSale
            ? product.sale.productPrice
            : product.productPrice;
        const calculatedVAT =
          ((params.productSellingPrice - productPriceOriginalOrSale) *
            countryVatPercentage *
            params.quantity) /
          100;
        return calculatedVAT > 0 ? calculatedVAT : 0;
      }),
    );
  }

  // eslint-disable-next-line
  getPreorderableProducts(params?: any): Observable<any> {
    let queryParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        if (params[key] !== undefined && params[key] !== '') {
          queryParams = queryParams.set(key, params[key]);
        }
      });
    }
    return this._httpClient
      .get<{ results: VariantGroupEntity[]; count: number }>(
        API_URLS.GET_BULK_PREORDERABLE_PRODUCTS_URL,
        {
          params: queryParams,
        },
      )
      .pipe(
        map((response: { results: VariantGroupEntity[]; count: number }) => ({
          products: response.results,
          count: response.count,
        })),
      );
  }

  setProductReview(params: { data: ProductReviewEntity; productId: string }): Observable<void> {
    return this._httpClient.post<void>(API_URLS.PRODUCT_REVEIW_URL(params.productId), params.data);
  }

  getUpSellableProductsByProdId(productId: string): Observable<VariantEntity[]> {
    return this._httpClient.get<VariantEntity[]>(
      API_URLS.GET_UPSELLABLE_VARIANTS_BY_PROD_ID_URL(productId),
    );
  }

  getVariantsByProductIds(productIds: Array<string>): Observable<VariantEntity[]> {
    return this._httpClient
      .post<{ data: VariantEntity[] }>(API_URLS.GET_PRODUCTS_BY_PROD_IDS_URL, productIds)
      .pipe(map(({ data }) => data));
  }

  getProductCreativesEligibility(productId: string): Observable<{ isAllowed: boolean }> {
    return this._httpClient.get<{ isAllowed: boolean }>(
      PRODUCTS_ENDPOINTS.GET_PRODUCT_CREATIVES_ELIGIBILITY(productId),
    );
  }

  createProductCheckout(data: ProductCheckoutCreateEntity): Observable<void> {
    return this._httpClient.post<void>(PRODUCTS_ENDPOINTS.CREATE_PRODUCT_CHECKOUT, data);
  }

  getProductCheckoutDetail(productId: string): Observable<ProductCheckoutEntity> {
    return this._httpClient.get<ProductCheckoutEntity>(
      PRODUCTS_ENDPOINTS.GET_PRODUCT_CHECKOUT_DETAIL(productId),
    );
  }

  updateProductCheckout(data: ProductCheckoutUpdateEntity): Observable<void> {
    const filteredData = this.filterData(data);
    return this._httpClient.patch<void>(
      PRODUCTS_ENDPOINTS.UPDATE_PRODUCT_CHECKOUT_DETAIL(data.uuid),
      filteredData,
    );
  }

  filterData(data: any) {
    const updatedFilter = Object.entries(data)
      .filter(([_, v]) => v != null && v !== '')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    return updatedFilter;
  }
}
