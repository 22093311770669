import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CountdownToInteractivityDirective } from '../../shared/directives/countdown-to-interactivity.directive';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { getUserAgentDirectionalityUtility } from '../../shared/utilities/get-user-agent-directionality.utility';
import { CesQuestionnaireComponent } from '../ces-questionnaire/ces-questionnaire.component';
import { CESQuestionModel, CESQuestionnaire, CloseQuestionnaireMeta } from '../interfaces';

@Component({
  selector: 'app-ces-questionnaire-overlay',
  templateUrl: './ces-questionnaire-overlay.component.html',
  styleUrls: ['./ces-questionnaire-overlay.component.scss'],
  standalone: true,
  imports: [
    CountdownToInteractivityDirective,
    NgTemplateOutlet,
    NgStyle,
    CesQuestionnaireComponent,
    NgIf,
    TranslateModule,
  ],
})
export class CesQuestionnaireOverlayComponent {
  @Input() marginBottom: string;

  @Input() questionnaire: CESQuestionnaire;

  public assetsPath: string = 'assets/img/customer-effort-support/';

  public countdownTimeInSeconds: number = 5;

  public directionality: string = getUserAgentDirectionalityUtility();

  public siteTranslateService: SiteTranslateService = inject(SiteTranslateService);

  public activeQuestion: CESQuestionModel;

  @Output() closeQuestionnaire$: EventEmitter<CloseQuestionnaireMeta> = new EventEmitter();

  private _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  @ViewChild('wrapperContentRef', { static: true })
  private _wrapperContentRef: ElementRef<HTMLDivElement>;

  private _renderer2: Renderer2 = inject(Renderer2);

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const { marginBottom } = changes;
    if (marginBottom) {
      const { currentValue } = marginBottom;
      if (currentValue) {
        this._renderer2.setStyle(this._wrapperContentRef.nativeElement, 'bottom', currentValue);
      }
    }
  }

  public cancelFillingQuestionnaire(): void {
    this.closeQuestionnaire$.next({ action: 'cancel' });
  }

  public onActiveQuestion(question: CESQuestionModel): void {
    this.activeQuestion = question;
    this._changeDetectorRef.detectChanges();
  }

  public submitQuestionnaireAnswers(answers: { [question: string]: any }): void {
    this.closeQuestionnaire$.next({ action: 'fill', answers });
  }
}
