<div class="wrapper">
  <div class="wrapper__question">
    <div class="wrapper__question__label"></div>
    <div class="wrapper__question__choices">
      <div *ngFor="let ratingItem of ratings" class="wrapper__question__choices__choice">
        <div
          (click)="pickRating(ratingItem.value)"
          class="wrapper__question__choices__choice__value body2--bold {{
            questionForm.dirty && questionForm.get('rating')?.value === ratingItem.value
              ? 'selected'
              : ''
          }}"
        >
          {{ ratingItem.rating }}
        </div>
        <div
          *ngIf="ratingItem.label"
          class="wrapper__question__choices__choice__label caption3--medium"
        >
          {{ ratingItem.label }}
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper__footer">
    <div class="wrapper__footer__button">
      <ng-container
        *ngTemplateOutlet="position === 'last' ? submitAnswersTemplate : navigateToQuestionTemplate"
      ></ng-container>
      <ng-template #submitAnswersTemplate>
        <ng-template
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{
            context: {
              class:
                'wrapper__footer__button__submit caption1--medium ' +
                (questionForm.invalid ? 'disabled' : ''),
              label: 'CUSTOMER_EFFORT_SUPPORT.SUBMIT_ANSWERS'
            }
          }"
        ></ng-template>
      </ng-template>
      <ng-template #navigateToQuestionTemplate>
        <ng-template
          [ngTemplateOutlet]="buttonTemplate"
          [ngTemplateOutletContext]="{
            context: {
              class:
                'wrapper__footer__button__next caption1--medium ' +
                (questionForm.invalid ? 'disabled' : ''),
              label: 'CUSTOMER_EFFORT_SUPPORT.NEXT_QUESTION',
              icon: 'next-question.svg',
              alt: 'next question'
            }
          }"
        ></ng-template>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #buttonTemplate let-context="context">
  <button
    [disabled]="questionForm.invalid"
    class="{{ context.class }} {{ siteTranslateService.currentDir }}"
    (click)="goToNextQuestion([questionForm.get(controlName)?.value])"
  >
    {{ context.label | translate
    }}<img *ngIf="context.icon" src="{{ assetsPath + context.icon }}" alt="{{ context.alt }}" />
  </button>
</ng-template>
