import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UpdateProfileModel } from '../../domain/auth/update-profile.model';
import { UserRepository } from '../../repositories/user.repository';

export class UpdateProfileUseCase implements UseCase<UpdateProfileModel, void> {
  constructor(private userRepository: UserRepository) {}

  execute(params: UpdateProfileModel): Observable<void> {
    return this.userRepository.updateProfile(params);
  }
}
