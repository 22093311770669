import { Injectable } from '@angular/core';
import { GetUserSuperPropertiesUseCase } from 'src/app/core/usecases/user/get-user-super-properties.usecase';
import { Tracking } from './tracking.interface';

@Injectable({
  providedIn: 'root',
})
export class AppTracker implements Tracking {
  constructor(
    private _trackers: Array<Tracking>,
    private _getUserSuperPropertiesUseCase: GetUserSuperPropertiesUseCase,
  ) {}

  initialize(): void {
    this._trackers.forEach((tracker) => {
      tracker.initialize();
    });
  }

  identifyUser(properties?: { [key: string]: string }): void {
    const superProperties = this._getUserSuperPropertiesUseCase.execute();
    this._trackers.forEach((tracker) => {
      tracker.identifyUser({ ...properties, ...superProperties });
    });
  }

  setUserProperty(property: { [key: string]: string }): void {
    this._trackers.forEach((tracker) => {
      tracker.setUserProperty(property);
    });
  }

  sendEvent(eventName: string, payload?: any): void {
    this._trackers.forEach((tracker) => {
      tracker.sendEvent(eventName, payload);
    });
  }
}
