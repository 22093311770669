import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { StockAvailabilityRepository } from '../../repositories/stock-availability.repository';

export class SendNotifyMeRequestsUseCase implements UseCase<string, void> {
  constructor(private _getStockAvailabilityRepository: StockAvailabilityRepository) {}

  execute(productId: string): Observable<void> {
    return this._getStockAvailabilityRepository.sendNotifyMeRequests(productId);
  }
}
