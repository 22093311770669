import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Merchant from '@taager-experience-shared/merchant';
import { TrackErrorUseCase } from '../../../core/usecases/analytics/track-error.usecase';
import { LogoutUseCase } from '../../../core/usecases/auth/logout.usecase';
import { appUrlsConstantsInjectionToken } from '../../../data/injection-tokens/app-urls-constants.injection-token';
import ApiLogMonitor = Merchant.com.taager.experience.api.logging.ApiLogMonitor;
import ApiExceptionMonitor = Merchant.com.taager.experience.api.exception.ApiExceptionMonitor;
import ApiException = Merchant.com.taager.experience.data.exception.ApiException;

@Injectable({
  providedIn: 'root',
})
export class ExperienceApiMonitor implements ApiLogMonitor, ApiExceptionMonitor {
  constructor(
    private readonly _trackError: TrackErrorUseCase,
    private readonly _router: Router,
    private readonly _logout: LogoutUseCase,
    @Inject(appUrlsConstantsInjectionToken) private readonly _appURLs: { [url: string]: string },
  ) {}

  public onLog(message: string) {
    // TODO Send logs to the crashlytics or any log manager
  }

  public onException(cause: ApiException) {
    this.trackError(cause);
    if (this._shouldLogOut(cause)) {
      this._doLogOut();
    }
    // TODO Report non-fatal events to the crashlytics or any error manager
  }

  private trackError(cause: ApiException) {
    this._trackError.execute({
      message: '[API]',
      cause,
    });
  }

  private _shouldLogOut(cause: ApiException): boolean {
    return cause instanceof ApiException.Client.Unauthorized && this.isNotAuthenticationUrl();
  }

  private isNotAuthenticationUrl(): boolean {
    return !this._router.url.startsWith(this._appURLs.AUTH_V2_URL);
  }

  private _doLogOut() {
    this._logout.execute();
    this._router.navigate([this._appURLs.LOGIN_V2_URL]).then();
  }
}
