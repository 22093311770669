<mat-sidenav-container class="sidenav-container" [ngClass]="viewState.darkModeClass$ | async">
  <mat-sidenav
    #sidenav
    autoFocus="false"
    mode="over"
    [(opened)]="viewState.isMenuOpen"
    class="sidenav"
    fixedInViewport="true"
  >
    <section
      class="sidenav__top-section"
      [ngClass]="{ 'sidenav__top-section--shifted': viewState.isSmartAppBannerVisible }"
    >
      <img
        loading="lazy"
        src="assets/img/header-icons/taager-logo-2022.svg"
        alt="Taager's logo"
        class="sidenav__taager-logo"
        width="200"
        height="35"
      />
      <button
        class="sidenav__close-button"
        (click)="presenter.emitViewEvent({
        type: 'ClickCloseSideNav',
      })"
      >
        <img
          loading="lazy"
          src="assets/img/close.png"
          alt=""
          class="sidenav__close-button__icon"
          width="15"
          height="15"
        />
      </button>
    </section>
    <div>
      <div class="sidenav__countries-dropdown-wrapper">
        <app-countries-dropdown></app-countries-dropdown>
      </div>

      <section class="sidenav__middle-section">
        <app-taager-sidenav-button
          *ngFor="let tab of viewState.firstSectionSidenavTabs"
          [tab]="tab"
          [shouldShowChevronButton]="true"
          (tabClicked)="presenter.emitViewEvent({
            type: 'ClickCloseSideNav',
          })"
        ></app-taager-sidenav-button>
      </section>

      <div class="sidenav__separator"></div>

      <div class="sidenav__bottom-section">
        <app-taager-sidenav-button
          *ngFor="let tab of viewState.secondSectionSidenavTabs"
          [tab]="tab"
          (tabClicked)="presenter.emitViewEvent({
            type: 'ClickCloseSideNav',
          })"
        ></app-taager-sidenav-button>
        <a
          *ngIf="viewState.shouldShowRequestProduct"
          class="sidenav-button-side"
          (click)="presenter.emitViewEvent({
            type: 'ClickRequestProductDialog',
          })"
        >
          <img class="info-links__icon" src="assets/img/header-icons/suggest-outline.png" />
          <p class="sidenav-text body1--medium">
            {{ 'HEADER.INFO_HEADER.SUGGEST_PRODUCT' | translate }}
          </p>
        </a>

        <button
          class="sidenav__sign-out-button body1--medium"
          (click)="presenter.emitViewEvent({
          type: 'ClickLogout',
        })"
        >
          {{ 'HEADER.PROFILE_DROPDOWN.LOGOUT' | translate }}
        </button>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header></app-header>
    <div
      class="page-wrapper"
      [ngClass]="{
        'page-wrapper--shifted': viewState.isSmartAppBannerVisible
      }"
    >
      <router-outlet class="page-height"></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-kyc-popup></app-kyc-popup>

<!-- So, we will have a couple of named routes within this default layout component -->

<!-- So, for now, since we have just one named outlet, we will name it as popup -->
<router-outlet name="popup"></router-outlet>
