/* eslint-disable prefer-destructuring */
import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { VerificationState } from 'src/app/data/repositories/auth/remote/entities/verification.entity';
import { CurrentUserStep } from '../components/shared-stepper-indicator/interfaces';
import { EMAIL_OTP_STATE, SKIP_EMAIL_OTP } from '../constants';
import { WEB_SIGNUP_V2 } from '../constants/feature-flags';
import { LocalStorageService } from '../services/local-storage.service';
import { featureAttributeAssign } from './feature-attribute-assign.utility';
import { JWTDecoder } from './jwt-decoder.utility';

/**
 * This utility class will make it possible for us to have a standard way of deciding the
 * step that the user is at.
 *
 * The possible steps are:
 *
 * 0. new users
 * 1. unverified
 * 2. phone-number-verified
 * 3. merchant-data-verified
 *
 * CAUTION: Since this will be shared, it is best to minimize:
 *
 * 1. Side effects
 * 2. Imports - since these may cause unintended bloating. IFF imports are really needed,
 * then consider using tree-shaking imports.
 */

/**
 * This is the routable component, and it is the one which will now, make call to the backend and then
 * it will receive the JWT token, which it will now use, to assess which step the user is currently
 * at, and then return 1 of these 3 steps:
 *
 * 1. landing
 * 2. otp
 * 3. details
 *
 * unverified -> OTP not verified
 * phone-number-verfied -> OPT is verified
 * merchant-data-verfied -> OTP and Data is filled and verified
 */

/**
 * UPDATE!
 *
 * So, we have the luxury of using two types of tokens:
 * 1. The one we receive directly from the backend, during a flow and it is this which we will always
 * prioritize.
 * 2. In other cases, when we don't have such a token, for example when the user is reloading the page,
 * maybe the landing page, then in that case, we will use what we may have in storage.
 *
 * TODO: Leaving this line of thought here for now:
 *
 * Unless the user is verified, then upon login, we don't store their information to storage, just because
 * they may get 'clever' and change the information on storage.
 */
@Injectable({
  providedIn: 'root',
})
export class UserRegistrationStepUtility {
  constructor(
    private _localStorageService: LocalStorageService,
    private _getFeatureAttribute: GetFeatureAttributeUsecase,
  ) {}

  public currentUserStep(jwtToken: string): CurrentUserStep {
    this._getFeatureAttribute.execute(WEB_SIGNUP_V2).subscribe({
      next: (attribute) => {
        const shouldShowEmailOTP = featureAttributeAssign(attribute, user.id);
        this._localStorageService.setStorage(EMAIL_OTP_STATE, shouldShowEmailOTP?.toString());
      },
    });

    const verificationState = (
      JWTDecoder(jwtToken && jwtToken !== '' ? jwtToken : user.accessToken) as any
    )?.user.verificationState;

    let verdict: CurrentUserStep;
    if (this._isUserOnLandingStep(verificationState)) {
      verdict = 'signupLanding';
    } else if (this._isUserOnOTPStep(verificationState)) {
      verdict = 'signupOtp';
    } else if (this._isUserOnDetailsStep(verificationState)) {
      verdict = 'signupDetails';
    } else if (this._isUserDataAlreadyVerified(verificationState)) {
      verdict = 'signupFullyVerified';
    }
    return verdict!;
  }

  private _isUserOnLandingStep(verificationState: VerificationState): boolean {
    if (!verificationState) {
      return true;
    }
    return false;
  }

  private _isUserOnOTPStep(verificationState: VerificationState): boolean {
    const verifyEmailOTP = this._localStorageService.getStorage(EMAIL_OTP_STATE);
    const skipEmailOTP = this._localStorageService.getStorage(SKIP_EMAIL_OTP);
    return (
      !!(!verificationState.phoneNumberVerified && !verificationState.merchantDataVerified) ||
      (verifyEmailOTP && !skipEmailOTP && !verificationState.emailVerified)
    );
  }

  private _isUserOnDetailsStep(verificationState: VerificationState): boolean {
    return !!(verificationState.phoneNumberVerified && !verificationState.merchantDataVerified);
  }

  private _isUserDataAlreadyVerified(verificationState: VerificationState): boolean {
    return verificationState.merchantDataVerified;
  }
}
