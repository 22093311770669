import { Mapper } from 'src/app/core/base/mapper';
import { CountryModel } from 'src/app/core/domain/country.model';
import { CountryEntity } from '../entities/country-entity';

export class CountryMapper extends Mapper<CountryEntity, CountryModel> {
  mapFrom(countryEntity: CountryEntity): CountryModel {
    return {
      isoCode2: countryEntity.countryIsoCode2,
      isoCode3: countryEntity.countryIsoCode3,
      isoNumber: countryEntity.countryIsoNumber,
      currencyIsoCode: countryEntity.currencyIsoCode,
      vat: countryEntity.vat,
    };
  }

  mapTo(countryModel: CountryModel): CountryEntity {
    return {
      countryIsoCode2: countryModel.isoCode2,
      countryIsoCode3: countryModel.isoCode3,
      countryIsoNumber: countryModel.isoNumber,
      currencyIsoCode: countryModel.currencyIsoCode,
      vat: countryModel.vat,
    };
  }
}
