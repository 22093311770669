import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { InsightsType, MerchantInsightsModel } from 'src/app/core/domain/merchant-insights.model';
import { MERCHANT_INSIGHTS_APIS_ENDPOINTS } from './merchant-insights-apis-endpoints';
import { InsightsLocalDataSource } from './merchant-insights-data-source';

@Injectable({
  providedIn: 'root',
})
export class MerchantInsightsApisService {
  constructor(
    private _http: HttpClient,
    private _insightsLocalDataSource: InsightsLocalDataSource,
  ) {}

  getInsightsUrl(type: InsightsType, id: number): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(MERCHANT_INSIGHTS_APIS_ENDPOINTS.GET_INSIGHTS_URL(), {
      params: { type, id },
    });
  }

  getInsightsData(): Observable<MerchantInsightsModel[]> {
    return of(this._insightsLocalDataSource.getInsights());
  }
}
