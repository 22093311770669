import {
  APP_URLS_CONSTANTS,
  CART_URL,
  CATALOG_URL,
  COMPLAIN_SUGGEST_URL,
  DYNAMIC_INCENTIVE_FEATURE,
  INCENTIVE_URL,
  LEARNING_CENTER_URL,
  LOGIN_URL,
  LOYALTY_PROGRAM_FEATURE,
  LOYALTY_URL,
  MERCHANT_INSIGHTS_URL,
  ORDERS_URL,
  PRE_ORDER_REQUESTS_URL,
  PRODUCTS_V2_URL,
  PRODUCT_CHECKOUT_URL,
  PROFILE_URL,
  REFERRAL_PROGRAM_FEATURE,
  REFERRAL_URL,
  REGISTER_URL,
  SHIPPING_INFO_URL,
  STATISTICS_URL,
  WALLET_URL,
} from '.';
import { HeaderTab } from '../interfaces/header.interface';
import { LOYALTY_PROGRAM, REFERRAL_PROGRAM, WEB_DYNAMIC_INCENTIVE_PROGRAM } from './feature-flags';

export const INFO_HEADER_TABS: HeaderTab[] = [
  {
    href: 'https://www.youtube.com/playlist?list=PLmEy_hlZ7kB8IntnGW0wMTod3Udw4MucE',
    iconPath: 'assets/img/header-icons/youtube.svg',
    mobileIconPath: 'icon-header-icons-mobile-header-icons-youtube',
    tabName: 'HEADER.INFO_HEADER.YOUTUBE_ONBOARDING_COURSE',
    isExternalLink: true,
  },
  {
    routerLink: [COMPLAIN_SUGGEST_URL],
    iconPath: 'icon-header-icons-feedback',
    mobileIconPath: 'icon-header-icons-mobile-header-icons-feedback',
    tabName: 'HEADER.HEADER_TABS.COMPLAIN_SUGGEST_TAB',
    mixpanelEventName: 'Go_to_complain_suggest',
  },
  {
    routerLink: [SHIPPING_INFO_URL],
    iconPath: 'assets/img/header-icons/document.svg',
    mobileIconPath: 'icon-header-icons-mobile-header-icons-document',
    tabName: 'HEADER.INFO_HEADER.SHIPPING_INFO',
    mixpanelEventName: 'Go_to_shipping_info',
  },
  {
    href: 'https://uxprogram.taager.com/',
    iconPath: 'assets/img/header-icons/ux-program',
    mobileIconPath: 'icon-header-icons-mobile-header-icons-ux-program',
    tabName: 'HEADER.HEADER_TABS.UX_PROGRAM',
    mixpanelEventName: 'ux_interviews_form_join_now_clicked',
    isExternalLink: true,
  },
];

export const SIGNOUT_BUTTON: HeaderTab = {
  /**
   * We need to calculate the user's landing, so that when they logout,
   * they will be redirected to the right path, as per their resolved
   * landing.
   */
  routerLink: [],
  iconPath: 'assets/img/account-navigation-icons/logout.svg',
  tabName: 'HEADER.PROFILE_DROPDOWN.LOGOUT',
  mixpanelEventName: 'Logout',
};

export const PRE_ORDER_REQUESTS_BUTTON: HeaderTab = {
  routerLink: [PRE_ORDER_REQUESTS_URL],
  iconPath: 'assets/img/account-navigation-icons/pre-order-requests.svg',
  mobileIconPath:
    'icon-account-navigation-icons-mobile-account-navigation-icons-pre-order-requests',
  tabName: 'HEADER.PROFILE_DROPDOWN.PRE_ORDER_REQUESTS',
  mixpanelEventName: 'Go_to_pre_order_requests',
};

export const NEW_INCENTIVE_BUTTON: HeaderTab = {
  routerLink: [INCENTIVE_URL],
  iconPath: 'assets/img/account-navigation-icons/run.svg',
  mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-incentive',
  tabName: 'HEADER.PROFILE_DROPDOWN.INCENTIVE_PROGRAM',
  mixpanelEventName: 'Go_to_incentive_program',
};

export const CUSTOM_CHECKOUT_BUTTON: HeaderTab = {
  routerLink: [PRODUCT_CHECKOUT_URL],
  iconPath: 'assets/img/account-navigation-icons/shop-checkout.svg',
  mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-incentive',
  tabName: 'HEADER.PROFILE_DROPDOWN.PRODUCT_CHECKOUT',
  mixpanelEventName: 'Go_to_product_checkout',
};

export const PROFILE_NAVIGATION_TABS: HeaderTab[] = [
  {
    routerLink: [PROFILE_URL],
    iconPath: 'assets/img/account-navigation-icons/account.svg',
    mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-account',
    tabName: 'HEADER.PROFILE_DROPDOWN.ACCOUNT',
    mixpanelEventName: 'Go_to_account',
  },
  {
    routerLink: [WALLET_URL],
    iconPath: 'assets/img/account-navigation-icons/wallet.svg',
    mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-wallet',
    tabName: 'HEADER.PROFILE_DROPDOWN.WALLET',
    mixpanelEventName: 'Go_to_wallet',
  },
  {
    routerLink: [ORDERS_URL],
    iconPath: 'assets/img/account-navigation-icons/orders.svg',
    mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-orders',
    tabName: 'HEADER.PROFILE_DROPDOWN.ORDERS',
    mixpanelEventName: 'Go_to_orders',
  },
  {
    routerLink: [LEARNING_CENTER_URL],
    iconPath: 'assets/img/account-navigation-icons/Learn.svg',
    mobileIconPath: 'icon-account-navigation-icons-mobile-account-navigation-icons-Learn',
    tabName: 'HEADER.PROFILE_DROPDOWN.LEARNING_CENTER',
    mixpanelEventName: 'learning_center_button_clicked',
  },
  SIGNOUT_BUTTON,
];

export const CART_TAB: HeaderTab = {
  routerLink: [CART_URL],
  iconPath: 'icon-header-icons-nav-cart',
  mobileIconPath: 'assets/img/header-icons/mobile-header-icons/nav-cart.svg',
  tabName: 'العربة',
  mixpanelEventName: 'Go_to_cart',
};

export const CATALOG_TAB: HeaderTab = {
  routerLink: [CATALOG_URL],
  iconPath: 'icon-header-icons-nav-catalog',
  mobileIconPath: 'assets/img/header-icons/mobile-header-icons/nav-catalog.svg',
  tabName: 'الكتالوج',
  mixpanelEventName: 'Go_to_catalog',
};

export const SHOPPING_NAVIGATION_TABS: HeaderTab[] = [CART_TAB, CATALOG_TAB];

export const PRODUCTS_TAB: HeaderTab = {
  routerLink: [PRODUCTS_V2_URL],
  tabName: 'HEADER.HEADER_TABS.PRODUCTS_TAB',
  mixpanelEventName: 'Go_to_products',
};

export const BULK_UPLOAD_TAB: HeaderTab = {
  routerLink: [CART_URL],
  iconPath: 'icon-header-icons-bulk',
  mobileIconPath: 'icon-header-icons-mobile-header-icons-bulk',
  tabName: 'HEADER.HEADER_TABS.BULK_UPLOAD_TAB',
  mixpanelEventName: 'bulk_upload_tab_clicked',
};

export const ACHIEVE_EARN_TAB: HeaderTab = {
  tabName: 'HEADER.HEADER_TABS.ACHIEVE_EARN',
  iconPath: 'icon-header-icons-bulk',
  mixpanelEventName: 'achieve_earn_tab_clicked',
  clickAction: 'open',
};

export const ACHIEVE_EARN_MENU_TABS: HeaderTab[] = [
  {
    tabName: 'HEADER.ACHIEVE_EARN_DROPDOWN.CHALLENGES',
    iconPath: 'assets/img/achieve-earn/challenges.svg',
    routerLink: [INCENTIVE_URL],
    tabFeatureFlag: WEB_DYNAMIC_INCENTIVE_PROGRAM,
    tabUserFeature: DYNAMIC_INCENTIVE_FEATURE,
    mixpanelEventName: 'achieve_and_earn_incentive_clicked',
  },
  {
    tabName: 'TIERING_SYSTEM_LANDING.TITLE',
    iconPath: 'assets/img/achieve-earn/loyalty.svg',
    routerLink: [LOYALTY_URL],
    tabFeatureFlag: LOYALTY_PROGRAM,
    tabUserFeature: LOYALTY_PROGRAM_FEATURE,
    mixpanelEventName: 'achieve_and_earn_loyalty_clicked',
  },
  {
    tabName: 'HEADER.ACHIEVE_EARN_DROPDOWN.REFERRAL',
    iconPath: 'assets/img/achieve-earn/referral.svg',
    routerLink: [REFERRAL_URL],
    tabFeatureFlag: REFERRAL_PROGRAM,
    tabUserFeature: REFERRAL_PROGRAM_FEATURE,
    mixpanelEventName: 'achieve_and_earn_referral_clicked',
    newTab: true,
  },
];

export const LOWER_HEADER_TABS: HeaderTab[] = [PRODUCTS_TAB, BULK_UPLOAD_TAB];

export const STATISTICS_TAB: HeaderTab = {
  routerLink: [STATISTICS_URL],
  iconPath: 'icon-header-icons-pie-chart',
  mobileIconPath: 'assets/img/header-icons/mobile-header-icons/pie-chart.svg',
  tabName: 'إحصائيات',
  mixpanelEventName: 'Go_to_statistics',
};

export const LOGGED_OUT_TABS: HeaderTab[] = [
  {
    routerLink: [LOGIN_URL],
    tabName: 'تسجيل دخول',
  },
  {
    routerLink: [REGISTER_URL],
    tabName: 'إنشاء حساب جديد',
  },
];

export const ORDER_SUMMARY_TAB: HeaderTab = {
  routerLink: ['/', { outlets: { popup: ['order-summary', 'currentMonth'] } }] as any,
  iconPath: 'icon-header-icons-performance-analytics',
  mobileIconPath: 'icon-header-icons-mobile-header-icons-performance-analytics',
  tabName: 'ORDER_SUMMARY.TAB_NAME',
  mixpanelEventName: 'orders_page_load',
  showActiveClass: false,
  queryParamsHandling: 'preserve',
};

export const MERCHANT_INSIGHTS_TAB: HeaderTab = {
  routerLink: [MERCHANT_INSIGHTS_URL],
  iconPath: 'icon-header-icons-performance-analytics',
  mobileIconPath: 'icon-header-icons-mobile-header-icons-performance-analytics',
  tabName: 'ORDER_SUMMARY.TAB_NAME',
  mixpanelEventName: 'merchant_insights_page_load',
};

export const PRE_ORDER_TAB: HeaderTab = {
  routerLink: [APP_URLS_CONSTANTS.preOrder],
  iconPath: 'assets/img/exclusive.svg',
  mobileIconPath: 'assets/img/exclusive.svg',
  tabName: 'PRE_ORDER_PAGE.TAB_NAME',
  mixpanelEventName: 'pre_order_tab_clicked',
};
