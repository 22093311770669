import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { OrderStatusListModel } from '../../domain/order/order-list.model';
import { OrderRepository } from '../../repositories/order.repository';

export class GetOrderStatusListUseCase implements UseCase<void, OrderStatusListModel> {
  constructor(private _orderRepository: OrderRepository) {}

  execute(): Observable<OrderStatusListModel> {
    return this._orderRepository.getOrderStatusList();
  }
}
