/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { RemoteConfigRepositoryImpl } from 'src/app/data/repositories/remote-config/remote-config-impl.repository';

interface IVersionCheckResponse {
  versionChanged: boolean;
  versionNumber?: string;
  forceReload?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(
    private http: HttpClient,
    private remoteConfigRepositoryImpl: RemoteConfigRepositoryImpl,
  ) {}

  /**
   * checks in every set frequency the version of frontend application
   *
   * @param url the url of the generated version.json file
   * @param frequency - in milliseconds, defaults to 30 minutes
   */
  public versionChange(url: string, frequency = 1000 * 60 * 30): Observable<IVersionCheckResponse> {
    return timer(0, frequency).pipe(switchMap(() => this.checkVersion(url)));
  }

  /**
   * @param hash the latest hash from the API
   * @returns a flag whether the version changed or not
   */
  async versionDidChange(hash: string, version: string): Promise<IVersionCheckResponse> {
    const FORCE_RELEASE_VERSION = await this.remoteConfigRepositoryImpl
      .getFeatureAttribute('WEB_FORCE_RELEASE_VERSION')
      .pipe(take(2))
      .toPromise();
    const forceReloadBasedOnVersionCode = FORCE_RELEASE_VERSION === version;
    if (forceReloadBasedOnVersionCode) {
      return { versionChanged: true, forceReload: true, versionNumber: version };
    }
    const hashDidChange = this.didHashChange(this.currentHash, hash);
    this.currentHash = hash;
    return { versionChanged: hashDidChange };
  }

  checkVersion(url: string): Observable<IVersionCheckResponse> {
    return this.getVersionHash(url).pipe(
      switchMap(({ hash, version }) => from(this.versionDidChange(hash, version))),
    );
  }

  /**
   * will do the call and check if the hash has changed or not
   *
   * @param url the url of the generated version.json file
   */
  private getVersionHash(url: string): Observable<any> {
    // timestamp these requests to invalidate caches
    return this.http.get(`${url}?t=${new Date().getTime()}`);
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   *
   * @param currentHash the version hash
   * @param newHash the new app version hash
   * @returns a flag whether it changed or not
   */
  private didHashChange(currentHash: any, newHash: any): boolean {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }

    return currentHash !== newHash;
  }
}
