import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { RemoteConfigRepository } from '../repositories/remote-config.repository';

export class GetFeatureAttributeUsecase implements UseCase<string, any> {
  constructor(private remoteConfigRepository: RemoteConfigRepository) {}

  execute(featureAttributeName: string): Observable<any> {
    return this.remoteConfigRepository.getFeatureAttribute(featureAttributeName);
  }
}
