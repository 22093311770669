import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlainLayoutService {
  private _showCloseButton: Subject<boolean> = new Subject<boolean>();

  private _confirmationDialogState: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) {}

  public getShowCloseButton() {
    return this._showCloseButton;
  }

  public setCloseButton(status: boolean) {
    this._showCloseButton.next(status);
  }

  public getConfirmationDialogState() {
    return this._confirmationDialogState;
  }

  public setConfirmationDialogState(opened: boolean) {
    this._confirmationDialogState.next(opened);
  }
}
