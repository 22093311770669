import { UseCase } from '../../base/use-case';
import { ProductsRepository } from '../../repositories/products.repository';

export class CheckUserNewExperienceEligibilityUseCase implements UseCase<void, boolean> {
  constructor(private _productsRepository: ProductsRepository) {}

  execute() {
    return this._productsRepository.checkUserNewExperienceEligibility();
  }
}
