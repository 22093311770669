import {
  Directive,
  EmbeddedViewRef,
  inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[appCountdownToInteractivity]',
  standalone: true,
})
export class CountdownToInteractivityDirective {
  @Input() private countdownInSeconds: number;

  @Input() private countdownElement: TemplateRef<HTMLElement>;

  @Input() data: any;

  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  private _embeddedViewRef: EmbeddedViewRef<HTMLElement>;

  ngOnInit(): void {
    this._initializeStructure();
    this._listenForCountdown();
  }

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
    this._embeddedViewRef.destroy();
  }

  private _initializeStructure(): void {
    this._updateHost(this.data);
  }

  private _listenForCountdown(): void {
    setTimeout(() => {
      this.data.showCountdown = false;
      this._updateHost(this.data);
    }, this.countdownInSeconds * 1000);
    setInterval(() => {
      this.data.currentTime -= 1;
    }, 1000);
  }

  private _updateHost(data: any): void {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    const context: any = { $implicit: data };
    this._embeddedViewRef = this.viewContainerRef.createEmbeddedView(
      this.countdownElement,
      context,
    );
  }
}
