/**
 * Okay, here, the assumption is that we will only be evoking feedback
 * for one feature per page.
 *
 * For example, when we are in the products page, we will only evoke
 * feedback for stock availability.
 *
 * We currently DON'T have the flexibility for more than one feeback
 * per page, rather A LIMIT ON ONE FEEDBACK PER PAGE
 */
export const QUESTIONNAIRE_LOCATION_MAP: { [routePath: string]: string } = {
  products: 'product-discover-ability-service',
  productDetails: 'stock-availability-feature',
  orders: 'orders-tracking-service',
  cart: 'orders-placement-service',
  insights: 'merchant-insights',
};
