import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { IncentiveProgramModel } from '../domain/incentive-program.model';
import { IncentiveProgramRepository } from '../repositories/incentive-program.repository';

export class GetIncentiveProgramDetailsUseCase implements UseCase<void, IncentiveProgramModel> {
  constructor(private incentiveProgramRepository: IncentiveProgramRepository) {}

  execute(): Observable<IncentiveProgramModel> {
    return this.incentiveProgramRepository.getIncentiveProgramDetails();
  }
}
