import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { UserModel } from '../../domain/user/user.model';
import { UserRepository } from '../../repositories/user.repository';

export class GetUserProfileUseCase implements UseCase<void, { data: UserModel }> {
  constructor(private _userRepository: UserRepository) {}

  execute(): Observable<{ data: UserModel }> {
    return this._userRepository.getUserProfile();
  }
}
