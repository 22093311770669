import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, startWith, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private hamburgerIconClicked = new BehaviorSubject<boolean>(false);

  private shoppingItemCountChanged = new Subject<void>();

  emitOpenSideNavBar(isClicked: boolean): void {
    this.hamburgerIconClicked.next(isClicked);
  }

  hamburgerIconClickedListener(): Observable<boolean> {
    return this.hamburgerIconClicked.asObservable();
  }

  emitshoppingItemCountChanged(): void {
    this.shoppingItemCountChanged.next();
  }

  shoppingItemsCountChangedListener(): Observable<void> {
    return this.shoppingItemCountChanged.pipe(startWith(undefined));
  }
}
