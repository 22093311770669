import { RequestEmailOTPUseCase } from 'app/core/usecases/auth/request-email-otp.usecase';
import { VerifyEmailOTPUseCase } from 'app/core/usecases/auth/verify-email.usecase';
import { AnalyticsRepository } from 'src/app/core/repositories/analytics.repository';
import { AuthRepository } from 'src/app/core/repositories/auth.repository';
import { ChangePasswordUseCase } from 'src/app/core/usecases/auth/change-password.usecase';
import { ForgotPasswordUseCase } from 'src/app/core/usecases/auth/forgot-password.usecase';
import { GetRegisterTokenUseCase } from 'src/app/core/usecases/auth/get-register-token.usecase';
import { LoginUserWithGoogleUseCase } from 'src/app/core/usecases/auth/login-user-with-google.usecase';
import { LoginAuthUserUseCase } from 'src/app/core/usecases/auth/login-user.usecase';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { RegisterUseCase } from 'src/app/core/usecases/auth/register.usecase';
import { RequestOTPUseCase } from 'src/app/core/usecases/auth/request-otp.usecase';
import { ResetPasswordUseCase } from 'src/app/core/usecases/auth/reset-password.usecase';
import { SetRegisterTokenUseCase } from 'src/app/core/usecases/auth/set-register-token.usecase';
import { CompleteUserProfileUseCase } from 'src/app/core/usecases/auth/update-user-profile.usecase';
import { VerifyPhoneNumberUseCase } from 'src/app/core/usecases/auth/verify-phone.usecase';
import { AnalyticsRepositoryInjectionToken } from '../injection-tokens/analytics-repository.injection-token';
import { authInjectionToken } from '../injection-tokens/auth-repository.injection-token';

const LoginUseCaseFactory = (authRepository: AuthRepository): LoginAuthUserUseCase =>
  new LoginAuthUserUseCase(authRepository);
export const loginAuthUserUseCaseProvider = {
  provide: LoginAuthUserUseCase,
  useFactory: LoginUseCaseFactory,
  deps: [authInjectionToken],
};

const loginUserWithGoogleUseCaseFactory = (
  authRepository: AuthRepository,
): LoginUserWithGoogleUseCase => new LoginUserWithGoogleUseCase(authRepository);

export const loginUserWithGoogleUseCaseProvider = {
  provide: LoginUserWithGoogleUseCase,
  useFactory: loginUserWithGoogleUseCaseFactory,
  deps: [authInjectionToken],
};

const RegisterUseCaseFactory = (authRepository: AuthRepository): RegisterUseCase =>
  new RegisterUseCase(authRepository);
export const registerUseCaseProvider = {
  provide: RegisterUseCase,
  useFactory: RegisterUseCaseFactory,
  deps: [authInjectionToken],
};

const RequestOTPUseCaseFactory = (authRepository: AuthRepository): RequestOTPUseCase =>
  new RequestOTPUseCase(authRepository);
export const requestOTPUseCaseProvider = {
  provide: RequestOTPUseCase,
  useFactory: RequestOTPUseCaseFactory,
  deps: [authInjectionToken],
};

const RequestEmailOTPUseCaseFactory = (authRepository: AuthRepository): RequestEmailOTPUseCase =>
  new RequestEmailOTPUseCase(authRepository);
export const requestEmailOTPUseCaseProvider = {
  provide: RequestEmailOTPUseCase,
  useFactory: RequestEmailOTPUseCaseFactory,
  deps: [authInjectionToken],
};

const VerifyPhoneNumberUseCaseFactory = (
  authRepository: AuthRepository,
): VerifyPhoneNumberUseCase => new VerifyPhoneNumberUseCase(authRepository);
export const verifyPhoneNumberUseCaseProvider = {
  provide: VerifyPhoneNumberUseCase,
  useFactory: VerifyPhoneNumberUseCaseFactory,
  deps: [authInjectionToken],
};

const VerifyEmailOTPUseCaseFactory = (authRepository: AuthRepository): VerifyEmailOTPUseCase =>
  new VerifyEmailOTPUseCase(authRepository);
export const verifyEmailOTPUseCaseProvider = {
  provide: VerifyEmailOTPUseCase,
  useFactory: VerifyEmailOTPUseCaseFactory,
  deps: [authInjectionToken],
};

const completeUserProfileUseCaseFactory = (
  authRepository: AuthRepository,
): CompleteUserProfileUseCase => new CompleteUserProfileUseCase(authRepository);
export const completeUserProfileUseCaseProvider = {
  provide: CompleteUserProfileUseCase,
  useFactory: completeUserProfileUseCaseFactory,
  deps: [authInjectionToken],
};

const logoutUseCaseFactory = (
  authRepository: AuthRepository,
  analyticsRepository: AnalyticsRepository,
): LogoutUseCase => new LogoutUseCase(authRepository, analyticsRepository);
export const logoutUseCaseProvider = {
  provide: LogoutUseCase,
  useFactory: logoutUseCaseFactory,
  deps: [authInjectionToken, AnalyticsRepositoryInjectionToken],
};

const forgotPasswordUseCaseFactory = (authRepository: AuthRepository): ForgotPasswordUseCase =>
  new ForgotPasswordUseCase(authRepository);

export const forgotPasswordUseCaseProvider = {
  provide: ForgotPasswordUseCase,
  useFactory: forgotPasswordUseCaseFactory,
  deps: [authInjectionToken],
};

const resetPasswordUseCaseFactory = (authRepository: AuthRepository): ResetPasswordUseCase =>
  new ResetPasswordUseCase(authRepository);
export const resetPasswordUseCaseProvider = {
  provide: ResetPasswordUseCase,
  useFactory: resetPasswordUseCaseFactory,
  deps: [authInjectionToken],
};

const changePasswordUseCaseFactory = (authRepository: AuthRepository): ChangePasswordUseCase =>
  new ChangePasswordUseCase(authRepository);

export const changePasswordUseCaseProvider = {
  provide: ChangePasswordUseCase,
  useFactory: changePasswordUseCaseFactory,
  deps: [authInjectionToken],
};

const setRegisterTokenUseCaseFactory = (_authRepository: AuthRepository): SetRegisterTokenUseCase =>
  new SetRegisterTokenUseCase(_authRepository);
export const setRegisterTokenUseCaseProvider = {
  provide: SetRegisterTokenUseCase,
  useFactory: setRegisterTokenUseCaseFactory,
  deps: [authInjectionToken],
};

const getRegisterTokenUseCaseFactory = (_authRepository: AuthRepository): GetRegisterTokenUseCase =>
  new GetRegisterTokenUseCase(_authRepository);
export const getRegisterTokenUseCaseProvider = {
  provide: GetRegisterTokenUseCase,
  useFactory: getRegisterTokenUseCaseFactory,
  deps: [authInjectionToken],
};
