import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CancelOrderModel } from 'src/app/core/domain/order/cancel-order.model';
import {
  ChildOrdersFilterModel,
  ChildOrdersResultModel,
} from 'src/app/core/domain/order/child-orders-filter.model';
import { FailedOrdersResponseModel } from 'src/app/core/domain/order/failed-orders.model';
import {
  OrderActivityResponseModel,
  OrderActivityWithStatusModel,
  OrderActivityWithStatusResponseModel,
} from 'src/app/core/domain/order/order-activity-with-status.model';
import { OrderStatusListModel } from 'src/app/core/domain/order/order-list.model';
import {
  OrderProductListModel,
  ProductPriceOrderModel,
} from 'src/app/core/domain/order/product-price.model';
import { RateOrderModel } from 'src/app/core/domain/order/rate-order.model';
import { SharedService } from 'src/app/presentation/shared/services/shared.service';
import {
  CalculateOrderCostRequestModel,
  CalculateOrderCostResponseModel,
  OrderListParamModel,
  OrderModel,
  OrdersFilter,
  OrdersListModel,
} from '../../../core/domain/order-model';
import { OrdersTracking } from '../../../core/domain/orders-tracking.model';
import { OrderRepository } from '../../../core/repositories/order.repository';
import { ProfileService } from '../../../presentation/shared/services/profile.service';
import { CancelOrderEntity } from './entities/cancel-order.entity';
import { OrderEntity } from './entities/order.entity';
import { CalculateOrderCostRequestMapper } from './mappers/calculate-order-cost-response.mapper';
import { FailedOrderMapper } from './mappers/failed-order.mapper';
import { OrdersFilterMapper } from './mappers/orders-filter-mapper';
import { OrderApisService } from './order-apis.service';
import { OrdersTrackingRemoteMapper } from './remote/mappers/orders-tracking-remote-mapper';

@Injectable({
  providedIn: 'root',
})
export class OrderRepositoryImpl implements OrderRepository {
  private _calculateOrderCostRequestMapper = new CalculateOrderCostRequestMapper();

  private _failedOrderReasonMapper = new FailedOrderMapper();

  constructor(
    private _profileService: ProfileService,
    private _ordersFilterMapper: OrdersFilterMapper,
    private _ordersTrackingMapper: OrdersTrackingRemoteMapper,
    private _orderApisService: OrderApisService,
    private _sharedService: SharedService,
  ) {}

  getFailedOrders(page: number, pageSize: number): Observable<FailedOrdersResponseModel> {
    return this._orderApisService.getFailedOrders(page, pageSize).pipe(
      map((requests) => ({
        count: requests.count,
        failedOrders: requests.failedOrders.map(this._failedOrderReasonMapper.mapFrom),
      })),
    );
  }

  getChildOrders(childOrdersFilter: ChildOrdersFilterModel): Observable<ChildOrdersResultModel> {
    return this._orderApisService.getChildOrders(childOrdersFilter);
  }

  cancelOrder(cancelOrder: CancelOrderModel): Observable<void> {
    return this._orderApisService.cancelOrder(cancelOrder as CancelOrderEntity);
  }

  getOrderActivityWithStatus(
    filter: OrderActivityWithStatusModel,
  ): Observable<OrderActivityWithStatusResponseModel> {
    return this._orderApisService.getOrderActivityWithStatus(filter);
  }

  rateOrder(rateOrder: RateOrderModel): Observable<void> {
    return this._orderApisService.rateOrder(rateOrder);
  }

  getOrdersTracking(filter: OrdersFilter): Observable<OrdersTracking> {
    return this._profileService
      .getUserOrders(filter.pageSize!, filter.pageNum!, this._ordersFilterMapper.mapFrom(filter))
      .pipe(map((response) => this._ordersTrackingMapper.mapFrom(response)));
  }

  calculateOrderPrices(
    orderData: CalculateOrderCostRequestModel,
  ): Observable<CalculateOrderCostResponseModel> {
    return this._orderApisService.calculateOrderPrices(
      this._calculateOrderCostRequestMapper.mapTo(orderData),
    );
  }

  makeOrderByCart(order: OrderModel): Observable<{ order: { orderID: string } }> {
    return this._orderApisService.makeOrderByCart(order as OrderEntity).pipe(
      finalize(() => {
        this._sharedService.emitshoppingItemCountChanged();
      }),
    );
  }

  getOrderProductPrices(productList: OrderProductListModel): Observable<ProductPriceOrderModel> {
    return this._orderApisService.getOrderProductPrices(productList);
  }

  getOrdersList(filter: OrderListParamModel): Observable<OrdersListModel> {
    return this._orderApisService.getOrdersList(filter);
  }

  trackOrderActivity(filter: OrderActivityWithStatusModel): Observable<OrderActivityResponseModel> {
    return this._orderApisService.trackOrderActivity(filter);
  }

  getOrderStatusList(): Observable<OrderStatusListModel> {
    return this._orderApisService.getOrderStatusList();
  }
}
