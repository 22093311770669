// Requested product quantity for preorder should be
// Wallet amount is insufficient
// super(`Product ${productId} is not available`);
const WAIT_LIST_REASON = {
  en: 'You are on a wait list for this SKU. You may consider raising a preorder request on another SKU.',
  ar: 'أنت في قائمة انتظار لهذا المنتج. يمكنك تقديم طلب مسبق على منتج آخر.',
};
const PRDOUCT_NOT_AVAILABLE_REASON = {
  en: 'Unfortunately the SKU is not available immediately. We will notify you once the SKU is available.',
  ar: 'هذا المنتج غير متوفر حاليا. سنقوم بإخطارك بمجرد توفره.',
};
const FULL_QUANTITY_NOT_AVAILABLE_REASON = {
  en: 'Please start a new pre-order request for X quantity of the product. The previously locked wallet balance will be unlocked.',
  ar: 'يرجى تقديم طلب جديد لكمية X من المنتج. وسيتم إعادة المبلغ المحجوز مسبقا إلي رصيد محفظتك.',
};

const PRICE_CHANGE_REASON = {
  en: 'The price of the product has changed. We will notify you once we update the price. You may choose to raise a new pre-order request. The previously locked wallet balance will be unlocked.',
  ar: 'لقد تغير سعر المنتج. سنخطرك بمجرد تحديث السعر. يمكنك تقديم طلب جديد وسيتم إعادة المبلغ المحجوز مسبقا إلي رصيد محفظتك.',
};
const PRDOUCT_NO_LONGER_AVAILABLE_REASON = {
  en: 'Your request cannot be processed as the product is no more available.',
  ar: 'المنتج لم يعد متوفرًا.',
};

export const translateRequestRejectionReason = (reason: string): string => {
  switch (reason) {
    case WAIT_LIST_REASON.en:
      return WAIT_LIST_REASON.ar;
    case PRDOUCT_NOT_AVAILABLE_REASON.en:
      return PRDOUCT_NOT_AVAILABLE_REASON.ar;
    case PRICE_CHANGE_REASON.en:
      return PRICE_CHANGE_REASON.ar;
    case PRDOUCT_NO_LONGER_AVAILABLE_REASON.en:
      return PRDOUCT_NO_LONGER_AVAILABLE_REASON.ar;
    default:
      break;
  }
  // check if the reason contains digits (available quantity)
  const reasonContainsDigitsMatch = reason.match(/(\d+)/);
  if (reasonContainsDigitsMatch) {
    // fetching the quantity from the reason string
    const availableQuantity = reasonContainsDigitsMatch[0];
    // replacing the quantity with X to compare it with the english reason
    const matchingReason = reason.split(availableQuantity).join('X');
    if (availableQuantity && matchingReason === FULL_QUANTITY_NOT_AVAILABLE_REASON.en) {
      // replacing the X with the quantity from the english reason to the arabic reason
      return FULL_QUANTITY_NOT_AVAILABLE_REASON.ar.replace('X', availableQuantity);
    }
  }
  return reason;
};
