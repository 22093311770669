import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { ALL_PRODUCTS_CATEGORY } from 'src/app/presentation/shared/constants';
import { SORT_BY_CLOSEST_MATCH } from 'src/app/presentation/shared/constants/category-products';
import { InternetCheckService } from 'src/app/presentation/shared/services/internetCheck.service';
import { ThemeService } from 'src/app/presentation/shared/services/theme.service';

@Component({
  selector: 'app-products-search-bar',
  templateUrl: './products-search-bar.component.html',
  styleUrls: ['./products-search-bar.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, AsyncPipe, NgClass],
})
export class ProductsSearchBarComponent implements OnInit {
  public productSearchForm: UntypedFormGroup;

  darkModeClass$: Observable<string>;

  constructor(
    private router: Router,
    private internetCheckService: InternetCheckService,
    private _themeService: ThemeService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.darkModeClass$ = this._themeService
      .isDarkMode()
      .pipe(map((isDarkMode) => (isDarkMode ? 'dark-mode' : 'light-mode')));
  }

  ngOnInit(): void {
    this.productSearchForm = new UntypedFormGroup({
      searchKey: new UntypedFormControl('', [Validators.required]),
    });
  }

  onSearchProducts(): void {
    const queryParams = {
      q: this.productSearchForm.value.searchKey,
      category: ALL_PRODUCTS_CATEGORY,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_CLOSEST_MATCH,
    };
    this.productSearchForm.reset();
    let onlineStatus: boolean;
    this.internetCheckService.createOnline$().subscribe({
      next: (res) => {
        onlineStatus = res;
      },
    });
    if (onlineStatus!) {
      this.router.navigate([this._appURLs.CATEGORY_PRODUCTS_URL], { queryParams });
    } else {
      this.router.navigate(['products', 'no-search-results'], {
        queryParams: { searchKey: 'internet-offline' },
      });
    }
  }
}
