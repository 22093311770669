import { YOUCAN_PROVIDER_NAME, ZID_PROVIDER_NAME } from 'src/app/presentation/shared/constants';
import { environment } from 'src/environments/environment';

export const getStoreRedirectUri = (provider: string): string | undefined => {
  switch (provider) {
    case ZID_PROVIDER_NAME:
      return environment.ZID_AUTHORIZE_URL;
    case YOUCAN_PROVIDER_NAME:
      return environment.YOUCAN_AUTHORIZE_URL;
  }
};
