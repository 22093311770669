/**
 * Names for selecting our UI pieces
 */

/**
 * Selects the current active user step index
 */
export const SelectCurrentActiveUserStepIndex =
  'Select current active user step index from the state manager';

/**
 * Selects the reward type for this user
 */
export const SelectRewardTypeForUse = 'Select the reward type which this user should be getting';

/**
 * Select whether the user has completed the onboarding journey
 */
export const SelectUserOnboardingCompletionStatus =
  'Select whether the user has completed the onboarding journey or not';
