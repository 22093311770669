import { BaseRepository, CACHE_FOR_ONE_HOUR_IN_SECONDS } from '@features/shared/data';
import cache from '@mongez/cache';
import { type GenericObject } from '@mongez/reinforcements';
import { environment } from 'environment';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getAll, getRemoteConfig } from 'firebase/remote-config';
import { type FeaturesListRepositoryContract } from '../../domain';
import { FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY } from '../constants';
import { mapFirebaseRemoteConfigFeatures } from '../mappers';

export class FirebaseRemoteConfigRepository
  extends BaseRepository
  implements FeaturesListRepositoryContract
{
  public async list(): Promise<GenericObject> {
    const cachedFeatures: string[] = cache.get(FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY);

    if (cachedFeatures) {
      return cachedFeatures;
    }

    const config = environment.firebase;

    // initialize Firebase
    const app = await initializeApp(config);

    const remoteConfig = getRemoteConfig(app);

    await fetchAndActivate(remoteConfig);

    // now we can use remoteConfig.getBoolean('feature_name') to check if a feature is enabled
    const featuresList = getAll(remoteConfig);

    const features = mapFirebaseRemoteConfigFeatures(featuresList);

    cache.set(FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY, features, CACHE_FOR_ONE_HOUR_IN_SECONDS);

    return features;
  }
}
