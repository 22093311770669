/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { user } from '@features/user/data';
import { Observable, of } from 'rxjs';
import { UserLocationRepository } from 'src/app/core/repositories/user-location.repository';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GEO_TRACKING_FUNCTION_NAMES } from 'src/app/presentation/shared/constants';
import { COUNTRIES, TIMEZONES } from 'src/app/presentation/shared/constants/location.constants';
import { getUserIPAddressUtility } from 'src/app/presentation/shared/utilities/get-user-ip-address.utility';

@Injectable({
  providedIn: 'root',
})
export class UserLocationRepositoryImplementation implements UserLocationRepository {
  constructor(private _logMixpanelEventUseCase: LogMixpanelEventUseCase) {}

  getUserCountry(): Observable<string> {
    return of(this._resolveLocationInformation(GEO_TRACKING_FUNCTION_NAMES.getCountryName));
  }

  getUserIPAddress(): Observable<string> {
    return new Observable((subscriber) => {
      getUserIPAddressUtility().then((ipAddress) => {
        subscriber.next(ipAddress);
      });
    });
  }

  private _resolveLocationInformation(functionToResolve: string): string {
    const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      switch (functionToResolve) {
        case GEO_TRACKING_FUNCTION_NAMES.getCountryName:
          return this._doDecodeCountry(detectedTimeZone);
      }
    } catch {
      this._logMixpanelEventUseCase.execute({
        eventName: 'failed_location_tracking',
        payload: {
          'Taager ID': user.id,
          attemptedAction: functionToResolve,
        },
      });
      return 'Unknown';
    }
    return 'Unknown';
  }

  private _doDecodeCountry(detectedTimeZone: string | undefined): string {
    if (detectedTimeZone === '' || !detectedTimeZone) {
      return 'Unknown';
    }

    return COUNTRIES[TIMEZONES[detectedTimeZone].c[0]];
  }
}
