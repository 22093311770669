import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { APIKeysGetModel } from '../../domain/api-keys-integration.model';
import { MerchantStoreRepository } from '../../repositories/merchant-store.repository';

export class GetAPIKeysUseCase implements UseCase<void, Observable<APIKeysGetModel[]>> {
  constructor(private _merchantStoreRepository: MerchantStoreRepository) {}

  execute(): Observable<APIKeysGetModel[]> {
    return this._merchantStoreRepository.getAPIKeys();
  }
}
