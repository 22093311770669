import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { DynamicIncentiveProgramModel } from '../../domain/dynamic-incentive-program.model';
import { DynamicIncentiveProgramRepository } from '../../repositories/dynamic-incentive-program.repository';

export class GetDynamicIncentiveProgramUseCase
  implements UseCase<void, DynamicIncentiveProgramModel[]>
{
  constructor(private _repository: DynamicIncentiveProgramRepository) {}

  execute(): Observable<DynamicIncentiveProgramModel[]> {
    return this._repository.getDynamicIncentiveProgram();
  }
}
