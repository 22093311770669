export type Direction = 'ltr' | 'rtl';

export function isLTR(): boolean {
  return document.documentElement.dir === 'ltr';
}

export function isRTL(): boolean {
  return document.documentElement.dir === 'rtl';
}

/**
 * Get current page direction
 */
export function direction(): Direction {
  return document.documentElement.dir as Direction;
}

export function changePageDirectionByLocaleCode(localeCode: string): Direction {
  const pageDirection = localeCode === 'en' ? 'ltr' : 'rtl';

  document.documentElement.dir = pageDirection;

  document.documentElement.lang = localeCode;

  return pageDirection;
}
