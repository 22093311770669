import { BaseRepository } from '@features/shared';
import { user } from '@features/user/data';
import {
  LockedTestableProductListData,
  LockedTestableProductsRepositoryContract,
} from '../../domain';
import { lockedTestableProductsAPI } from '../constants';
import { mapLockedTestableProductsData } from '../mappers';
import {
  LockedTestableProductFilterModel,
  LockedTestableProductListModel,
  MerchantTestableProductRelationModel,
} from '../models';

export class LockedTestableProductsRepository
  extends BaseRepository
  implements LockedTestableProductsRepositoryContract
{
  public async lockedTestableProductsList(
    params: LockedTestableProductFilterModel,
  ): Promise<LockedTestableProductListData> {
    const { data } = await this.http.get<LockedTestableProductListModel>(
      lockedTestableProductsAPI.lockedTestableProductsList,
      {
        params,
      },
    );

    const mappedData: LockedTestableProductListData = {
      count: data.count,
      data: data.data.map(mapLockedTestableProductsData),
    };

    return mappedData;
  }

  public async merchantTestableProductRelation(
    productId: string,
  ): Promise<MerchantTestableProductRelationModel> {
    const userId = user.id;

    const { data } = await this.http.get<MerchantTestableProductRelationModel>(
      lockedTestableProductsAPI.merchantTestableProductRelation(userId, productId),
    );

    return data;
  }
}
