import { Observable } from 'rxjs';
import { UseCase } from '../../base/use-case';
import { WalletModel } from '../../domain/wallet/wallet.model';
import { WalletRepository } from '../../repositories/wallet.repository';

export class GetWalletsUseCase implements UseCase<void, Array<WalletModel>> {
  constructor(private _walletRepository: WalletRepository) {}

  execute(): Observable<WalletModel[]> {
    return this._walletRepository.getWallets();
  }
}
