import { UseCase } from '../../base/use-case';
import { UserModel } from '../../domain/user/user.model';
import { UserRepository } from '../../repositories/user.repository';

export class GetUserUseCase implements UseCase<void, UserModel | undefined> {
  constructor(private userRepository: UserRepository) {}

  execute(params: void): UserModel | undefined {
    return this.userRepository.getUser();
  }
}
