import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LearningRemoteEntity } from './entities/learning-remote-entity';
import { LearningProvider } from './learning-provider';

@Injectable({
  providedIn: 'root',
})
export class LearningApi {
  constructor(private provider: LearningProvider) {}

  getLearning(): Observable<LearningRemoteEntity> {
    return of(this.provider.getLearning());
  }
}
