import { UseCase } from '../../base/use-case';
import { ForgotPasswordFormModel } from '../../domain/auth/forgot-password.model';
import { AuthRepository } from '../../repositories/auth.repository';

export class ForgotPasswordUseCase implements UseCase<ForgotPasswordFormModel, void> {
  constructor(private _authRepository: AuthRepository) {}

  execute(params: ForgotPasswordFormModel) {
    return this._authRepository.forgotPassword(params);
  }
}
